import React, { useState, useContext } from 'react';
import './NodeConnection.css';
import { DragDropProvider, DragEventHandler, EventPoint } from '../drag.service';
import { InputConnectorLocation } from '../connector/InputConnectorComponent';
import { Pos } from '../../position';
import { generateManualRelativePath } from './path';
import { Connection } from '../../circuitStructure';
import { DiagramActions } from '../builder/DiagramBuilder';
import { InputConnectorState } from 'diagram/connectorState';

/*
    Represent both a possible connection and an actual connection
*/
export function NodeConnection(props: {
    connection?: Connection;
    inputConnector: InputConnectorState;
    inputConnectorComponent?: InputConnectorLocation;
    selectedConnector: InputConnectorState | undefined;
    diagramActions: DiagramActions;
}) {
    const isSelected = props.inputConnector === props.selectedConnector;

	const [state, setState] = useState(()=>{
		return {
			manualPos: undefined as undefined | Pos,
			arrowHoverState: false,
		};
	});

    const elementRef = React.createRef<SVGGElement>();
    const dragContext = useContext(DragDropProvider);

    function deleteConnection() {
        props.inputConnector.deleteConnection();
        props.diagramActions.disconnect(props.inputConnector);
    }

    function getTriangleClientPos() {
        const rootElem = elementRef.current as Element;
        const triangle = rootElem.querySelector('.triangle') as SVGPolygonElement;
        return triangle.getBoundingClientRect();
    }

    /* drag of the arrowhead */
    function arrowOverlayMouseDown(event: React.MouseEvent) {
        event.stopPropagation();
        connectionDragStart(event, event.nativeEvent);
    }

    // drag of the connection line
    function connectionDragStart(point: EventPoint, event: Event) {
        // offestX/Y is not consistent for SVG so we need to use clientXY
        // we find position relative to the triangle

        // clientXY is the point relative to view-port
        // we need to find the point relative to the connector triangle
        // (Important not to look at the connection container, since it will grow to accommodate the line!)
        const triangleClientPos = getTriangleClientPos();
        const startPos = new Pos(point.clientX - triangleClientPos.left - 10, point.clientY - triangleClientPos.top - 10);

        const handler: DragEventHandler = {
            onDragMove: (deltaX, deltaY) => {
                // wait until first move
                // delete the current connection
                deleteConnection();
                setState({...state, manualPos: startPos.add(deltaX, deltaY)})
                props.diagramActions.clickConnectStart(props.inputConnector);
            },
            onDrop: (_target) => {
                /* dropping a connection on an output connector
                    is handled by the output conenctor in the 'drop' event.
                    So we dont need to do anything here.
                */
            },
            onDragEnd: () => {
                // whether connected or not, we reset the manual arrow
                setState({...state, manualPos: undefined});
            },
            hasDropTarget(elem) {
                if (elem.classList.contains('output-connector-droptarget')) {
                    return elem;
                }
                return null;
            }
        };
        dragContext.startDrag('connection', point, event, handler);
    }

    // click on mobile
    function touchend(event: React.TouchEvent) {
        toggleConnector();
        event.preventDefault();
    }

    function click(event: React.MouseEvent) {
        toggleConnector();
        event.preventDefault();
    }

    function toggleConnector() {
        if (!isSelected) {
            props.diagramActions.clickConnectStart(props.inputConnector);
        } else {
            props.diagramActions.cancelConnectMode();
        }
    }

    /* manual path when dragging arrow */
    let manualPath;
    if (!state.manualPos) {
        manualPath = '';
    } else {
        // connected to an output connector
        // adjust to get bottom middle point
        const pos = state.manualPos.add(10, 24);
        if (props.inputConnectorComponent) {
            manualPath = generateManualRelativePath(pos, props.inputConnectorComponent);
        } else {
            manualPath = '';
        }
    }

    /* simulate :hover (which does not work correctly with touch) */
    function arrowOverlayMouseEnter(_event: React.MouseEvent) {
        // We dont mark arrow hover when in selection mode
        if (!props.selectedConnector) {
            setState({...state, arrowHoverState: true});
        }
    }
    function arrowOverlayMouseLeave(_event: React.MouseEvent) {
        setState({...state, arrowHoverState: false});
    }
	const arrowHoverState = state.arrowHoverState;

	return (<g ref={elementRef} className={'connection' + (arrowHoverState ? ' arrow-hover' : '') + (isSelected ? ' touch-connected-selected' : '')}>

    <polygon points='10,20 4,28 16,28' className='triangle' />
    <polygon points='10,16 0,30 20,30' className={'triangle-outline' + (arrowHoverState ? ' hover' : '')} />
    <rect x='-2' y='2' width='24' height='30'
        onClick={click}
        onTouchEnd={touchend}
        onMouseDown={arrowOverlayMouseDown}
        onMouseEnter={arrowOverlayMouseEnter}
        onMouseLeave={arrowOverlayMouseLeave}
        className='event-overlay' />
        {manualPath &&
            <path d={manualPath} className='connection-line manual-drag' />}
    </g>
);
}

