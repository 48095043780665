export class ErrorResult {
    constructor(public message: string) {}
}
export type Option<T> = T | ErrorResult;

/* Implemented by tokens, indicate a particular position in source code. */
export interface SourceContext {
    startCharacterIndex: number;
}

/* Marker for the source context at the end of the source. (Used for 'unexpected end of file' errors)  */
export class EndOfSource {
    startCharacterIndex = -1;
}

export class SourcedErrorResult extends ErrorResult {
    constructor(public message: string, public token: SourceContext) {
        super(message);
    }
    get characterIndex() {
        return this.token.startCharacterIndex;
    }
}
