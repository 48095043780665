import { ILine } from 'ide/editor/iEditorBackend';
import { Macro } from './instructionProvider';
import { AInstruction, Instruction } from './instructions';
import { NumericToken, AssemblerToken } from './scanner';

// Represent a line of assembler source
export abstract class AsmSyntax implements ILine {
    isValid = true;
    errorText: string | undefined;
    abstract lineType: string;
    abstract line: string;

    constructor(public readonly tokens: AssemblerToken[]) {
        if (tokens.find(t => !t.isValid)) {
            this.setError('Syntax error');
        }
    }
    get lineOffset() {
        return this.tokens[0]!.location.lineOffset;
    }
    get lineNumber() {
        return this.tokens[0]!.location.lineNumber;
    }
    setError(msg: string) {
        this.errorText = msg;
        this.isValid = false;
    }
}

// An instruction occupies an address
// (as opposed to comments, lables etc. which are only assembler-level)
/* Need to be an abstract class since we check for instances at runtime */
export abstract class AsmInstructionSyntax extends AsmSyntax {
    address: number | undefined;
    abstract instruction: Instruction;
}

export class UnknownAsm extends AsmSyntax {
    readonly lineType = 'unknown';
    readonly content: string;
    constructor(readonly line: string, tokens: AssemblerToken[], errorText: string) {
        super(tokens);
        this.setError(errorText);
        this.content = line;
    }
}

export class BlankLineAsm extends AsmSyntax {
    readonly lineType = 'blank';
    constructor(readonly line: string, tokens: AssemblerToken[]) {
        super(tokens);
    }
}

export class AsmComment extends AsmSyntax {
    readonly lineType = 'comment';
    readonly content: string;
    constructor(readonly line: string, tokens: AssemblerToken[]) {
        super(tokens);
        this.content = line;
    }
}

export class AsmComputation extends AsmInstructionSyntax {
    readonly lineType = 'computation';
    constructor(readonly line: string, tokens: AssemblerToken[], public instruction: Instruction) {
        super(tokens);
    }
}


/* Identfier is either a label or a macro
 *
 * The labelReference is assigned in a second pass (since it could be defined)
 * and the reference is resolved to an address in a third pass
 */
export class AsmLoadLabelInstruction extends AsmInstructionSyntax {
    readonly lineType = 'load';
    instruction: Instruction = new AInstruction(0);
    reference: Definition | undefined;
    readonly identifier;
    constructor(
        readonly line: string,
        readonly identifierToken: AssemblerToken,
        tokens: AssemblerToken[]
    ) {
        super(tokens);
        this.identifier = identifierToken.value;
    }
    resolveReference() {
        if (this.reference) {
            const value = this.reference.resolve();
            if (value) {
                this.instruction = new AInstruction(value);
            }
        }
    }

    get referencedAddress() {
        return this.instruction.toWord();
    }

    setIdentifierError(msg: string) {
        this.setError(msg);
        // mark identifier token as error
        this.identifierToken.isValid = false;
    }
}

/* Loads a definition or placeholder
    (A = <foo>)
 */
export class AsmLoadDefinitionInstruction extends AsmInstructionSyntax {
    readonly lineType = 'load';
    readonly identifier;
    readonly instruction;
    constructor(readonly line: string, readonly identifierToken: AssemblerToken, readonly expansion: number, tokens: AssemblerToken[]) {
        super(tokens);
        this.identifier = identifierToken.value;
        this.instruction = new AInstruction(expansion);
    }
}

export class AsmLabel extends AsmSyntax {
    readonly lineType = 'label';
    readonly identifier;
    labelAddress: number | undefined;
    constructor(readonly line: string, readonly identifierToken: AssemblerToken, public tokens: AssemblerToken[]) {
        super(tokens);
        this.identifier = identifierToken.value;
    }
    error(msg: string) {
        this.setError(msg);
        // mark identifier token as error
        this.identifierToken.isValid = false;
    }
}

/* A literal machine code value rather than a symbolic assembler instruction */
export class AsmMachineLiteral extends AsmInstructionSyntax {
    readonly lineType = 'machine';
    readonly instruction: Instruction;
    constructor(readonly line: string, readonly numToken: NumericToken, public tokens: AssemblerToken[]) {
        super(tokens);
        this.instruction = {
            cInstruction: false,
            toWord() { return numToken.numericValue; } ,
            toText() { return `MACHINE`; }
        };
    }
}


export class AsmDefine extends AsmSyntax {
    readonly lineType = 'define';
    readonly identifier;
    readonly value;
    constructor(readonly line: string, readonly identifierToken: AssemblerToken, readonly valueToken: NumericToken, public tokens: AssemblerToken[]) {
        super(tokens);
        this.identifier = identifierToken.value;
        this.value = valueToken.numericValue;
    }
    error(msg: string) {
        this.setError(msg);
        // mark identifier token as error
        this.identifierToken.isValid = false;
    }
}

export class AsmMacroInvocation extends AsmSyntax {
    readonly lineType = 'macroinv';
    // label definition in the inner scope
    definitions?: Definitions;
    addresses: [number, number] | undefined;

    constructor(
        readonly line: string,
        readonly macro: Macro,
        tokens: AssemblerToken[],
        readonly expansion: AsmSyntax[],
        readonly labelArguments: {placeholder: string; token: AssemblerToken}[]
    ) {
        super(tokens);
    }
}


export class LabelDef {
    constructor (readonly node: AsmLabel) { }
    resolve() { return this.node.labelAddress; }
}
export class DefineDef {
    constructor (readonly node: AsmDefine) { }
    resolve() { return this.node.value;  }
}
export class GlobalDef {
    constructor (readonly value: number) { }
    resolve() { return this.value;  }
}
export class PlaceholderDef {
    constructor (readonly value: number) { }
    resolve() { return this.value;  }
}


export type Definition =
    LabelDef |
    DefineDef |
    GlobalDef |
    PlaceholderDef;


export class Definitions {
    defines = new Map<string, AsmDefine>();
    labels = new Map<string, AsmLabel>();
}



