import { stateTest } from './codeTester';
import { Placeholder } from '../../assembler/instructionProvider';
import { StackMissionBase } from './stackMission';

export const gotoMissionTests = [
    stateTest(
        {
            stack: [],
            placeholders: {label: 99},
        },
        {
            stack: [],
            pc: 99
        })
];

export const gotoMission = new class extends StackMissionBase{
    key = 'STACK_GOTO';
    macro = {
        name: 'goto',
        placeholders: [new Placeholder('label')],
    };
    tests = gotoMissionTests;
    defaultTestCode = `\
init.stack
push.value x42
goto end
# this line should not be executed:
push.value x17
label end:
# top of stack should be x42
`
};

export const ifGotoMissionTests =  [
    stateTest(
        {
            // push true on the stack
            stack: [0xFFFF],
            placeholders: {label: 99},
        },
        {
            stack: [],
            pc: 99
        }),
    stateTest(
        {
            stack: [0],
            placeholders: {label: 99},
        },
        {
            stack: [],
        })
];

export const ifGotoMission = new class extends StackMissionBase {
    key = 'STACK_IF_GOTO';
    macro = {
        name: 'if.goto',
        placeholders: [new Placeholder('label')],
    }
    tests = ifGotoMissionTests;
    defaultTestCode = `\
init.stack
push.value 0
if.goto end
# this line should be execued
push.value 1
if.goto end
# this line should not be executed:
push.value x17
label end:
`
};
