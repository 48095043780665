import { Consts, stateTest } from './codeTester';
import { Placeholder } from '../../assembler/instructionProvider';
import { StackMissionBase } from './stackMission';

export const pushStaticMissionTests = [
    /* Macro should push memory value on stack */
    stateTest({
        placeholders: {address: 42},
        memory: {
            42: 17,
        }},
        {
            stack: [17]
        })
];

export const pushStaticMission = new class extends StackMissionBase {
    key = 'STACK_PUSH_STATIC';
    macro = {
        name: 'push.static',
        placeholders: [new Placeholder('address')],
    }
    tests = pushStaticMissionTests;
    defaultTestCode = `\
init.stack
# store 42 at address 16
A = 42
D = A
A = 16
*A = D
push.static 16
# top of stack should be 42
`
};

export const popStaticMission = new class extends StackMissionBase{
    key = 'STACK_POP_STATIC';
    macro = {
        name: 'pop.static',
        placeholders: [new Placeholder('address')],
    };
    tests = [
        stateTest(
            {
                stack: [17],
                placeholders: {address: 42},
            },
            {
                stack: [],
                memory: {42: [17, '']}
            })
    ];
    defaultTestCode = `\
init.stack
push.value x42
pop.static x16
# address 16 should have value 42
`
};


export const pushMemoryMissionTests = [
    stateTest({
        stack: [1042],
        memory: {1042: 17},
    },
    {
        stack: [17]
    })
];

// addr -> val
export const pushMemoryMission = new class extends StackMissionBase{
    key = 'STACK_PUSH_MEMORY';
    macro = {
        name: 'push.memory',
        placeholders: [],
    };
    tests = pushMemoryMissionTests;
    defaultTestCode = `\
init.stack
# store 42 at address 16
A = 42
D = A
A = 16
*A = D

push.value 16
push.memory
# top of stack should have value 42
`
};

// addr, val -> ()
export const popMemoryMission = new class extends StackMissionBase{
    key = 'STACK_POP_MEMORY';
    macro = {
        name: 'pop.memory',
        placeholders: [],
    }
    tests = [
        stateTest({
                stack: [1001, 17],
                memory: {1001: 32}
            },
            {
                stack: [],
                memory: {1001: [17, '']}
            })
        ];
        defaultTestCode = `\
init.stack
push.value 16
push.value 42
pop.memory
# address 16 should have value 42
`
};

export const pushValueMission = new class extends StackMissionBase{
    key = 'STACK_PUSH_VALUE';
    macro = {
        name: 'push.value',
        placeholders: [new Placeholder('value')],
    };
    tests =  [
        stateTest({
                stack: [],
                placeholders: {value: 42},
            },
            {
                stack: [42]
            })
        ];
    defaultTestCode = `\
init.stack
push.value 0x7c89
# Inspect stack: top should be 0x7c89
`
};

export const pushLocalMissionTests = [
    /* Macro should push memory value on stack */
    stateTest({
        stack: [],
        placeholders: {index: 3},
        memory: {[Consts.LOCALS]: 1000, [1000 + 3]: 42}},
        {
            stack: [42]
        })
];

export const pushLocalMission = new class extends StackMissionBase{
    key = 'STACK_PUSH_LOCAL';
    macro = {
        name: 'push.local',
        placeholders: [new Placeholder('index')],
    };
    tests = pushLocalMissionTests;
    // TODO: test code
    defaultTestCode = ``;
};

export const popLocalMissionTests =  [
    stateTest({
        stack: [17],
        placeholders: {index: 2},
        memory: {[Consts.LOCALS]: 2000, [2000 + 2]: 42}},
        {
            stack: [],
            memory: {[2000 + 2]: [17, '']}
        })
];


export const popLocalMission = new class extends StackMissionBase{
    key = 'STACK_POP_LOCAL';
    macro = {
        name: 'pop.local',
        placeholders: [new Placeholder('index')],
    };
    tests = popLocalMissionTests;
    // TODO: test code
    defaultTestCode = ``;
};


export const pushArgMissionTests = [
    /* Macro should push memory value on stack */
    stateTest({
        placeholders: {index: 3},
        memory: {
            [Consts.ARGS]: 1000,
            1000: 43,
            [1000 - 3]: 41,
            [1000 + 3]: 42
        }},
        {
            stack: [42]
        })
];

export const pushArgMission = new class extends StackMissionBase{
    key = 'STACK_PUSH_ARG';
    macro = {
        name: 'push.argument',
        placeholders: [new Placeholder('index')],
    }
    tests = pushArgMissionTests;
    // TODO: test code
    defaultTestCode = ``;
};

export const popArgMissionTests = [
    stateTest({
        stack: [17],
        placeholders: {index: 1},
        memory: {[Consts.ARGS]: 2000, [2000 + 1]: 42}},
        {
            stack: [],
            memory: {[2000 + 1]: [17, '']}
        })
];

export const popArgMission = new class extends StackMissionBase {
    key = 'STACK_POP_ARG';
    macro = {
        name: 'pop.argument',
        placeholders: [new Placeholder('index')],
    };
    tests = popArgMissionTests;
    // TODO: test code
    defaultTestCode = ``;
};
