import { IEditorBackend } from 'ide/editor/iEditorBackend';
import { FileModel } from './fileModel';
import { AsmInstructionSyntax, AsmMacroInvocation } from './syntax';
import { StringSourceFile } from 'ide/sourceFile';

/* Expose AST hints for editor and receive text changes
 */
export class AssemblerEditorBackend implements IEditorBackend {
    currentLineIx: number | undefined;
    currentLineChanged?: () => void;

    constructor(readonly path: string, readonly fileModel: FileModel) { }
    /* called to initilize editor with content */
    getText() {
        return this.fileModel.source.load();
    }
    /* called when code changed in editor */
    updateCode(code: string) {
        this.fileModel.source.save(code);
    }
    get size() {
        return this.getLines().length;
    }
    getLines() {
        return this.fileModel.parsed?.lines ?? [];
    }
    mapLineNo(nr: number) {
        const line = this.getLines()[nr - 1];
        if (line instanceof AsmInstructionSyntax && line.address !== undefined) {
            return line.address.toString();
        }
        if (line instanceof AsmMacroInvocation && line.addresses) {
            // Show start address for macro
            return line.addresses[0].toString() ;
        }
        return '';
    }
    setCurrentLine(lineIx: number | undefined) {
        this.currentLineIx = lineIx;
        this.currentLineChanged?.();
    }
}


/* Expose parsed code for editor when macro is pair with test code
 */
export class MacroEditorBackend extends AssemblerEditorBackend {
    // TODO: should remap when active
    mapLineNo(nr: number) { return ''; }
}

/*
 * Headless code container (no editor) used by tests
*/
export class HeadlessEditorBackend extends AssemblerEditorBackend {
    constructor() {
        super('unit-test', new FileModel(new StringSourceFile('')));
    }
}

