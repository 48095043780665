import { setBit, getBit } from '../common/bits';
import { disassemble } from '../assembler/instructions';
import { toHexWord } from '../common/hex';

/* A machine code unit */
export interface CodeUnit {
    lines: CodeLine[];
    save(address: number, value: number): void;
}

/* Wraps an array and exposes it as editable machine code lines */
export class ArrayCodeUnit implements CodeUnit {
    lines: CodeLine[];
    onChange?: () => void;
    constructor(public readonly data: number[]) {
        this.lines = data.map((v, ix) => new CodeLine(this, v, ix));
    }
    get length() { return this.data.length; }
    save() {
        if (this.onChange) { this.onChange(); }
    }
}

export class Bit {
    constructor(readonly line: CodeLine, readonly bitIx: number) { }
    toggle() {
        this.setBit(!this.bit);
    }
    get bit() {
        return this.line.getBit(this.bitIx);
    }
    setBit(status: boolean) {
        this.line.setBit(this.bitIx, status);
    }
}

export class CodeLine {
    bits = Array(16).fill(1).map((_, ix) => new Bit(this, ix)).reverse();
    constructor(readonly state: CodeUnit, public value: number, public address: number) { }
    changed() {
        this.state.save(this.address, this.value);
    }
    setBit(bitIx: number, status: boolean) {
        this.value = setBit(this.value, bitIx, status);
        this.changed();
    }
    getBit(bitIx: number) {
        const b = getBit(this.value, bitIx);
        return b;
    }
    get text() {
        // TODO: should be made safe
        try {
            return disassemble(this.value).toText();
        } catch (e) {
            return '(undefined)';
        }
    }
    get hex() { return toHexWord(this.value); }
}
