import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export function VmInstructionsHelp() {
    return (<>
        <p>Instructions supported:</p>
        <ul>
            <li>Artihmetics: <code>add</code>, <code>sub</code>, <code>neg</code></li>
            <li>Logic: <code>and</code>, <code>or</code>, <code>not</code></li>
            <li>Comparisons: <code>gt</code>, <code>eq</code>, <code>lt</code></li>
            <li>Jumps: <code>label</code>, <code>goto</code>, <code>if_goto</code></li>
            <li><code>push_value</code></li>
            <li>Memory: <code>push_</code>, <code>pop_</code>,
            followed by one of <code>static</code>, <code>memory</code>, <code>argument</code>, or <code>local</code>.
                E.g. <code>push_static</code>, <code>pop_argument</code> etc. </li>
        </ul>
        <p>Code is case-insensitive, so <code>push_value x</code> is the same as <code>push.value X</code></p>
    </>);
}

export function PopupHelp(props: { children: React.ReactNode }) {
    const popover = (
        <Popover id="popover-basic">
          <Popover.Body>
            {props.children}
          </Popover.Body>
        </Popover>
      );
    return (<div>
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant="success">Help</Button>
        </OverlayTrigger>
    </div>);
}
