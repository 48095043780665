import { Machine } from '../../assembler/machine';
import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { CodeTester, Consts } from './codeTester';
import { Placeholder } from '../../assembler/instructionProvider';
import { StackMissionBase } from './stackMission';

/*
    Function should set LOCAL to SP, and then add localsCount to to SP
*/
class FunctionTest extends CodeTester {
    override setup() {
        // No setup
    }
    getPlaceholders() {
        return {'functionName': 42, 'localsCount': 3};
    }
    override verify(machine: Machine, programLength: number) {
        // Assert stack is 3, corresponding to number of locals
        const err = this.expectStackSize(machine, 3);
        if (err) {
            return err;
        }
        // assert LOCALS = the stack before locals
        const locals = machine.ram.peek(Consts.LOCALS);
        const expected = Consts.STACK_START;
        if (locals !== expected) {
            return new VerificationError(`Expected LOCALS (${Consts.LOCALS}) to be ${expected}. (Was ${locals.toString(16)})`);
        }
        const err2 = this.expectAtEnd(machine, programLength);
        if (err2) {
            return err2;
        }

        return new VerificationOk();
    }
}

export const functionMission = new class extends StackMissionBase {
    key = 'FUNCTION';
    macro = {
        name: 'function',
        placeholders: [new Placeholder('functionName'), new Placeholder('localsCount')],
    };
    tests = [
        FunctionTest,
    ];
    // TODO: test code
    defaultTestCode = ``;
};


class ReturnTest extends CodeTester {
    setup(machine: Machine) {
        // return address
        this.push(machine, 1000);
        const sp = machine.ram.peek(Consts.SP);
        // store current stack in LOCALS
        this.setupMemory(machine, {[Consts.LOCALS]: sp});
        // push return value
        this.push(machine, 42);
    }
    verify(machine: Machine) {
        // assert RETVAL is the value which was on the stack
        const retval = machine.ram.get(Consts.RETVAL);
        if (retval !== 42) {
            return new VerificationError(`Expected RETVAL (memory address ${Consts.RETVAL}) to be 42. (Was ${retval.toString(16)})`);
        }
        // assert SP is now the LOCAL value
        const err = this.expectStack(machine, []);
        if (err) {
            return err;
        }
        return new VerificationOk();
    }
}

export const returnMission = new class extends StackMissionBase {
    key = 'RETURN';
    macro = { name: 'return' };
    tests =  [ReturnTest];
    // TODO: test code
    defaultTestCode = ``;
};

class CallTest extends CodeTester {
    INITIAL_ARGS = 0x0AB7;
    INITIAL_LOCALS = 0x0ABE;
    callExecuted = false;
    spBeforeArgs = -1;
    spAfterArgs = -1;
    setup(machine: Machine) {
        // Push three arguments on stack
        this.spBeforeArgs = machine.ram.peek(Consts.SP);
        this.setupStack(machine, [7, 9, 13]);
        this.spAfterArgs = machine.ram.peek(Consts.SP);
        // current ARGS and LOCALS which should be saved and restored
        this.setupMemory(machine, {
            [Consts.ARGS]: this.INITIAL_ARGS,
            [Consts.LOCALS]: this.INITIAL_LOCALS
        });
    }
    getPlaceholders() {
        return {
            'functionName': 0x7FFF,
            'argumentCount': 3,
        };
    }
    onTick(machine: Machine) {
        const pc = machine.pc.get();
        if (pc === 0x7FFF) {
            // the call has been executed.
            this.callExecuted = true;

            // Verify new ARGS
            const expectedArgs = this.spBeforeArgs;
            const err1 = this.expectNamedMemorySlot(machine, Consts.ARGS, expectedArgs, 'ARGS');
            if (err1) {
                return err1;
            }
            // Change LOCALS value, so we can test it is restored correctly
            machine.ram.pokeImmediately(Consts.LOCALS, 0xBEEF);

            // Simulate a function.
            //
            // stack starting from current ARGS should be:
            // - arguments
            // - prev ARGS
            // - prev LOCALS
            // - return addr
            //
            // pop return addr:
            const retAddr = this.pop(machine);
            // set return value
            machine.ram.pokeImmediately(Consts.RETVAL, 42);
            // jump to to return address
            machine.pc.setImmediately(retAddr);
        }
        return null;
    }
    verify(machine: Machine, programLength: number) {
        if (!this.callExecuted) {
            return new VerificationError(`The code does not contain a jump to the address given by the 'functionName' placeholder.`);
        }
        // The SP should be restored to before the arguments were pushed
        // but with the return value pushed
        const err = this.expectStackSize(machine, 1);
        if (err) {
            return err;
        }
        // on top of stack should be the RETVAL
        const retVal = this.stackTop(machine);
        const expected = 42;
        if (retVal !== expected) {
            return new VerificationError(`Expected return value on top of stack to be ${expected}  (Was ${retVal.toString(16)})`);
        }
        return this.expectAtEnd(machine, programLength)
            // Assert ARGS and LOCALS are restored to previous state
            ?? this.expectNamedMemorySlot(machine, Consts.ARGS, this.INITIAL_ARGS, 'ARGS')
            ?? this.expectNamedMemorySlot(machine, Consts.LOCALS, this.INITIAL_LOCALS, 'LOCALS')
            ?? new VerificationOk();
    }
}

export const callMissionTests = [CallTest];

export const callMission = new class extends StackMissionBase {
    key = 'CALL';
    macro = {
        name: 'call',
        placeholders: [new Placeholder('functionName'), new Placeholder('argumentCount')],
    }
    tests = callMissionTests;
    // TODO: test code
    defaultTestCode = ``;
};
