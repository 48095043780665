import { CodeGenerationRule } from './codeGeneration';
import { SyntaxRule } from './SyntaxRules';
import { PatternType, TokenAction, TokenSpec } from './tokenize';
import { Repository } from '../app/repository';
import { TokenSpecification } from './tokenSpecification';
import { StorageService } from 'common/storage.service';

type CompilerPersistence = {
    lexical: TokenSpecPersistence[];
    runtimeLibrary: string;
    rules: RulePersistence[];
};
type TokenSpecPersistence = {
    type: PatternType;
    pattern: string;
    action: TokenAction;
    label?: string;
};
type RulePersistence = {
    lhs: string;
    rhs: string;
    codegen: string;
};

const initial = {
    lexical: [
        new TokenSpecification(0, PatternType.Pattern, '[ ]+', TokenAction.Ignore),
        new TokenSpecification(1, PatternType.Pattern, '[0-9]+', TokenAction.Name, 'Number'),
        new TokenSpecification(2, PatternType.Exact, '+', TokenAction.Literal),
    ],
    codegen: [] as CodeGenerationRule[],
    rules: [
        new SyntaxRule('Expression', 'Expression + Number', '[Expression]\n[Number]\nADD'),
        new SyntaxRule('Expression', 'Number', 'push.value [Number]'),
    ],
};
function migrateCodeGen(code?: string) {
    if (!code) {
        return code;
    }
    return code.replace(/%(.*?)%/g, '[$1]');
}
export class SharedCompilerState {
    key = 'SHARED_COMPILER_CONFIG';
    lexical;
    rules;
    runtimeLibrary;
    constructor(readonly storage: StorageService) {
        const repository = new Repository(storage);
        const data = repository.getLevelData<Partial<CompilerPersistence>>(this.key);
        if (data) {
            this.lexical = data.lexical?.map((s, ix) => new TokenSpecification(ix, s.type, s.pattern, s.action, s.label)) ?? [];
            this.rules = data.rules?.map(r => new SyntaxRule(r.lhs, r.rhs, migrateCodeGen(r.codegen))) ?? [];
            this.runtimeLibrary = data.runtimeLibrary ?? '';
        } else {
            this.lexical = initial.lexical;
            this.rules = initial.rules;
            this.runtimeLibrary = '';
        }
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService): void {
        const repository = new Repository(storage);
        repository.saveLevel(this.key,
            {
                lexical: this.lexical.map(s => ({
                        type: s.type,
                        pattern: s.pattern,
                        action: s.action,
                        label: s.label,
                        }) as TokenSpec),
                rules: this.rules.map(r => ({
                        lhs: r.lhs,
                        rhs: r.rhs,
                    codegen: r.codegen }) as RulePersistence),
                runtimeLibrary: this.runtimeLibrary,
        } as CompilerPersistence);
    }
}
