import React from 'react';
import Draggable from 'react-draggable';
import { UiText } from '../locale/UiText';
import './game/popup-dialog.css';

export function WithPopupWindow(props: {
    children: React.ReactNode;
    title: string;
    onClose: () => void;
    btnOk?: boolean;
}) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable onStart={ev=>ev.stopPropagation()} cancel='button' handle='.card-header' nodeRef={nodeRef}>
        <div className="popup-dialog levels card" ref={nodeRef}>
            {props.title !== '' &&
            <div className="card-header">
                {props.title}
                <button type="button" className="btn-close" aria-label="Close" onClick={props.onClose}></button>
            </div>}
            <div className="card-body" style={{overflowY:'auto', overflowX: 'hidden'}}>
                {props.children}
            </div>
            <div className='card-footer'>
            { props.btnOk === true ?
                <button onClick={props.onClose} className='btn btn-primary'><UiText id='btn_ok' /></button> :
                <button type="button" className="btn btn-primary" aria-label="Close" onClick={props.onClose}><UiText id='btn_close' /></button>
            }
            </div>
        </div>
        </Draggable>
    );
}
