import { useState } from 'react';
import './DiagramHint.css';

import { MissionTexts } from '../../diagramMissionType';
import { Hint, HintPosition } from '../../hint';

export function DiagramHint(props: {
		hint: Hint;
		missionTexts: MissionTexts}) {

    const [state, setState] = useState({ open: true });

    const width =  props.hint.width;
    const html =  props.missionTexts.hintText(props.hint.key);

    function locateTargetElement() { return document.querySelector(props.hint.selector) as HTMLElement; }

    const targetElement = locateTargetElement();
    if (!targetElement) {
        return null;
    }
    const position = getPosition(targetElement);
    const visible =  props.hint != null && position != null && state.open;

    function getPosition(refNode: HTMLElement) {
        const b = refNode.getBoundingClientRect();
        const refLeft = b.left + window.scrollX;
        const refTop = b.top + window.scrollY;
        switch (props.hint.position) {
            case HintPosition.Left: {
                const left = refLeft - width + props.hint.offsetX;
                const top = refTop + props.hint.offsetY;
                return { left: `${left}px`, top: `${top}px` };
            }
            case HintPosition.Right: {
                const refRight = b.right + window.scrollX + props.hint.offsetX;
                const top = refTop + props.hint.offsetY;
                return { left: `${refRight}px`, top: `${top}px` };
            }
            case HintPosition.Below: {
                const left = refLeft + (b.width / 2) - (width / 2) + props.hint.offsetX; /* centered below */
                const refBottom = refTop + b.height + props.hint.offsetY;
                return { left: `${left}px`, top: `${refBottom}px` };
            }
        }
    }

    function close() {
        setState({open: false});
    }
	const hint = props.hint;

    if (!visible) {
        return null;
    }

	return (
    <div style={position} className='hint tip'>

    { hint.position===HintPosition.Below && (
        <div className='top-arrow'>
        <svg className='tip-svg'>
            <polyline points='0 11 15 0 30 11' className='bottom' />
            <polygon points='0 12 15 2 30 12' className='top' />
        </svg>
    </div>)}

    <div className='box-row'>
        { hint.position===HintPosition.Right && (
            <div className='left-arrow'>
            <svg className='tip-svg'>
                <polyline points='10 0 0 10 10 20' className='bottom' />
                <polygon points='12 0 1 10 12 20' className='top' />
            </svg>
        </div>)}

        <div className='inner' style={{width: width}}>
            <button type='button' aria-label='Close' onClick={()=>close()} className='btn-close close'></button>
            <div dangerouslySetInnerHTML={{__html: html}} className='content'></div>
        </div>

        { hint.position===HintPosition.Left && (
            <div className='right-arrow'>
            <svg className='tip-svg'>
                <polygon points='2 0 12 10 2 20' className='bottom' />
                <polygon points='1 0 10 10 1 20' className='top' />
            </svg>
        </div>)}

    </div>

</div>);
}

