import { StorageService } from 'common/storage.service';
import { MissionProgression } from '../app/missionProgression';
import { Repository } from '../app/repository';
import { MissionKind } from '../app/task';
import { CodegenMissionPersistence, CodegenMissionState } from './codegenMissionState';
import { expressionTests } from './grammarTests';


class ExpressionCodegenMissionState extends CodegenMissionState {
    startSymbol = 'Expression';
    getInitialSource() {
        return '2 + 2';
    }
    tests = expressionTests;
}

export const expressionCodegenMission = {
    key: 'CODEGEN_EXPRESSION',
    kind: MissionKind.Compiler,
    start(storage: StorageService, game: MissionProgression) {
        return new ExpressionCodegenMissionState(this, storage, game);
    },
    restore(storage: StorageService, game: MissionProgression) {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as CodegenMissionPersistence;
        return new ExpressionCodegenMissionState(this, storage, game, data);
    },
};
