import React from 'react';
import './Opcodes2MissionComponent.css';
import { ErrorState, Opcodes2MissionState, Bit2, Opcode2Line } from './opcodes2';
import Arrow, { DIRECTION } from 'react-arrows';


export function Opcodes2MissionComponent(props: { missionState: Opcodes2MissionState }) {
    const missionState =  props.missionState;
    return (
    <div className='opcodes-content main'>
        <div className="main-block">
        <div className="example header">Example instruction:</div>
        <div className="example example-sample">
            <div className="segment-block">
                <div className="example-segment">D =</div>
                <div className='segment-marker'></div>
                <div className="segment-label" id="segment1">Destination</div></div>
            <div className="segment-block">
                <div className="example-segment">D + 1</div>
                <div className='segment-marker'></div>
                <div className="segment-label" id="segment2">Calculation</div></div>
            <div className="segment-block">
                <div className="example-segment">; JNE</div>
                <div className='segment-marker'></div>
                <div className="segment-label" id="segment3">Jump-condition</div></div>
        </div>
        <Arrow className="opcodes-arrow"
            from={{
                direction: DIRECTION.BOTTOM,
                node: () => document.getElementById('segment1'),
                translation: [0, 1],
            }}
            to={{
                direction: DIRECTION.TOP,
                node: () => document.getElementById('opcodes1'),
                translation: [0, -1],
            }} />
        <Arrow className="opcodes-arrow"
            from={{
                direction: DIRECTION.BOTTOM,
                node: () => document.getElementById('segment2'),
                translation: [0, 0.5],
            }}
            to={{
                direction: DIRECTION.TOP,
                node: () => document.getElementById('opcodes2'),
                translation: [0, -0.5],
            }} />
        <Arrow className="opcodes-arrow"
            from={{
                direction: DIRECTION.BOTTOM,
                node: () => document.getElementById('segment3'),
                translation: [0, 1],
            }}
            to={{
                direction: DIRECTION.TOP,
                node: () => document.getElementById('opcodes3'),
                translation: [0, -1],
            }} />
        <div className="segments">
            <div id="opcodes1"><DestOpcodes missionState={missionState} /></div>
            <div id="opcodes2"><CalcOpcodes missionState={missionState} /></div>
            <div id="opcodes3"><JmpOpcodes missionState={missionState} /></div>
        </div>
        </div>
    </div>);
}

export function DestOpcodes(props: { missionState: Opcodes2MissionState}) {
    const labels = ['a','d','*a'];
    return (<OpcodesTable labels={labels} lines={props.missionState.destLines} missionState={props.missionState} />);
}

export function CalcOpcodes(props: { missionState: Opcodes2MissionState}) {
    const labels = ['u','op1','op0','zx','sw'];
    return (<OpcodesTable labels={labels} lines={props.missionState.calcLines} missionState={props.missionState} />);
}

export function JmpOpcodes(props: { missionState: Opcodes2MissionState}) {
    const labels = ['lt','eq','gt'];
    return (<OpcodesTable labels={labels} lines={props.missionState.jmpLines} missionState={props.missionState} />);
}

export function OpcodesTable(props: {
    missionState: Opcodes2MissionState,
    labels: string[],
    lines: Opcode2Line[],
}) {
    const lines =  props.lines;

    const [_state,setState] = React.useState(()=>{
        return {lines: props.lines};
    });

    function toggle(bit: Bit2) {
        bit.toggle();
        setState({lines: props.lines});
    }
    function marker(state: ErrorState) {
        if (state === 'error') {
            return (<i className='bi bi-x-lg'></i>);
        } if (state === 'ok') {
            return (<i className='bi bi-check-lg'></i>);
        } else {
            return null;
        }
    }
	return (
<div className=''>
    <table>
        <thead>
            <tr>
                <th>Opcode</th>
                <th colSpan={props.labels.length}>Bit flags</th>
            </tr>
            <tr>
                <th></th>
                {props.labels.map(label => <th key={label}>{label}</th>)}
            </tr>
        </thead>
        <tbody>
            {lines.map(instr => (
                <tr key={instr.opcode}>
                    <td className='opcode'>{instr.text !== '' ? instr.text : <i>(blank)</i>}</td>
                    {(instr.bits).map((bit, ix) => (
                        <td key={ix}>
                        <span tabIndex={1}
                            onKeyPress={()=>toggle(bit)}
                            onClick={()=>toggle(bit)} className='bit'>{bit.bit ? '1' : '0'}</span>
                        </td>))}
                    <td className='marker'>{marker(instr.errorState)}</td>
                </tr>))}
        </tbody>
    </table>
</div>
);
}

