import './TruthTableComponent.css';
import { TruthTable } from '../../truthTable';
import { DiagramVerificationResultSet } from '../../verification';


export function TruthTableComponent(
    {table, verification}: {
    table: TruthTable,
    verification?: DiagramVerificationResultSet}) {

    const rows = table.truths.map(row =>
        ({ inputs: table.visibleInputs.map(p => row[0][p.index]),
            outputs: table.visibleOutputs.map(p => row[1][p.index])
        }));

    // If there is only one input and one output, we don't show the additional row with connector labels.
    const showLabels =  table.visibleInputs.length + table.visibleOutputs.length > 2;


	return (
<table className='truth'>
    <colgroup className='input'>
        {table.visibleInputs.map((o, ix) => <col key={ix} />)}
    </colgroup>
    <colgroup className='output'>
        {table.visibleOutputs.map((o, ix) => <col key={ix} />)}
    </colgroup>
    <thead>
    <tr>
        <th colSpan={table.visibleInputs.length} className='input'>Input</th>
        <th colSpan={table.visibleOutputs.length} className='output'>Output</th>
    </tr>
    { showLabels && (
        <tr>
        {table.visibleInputs.map((pin, ix) => <th key={ix}>{pin.label}</th>)}
        {table.visibleOutputs.map((pin, ix) => <th key={ix}>{pin.label}</th>)}
    </tr>)}
    </thead>
    <tbody>
    {verification ?
        verification.ioResults.map((result, ix) =>
            <tr key={ix} className={result.succeeded ? 'ok' : 'error'}>
                {table.visibleInputs.map(pin => <td key={pin.index}>{result.inputs[pin.index] ? 1 : 0}</td>)}
                {table.visibleOutputs.map(pin => <td key={pin.index}>{result.outputs[pin.index]!.expected ? 1 : 0}</td>)}
                <td className='flag'>
                    { result.succeeded ?
                        <i className='bi bi-check-lg'></i> : <i className='bi bi-x-lg'></i> }
                </td>
            </tr>)
    : rows.map((rows, ix) =>
        <tr key={ix}>
        {rows.inputs.map((val, ix) => <td key={ix}>{val ? 1 : 0}</td>)}
        {rows.outputs.map((val, ix) => <td key={ix}>{val ? 1 : 0}</td>)}
        </tr>)
    }
    </tbody>
</table>);
}

