import { VerificationResultSet, VerificationOk, VerificationError, UnitVerificationResultSet, VerificationResult } from '../app/verificationResults';
import { add, sub, bitwiseAnd, inv, neg, bitwiseOr, bitwiseXor } from '../common/arithmetics';
import { resolveAlu2 } from 'diagram/missions/alu2missions';
import { MissionKind, MissionState, Task } from '../app/task';
import { Repository } from '../app/repository';
import { jumpFlagsMappings } from '../assembler/mnemonics2';
import { bitArrayToNumber, boolArrayToNumber } from '../common/bits';
import { StorageService } from 'common/storage.service';
import { cpu3MissionTask } from 'diagram/missions/cpuMission';
import { DenseArray } from 'common/utilities';
import { Opcodes2MissionComponent } from './Opcodes2MissionComponent';


export class Bit2 {
    constructor(readonly line: Opcode2Line, readonly bitIx: number) { }
    toggle() {
        this.bit = !this.bit;
    }
    get bit() {
        return this.line.value[this.bitIx] ?? false;
    }
    set bit(status: boolean) {
        this.line.value[this.bitIx] = status;
        this.line.changed();
    }
}

// We can't just compare to the expected solution, since multiple configurations may be valid
export function verifyAluConfiguration(opcode: string, flags: boolean[]) {
    const expectedFunction = expectedAluFunctions[opcode];
    if (!expectedFunction) {
        throw new Error(opcode);
    }
    // Test numbers
    // 7, 13 chosen so that neither x & y or x | y equal any of the operands
    // otwerwise we might get false positives in tests
    const testInputs = [[1, 2], [7, 13], [13, 7]] as const;
    for (const [x, y] of testInputs) {
        const expected = expectedFunction(x, y);
        const [u, op1, op0, zx, sw] = flags.map(f => f ? 1 : 0) as readonly number[] as DenseArray;
        const actual = resolveAlu2(u, op1, op0, zx, sw, x, y);
        if (expected !== actual) {
            return `Tried with X = ${x} and Y = ${y}. Expected result ${expected} but was ${actual} `;
        }
    }
    return null;
}

export type ErrorState = 'none' | 'ok' | 'error';

export class Opcode2Line {
    bits = this.value.map((_, ix) => new Bit2(this, ix));
    errorState: ErrorState = 'none';
    constructor(readonly state: Opcodes2MissionState, readonly opcode: string, public value: boolean[]) { }
    changed() {
        this.state.save();
        this.errorState = 'none';
    }
    get text() { return this.opcode; }
}

type SimFun = ((x: number, y: number) => number);
type ExpectedFunctionMap = { [key: string]: SimFun };
export const expectedAluFunctions: ExpectedFunctionMap = {
    'D+A': ((x, y) => add(x, y)),
    'D-A': ((x, y) => sub(x, y)),
    'A-D': ((x, y) => sub(y, x)),
    '0': ((_x, _y) => 0),
    '1': ((_x, _y) => 1),
    '-1': ((_x, _y) => neg(1)),
    'D': ((x, _y) => x),
    'A': ((_x, y) => y),
    '-D': ((x, _y) => neg(x)),
    '-A': ((_x, y) => neg(y)),
    'D+1': ((x, _y) => add(x, 1)),
    'A+1': ((_x, y) => add(y, 1)),
    'D-1': ((x, _y) => sub(x, 1)),
    'A-1': ((_x, y) => sub(y, 1)),
    'D&A': ((x, y) => bitwiseAnd(x, y)),
    'D|A': ((x, y) => bitwiseOr(x, y)),
    'D^A': ((x, y) => bitwiseXor(x, y)),
    '~D': ((x, _y) => inv(x)),
    '~A': ((_x, y) => inv(y))
};


// start with the simplest
const calcOpcodes = [
    // arithmetic
    ['D+A'],
    ['D-A'],
    ['A-D'],
    ['D+1'],
    ['A+1'],
    ['D-1'],
    ['A-1'],
    ['-D'],
    ['-A'],
    ['-1'],
    ['1'],
    ['D'],
    ['A'],
    // logic
    ['D&A'],
    ['D|A'],
    ['~D'],
    ['~A'],
    ['0'],
] as const;

const destOpcodes: [string, [boolean, boolean, boolean]][] = [
    // a, d, *a
    ['', [false, false, false]],
    ['A =', [true, false, false]],
    ['D =', [false, true, false]],
    ['*A =', [false, false, true]],
    ['A, D =', [true, true, false]],
    ['D, *A =', [false, true, true]],
    ['A, D, *A =', [true, true, true]],
];
const destOpcodesDict = Object.fromEntries(destOpcodes);

const jumpOpcodes = [
    ['', 'NULL'],
    ['; JLT', 'JLT'],
    ['; JEQ', 'JEQ'],
    ['; JGT', 'JGT'],
    ['; JLE', 'JLE'],
    ['; JGE', 'JGE'],
    ['; JMP', 'JMP'],
] as const;
const jumpOpcodesDict = Object.fromEntries(jumpOpcodes);

export class Opcodes2MissionState implements MissionState {
    isCompleted = false;
    readonly destLines = destOpcodes.map(([opcode, _flags]) => new Opcode2Line(this, opcode, [false, false, false]));
    readonly calcLines = calcOpcodes.map(([opcode]) => new Opcode2Line(this, opcode, [false, false, false, false, false]));
    readonly jmpLines = jumpOpcodes.map(([opcode, _mn]) => new Opcode2Line(this, opcode, [false, false, false]));
    constructor(private readonly storage: StorageService, data?: Opcode2Persistence) {
        function restoreLines(lines: Opcode2Line[], data?: OpcodeItemPersistence[]) {
            for (const item of data ?? []) {
                const state = lines.find(o => o.opcode === item.key);
                if (state) {
                    state.value = item.value;
                }
            }
        }
        if (data) {
            restoreLines(this.destLines, data.destinations);
            restoreLines(this.calcLines, data.calculations);
            restoreLines(this.jmpLines, data.jumps);
        }
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        const repository = new Repository(storage);
        repository.saveLevel(opcodes2Mission.key, {
            destinations: this.destLines.map(l => ({ key: l.opcode, value: l.value })),
            calculations: this.calcLines.map(l => ({ key: l.opcode, value: l.value })),
            jumps: this.jmpLines.map(l => ({ key: l.opcode, value: l.value })),
        });
    }
    verifyLines(): VerificationResult {
        for (const line of this.destLines) {
            const expected = destOpcodesDict[line.opcode]!;
            const isValid = boolArrayToNumber(expected) === boolArrayToNumber(line.value);
            line.errorState = isValid ?  'ok' : 'error';
            if (!isValid) {
                const text = line.text === '' ? 'blank' : `'${line.text}'`;
                return new VerificationError(`Flags for ${text} destination is not correct. `);
            }
        }
        for (const line of this.calcLines) {
            const optError = verifyAluConfiguration(line.opcode, line.value);
            line.errorState = optError !== null ? 'error' : 'ok';
            if (optError !== null) {
                return new VerificationError(`Flags for '${line.text}' is not correct. ` + optError);
            }
        }
        for (const line of this.jmpLines) {
            const key = jumpOpcodesDict[line.opcode]!;
            const expected = jumpFlagsMappings[key]!;
            const isValid = bitArrayToNumber(expected) === boolArrayToNumber(line.value);
            line.errorState = isValid ?  'ok' : 'error';
            if (!isValid) {
                const text = line.text === '' ? 'blank' : `'${line.text}'`;
                return new VerificationError(`Flags for ${text} jump condition is not correct. `);
            }
        }
        return new VerificationOk();
    }
    verify(): VerificationResultSet {
        const result = this.verifyLines();
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
    hasState = false;
    resetState() { /* no state */ }
    getComponent() {
        return (<Opcodes2MissionComponent missionState={this} />);
    }
}

type Opcode2Persistence = {
    destinations: OpcodeItemPersistence[];
    calculations: OpcodeItemPersistence[];
    jumps: OpcodeItemPersistence[];
}
type OpcodeItemPersistence = {
    readonly key: string;
    readonly value: boolean[];
};

export const opcodes2Mission = new class implements Task {
    readonly key = 'OPCODES2';
    readonly kind = MissionKind.Custom;
    readonly depends = [cpu3MissionTask];
    start(storage: StorageService) {
        return new Opcodes2MissionState(storage);
    }
    restore(storage: StorageService) {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as Opcode2Persistence;
        return new Opcodes2MissionState(storage, data);
    }
};
