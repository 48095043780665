import { Scanner, AssemblerToken, TokenType, SyntaxErr, ErrorToken, GenericToken } from './scanner';
import {
    AsmComputation,
    AsmLoadLabelInstruction,
    AsmInstructionSyntax,
    UnknownAsm,
    AsmSyntax,
    AsmLoadDefinitionInstruction,
    BlankLineAsm,
    AsmComment,
    AsmLabel,
    AsmMacroInvocation,
    AsmDefine,
    AsmMachineLiteral,
} from './syntax';
import { aluFlagsMappings, jumpFlagsMappings } from './mnemonics2';
import { AInstruction, CInstruction } from './instructions';
import { ConstantsProvider, InstructionProvider, Macro } from './instructionProvider';
import { PlaceholderValues } from './assembler';
import { SourceUnit, TokenLocation } from 'common/location';

class DefinitionsConstants implements ConstantsProvider {
    constants = new Map<string, number>();
    get names() {
        return Array.from(this.constants.keys());
    }
    add(name: string, value: number) {
        this.constants.set(name, value);
    }
    get(name: string) {
        return this.constants.get(name);
    }
}

class CombinedConstantsProvider implements ConstantsProvider {
    constructor(readonly localConstants: ConstantsProvider, readonly constants: ConstantsProvider) {}
    get names() {
        return this.constants.names.concat(this.localConstants.names);
    }
    get(name: string) {
        const local = this.localConstants.get(name);
        if (local !== undefined) {
            return local;
        }
        return this.constants.get(name);
    }
}

export class Parser {
    readonly jumps = ['JMP', 'JEQ', 'JLT', 'JGT', 'JLE', 'JGE', 'JNE'];
    constants;
    // DEFINES - valid for a macro scope
    localConstants;
    constructor(public macroProvider: InstructionProvider,
        readonly globalConstants: ConstantsProvider,
        readonly macroStack: Macro[] = [],
        readonly placeholders: PlaceholderValues | null = null,
    ) {
        this.localConstants = new DefinitionsConstants();
        this.constants = new CombinedConstantsProvider(this.localConstants, globalConstants);
    }

    scanLines(code: string, unit: SourceUnit) {
        const lines = code.split('\n');
        return lines.map((line, lineIx) => this.scanLineSafe(line, lineIx, unit));
    }

    /* parsing may throw an exception in case of a syntax error */
    scanLineSafe(line: string, lineOffset: number, unit: SourceUnit): AsmSyntax {
        try {
            return this.scanAndParseLine(line, lineOffset, unit);
        } catch (e) {
            if (e instanceof SyntaxErr) {
                const token = new ErrorToken(new TokenLocation(lineOffset, 0, line.length, unit), line, null);
                return new UnknownAsm(line, [token], 'Syntax error: ' + e.message);
            }
            throw e;
        }
    }

    scanLinesUnsafe(code: string, unit: SourceUnit) {
        const lines = code.split('\n');
        return lines.map((line, lineIx) => this.scanAndParseLine(line, lineIx, unit));
    }

    /*
        Only parses instructions but not other assembler syntax like labels, macros etc.
        Throw in case of syntax error

        (Only used in tests)
    */
    parseInstruction(line: string, unit: SourceUnit): AsmInstructionSyntax {
        const node = this.scanAndParseLine(line, -1, unit);
        if (!(node instanceof AsmInstructionSyntax && node.isValid)) {
            throw new SyntaxErr('Expected an instruction');
        }
        return node;
    }

    /*
     * Sans and parses a line into an syntax node.
     * When the syntax type is identified, the parsing is passed off to the
     * appropriate syntax node, which may provide syntax-specific errors.
     */
    scanAndParseLine(line: string, lineOffset: number, unit: SourceUnit): AsmSyntax {
        line = line.trimEnd();

        const trimmed = line.trimStart();
        if (trimmed === '') {
            return new BlankLineAsm(line, []);
        }
        const char = trimmed[0];
        if (char === '#') {
            const token = new GenericToken(new TokenLocation(lineOffset, 0, line.length, unit), TokenType.Comment, line, null);
            return new AsmComment(line, [token]);
        }

        const scanner = new Scanner(line, lineOffset, unit);

        scanner.optInitialWhitespace();

        // bare numeric expression - this must be first or only operand in an expression
        const numTok = scanner.optNumber();
        if (numTok) {
            return this.parseExpressionCont(scanner, [], numTok);
        }

        // recognize registers before parsing macro identifiers
        const regTok = scanner.optRegister();
        if (regTok) {
            return this.parseRegisterCont(scanner, regTok);
        }

        // prefix operator - this must be an unary expression
        const prefixOp = scanner.optPrefixOperator();
        if (prefixOp) {
            return this.parseExpressionPfxCont(scanner, [], prefixOp);
        }

        // label?
        const idTok = scanner.optIdentifier();
        if (idTok) {
            const identifier = idTok.value;

            if (identifier === 'JMP') {
                // special case for unconditional jump with no expression
                // 'JMP' is parsed as a shorthand for '-1;JMP'

                scanner.optTrailer();

                const i = new CInstruction();
                i.computation = '-1';
                i.jumpCondition = 'JMP';
                return new AsmComputation(scanner.code, scanner.tokens, i);
            }

            if (this.jumps.includes(identifier)) {
                return new UnknownAsm(
                    scanner.code,
                    scanner.tokens,
                    `Jump condition '${identifier}' must must follow after a calculation and a semicolon. For example 'D-1;JNE'. `
                );
            }

            /* we support both "LABEL foo" and "foo:" syntax. */
            if (identifier.toUpperCase() === 'LABEL') {
                const labelTok = scanner.optIdentifier();
                if (!labelTok) {
                    return new UnknownAsm(scanner.code, scanner.tokens, 'LABEL must be followed by a name.');
                }
                scanner.optPunctuation(':');
                scanner.optTrailer();
                return new AsmLabel(line, labelTok, scanner.tokens);
            }

            if (identifier.toUpperCase() === 'MACHINE') {
                const numTok = scanner.optNumber();
                if (!numTok) {
                    return new UnknownAsm(scanner.code, scanner.tokens, 'MACHINE must be followed by a number.');
                }
                scanner.optTrailer();
                return new AsmMachineLiteral(line, numTok, scanner.tokens);
            }

            if (identifier.toUpperCase() === 'DEFINE') {
                const nameTok = scanner.optIdentifier();
                if (!nameTok) {
                    return new UnknownAsm(scanner.code, scanner.tokens, 'DEFINE must be followed by a name.');
                }
                const numTok = scanner.optNumber();
                if (!numTok) {
                    return new UnknownAsm(scanner.code, scanner.tokens, 'DEFINE must be followed by a name and a number.');
                }
                if (numTok.numericValue > 0x7fff) {
                    numTok.isValid = false;
                    return new UnknownAsm(
                        scanner.code,
                        scanner.tokens,
                        'Number is too big. The highest number which can be assigned to A is 15 bits (0x7FFF in hexadecimal). '
                    );
                }

                scanner.optTrailer();
                return new AsmDefine(line, nameTok, numTok, scanner.tokens);
            }

            // if an identifier is followed by a colon, it is a label
            const optColon = scanner.optPunctuation(':');
            if (optColon) {
                scanner.optTrailer();
                return new AsmLabel(line, idTok, scanner.tokens);
            }

            // otherwise it must be a macro
            return this.parseMacroInvocation(scanner, identifier);
        }

        return new UnknownAsm(line, [new ErrorToken(new TokenLocation(lineOffset, 0, line.length, unit), line, null)], 'Syntax error');
    }

    /* We have parsed a register. This can either be lhs of a statement or first operand of an expression.
        Next token will disambiguate:

        ',' - first of multiple targets, will be followed by a register
        <register> - first of multiple targets (comma separation is optional)
        '=' is was a single target, will be followed by an expression

        Anything else means it was first or single operand in an expression with no lhs
    */
    parseRegisterCont(scanner: Scanner, firstRegister: AssemblerToken): AsmSyntax {
        /* if the first token is a register name, it could be a target or an operand in an expression
            if it is a target, it will be followed by ',' or '='
        */
        const comma = scanner.optPunctuation(',');
        if (comma) {
            // must be followed by a register
            const secondRegister: AssemblerToken = scanner.register();
            return this.parseLhsCont(scanner, firstRegister, secondRegister);
        } else {
            const secondRegister = scanner.optRegister();
            if (secondRegister) {
                return this.parseLhsCont(scanner, firstRegister, secondRegister);
            }
        }

        // if followed by '=', the firstRegister was a target
        const eq = scanner.optPunctuation('=');
        if (eq) {
            const targets: AssemblerToken[] = [firstRegister];
            // '=' consumed, must be followed by an expression
            return this.parseExpression(scanner, targets);
        }

        // otherwise, the register must be first (or only) operand in an expression with no lhs
        return this.parseExpressionCont(scanner, [], firstRegister);
    }

    /* Two lhs registers have been parsed. There may be a third.
       Will be followed by '=' and an expression */
    parseLhsCont(scanner: Scanner, firstRegister: AssemblerToken, secondRegister: AssemblerToken): AsmSyntax {
        const comma = scanner.optPunctuation(',');
        const targets: AssemblerToken[] = [firstRegister, secondRegister];
        if (comma) {
            // must be followed by a register
            const thirdRegister: AssemblerToken = scanner.register();
            targets.push(thirdRegister);
        } else {
            const thirdRegister = scanner.optRegister();
            if (thirdRegister) {
                targets.push(thirdRegister);
            }
        }

        // check for duplicates, e.g. A, A = 1
        const visited: string[] = [];
        for (const target of targets) {
            if (visited.includes(target.value)) {
                target.isValid = false;
                return new UnknownAsm(scanner.code, scanner.tokens, 'Repeated register');
            } else {
                visited.push(target.value);
            }
        }

        // must be followed by '='
        scanner.expect('=', 'Must be followed by "="', TokenType.Punctuation);

        // '=' consumed, must be followed by an expression
        return this.parseExpression(scanner, targets);
    }

    // parse the expession follwing a '='
    parseExpression(scanner: Scanner, targets: AssemblerToken[]) {
        // could be a prefix operator like  ~X or -X
        const prefixOp = scanner.optPrefixOperator();
        if (prefixOp) {
            return this.parseExpressionPfxCont(scanner, targets, prefixOp);
        }

        const optZeroOne = scanner.optZeroOne();
        if (optZeroOne) {
            return this.parseExpressionCont(scanner, targets, optZeroOne);
        }

        // if followed by a non-simple number, it must be an A-instruction
        const optNum = scanner.optNumber();
        if (optNum) {
            // Verify targets is A (and only A)
            if (targets.length !== 1 || targets[0]!.value !== 'A') {
                return new UnknownAsm(
                    scanner.code,
                    scanner.tokens,
                    'Only the A register can be directly assigned a number. To assign to D or *A, first assign the value to A and then copy A to D or *A in the next instruction.'
                );
            }

            scanner.optTrailer();

            if (optNum.numericValue > 0x7fff) {
                optNum.isValid = false;
                return new UnknownAsm(
                    scanner.code,
                    scanner.tokens,
                    'Number is too big. The highest number which can be assigned to A is 15 bits (0x7FFF in hexadecimal). '
                );
            }

            const i = new AInstruction(optNum.numericValue);
            return new AsmComputation(scanner.code, scanner.tokens, i);
        }

        // need to check register before identifier
        const optRegister = scanner.optRegister();
        if (optRegister) {
            return this.parseExpressionCont(scanner, targets, optRegister);
        }

        // if followed by a an indentifier, this must be an A-instruction with a label or definition as argument
        const identTok = scanner.optIdentifier();
        if (identTok) {
            const identifier = identTok.value;

            // check for keywords
            if (this.jumps.includes(identifier)) {
                return new UnknownAsm(scanner.code, scanner.tokens, `Jump condition ${identifier} must must follow after a semicolon.`);
            }
            if (['LABEL'].includes(identifier)) {
                return new UnknownAsm(scanner.code, scanner.tokens, `Keyword '${identifier}' must be the first word in an instruction.`);
            }
            if (this.macroProvider.get(identifier)) {
                return new UnknownAsm(scanner.code, scanner.tokens, `Keyword '${identifier}' must be the first word in an instruction.`);
            }

            // Verify targets is A (and only A)
            if (targets.length !== 1 || targets[0]!.value !== 'A') {
                return new UnknownAsm(
                    scanner.code,
                    scanner.tokens,
                    'Only the A register can be directly assigned a label or constant. To assign to D or *A, first assign the value to A and then copy A to D or *A in the next instruction.'
                );
            }

            // label or definition (A = <foo>)
            scanner.optTrailer();

            // IS the idenfinfier a constant
            // (either global constant or DEFINE in the current source unit)
            const expansion = this.constants.get(identifier);
            if (expansion !== undefined) {
                return new AsmLoadDefinitionInstruction(scanner.code, identTok, expansion, scanner.tokens);
            }

            // is it a placeholder?
            if (this.placeholders) {
                const expansion = this.placeholders.get(identifier);
                if (expansion !== undefined) {
                    return new AsmLoadDefinitionInstruction(scanner.code, identTok, expansion, scanner.tokens);
                }
            }

            // must be label
            return new AsmLoadLabelInstruction(scanner.code, identTok, scanner.tokens);
        }

        const firstTok = scanner.operand();

        return this.parseExpressionCont(scanner, targets, firstTok);
    }

    // opTok is prefix operator in an expression
    parseExpressionPfxCont(scanner: Scanner, targets: AssemblerToken[], opTok: AssemblerToken) {
        const operandTok = scanner.operand();
        const expr = opTok.value + operandTok.value;
        return this.buildInstruction(scanner, targets, expr);
    }

    // firstTok is first operand in an expression
    parseExpressionCont(scanner: Scanner, targets: AssemblerToken[], firstTok: AssemblerToken) {
        const operand1 = firstTok.value;
        const operatorToken = scanner.optOperator();
        let expr;
        if (operatorToken) {
            const operator = operatorToken.value;
            const operand2token = scanner.operand();
            const operand2 = operand2token.value;
            // binary expression
            expr = operand1 + operator + operand2;

            // common bug is to chain operations D + D + D...
            // so we have a specific check for this
            const operator2 = scanner.optOperator();
            if (operator2) {
                operator2.isValid = false;
                return new UnknownAsm(scanner.code, scanner.tokens,
                    'The ALU only support a single calculation per instruction');
            }

        } else {
            // single operand
            expr = operand1;
        }
        return this.buildInstruction(scanner, targets, expr);
    }

    buildInstruction(scanner: Scanner, targets: AssemblerToken[], expr: string) {
        // Parse optional jump
        const optSemicolon = scanner.optPunctuation(';');
        let jumpCondition;
        if (optSemicolon) {
            const jumpTok = scanner.jump();
            const jump = jumpTok.value;
            if (!this.jumps.includes(jump.toUpperCase())) {
                return new UnknownAsm(scanner.code, scanner.tokens, `The jump instruction ${jump} does not exist`);
            }
            const bitmap = jumpFlagsMappings[jump.toUpperCase()];
            if (!bitmap) {
                throw new Error(`Unsupported jump: '${jump}' `);
            }
            jumpCondition = jump;
        } else {
            jumpCondition = 'NULL';
        }

        scanner.optTrailer();

        //-- build instruction

        const i = new CInstruction();

        // validate destinations
        for (const destination of targets) {
            this.setDestination(i, destination.value);
        }

        i.address = expr.indexOf('*') >= 0;
        i.computation = this.getComputation(expr.toUpperCase());
        i.jumpCondition = jumpCondition.toUpperCase();

        return new AsmComputation(scanner.code, scanner.tokens, i);
    }

    // find mnemonics for expression
    getComputation(expr: string) {
        const template = expr.replace('D', 'X').replace('*A', 'Y').replace('A', 'Y');
        const bitset = aluFlagsMappings[template];
        if (!bitset) {
            let hint = '';
            // For most operators, the order have to be D <operator> A.
            // The opposite order is a syntax error, but we try to provide a helpful hint
            const ops = ['+', '&', '|', '^'];
            for (const op of ops) {
                if (expr === `A${op}D`) {
                    hint = `Perhaps you want D ${op} A?`;
                    break;
                }
                if (expr === `*A${op}D`) {
                    hint = `Perhaps you want D ${op} *A?`;
                    break;
                }
            }
            throw new SyntaxErr(`The ALU does not support the operation ${expr}. ${hint}`);
        }
        return template;
    }

    setDestination(i: CInstruction, dest: string) {
        if (dest === '*A') {
            i.dm = true;
        } else if (dest === 'A') {
            i.da = true;
        } else if (dest === 'D') {
            i.dd = true;
        } else {
            throw new SyntaxErr(`Unsupported destination: '${dest}' `);
        }
    }

    parseMacroInvocation(scanner: Scanner, macroName: string) {
        // must be macro:
        const macro = this.macroProvider.get(macroName.replace('_', '.'));
        if (!macro) {
            return new UnknownAsm(scanner.code, scanner.tokens, `Keyword '${macroName}' not found.`);
        }

        // parse arguments
        const replacements: [string, string][] = [];
        const labels: {placeholder: string; token: AssemblerToken}[] = [];
        for (const placeholder of macro.placeholders) {
            let value;
            const optNum = scanner.optNumber();
            if (optNum) {
                value = optNum.value.toString();
            } else {
                const identTok = scanner.optIdentifier();
                if (!identTok) {
                    return new UnknownAsm(scanner.code, scanner.tokens, `Expected a number or label.`);
                }
                value = identTok.value;
                const constant = this.constants.get(identTok.value);
                if (constant === undefined) {
                    const placeholderValue = this.placeholders?.get(value);
                    if (placeholderValue !== undefined) {
                        // Top level placeholder
                        value = placeholderValue.toString();
                    } else {
                        // if the identifier is not a constant/placeholder name, it must be a label
                        labels.push({ placeholder: placeholder.name, token: identTok});
                    }
                } else {
                    value = constant.toString();
                }
            }
            replacements.push([placeholder.name, value]);
        }
        const extra = scanner.optTrailer();
        if (extra) {
            return new UnknownAsm(
                scanner.code,
                scanner.tokens,
                `The macro name ${macro.identifier} should be followed by ${macro.placeholders.length} arguments.`
            );
        }

        // apply placeholders
        let expansionText = macro.expansion;
        for (const [placeholder, value] of replacements) {
            const escapedPlaceholder = placeholder.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
            // we can't use a lookbehind since Safari does not support lookahead/lookbehind
            expansionText = expansionText.replace(new RegExp('(\\s|\\b|=)' + escapedPlaceholder + '\\b', 'gi'), '$1' + value);
        }
        // expand macro
        if (this.macroStack.length > 20) {
            throw new SyntaxErr('Recursive macro.');
        }
        // Only pass the global constants to the macro expansion
        // - local DEFINE's are only scoped to the current source unit
        // - placeholders are not passed
        const p2 = new Parser(this.macroProvider,
            this.globalConstants,
            this.macroStack.concat([macro]),
            null);
        // use unsafe so recursion error is not swallowed
        const expansion = p2.scanLinesUnsafe(expansionText, macro);
        return new AsmMacroInvocation(scanner.code, macro, scanner.tokens, expansion, labels);
    }
}
