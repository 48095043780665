import { GrammarTest, testGrammar } from '../grammarTests';
import { Repository } from '../../app/repository';
import { MissionState, Task } from '../../app/task';
import { UnitVerificationResultSet, VerificationError, VerificationOk, VerificationResultSet } from '../../app/verificationResults';
import { MissionProgression } from '../../app/missionProgression';
import { StorageService } from 'common/storage.service';
import { GrammarMissionComponent } from './GrammarMissionComponent';

/*
    For compiler-state missions,
    only the source code (used for testing) is persisted pr mission
    The actual configuration for tokenizer/parser/codegen is persisted globally
*/
export type CompilerLevelPersistence = { source: string };


export abstract class GrammarState implements MissionState {
    source;
    abstract tests: GrammarTest[];
    abstract startSymbol: string;
    constructor(
        public readonly mission: Task,
        private readonly storage: StorageService,
        protected readonly game: MissionProgression,
        data?: CompilerLevelPersistence
    ) {
        if (data) {
            this.source = data.source;
        } else {
            // initial state
            this.source = this.getInitialCode();
        }
    }
    get compilerState() {
        return this.game.sharedCompiler;
    }
    abstract getInitialCode(): string;
    verify1() {
        for (const { text } of this.tests) {
            const result = testGrammar(text, this.compilerState.lexical, this.compilerState.rules, this.startSymbol);
            if (result instanceof VerificationError) {
                return result;
            }
        }
        return new VerificationOk();
    }
    verify(): VerificationResultSet {
        const result = this.verify1();
        this.isCompleted = (result instanceof VerificationOk);
        return new UnitVerificationResultSet(result);
    }
    hasState = false;
    resetState(): void {
        // TODO
    }
    save(): void {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        this.compilerState.store(storage);
        const repository = new Repository(storage);
        repository.saveLevel(this.mission.key, { source: this.source });
    }
    getComponent() {
        return (<GrammarMissionComponent missionState={this} />);
    }
    isCompleted = false;
}
