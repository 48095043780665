import { MissionTexts } from 'diagram/diagramMissionType';
import { TextService } from '../../locale/text.service';
import { Task } from '../task';

export class MissionTextLoader implements MissionTexts {
    constructor(readonly mission: Task, readonly textService: TextService) { }
    missionText(textKey: string) {
        return this.textService.getText('missions', this.mission.key, textKey);
    }
    missionOptText(textKey: string) {
        return this.textService.getOptText('missions', this.mission.key, textKey);
    }
    get title() { return this.missionText('title'); }
    get specification() { return this.missionText('specification'); }
    get splash() { return this.missionOptText('splash'); }
    get spoilers() { return this.textService.getNode('missions', this.mission.key, 'spoilers') as unknown as string[] | undefined; }
    get elaboration() { return this.missionOptText('elaboration'); }
    get debriefing() { return this.missionOptText('debriefing'); }
    hintText(key: string) { return this.textService.getText('missions', this.mission.key, 'hints', key); }
}
