import { enTexts, TranslationDict, TranslationNode } from "../locale/text.service";


export class TranslationContext {
    en: TranslationDict;
    other: TranslationDict;
    constructor(readonly lang: string) {
        this.en = enTexts;
        this.other = this.getLanguage();
    }
    getStorageKey() {
        return 'beta_translation_workspace_' + this.lang;
    }
    getLanguage() {
        const savedJson = window.localStorage.getItem(this.getStorageKey());
        if (savedJson !== null) {
            return JSON.parse(savedJson) as TranslationDict;
        } else {
            // new empty translation set
            return {};
        }
    }
    initLanguage() {
        return {};
    }
    getChildren() {
        return Object
                .entries(this.en)
                .map(([k, v]) => new TranslationItem(k, v, this));
    }
    getOther(path: string[]) {
        let current: TranslationNode | undefined = this.other;
        for (const key of path) {
            if (current === undefined) {
                return '';
            }
            current = (current as TranslationDict)[key];
        }
        return current;
    }
    setOther(path: string[], txt: string) {
        this.setText(this.other, path, txt);
        this.save();
    }
    setText(data: TranslationDict, path: string[], txt: string) {
        let current = data;
        const path1 = path.slice(0, path.length - 1);
        const lastKey = path.at(-1)!;
        for (const key of path1) {
            if (!(key in current)) {
                current[key] = {};
            }
            current = current[key] as TranslationDict;
        }
        current[lastKey] = txt;
    }
    save() {
        const json = this.jsonExport();
        window.localStorage.setItem(this.getStorageKey(), json);
    }
    jsonExport() { return JSON.stringify(this.other); }
}

// Indicates a node in the translation tree
export class TranslationItem {
    isDirty = false;
    constructor(readonly key: string, readonly item: TranslationNode, readonly translation: TranslationContext, private readonly parent?: TranslationItem) { }
    get isLeaf() { return typeof(this.item) === 'string'; }
    getChildren() {
        return Object
            .keys(this.item)
            .map(k => new TranslationItem(k, (this.item as TranslationDict)[k]!, this.translation, this));
    }
    get enText() { return this.item; }
    get path(): string[] { return (this.parent === undefined ? [] : this.parent.path).concat([this.key]); }
    get otherText() { return this.translation.getOther(this.path) as string; }
    set otherText(txt: string) { this.translation.setOther(this.path, txt); this.isDirty = true; }
}
