import { useState } from 'react';
import { Help } from './Help';
import { SyntaxNode } from './parse';
import { ErrorDisplay } from './ErrorDisplay';
import { ErrorResult, Option } from './shared';
import './SyntaxTreeComponent.css';

function SyntaxTreeNodeComponent(props: { node: SyntaxNode; hide: boolean }) {
    const node = props.node;
    if (props.hide && !node.token && node.children && node.children.length === 1) {
        return <SyntaxTreeNodeComponent hide={props.hide} node={node.children[0]!} />;
    }

    if (node.token) {
        return (
            <div className="syntax-branch">
                <div className="syntax-node terminal" title={node.token.value}>{node.type}</div>
            </div>
            );
    } else {
        const children = props.node.children.map((n, ix) =>
            <SyntaxTreeNodeComponent key={ix} node={n} hide={props.hide} />);
        const text = node.type;
        return (
            <div className="syntax-branch">
                <div className="syntax-node nonterminal">{text}</div>
                <div className="syntax-children">{children}</div>
            </div>
            );
    }
}

export function SyntaxTreeComponent(props: { syntaxTree: Option<SyntaxNode> }) {
    const [hide, setHide] = useState(false);
    if (props.syntaxTree instanceof ErrorResult) {
        return <ErrorDisplay message={props.syntaxTree.message} />;
    }
    return (
        <div>
            <Help>The syntax tree of the source code parsed by the grammar.</Help>

            <div className="syntax-tree-root">
                <SyntaxTreeNodeComponent node={props.syntaxTree} hide={hide} />
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={hide} onChange={event => setHide(event.target.checked)} />
                    Hide nodes which have only a single child.
                </label>
            </div>
        </div>
    );
}
