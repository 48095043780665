import React from 'react';
import './OutputConnectorComponent.css';
import './ConnectorComponent.css';
import { findParentDiagramNode, NodeLocation } from '../node/NodeComponent';
import { Pos } from '../../position';
import { toHex } from '../../../common/hex';
import { IOutputConnectorState } from '../../componentType';
import { CanvasContext, CanvasService, DiagramActions } from '../builder/DiagramBuilder';
import { ConnectorLocation } from './ConnectorLocation';
import { InputConnectorState, OutputConnectorState } from 'diagram/connectorState';

/* Not a react component, but an association between a connector and the rendered html element */
export class OutputConnectorLocation implements ConnectorLocation {
    constructor(readonly connector: OutputConnectorState,
        readonly element: SVGCircleElement,
        readonly canvasService: CanvasService,
        readonly nodeComponent: NodeLocation) { }
    get connectorIndex() { return this.connector.index; }
    // position of connector (relative to canvas), for drawing of arrows
    get canvasPos() {
        const pos = this.element.getBoundingClientRect();
        if (pos.left === 0 && pos.right === 0) {
            // Happens if element is deleted
            // Should not be possible, but happened because we held on to a deleted connection
            throw new Error('Disconnected element');
        }
        const canvasElem = this.canvasService.canvasElement;
        const canvasPos = canvasElem.getBoundingClientRect();
        // Round to whole pixels
        const pos1 = new Pos(
            Math.round(pos.left - canvasPos.left + 10),
            Math.round(pos.top - canvasPos.top + 4));
        return pos1;
    }

}

export function OutputConnectorComponent(props: {
        isPalette: boolean,
        isFree: boolean;
        selectedConnector: InputConnectorState | undefined;
		connector: IOutputConnectorState;
        diagramActions: DiagramActions;
    }) {

    const elementRef = React.createRef<SVGCircleElement>();
    const canvasService = React.useContext(CanvasContext);

    const connectorClassName =  props.connector.name;

    const state =  props.connector.state;
    const stateText = (state === null) ? '?' : toHex(props.connector.numState, 1);
    const isActive =  !props.isPalette;
    const isFree =  props.isFree;
    const sourceSelectMode = props.selectedConnector !== undefined;
    const stateVisible =  isActive && !sourceSelectMode && isFree && state !== null;
    const width =  props.connector.width;
    const oscillating =  props.connector.oscillating;

    function dragEnter(_event: React.DragEvent<SVGCircleElement>) {
    }

    function touchStart(_event: React.TouchEvent) {
        if (sourceSelectMode) {
            props.diagramActions.connect(props.connector as OutputConnectorState);
        }
    }
    function click(_event: React.MouseEvent) {
        if (sourceSelectMode) {
            props.diagramActions.connect(props.connector as OutputConnectorState);
        }
    }

    React.useEffect(() => {
        const element = elementRef.current;
        let connectorRef: OutputConnectorLocation | undefined = undefined;
        if (!props.isPalette && element) {
            connectorRef = new OutputConnectorLocation(props.connector as OutputConnectorState, element, canvasService,
                new NodeLocation(findParentDiagramNode(element), canvasService));
            canvasService.registerOutputConnectorComponent(connectorRef);
        }
        return ()=>{
            if (connectorRef) {
                canvasService.unregisterOutputConnectorComponent(connectorRef)
            }
        }
    }, []);

    function drop() {
        if (sourceSelectMode) {
            props.diagramActions.connect(props.connector as OutputConnectorState);
        }
    }

	const connector = props.connector;

	return (<div
        className={'output-connector connector ' + connectorClassName + (isActive ? ' active' : '') + (sourceSelectMode ? ' source-select-mode' : '')}>

    { stateVisible && (
        <div className='state'>{stateText}</div>)}

    <svg width='22' height='20' className={'svg-connector ' + (oscillating ? ' oscillating' : '')}>
        <circle r='14' cx={10} cy={10} className='connector-outline' />
        <circle r='10' cx={10} cy={10} className={'connector-circle' + (state ? ' active-state' : '')} />
        <text x='10' y='14' textAnchor='middle' className='connector-text output-connector-text'>
            {connector.name}
        </text>

        { width > 1 && (
            <text x='0' y='0' textAnchor='middle' className={'width-indicator'}>{width}</text>)}

        <circle r='10' cx={10} cy={10} data-connector={props.connector} ref={elementRef}
            onTouchStart={touchStart}
            onClick={click}
            onDragEnter={dragEnter}
            onDrop={drop}
            className='output-connector-droptarget' />
    </svg>
</div>
);
}




