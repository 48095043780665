import { Pos } from './position';
import { deleteItem } from '../common/utilities';
import { ComponentType } from './componentType';
import { NodeInputConnector, NodeOutputConnector, ComponentInstance, CircuitStructure, DiagramEvents, PaletteComponentInstance, InputConnector, EdgeOutputConnector, EdgeInputConnector, OutputConnector, InputGroupStructure, OutputGroupStructure } from './circuitStructure';
import { EdgeInputConnectorState, EdgeOutputConnectorState, NodeInputConnectorState } from './connectorState';
import { DiagramType } from './diagramMissionType';
import { Resolver } from './resolver';
import { ComponentInstanceState } from './componentState';
export { PaletteComponentInstance };




export const createPaletteNode = (nodeType: ComponentType) => new PaletteComponentInstance(nodeType);

export class InputStateGroup {
    constructor(public g: InputGroupStructure | null, public nodes: EdgeOutputConnectorState[]) { }
    get label() { return this.g?.label; }
}

export class OutputStateGroup {
    constructor(public g: OutputGroupStructure | null, public nodes: EdgeInputConnectorState[]) {}
    get label() { return this.g?.label; }
}

/*  Represent the state of a circuit
 *  There may be multiple state instances corresponding to a single circuit structure
 *  e.g each instance of a custom component will have a state instance for the same structure
 */
export class CircuitState implements DiagramEvents {

    inputGroups: InputStateGroup[] = [];
    outputGroups: OutputStateGroup[] = [];
    nodes: ComponentInstanceState[] = [];
    structureNodeMap = new WeakMap<ComponentInstance, ComponentInstanceState>();
    isUnstable = false;

    constructor(readonly structure: CircuitStructure) {
        this.nodes = structure.nodes.map(n => this.createNode(n));
        this.loadPins();
        this.update();
        structure.eventListeners.push(this);
        structure.onStructureChange.addListener(() => {
            this.update();
        });
    }
    loadPins() {
        this.inputGroups = this.structure.inputGroups.map(ig =>
            new InputStateGroup(ig, ig.nodes.map(icn => new EdgeOutputConnectorState(icn))));
        this.outputGroups = this.structure.outputGroups.map(og =>
            new OutputStateGroup(og, og.nodes.map(oun => new EdgeInputConnectorState(oun))));
    }
    get inputPins(): EdgeOutputConnectorState[] { return this.inputGroups.flatMap(ig => ig.nodes); }
    get outputPins(): EdgeInputConnectorState[] { return this.outputGroups.flatMap(ig => ig.nodes); }

    addNode(nodeType: ComponentType, pos: Pos) {
        const initialState = nodeType.hasPersistentState ? nodeType.initPersistentState() : undefined;
        const structureNode = this.structure.addNode(nodeType, pos, initialState);
        const node = this.structureNodeMap.get(structureNode);
        if (!node) {
            throw new Error('Node not found in map');
        }
        return node;
    }

    createNode(structureNode: ComponentInstance) {
        const node = new ComponentInstanceState(this, structureNode);
        this.structureNodeMap.set(structureNode, node);
        return node;
    }

    nodeAdded(structureNode: ComponentInstance) {
        this.nodes.push(this.createNode(structureNode));
    }

    deleteNode(node: ComponentInstanceState) {
        this.structure.deleteNode(node.componentInstance);
    }

    // event from structure when a node is deleted
    // delete corresponding node in state layer
    nodeDeleted(structureNode: ComponentInstance) {
        const node = this.structureNodeMap.get(structureNode);
        if (node) {
            deleteItem(this.nodes, node);
        }
    }
    outputPinAdded(cnn: EdgeInputConnector) {
        const cnnState = new EdgeInputConnectorState(cnn);
        this.outputGroups.push(new OutputStateGroup(null, [cnnState]));
    }
    inputPinAdded(cnn: EdgeOutputConnector) {
        const cnnState = new EdgeOutputConnectorState(cnn);
        this.inputGroups.push(new InputStateGroup(null, [cnnState]));
    }
    outputPinDeleted(cnn: EdgeInputConnector) {
        for (const grp of this.outputGroups) {
            grp.nodes = grp.nodes.filter(n => n.connector !== cnn);
        }
        this.outputGroups = this.outputGroups.filter(grp => grp.nodes.length > 0);
    }
    inputPinDeleted(cnn: EdgeOutputConnector) {
        for (const grp of this.inputGroups) {
            grp.nodes = grp.nodes.filter(n => n.connector !== cnn);
        }
        this.inputGroups = this.inputGroups.filter(grp => grp.nodes.length > 0);
    }
    update() {
        this.resolve();
    }

    findInputConnector(connectorStructure: InputConnector) {
        if (connectorStructure instanceof EdgeInputConnector) {
            // might be an output pin
            const outp = this.outputPins.find(n => n.connector === connectorStructure);
            if (!outp) { throw new Error('output pin not found'); }
            return outp;
        }
        const nodeConnector = connectorStructure as NodeInputConnector;
        const nodeStructure = nodeConnector.node;
        const node = this.structureNodeMap.get(nodeStructure);
        if (!node) {
            throw new Error(`node not found ${nodeStructure.nodeType.key}`);
        }
        return node.inputConnectorStates.single(ic => ic.connector === connectorStructure);
    }

    findOutputConnector(connectorStructure: OutputConnector) {
        if (connectorStructure instanceof EdgeOutputConnector) {
            // might be an input pin
            const inp = this.inputPins.find(n => n.connector === connectorStructure);
            if (!inp) { throw new Error('input pin not found'); }
            return inp;
        }
        const nodeConnector = connectorStructure as NodeOutputConnector;
        const nodeStructure = nodeConnector.node;
        const node = this.structureNodeMap.get(nodeStructure)!;
        const cnn = node.outputConnectorStates.find(ic => ic.connector === connectorStructure);
        if (!cnn) { throw new Error('connector not found'); }
        return cnn;
    }

    get disconnectedState() {
        if (this.structure.diagramType === DiagramType.TransistorLevel) {
            // In transistor-level diagrams, unconnected input pins are not implicitly connected to 0
            return null;
        }
        return 0;
    }

    setInputs(inputs: number[]) {
        if (inputs.length !== this.inputPins.length) {
            const names = this.inputPins.map(inp => inp.name);
            throw new Error(`Input array size mismatch: ${inputs} does not match ${names} `);
        }
        inputs.forEach((state, ix) => {
            this.inputPins[ix]!.setState(state);
        });
        this.update();
    }

    getOutputs() {
        return this.outputPins.map(n => n.state);
    }

    resolveInputs(inputs: number[]) {
        this.setInputs(inputs);
        return this.getOutputs();
    }

    clearInputs() {
        for (const input of this.inputPins) {
            input.clear();

        }
        this.update();
    }
    get hasState() {
        return this.structure.nodes.some(n => n.hasState) || this.structure.hasCircularConnection;
    }
    resetState() {
        for (const node of this.nodes) {
            node.internalState.reset();
            for (const c of node.outputConnectorStates) {
                c.state = 0;
            }
            for (const c of node.inputConnectorStates) {
                c.state = 0;
            }
        }
        this.update();
    }
    clearCanvas() {
        this.structure.clearCanvas();
    }
    findInternalNode(type: ComponentType) {
        return this.nodes.find(n => n.nodeType === type);
    }
    findInternalNodes(type: ComponentType) {
        return this.nodes.filter(n => n.nodeType === type);
    }
    resolve() {
        const resolver = new Resolver(this);
        const unstable = resolver.resolve();
        if (unstable.length === 0) {
            this.isUnstable = false;
        } else {
            this.isUnstable = true
            this.markUnstable(unstable);
        }
    }
    markUnstable(unstable: ComponentInstanceState[]) {
        const marked: ComponentInstanceState[] = [];
        for (const node of unstable) {
            this.markUnstableRecursive(node, marked);
        }
    }

    markUnstableRecursive(node: ComponentInstanceState, marked: ComponentInstanceState[]) {
        if (marked.includes(node)) {
            return;
        }
        node.markError('Unstable');
        marked.push(node);
        for (const connector of node.outputConnectorStates) {
            connector.state = null;
            connector.oscillating = true;
            for (const connection of connector.connector.connections) {
                const inputConnector = this.findInputConnector(connection.targetConnector);
                inputConnector.state = null;
                inputConnector.oscillating = true;
                if (inputConnector instanceof NodeInputConnectorState) {
                    this.markUnstableRecursive(inputConnector.node, marked);
                }
            }
        }
    }
}


