import { CodeMissionPersistence, MissionSourceFile } from './codeMission';
import { AssemblerMissionState } from './assemblerMissions';
import {
    UnitVerificationResultSet,
    VerificationError,
    VerificationOk,
    VerificationResult,
    VerificationResultSet,
} from '../app/verificationResults';
import { MissionKind, Task } from '../app/task';
import { Repository } from '../app/repository';
import { MissionProgression } from '../app/missionProgression';
import { IoRegister, Machine, MemoryMapping } from '../assembler/machine';
import { MacroAssembler } from '../assembler/assembler';
import { Controller1 } from '../assembler/controller';
import { DisplayDevice, getFigureBounds } from '../engine/devices/DisplayComponent';
import { StackInstructionsSet } from './stack/stackInstructionsSet';
import { ConstantsProvider, InstructionProvider } from '../assembler/instructionProvider';
import { NetworkDevice } from '../engine/devices/networkDevice';
import { StorageService } from 'common/storage.service';
import { NetworkMissionComponent } from './NetworkMissionComponent';
import { SourceFile } from 'ide/sourceFile';
import { AssemblerEditorBackend } from 'assembler/assemblerEditorBackend';
import { FileModel } from 'assembler/fileModel';

/*

*/
export function verifyNetworkCode(
    source: SourceFile,
    macroProvider: InstructionProvider,
    sharedConstants: ConstantsProvider,
): VerificationResult {
    // (1) Verify syntax of source code
    const assembler1 = new MacroAssembler(macroProvider, sharedConstants);
    const program = assembler1.assemble(source.load(), source);
    const errors = program.getAllErrors();
    if (errors.length > 0) {
        return new VerificationError(`Syntax error in assembler code: ${errors[0]} `);
    }
    if (program.instructions.length === 0) {
        return new VerificationError('Program does not have any instructions.');
    }
    // (2) Execute code in sandbox
    // IO
    const mappings: MemoryMapping[] = [];
    // display
    const display = new DisplayDevice();
    mappings.push({from: 0x4000, to: 0x5FFF, device: display});
    // network device
    const networkDevice = new NetworkDevice();
    const networkRegister = new IoRegister(networkDevice);
    mappings.push({from: 0x6001, to: 0x6001, device: networkRegister});
    //networkDevice.changed.addListener(() => networkRegister.);
    //networkRegister.changed = () => callback();
    const machine = new Machine(mappings);
    const assembler = new MacroAssembler(macroProvider, sharedConstants);
    const provider = new AssemblerEditorBackend('?', new FileModel(source));
    const controller = new Controller1(machine, provider, assembler);

    const max = 100000;
    for (let i = 0; i < max; i++) {
        controller.tick();
        if (i % 200 === 0 && i > 100) {
            networkDevice.step();
            // console.log('network step', networkRegister.get());
            // check display
            for (let index = 0x4000; index < 0x6000; index++) {
                const x = display.peek(index);
                if (x) {
                    console.log('SCREEN', index, x.toString(2));
                }
            }
        }
    }
    const bounds = getFigureBounds(display);
    if (!bounds) {
        return new VerificationError(`Ran ${max} clock cycles, but did not detect any display output.`);
    }
    if (bounds.width !== 13 || bounds.height !== 11) {
        return new VerificationError(`The displayed shape does not have the expected dimensions. (Was ${bounds.width} x ${bounds.height})`);
    }

    return new VerificationOk();
}

export class NetworkMissionState implements AssemblerMissionState {
    enableDisplay = false;
    enableLamp = false;
    enableKeyboard = false;
    code: MissionSourceFile;
    isCompleted = false;
    globalConstants;
    constructor(readonly mission: Task,
        private readonly storage: StorageService,
        protected readonly game: MissionProgression,
        readonly data?: CodeMissionPersistence) {
        const code = this.getInitialCode(data);
        this.code = new MissionSourceFile(code, this);
        this.globalConstants = game.sharedConstants;
    }
    private getInitialCode(data?: CodeMissionPersistence) {
        if (data) {
            return data.code;
        } else {
            // default source text
            return `# Assembler code \n`;
        }
    }
    save(): void {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        const data: CodeMissionPersistence = { code: this.code.text };
        const repository = new Repository(storage);
        repository.saveLevel(this.mission.key, data);
    }
    verify(): VerificationResultSet {
        const macroProvider = new StackInstructionsSet(this.game);
        const result = verifyNetworkCode(this.code, macroProvider, this.game.sharedConstants);
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
    hasState = false;
    resetState() {
        /* not supported in the toolbar, although there is a reset button on the engine */
    }
    getComponent() {
        return (<NetworkMissionComponent missionState={this} />);
    }
}

export const networkRecieveMission = new class implements Task {
    readonly key = 'ASSEMBLER_NETWORK_RECEIVE';
    readonly kind = MissionKind.Custom;

    start(storage: StorageService, missions: MissionProgression): NetworkMissionState {
        return new NetworkMissionState(this, storage, missions);
    }
    restore(storage: StorageService, missions: MissionProgression): NetworkMissionState {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as CodeMissionPersistence;
        return new NetworkMissionState(this, storage, missions, data);
    }
};
