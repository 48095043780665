interface StringStorage {
    setItem(key: string, value: string): void;
    getItem(key: string): string | null;
    removeItem(key: string): void;
    getKeys(): string[];
}

export class PersistentStorageService implements StringStorage {
    setItem(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }
    getItem(key: string) {
        return window.localStorage.getItem(key);
    }
    removeItem(key: string) {
        window.localStorage.removeItem(key);
    }
    getKeys() {
        const keys = [];
        for (let ix = 0; ix < window.localStorage.length; ix ++) {
            const val = window.localStorage.key(ix);
            if (val) {
                keys.push(val);
            }
        }
        return keys;
    }
}

export interface StorageService {
    setItem(key: string, data: unknown): void;
    getItem<T>(key: string): T | null;
    removeItem(key: string): void;
    getKeys(): string[];
}


export class JsonStorageService implements StorageService {
    constructor(protected readonly storage: PersistentStorageService) { }

    setItem(key: string, data: unknown) {
        const json = JSON.stringify(data);
        this.storage.setItem(key, json);
    }
    getItem<T>(key: string) {
        const jsonString = this.storage.getItem(key);
        if (jsonString == null) {
            return null;
        }
        return JSON.parse(jsonString) as T;
    }
    removeItem(key: string) {
        this.storage.removeItem(key);
    }
    getKeys() { return this.storage.getKeys(); }
}


/** Stores strings in a dictionary rather than LocalStorage */
class InMemoryStringStorage implements StringStorage {
    data = new Map<string, string>();
    setItem(key: string, value: string) {
        this.data.set(key, value);
    }
    getItem(key: string) {
        return this.data.get(key) ?? null;
    }
    removeItem(key: string) {
        this.data.delete(key);
    }
    getKeys() {
        return Array.from(this.data.keys());
    }
}

export class InMemoryStorage extends JsonStorageService {
    constructor() {
        super(new InMemoryStringStorage());
    }
    get data() { return (this.storage as InMemoryStringStorage).data; }
}
