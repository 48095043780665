import { Repository } from '../app/repository';
import { MissionKind, MissionState, Task } from '../app/task';
import { disassemble } from '../assembler/instructions';
import { EventEmitter2 } from '../common/eventEmitter';
import { UnitVerificationResultSet, VerificationError, VerificationOk, VerificationResult, VerificationResultSet } from '../app/verificationResults';
import { ArrayCodeUnit, CodeLine } from '../machinecode/codeline';
import { StorageService } from 'common/storage.service';
import { MachineCodeMissionComponent } from './MachineCodeMissionComponent';

// wrapper to expose a machine code provider for the engine
class MachineCodeProvider {
    constructor(private state: CodeMissionState) { }
    get size() { return this.state.code.lines.length; }
    currentValueChanged = new EventEmitter2<number>();
    fetch(address: number): number {
        if (address >= this.state.code.lines.length) {
            return 0;
        } else {
            return this.state.code.lines[address]!.value;
        }
    }
}

export function verifyLines(lines: CodeLine[]): VerificationResult {
    const expected = ['D = 0', 'A = 2', 'D = D+1', 'JMP'];
    for (let i = 0; i < expected.length; i++) {
        const expectedInstruction = expected[i];
        const actual = lines[i]!.value;
        const actualInstruction = disassemble(actual).toText();
        if (actualInstruction !== expectedInstruction) {
            return new VerificationError(`Instruction at address ${i} is '${actualInstruction ?? '???'}' but should be '${expectedInstruction}' `);
        }
    }
    return new VerificationOk();
}

export class CodeMissionState implements MissionState {
    isCompleted = false;
    code: ArrayCodeUnit;
    codeProvider = new MachineCodeProvider(this);
    constructor(private readonly storage: StorageService, data?: CodePersistence) {
        const code = this.getCode(data);
        this.code = new ArrayCodeUnit(code);
        this.code.onChange = () => this.save();
    }
    getCode(data?: CodePersistence) {
        if (data) {
            return data.code;
        } else {
            return [0, 0, 0, 0];
        }
    }
    changed() { this.save(); }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        const data: CodePersistence = { code: this.code.lines.map(l => l.value) };
        const repository = new Repository(storage);
        repository.saveLevel(programMission1.key, data);
    }
    verify(): VerificationResultSet {
        const result = verifyLines(this.code.lines);
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
    hasState = false;
    resetState() {
        // TODO: connection controller to state, so it can be reset from here
        // this.controller.reset();
    }
    getComponent() {
        return (<MachineCodeMissionComponent missionState={this} />);
    }
}

type CodePersistence = {code: number[]};

export const programMission1 = new class implements Task {
    readonly key = 'PROGRAM1';
    readonly kind = MissionKind.Custom;
    start(storage: StorageService) {
        return new CodeMissionState(storage);
    }
    restore(storage: StorageService) {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as CodePersistence;
        return new CodeMissionState(storage, data);
    }
    store(_storage: StorageService) {
    }
};
