import { SyntaxRule } from './SyntaxRules';
import { Help } from './Help';
import { PatternType, TokenAction, TokenSpec } from './tokenize';
import { Workbench } from './Workbench';
import { JsonStorageService, PersistentStorageService, StorageService } from '../common/storage.service';
import { Repository } from '../app/repository';
import { TokenSpecification } from './tokenSpecification';

export const START_SYMBOL = 'PROGRAM';

type CompilerPersistence = {
    source: string;
    lexical: TokenSpecPersistence[];
    runtimeLibrary: string;
    rules: RulePersistence[];
};
type TokenSpecPersistence = {
    type: PatternType;
    pattern: string;
    action: TokenAction;
    label?: string;
};
type RulePersistence = {
    lhs: string;
    rhs: string;
    codegen: string;
};

export const initial = {
    source: '2 + 2',
    lexical: [
        new TokenSpecification(0, PatternType.Pattern, '[ ]+', TokenAction.Ignore),
        new TokenSpecification(1, PatternType.Pattern, '[0-9]+', TokenAction.Name, 'Number'),
        new TokenSpecification(2, PatternType.Exact, '+ -', TokenAction.Literal),
    ],
    rules: [
        new SyntaxRule('PROGRAM', 'Expression', 'FUNCTION main 0\n[Expression]\nreturn'),
        new SyntaxRule('Expression', 'Expression + Number', '[Expression]\n[Expression]\nADD'),
        new SyntaxRule('Expression', 'Number', 'push.value [Number]'),
    ],
    runtimeLibrary: 'function mul 0\n// implementation missing\nPUSH_VALUE 0\nRETURN\n',
};

class LanguageConfiguration {
    storage = new JsonStorageService(new PersistentStorageService());
    key = 'PLAYGROUND:LANGUAGE:1';
    source;
    lexical;
    rules;
    runtimeLibrary;
    constructor() {
        const repository = new Repository(this.storage);
        const data = repository.getLevelData<CompilerPersistence>(this.key);
        if (data) {
            this.source = data.source;
            this.lexical = data.lexical.map((s, ix) => new TokenSpecification(ix, s.type, s.pattern, s.action, s.label));
            this.rules = data.rules?.map(r => new SyntaxRule(r.lhs, r.rhs, r.codegen)) ?? [];
            this.runtimeLibrary = data.runtimeLibrary ?? '';
        } else {
            this.source = initial.source;
            this.lexical = initial.lexical;
            this.rules = initial.rules;
            this.runtimeLibrary = initial.runtimeLibrary;
        }
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService): void {
        const repository = new Repository(storage);
        repository.saveLevel(this.key,
            {
            source: this.source,
                lexical: this.lexical.map(s => ({
                        type: s.type,
                        pattern: s.pattern,
                        action: s.action,
                        label: s.label,
                        }) as TokenSpec),
                rules: this.rules.map(r => ({
                        lhs: r.lhs,
                        rhs: r.rhs,
                    codegen: r.codegen }) as RulePersistence),
        } as CompilerPersistence);
    }
}

export function CompilerPlayground() {
    const config = new LanguageConfiguration();
    return (
        <div>
            <div style={{ marginLeft: '30px' }}>
                <Help>
                    <p>
                        Welcome to <b>Build your own compiler</b>
                    </p>
                    <p>
                        The pre-configured language only support adding integers, but the compiler can be expanded to support arbitrary
                        complex languages.
                    </p>
                </Help>
            </div>
            <Workbench source={config.source} config={config} startSymbol='START_SYMBOL' />
        </div>
    );
}
