export class Placeholder {
    constructor(readonly name: string, readonly definesLabel = false) { }
}

export interface Macro {
    readonly identifier: string;
    readonly placeholders: readonly Placeholder[];
    readonly expansion: string;
}

export interface InstructionProvider {
    names: string[];
    get(name: string): Macro | undefined;
}

export interface ConstantsProvider {
    names: string[];
    get(name: string): number | undefined;
}
