import { CustomComponentPersistence } from './customComponentPersistence';
import { StorageService } from '../common/storage.service';

const componentsKey = 'NandGame:CustomComponents';
const componentKey = 'NandGame:CustomComponent:';

export class CustomComponentRepositoryService {
    constructor(private readonly storage: StorageService) { }

    getComponentKey(key: string) {
        return componentKey + key;
    }

    getComponents() {
        return this.storage.getItem<string[]>(componentsKey);
    }

    getComponent(key: string) {
        const comp = this.storage.getItem<CustomComponentPersistence>(this.getComponentKey(key));
        if (comp == null) { throw new Error(`No diagram stored for ${key} `); }
        return comp;
    }

    saveComponent(componentKey: string, data: CustomComponentPersistence) {
        this.storage.setItem(this.getComponentKey(componentKey), data);
    }

    deleteComponent(componentKey: string) {
        this.storage.removeItem(this.getComponentKey(componentKey));
    }

    saveComponents(keys: string[]) {
        this.storage.setItem(componentsKey, keys);
    }
}

