import './App.css';
import { AppRoot } from './app/AppRoot';
import { GameStateContext, initGameState } from './app/gameState';
import { TextContext, TextService } from './locale/text.service';
import './errors';


function App() {
  const textService = new TextService();
  return (
    <GameStateContext.Provider value={initGameState()}>
    <TextContext.Provider value={textService}>
      <AppRoot />
    </TextContext.Provider>
    </GameStateContext.Provider>
  );
}

export default App;
