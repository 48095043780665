import { Constant, SharedConstants } from './sharedConstants';
import './Constants.css';
import { WithHelpBox } from './WithHelpBox';

export function ConstantsComponent({constants, updated}: {constants: SharedConstants, updated: () => void}) {
    function add() {
        constants.add();
        updated();
    }
    function changeName(c: Constant, value: string) {
        c.name = value;
        updated();
    }
    function changeValue(c: Constant, value: string) {
        constants.updateValue(c, value);
        updated();
    }
    function deleteConstant(c: Constant) {
        constants.delete(c);
        updated();
    }
    const rows = constants.constants.map((c, ix) => (
        <tr key={ix}>
            <td>
                <input className='form-control' value={c.name} onChange={event => changeName(c, event.target.value)} />
            </td>
            <td>
                <input className='form-control' value={c.value} onChange={event => changeValue(c, event.target.value)} />
            </td>
            <td>{c.value === undefined && !(c.name === '' && c.valueText === '') && <i className='bi bi-exclamation-triangle-fill'></i>}</td>
            <td><button onClick={()=>deleteConstant(c)} className='btn btn-secondary delete-constant'><i className='bi bi-trash' title='Delete constant'></i></button></td>
        </tr>
    ));
    let consts;
    if (constants.constants.length > 0) {
        consts = <><table className=''>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Number</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
            <button onClick={add} className="btn btn-secondary">
                Add
            </button></>;
    } else {
        consts = <button onClick={add} className="btn btn-secondary">
                            Define Constant
                        </button>;
    }
    return (
        <div className="constants">
            <WithHelpBox>
                <p>Constants associate a name with a number. The name can be used in the assembler code, and will be replaced with the numeric value by the assembler.</p>
                <p>For example <code>A = SP</code> means <code>A = 0</code> if SP is defined as 0.</p>
                <p>Using names instead of numbers can make the code easier to understand.</p>
            </WithHelpBox>

            <h3>Shared Constants</h3>
            {consts}
        </div>
    );
}

