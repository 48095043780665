import { DiagramMissionsSet, MissionProgression, MissionItem } from './missionProgression';
import { CustomComponentBuilders } from 'diagram/customComponentBuilders';
import { DiagramRepository } from './diagramRepository';
import { DiagramDeserializer } from 'diagram/diagramPersistence';
import { CustomComponentType } from 'diagram/customComponent';
import { CircuitStructure } from 'diagram/circuitStructure';
import { CustomComponentPersistence } from 'diagram/customComponentPersistence';
import { CustomComponentRepositoryService } from 'diagram/customComponentRepository.service';
import { getMissions } from 'missions/track';
import { CustomComponents } from 'diagram/customComponents';
import { MissionNavigator } from './missionProgression';
import { addcMission } from 'diagram/missions/arithmeticMissions';
import { notNull } from '../common/utilities';
import { MissionStatus } from './missionStatus';
import { Task } from './task';
import React from 'react';
import { JsonStorageService, PersistentStorageService, StorageService } from '../common/storage.service';
import { toJSON } from 'common/json';


export class GameState {
    missionProgression!: MissionProgression;
    missionNavigator!: MissionNavigator;
    customComponents!: CustomComponentBuilders;
    diagramSet!: DiagramMissionsSet;
    currentMission!: MissionItem;
    levelRepository;

    constructor(readonly storage: StorageService, private readonly repository: CustomComponentRepositoryService) {
        this.levelRepository = new DiagramRepository(storage);
        this.loadState();
        this.startGame();
    }
    loadState() {
        new GameLoader(this.storage, this.repository, this);
    }
    startGame() {
        let mission: MissionItem;
        if (this.isNewGame()) {
            mission = this.missionNavigator.getFirstMission();
        } else {
            const maybeMission = this.missionNavigator.getFirstIncomplete();
            if (maybeMission) {
                mission = maybeMission;
            } else {
                mission = this.missionNavigator.getLastMission();

                // No incomplete missions in hardware epic.
                // we navigate to code epic UNLESS hardware epic have been explicitly selected
                /*
                const selectedEpic = sessionState.selectedEpic();
                if (selectedEpic === null || selectedEpic === 'code') {
                    window.location.assign('/code');
                    return;
                }
                */
            }
        }
        this.selectMission(mission);
    }
    isNewGame() {
        return this.levelRepository.isNewGame();
    }
    get hasUnlockedCustomComponents() {
        // after add 16, we allow custom components
        const addcitem = this.diagramSet.getDiagramMissionStateByType(addcMission);
        return addcitem && addcitem.isCompleted;
    }
    selectMission(item: MissionItem) {
        if (item.status === MissionStatus.Locked || item.status === MissionStatus.Unlocked) {
            item.start(this.storage, this.missionProgression);
        }
        this.missionProgression.save();
        this.currentMission = item;
        // remember selected mission across page reloads
        sessionState.selectMission(item.mission);
    }

    getUserId() {
        let userId = localStorage.getItem('Nandgame:UserId');
        if (!userId) {
            userId = window.crypto.randomUUID();
            localStorage.setItem('Nandgame:UserId', userId);
        }
        return userId;
    }

    getSnapshot() {
        const data: Record<string, unknown> = {};
        this.storage.getKeys()
            .filter(key => key.startsWith('NandGame:') && key !== 'Nandgame:UserId')
            .forEach(key => data[key] = this.storage.getItem(key));
        return data;
    }

    backup() {
        const snapshotData = this.getSnapshot();
        const userId = this.getUserId();
        const data = { userId: userId, snapshot: { data: snapshotData } };
        const json = toJSON(data);
        // fire and forget
        void window.fetch('/api/account/progress', {
            method: 'POST',
            body: json
            }).catch(e => {console.error(e)});
    }

    restoreShapshot(snapshotData: Record<string, unknown>) {
        for (const [key, value] of Object.entries(snapshotData)) {
            localStorage.setItem(key, JSON.stringify(value));
        }
        this.loadState();
        this.startGame();
    }
}

const sessionState = {
    selectedEpic() {
        return window.sessionStorage.getItem('Nandgame:selected-epic');
    },
    selectMission(mission: Task) {
        window.sessionStorage.setItem('Nandgame:selected-mission', mission.key);
    }
}

class PartiallyLoadedNode {
    constructor(readonly nodeType: CustomComponentType, readonly data: CustomComponentPersistence) { }
}

class GameLoader {
    diagramPersister: DiagramDeserializer;

    constructor(readonly storage: StorageService,
        private readonly repository: CustomComponentRepositoryService,
        gameState: GameState) {

        // We need to be careful about loading state in the correct order
        // because of the inter-dependencies between components and missions

        // (1) load interfaces of custom components, but not the diagrams
        const customNodeInterfaces = this.restoreInterfaces();

        const customNodeTypes = customNodeInterfaces.map(pair => pair.nodeType);
        this.diagramPersister = new DiagramDeserializer(customNodeTypes);

        // Load definitions (diagrams) into custom components
        // depends on interfaces being loaded since a diagram may contain other components
        for (const item of customNodeInterfaces) {
            this.restoreDiagram(item, this.diagramPersister);
        }

        // Load history (depends on interfaces of custom components)
        const missions = getMissions();
        const progression = new MissionProgression(storage, this.diagramPersister, missions);
        const diagramSet = new DiagramMissionsSet(progression);
        gameState.missionProgression = progression;
        gameState.diagramSet = diagramSet;
        gameState.missionNavigator = new MissionNavigator(progression);
        const customComponents = new CustomComponents(customNodeTypes);
        gameState.customComponents = new CustomComponentBuilders(storage, diagramSet, customComponents);

        // need to run tests after all the mission items have been loaded
        // sinde a mission may depend on macros on other missionItems
        progression.testAll(progression.activatedItems);
    }

    restoreInterfaces() {
        const comps = this.repository.getComponents() ?? [];
        return comps
            .map(key => this.restoreInterfaceSafe(key))
            // null if restore caused an error. Filter nulls out.
            .filter(notNull);
    }

    restoreInterfaceSafe(key: string) {
        try {
            const data = this.repository.getComponent(key);
            return this.restoreInterface(data, this.storage);
        } catch (e) {
            // errors in restore are skipped
            console.error('Restore error for ', key, e);
            return null;
        }
    }

    restoreInterface(data: CustomComponentPersistence, storage: StorageService): PartiallyLoadedNode {
        const diagram = new CircuitStructure([], []);
        for (const grp of data.inputs) {
            for (const inp of grp.pins) {
                diagram.addInputPin(inp.label, inp.width);
            }
        }
        for (const grp of data.outputs) {
            for (const inp of grp.pins) {
                diagram.addOutputPin(inp.label, inp.width);
            }
        }
        const node = new CustomComponentType(data.key, data.name, diagram, storage);
        return new PartiallyLoadedNode(node, data);
    }

    restoreDiagram(item: PartiallyLoadedNode, diagramPersister: DiagramDeserializer) {
        diagramPersister.restoreDiagram(item.data.diagram, item.nodeType.diagram);
    }
}

export const GameStateContext = React.createContext(null as unknown as GameState);

export function initGameState() {
    const storage = new JsonStorageService(new PersistentStorageService());
    const ccRepository = new CustomComponentRepositoryService(storage)
    const gameState = new GameState(storage, ccRepository);
    return gameState;
  }
