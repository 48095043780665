import { generateCode } from '../compiler/codeGeneration';
import { runMain } from '../vm/engine';
import { parse } from '../compiler/parse';
import { ErrorResult } from '../compiler/shared';
import { SyntaxRule, validateCodegenRules, validateGrammarRules } from '../compiler/SyntaxRules';
import { getTerminalSymbols, tokenize } from '../compiler/tokenize';
import { linkVm, parseVm, parseVmStr } from '../vm/vmParse';
import { CompilerConfig } from '../compiler/Workbench';
import { VerificationError } from '../app/verificationResults';
import { TokenSpecification } from '../compiler/tokenSpecification';

export type GrammarTest = {
    readonly text: string;
    readonly expected: string[];
    readonly result: number;
};

export const expressionTests: GrammarTest[] = [
    { text: '2 + 2', expected: ['Number', '+', 'Number'], result: 4 },
    { text: '3 - 2', expected: ['Number', '-', 'Number'], result: 1 },
    {
        text: '6 - (3 - 1)',
        expected: ['Number', '-', '(', 'Number', '-', 'Number', ')'],
        result: 4
    },
    { text: '-3 + 4', expected: ['-', 'Number', '+', 'Number'], result: 1 },
    { text: '8 + (-4)', expected: ['Number', '+', '(', '-', 'Number', ')'], result: 4 },
];

export const controlStructuresTests = expressionTests.concat([
    {
        text: 'if (7 > 4) { 5 }',
        expected: ['if', '(', 'Number', '>', 'Number', ')', '{', 'Number', '}'],
        result: 5,
    },
    {
        text: 'if (4 > 7) { 5 } else { 7 }',
        expected: ['if', '(', 'Number', '>', 'Number', ')', '{', 'Number', '}', 'else', '{', 'Number', '}'],
        result: 7,
    },
    /*
    {
        text: 'while (2 < 4) { 6 }',
        expected: ['while', '(', 'Number', '<', 'Number', ')', '{', 'Number', '}'],
        result:
    },*/
]);

export const functionTests = expressionTests.concat([
    {
        text: 'function main() { seven(); }\nfunction seven() { 7 }',
        expected: ['function', 'Identifier', '(', ')', '{', 'Identifier', '(', ')', ';', '}', 'function', 'Identifier', '(', ')', '{', 'Number', '}'],
        result: 7,
    },
]);



export function testTokenize(code: string, lexical: TokenSpecification[]) {
    const errorContext = `Error when parsing '${code}'. `;
    const validatedLexical = lexical.filter(l => l.isValid);
    const tokenSet = tokenize(code, validatedLexical);
    if (tokenSet instanceof ErrorResult) {
        return new VerificationError(errorContext + tokenSet.message);
    }
    return { tokenSet, validatedLexical };
}

export function testGrammar(code: string, lexical: TokenSpecification[], rules: SyntaxRule[], startSymbol: string) {
    const tokenizeResult = testTokenize(code, lexical);
    if (tokenizeResult instanceof VerificationError) {
        return tokenizeResult;
    }
    const { tokenSet, validatedLexical } = tokenizeResult;
    const terminals = getTerminalSymbols(validatedLexical);
    const parsedGrammar = validateGrammarRules(rules, terminals);
    const errorContext = `Error when parsing '${code}'. `;
    if (parsedGrammar instanceof ErrorResult) {
        return new VerificationError(errorContext + parsedGrammar.message);
    }
    const syntaxTree = parse(startSymbol, tokenSet.tokens, parsedGrammar);
    if (syntaxTree instanceof ErrorResult) {
        return new VerificationError(errorContext + syntaxTree.message);
    }
    return { syntaxTree };
}

export function testCodegen(code: string, config: CompilerConfig, startSymbol: string) {
    const gammarResult = testGrammar(code, config.lexical, config.rules, startSymbol);
    if (gammarResult instanceof VerificationError) {
        return gammarResult;
    }
    const { syntaxTree } = gammarResult;
    const rules = config.rules;
    const codegenRules = validateCodegenRules(rules);
    if (codegenRules instanceof ErrorResult) {
        return new VerificationError(`Error in code generation rules for '${code}'. ` + codegenRules.message);
    }
    const generatedCode = generateCode(syntaxTree, codegenRules);
    if (generatedCode instanceof ErrorResult) {
        return new VerificationError(`Error in code generation for '${code}'. ` + generatedCode.message);
    }
    const generatedParsed = parseVm(generatedCode);
    if (generatedParsed instanceof ErrorResult) {
        return new VerificationError(`Error in generated code for '${code}'. ` + generatedParsed.message);
    }
    const runtimeLibraryParsed = parseVmStr(config.runtimeLibrary);
    if (runtimeLibraryParsed instanceof ErrorResult) {
        return new VerificationError(`Error in runtime library'. ` + runtimeLibraryParsed.message);
    }
    const linked = linkVm([generatedParsed, runtimeLibraryParsed]);
    if (linked instanceof ErrorResult) {
        return new VerificationError(`Error in lining'. ` + linked.message);
    }
    const runResult = runMain(linked);
    if (runResult instanceof ErrorResult) {
        return new VerificationError(runResult.message);
    }
    return { generatedCode: generatedCode, runResult: runResult };
}
