import { DummySourceUnit, SourceUnit } from 'common/location';
import { ConstantsProvider, InstructionProvider, Placeholder } from './instructionProvider';
import { Instruction } from './instructions';
import { Parser } from './parser';
import { AsmDefine, AsmInstructionSyntax, AsmLabel, AsmLoadLabelInstruction, AsmMacroInvocation, AsmSyntax, DefineDef, Definition, Definitions, GlobalDef, LabelDef, PlaceholderDef } from './syntax';

/*
 * Parsed syntax tree for an assembler program

    Process the syntax tree in multiple stages:
    - registers all labels and defines
    - expand macros
 */
export class AssemblerProgram {
    // instructions is the code with all macros expanded
    instructions: AsmInstructionSyntax[] = [];
    constructor(readonly lines: AsmSyntax[],
        readonly globalConstants: ConstantsProvider,
        readonly placeholders?: PlaceholderValues
    ) {
        const scope = new Definitions();
        const defs = this.definitionsPass(lines, scope);
        this.hoistLabels(lines, defs);
        this.resolvePass(lines, defs);
        this.instructions = [];
        this.resolveAddresses(lines);
        this.resolveLabelAddresses(lines);
    }

    get machineCode() {
        return this.instructions.map(i => i.instruction.toWord());
    }

    get errors() {
        return this.lines.filter(i => !i.isValid);
    }
    // errors including errors in macro expansions
    getAllErrors() {
        function getAllErrors(lines: AsmSyntax[]): string[] {
            const errors = lines.filter(i => !i.isValid).map(l => l.errorText!);
            const macroErrors = lines
                .filter(line => line instanceof AsmMacroInvocation)
                .flatMap(line => getAllErrors(line.expansion));
            return errors.concat(macroErrors);
        }
        return getAllErrors(this.lines);
    }

    checkNameInUse(scope: Definitions, name: string, node: AsmSyntax) {
        const def = this.lookupDefinition(scope, name);
        if (def) {
            node.setError(`Label '${name}' is already defined.`);
            return true;
        }
        return false;
    }

    registerLabel(scope: Definitions, labelNode: AsmLabel) {
        const name = labelNode.identifier;
        const id = labelNode.identifier.toUpperCase();
        // a label can have the same name as a placeholder (e.g. in function)
        // but cannot have the name of a DEFINE, global or another label
        if (scope.labels.get(id) || scope.defines.get(id)) {
            labelNode.setError(`Label '${name}' is already defined.`);
            return;
        }
        // NOTE: Not case-insensitive
        const global = this.globalConstants.get(name);
        if (global !== undefined) {
            labelNode.setError(`A shared constant with the name '${name}' is already defined.`);
            return;
        }

        scope.labels.set(id, labelNode);
    }
    registerDefine(scope: Definitions, defineNode: AsmDefine) {
        if (!this.checkNameInUse(scope, defineNode.identifier, defineNode)) {
            const id = defineNode.identifier.toUpperCase();
            scope.defines.set(id, defineNode);
        }
    }
    lookupDefinition(scope: Definitions, name: string): Definition | undefined {
        const id = name.toUpperCase();
        const label = scope.labels.get(id);
        if (label) {
            return new LabelDef(label);
        }
        const define = scope.defines.get(id);
        if (define) {
            return new DefineDef(define);
        }
        // NOTE: Not case-insensitive
        const global = this.globalConstants.get(name);
        if (global !== undefined) {
            return new GlobalDef(global);
        }
        const placeholder = this.placeholders?.get(name);
        if (placeholder !== undefined) {
            return new PlaceholderDef(placeholder);
        }
        return undefined;
    }

    // Registers labels and DEFINE's
    definitionsPass(lines: AsmSyntax[], scope: Definitions) {
        for (const line of lines) {
            if (line.isValid) {
                if (line instanceof AsmLabel) {
                    this.registerLabel(scope, line);
                }
                if (line instanceof AsmDefine) {
                    this.registerDefine(scope, line);
                }
                if (line instanceof AsmMacroInvocation) {
                    const subScope = new Definitions();
                    this.definitionsPass(line.expansion, subScope);
                    line.definitions = subScope;
                }
            }
        }
        return scope;
    }

    hoistLabels(lines: AsmSyntax[], scope: Definitions) {
        for (const line of lines) {
            if (line.isValid && line instanceof AsmMacroInvocation && line.definitions) {
                const subScope = line.definitions;
                this.hoistLabels(line.expansion, subScope);
                // hoist labels
                // If a maco argument is not defined in curretn scope AND
                // is defined in inner scope, then hois definition
                for (const { token: labelToken} of line.labelArguments) {
                    const definition = this.lookupDefinition(scope, labelToken.value);
                    if (!definition) {
                        const innerDef = subScope.labels.get(labelToken.value.toUpperCase());
                        if (innerDef) {
                            // label is hoisted from macro implementation
                            // we register it in the outer scope, eg. so if it is hoised by a FUNCTION
                            // it can be referred to by a CALL.
                            scope.labels.set(labelToken.value.toUpperCase(), innerDef);
                            // console.log('HOIST', labelToken.value);
                        }
                    }
                }
            }
        }
    }

    // resolves label references to syntax nodes
    resolvePass(lines: AsmSyntax[], defs: Definitions) {
        for (const line of lines) {
            if (line.isValid) {
                if (line instanceof AsmLoadLabelInstruction) {
                    const definition = this.lookupDefinition(defs, line.identifier);
                    line.reference = definition;
                    if (!definition) {
                        line.setIdentifierError(`label '${line.identifier}' not found.`);
                    }
                }
                if (line instanceof AsmMacroInvocation) {
                    const innerDefs = line.definitions!;
                    // Verify label arguments exist, and pass to macro expansion scope
                    for (const { token: labelToken} of line.labelArguments) {
                        const definition = this.lookupDefinition(defs, labelToken.value);
                        if (definition) {
                            if (definition instanceof LabelDef) {
                                // Pass label definition to macro expansion.
                                // Since the label text will have been replaced at expansion time
                                // this will be the same name
                                innerDefs.labels.set(definition.node.identifier.toUpperCase(), definition.node);
                            } else if (definition instanceof DefineDef) {
                                innerDefs.defines.set(definition.node.identifier.toUpperCase(), definition.node);
                            }
                        } else {
                            labelToken.isValid = false;
                            line.setError(`label '${labelToken.value}' not found.`);
                        }
                    }
                    // Resolve recursively in the expanded macro
                    this.resolvePass(line.expansion, innerDefs);
                }
            }
        }
    }
    // generate sequence of instructions (including macro expansions (recursively))
    resolveAddresses(lines: AsmSyntax[]) {
        for (const line of lines) {
            if (line.isValid) {
                if (line instanceof AsmLabel) {
                    // will be the address of the next instruction
                    // since a label does not itself occupy an address
                    line.labelAddress = this.instructions.length;
                }
                if (line instanceof AsmMacroInvocation) {
                    const startAddr = this.instructions.length;
                    this.resolveAddresses(line.expansion);
                    const endAddr = this.instructions.length;
                    line.addresses = [startAddr, endAddr];
                }
                if (line instanceof AsmInstructionSyntax) {
                    line.address = this.instructions.length;
                    this.instructions.push(line);
                }
            }
        }
    }
    // After all valid labels have assigned an address, we resolve references to labels into adresses
    resolveLabelAddresses(lines: AsmSyntax[]) {
        for (const line of lines) {
            if (line instanceof AsmLoadLabelInstruction) {
                if (line.isValid) {
                    line.resolveReference();
                }
            }
            if (line instanceof AsmMacroInvocation) {
                this.resolveLabelAddresses(line.expansion);
            }
        }
    }
}

export class PlaceholderValues implements ConstantsProvider {
    names;
    map;
    constructor(readonly placeholders: Placeholder[]) {
        this.names = placeholders.map(pl => pl.name);
        this.map = new Map(this.names.map(name => [name, 0]));
    }
    get(name: string) {
        return this.map.get(name);
    }
    set(name: string, value: number) {
        return this.map.set(name, value);
    }
}

export class MacroAssembler {
    parser;
    constructor(readonly macros: InstructionProvider,
        readonly constants: ConstantsProvider,
        readonly placeholders?: PlaceholderValues,
    ) {
        this.parser = new Parser(macros, constants, [], placeholders);
    }
    assemble(code: string, unit: SourceUnit) {
        const stmts = this.parser.scanLines(code, unit);
        return new AssemblerProgram(stmts, this.constants, this.placeholders);
    }
}

export class EmptyMacroProvider implements InstructionProvider {
    names: string[] = [];
    get(_name: string) {
        return undefined;
    }
}

export class EmptyConstantsProvider implements ConstantsProvider {
    names: string[] = [];
    get(_name: string) {
        return undefined;
    }
}

export class DefaultAssembler extends MacroAssembler {
    constructor() {
        super(new EmptyMacroProvider(), new EmptyConstantsProvider(), new PlaceholderValues([]));
    }
    scanLine1(line: string, unit: SourceUnit): AsmSyntax {
        return this.parser.scanAndParseLine(line, -1, unit);
    }
}

export class DefaultParser extends Parser {
    constructor() {
        super(new EmptyMacroProvider(), new EmptyConstantsProvider());
    }
}

/**  Only for testing
 * Assembles source to machine code (without preserving a syntax tree)
 * Throws on error
 * */
export function assembleValidate(code: string) {
    const assembler = new DefaultAssembler();
    const program = assembler.assemble(code, new DummySourceUnit());
    if (program.getAllErrors().length > 0) {
        throw new Error();
    }
    return program.machineCode;
}

/*  Parses an assembler instruction but discards the AST and returns just the instruction
 */
export function assembleInstruction(line: string): Instruction {
    const defaultParser = new DefaultParser();
    const syntx = defaultParser.parseInstruction(line, new DummySourceUnit());
    return syntx.instruction;
}
