import { useContext, useState } from 'react';
import { GameStateContext } from './gameState';
import { AppControls } from 'engine/controls/ControlsComponent';
import { EngineComponent } from 'engine/EngineComponent';
import { AssemblerEditorComponent } from 'ide/editor/EditorComponent';
import { ConstantsComponent } from 'missions/stack/Constants';
import { Controller1 } from 'assembler/controller';
import { MissionProgression } from './missionProgression';
import { Machine } from 'assembler/machine';
import { AssemblerEditorBackend } from 'assembler/assemblerEditorBackend';
import { MacroAssembler, PlaceholderValues } from 'assembler/assembler';
import { StackInstructionsSet } from 'missions/stack/stackInstructionsSet';
import { SourceFile } from 'ide/sourceFile';
import { Placeholder } from 'assembler/instructionProvider';
import { JsonStorageService, PersistentStorageService } from 'common/storage.service';
import { DisplayComponent } from 'engine/devices/DisplayComponent';
import { hardwareSetup } from 'missions/hardwareSetup';
import { KeyboardComponent } from './KeyboardComponent';
import { EventEmitter2 } from 'common/eventEmitter';
import { FileModel } from 'assembler/fileModel';

interface Program {
    readonly id: string;
    name: string;
    readonly type: 'assembler' | 'stack-machine';
    readonly placeholders?: Placeholder[];
    code: string;
}

class ProgramStorage {
    storage = new JsonStorageService(new PersistentStorageService());
    programs;
    constructor() {
        this.programs = this.storage.getItem<Program[]>('NandGame:Programs') ?? [];
    }
    createNew() {
        const id = crypto.randomUUID();
        // get new unique name
        let no = this.programs.length;
        let name: string;
        const names = this.programs.map(p => p.name);
        do {
            no++;
            name = `Program ${no}`;
        } while (names.includes(name));
        const newProgram = {name, type: 'assembler' as const, code: '# program\n' , id };
        this.programs = [...this.programs, newProgram];
        return newProgram;
    }
    save() {
        this.storage.setItem('NandGame:Programs', this.programs);
    }
}


export class PlaygroundSourceFile implements SourceFile {
    constructor(public program: Program, readonly storage: ProgramStorage) { }
    onChanged = new EventEmitter2<unknown>();
    load() {
        return this.program.code;
    }
    save(src: string) {
        this.program.code = src;
        this.storage.save();
        this.onChanged.fire(undefined);
    }
}

export class StackProgramState {
    readonly code: PlaygroundSourceFile;
    readonly machine;
    readonly editorBackend;
    readonly controller;
    readonly macroProvider;
    readonly placeholders;
    readonly globalConstants;
    constructor(
        private readonly repository: ProgramStorage,
        protected readonly game: MissionProgression,
        readonly program: Program,
    ) {
        this.code = new PlaygroundSourceFile(program, this.repository);
        this.machine = new Machine();
        this.globalConstants = game.sharedConstants;
        this.placeholders = new PlaceholderValues(this.program.placeholders ?? []);
        this.macroProvider = new StackInstructionsSet(game);
        const assembler = new MacroAssembler(this.macroProvider, this.globalConstants, this.placeholders);
        this.editorBackend = new AssemblerEditorBackend('playground-stack', new FileModel(this.code));
        this.controller = new Controller1(this.machine, this.editorBackend, assembler);
    }
    save() {
        this.repository.save();
    }
    hasState = false;
    resetState() {
        /* do nothing */
    }
}

function ProgramsList(props: {
    programs: Program[],
    current: Program
    onCreateProgram: ()=>void,
    onSelectProgram: (p:Program)=>void,
}) {
    return <div className='mx-2'>
        <div className='card p-2'>
        <div className='card-header'>Programs</div>
        <ul className='list-group list-group-flush'>{
            props.programs.map(p =>
                p === props.current
                ? <li className='list-group-item active' key={p.id}>{p.name  || '(Unnamed)'}</li>
                : <li className='list-group-item' onClick={()=>props.onSelectProgram(p)} key={p.id}>{p.name || '(Unnamed)'}</li>
            )}
        </ul>
        <button onClick={props.onCreateProgram} className='btn btn-secondary my-2'>New program</button>
        </div>
        </div>
}

export function AssemblerPlayground() {
    const [state, setState] = useState(() => {
        const programsStorage = new ProgramStorage();
        const programs = programsStorage.programs;
        const program = programs.length > 0 ? programs[0]! : null;
        return { program, programsStorage }
    });
    const { program, programsStorage } = state;
    function onCreateProgram() {
        const program = programsStorage.createNew();
        setState(st => ({ ...st, program }));
    }
    function onProgramDelete(doomedProgram: Program) {
        const ix = programsStorage.programs.indexOf(doomedProgram);
        const programs = [...programsStorage.programs.filter(p => p !== doomedProgram)];
        programsStorage.programs = programs;
        const selectIx = ix === programs.length ? ix - 1 : ix;
        const program = programs.length > 0 ? programs[selectIx]! : null;
        programsStorage.save();
        setState(st => ({ ...st, program }));
    }
    if (program === null) {
        return (
        <div className='custom-components start-banner'>
            <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                <h1>Assembler Playground</h1>
                <p className="lead">
                    Use the playground to create your own assembler programs or macros.
                </p>
                <button onClick={onCreateProgram} className='btn btn-primary btn-lg'>Create first program</button>
            </div>
        </div>);
    }
    function onSelectProgram(program: Program) {
        setState(st => ({ ...st, program }));
    }
    function onProgramChanged(program: Program) {
        programsStorage.save();
        setState(st => ({ ...st, program }));
    }
    return (
        <div className="stack-playground row mt-4">
            <div className="col col-lg-2">
                <ProgramsList
                    programs={programsStorage.programs}
                    current={program}
                    onSelectProgram={onSelectProgram}
                    onCreateProgram={onCreateProgram} />
            </div>
            <div className="col-md-auto">
                <AssemblerPlaygroundIde
                    program={program}
                    programsStorage={programsStorage}
                    onProgramChanged={onProgramChanged}
                    onProgramDelete={onProgramDelete}
                    />
            </div>
        </div>
    );
}

export function AssemblerPlaygroundIde({ program, programsStorage, onProgramChanged, onProgramDelete }: {
    program : Program,
    programsStorage: ProgramStorage,
    onProgramChanged: (program: Program) => void,
    onProgramDelete: (program: Program) => void,
}) {
    const gameState = useContext(GameStateContext);
    const [state, setState] = useState(() => {
        const source = new PlaygroundSourceFile(program, programsStorage);
        const stackGroupState = gameState.missionProgression.sharedConstants;
        const combinedConstants = stackGroupState;
        const macroProvider = new StackInstructionsSet(gameState.missionProgression);
        // TODO: Should also include other playgound macros
        const editor = new AssemblerEditorBackend('playground-2', new FileModel(source));

        const hardware = hardwareSetup();
        const { networkDevice, networkRegister, machine } = hardware;
        networkDevice.changed.addListener(() => onUpdate());
        networkRegister.changed = () => onUpdate();

        const controller = new Controller1(machine, editor, new MacroAssembler(macroProvider, combinedConstants));
        return {
            source,
            networkDevice,
            networkRegister,
            hardware,
            machine,
            controller,
            editor,
            program,
        };
    });
    if (program !== state.program) {
        setState(st => {
            const source = new PlaygroundSourceFile(program, programsStorage);
            const editor = new AssemblerEditorBackend('playground', new FileModel(source));
            return {
                ...st,
                editor,
                program,
            };
        });
    }

    function onTick() {
        const complete = state.controller.tick();
        setState(st => ({ ...st }));
        return complete;
    }
    function onReset() {
        state.controller.reset();
        setState(st => ({ ...st }));
    }
    function onUpdate() {
        setState(st => ({ ...st }));
    }
    function setName(name: string) {
        program.name = name;
        onProgramChanged(program);
    }

    return (
        <div className="stack-ide">
            <div className="ide">
                <div>
                    <div className='input-group'>
                        <input type="text" className="form-control"
                            value={program.name}
                            onChange={(e) => setName(e.target.value)} />
                        <button className='btn btn-danger mx-2' onClick={() => onProgramDelete(program)}>Delete Program</button>
                    </div>
                    <div style={{ height: '300px' }}>
                        <AssemblerEditorComponent editorBackend={state.editor} />
                    </div>
                </div>
                <div className="machine-column">
                    <DisplayComponent displayIo={state.hardware.display} />
                    <KeyboardComponent onChange={(v) => state.hardware.keyboardDevice.setState(v)} />
                    <div className="machine">
                        <div className="machine-header">
                            <p>
                                <b>computer</b>
                            </p>
                            <AppControls
                                onTick={onTick}
                                onReset={onReset}
                                isFinished={state.controller.isFinished}
                                />
                        </div>
                        <div className="engine">
                            <EngineComponent machine={state.machine} showRam showStack
                                onUpdate={onUpdate}
                                constantsProvider={gameState.missionProgression.sharedConstants} />
                        </div>
                    </div>
                </div>
                <div>
                    <ConstantsComponent constants={gameState.missionProgression.sharedConstants} updated={onUpdate} />
                </div>
            </div>
        </div>
    );
}
