import { expressionTests } from '../grammarTests';
import { Repository } from '../../app/repository';
import { MissionKind } from '../../app/task';
import { GrammarState } from './grammarState';
import { MissionProgression } from '../../app/missionProgression';
import { StorageService } from 'common/storage.service';

/*
    For compiler-state missions,
    only the source code (used for testing) is persisted pr mission
    The actual configuration for tokenizer/parser/codegen is persisted globally
*/
type CompilerLevelPersistence = { source: string };

export class ExpressionGrammarState extends GrammarState {
    tests = expressionTests;
    startSymbol = 'Expression';
    getInitialCode() {
        return '2 + 2';
    }
}

export const expressionGrammarMission = {
    key: 'GRAMMAR_EXPRESSION',
    kind: MissionKind.Grammar,
    start(storage: StorageService, game: MissionProgression) {
        return new ExpressionGrammarState(this, storage, game);
    },
    restore(storage: StorageService, game: MissionProgression) {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as CompilerLevelPersistence;
        return new ExpressionGrammarState(this, storage, game, data);
    },
};
