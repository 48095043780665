import { Machine } from '../../assembler/machine';
import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { initStackMission } from './initMission';
import { StackMissionBase } from './stackMission';


class PopDTest extends CodeTester {
    setup(machine: Machine) {
        // Setup - value 42 on stack
        this.push(machine, 42);
    }
    verify(machine: Machine) {
        // assert D is 42
        const d = machine.d.get();
        if (d !== 42) {
            return new VerificationError(`Expected D to be 42. (Was ${d.toString(16)})`);
        }
        const err = this.expectStack(machine, []);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export const popDMission = new class extends StackMissionBase  {
    key = 'STACK_POP_D';
    macro = { name: 'pop.D', lowLevel: true };
    depends = [initStackMission];
    examples = [
        {label: 'before', stack: [{value:'42'}]},
        {label: 'after', registers: [{name: 'D', value: '42'}], stack: []},
    ];
    tests = [PopDTest];
    defaultTestCode = `\
init.stack
D = 1
push.d
A = 0x48
D = A
pop.D
# inspect D. Should be 1.
`
};
