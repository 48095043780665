
export type Boolean16 = readonly [
    boolean, boolean, boolean, boolean,
    boolean, boolean, boolean, boolean,
    boolean, boolean, boolean, boolean,
    boolean, boolean, boolean, boolean,
];
export type DenseArray = readonly [number, number, number, number, number, number, number, number, ...number[]];

/** Removes an item in from an array. (Only first removed if duplicates) */
export function deleteItem<T>(array: T[], item: T): void {
    const ix = array.indexOf(item);
    if (ix > -1) {
        array.splice(ix, 1);
    }
}

export function insertAt<T>(array: T[], index: number, item: T): void {
    array.splice(index, 0, item);
}

/** Removes the first item in the array which satisfies the predicate. Returns the removed item */
export function removeWhere<T>(array: T[], predicate: (item: T) => boolean): T | undefined {
    const ix = array.findIndex(predicate);
    if (ix === -1) {
        return undefined;
    }
    const item = array[ix];
    array.splice(ix, 1);
    return item;
}

export function permutations<T>(poss: T[][]): T[][] {
    if (poss.length === 0) {
        return [[]];
    }
    const item = poss[0]!;
    const rest = permutations(poss.slice(1));
    return item.flatMap(it => rest.map(r => [it].concat(r)));
}

/**
 * pairwise values from array, e.g. [red, green, blue] --> [[red,green], [green,blue]]
 */
export function pairs<T>(arr: T[]): [T, T][] {
    const result: [T, T][] = [];
    for (let ix = 0; ix < arr.length - 1; ix++) {
        result.push([arr[ix]!, arr[ix + 1]!]);
    }
    return result;
}


/** Utility for filtering nulls out of an array.
    use like: array.filter(notNull)
 */
export function notNull<TValue>(value: TValue | null): value is TValue {
    return value !== null;
}

/** Utility for filtering udenfined out of an array.
    use like: array.filter(isDefined)
 */
export function isDefined<TValue>(value: TValue | undefined): value is TValue {
    return value !== undefined;
}

declare global {
    interface Array<T> {
        min(): T;
        max(): T;
        first(): T;
        first(f: (i: T) => boolean): T;
        single(): T;
        single(f: (i: T) => boolean): T;
    }
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.min) {
    Array.prototype.min = function<T>(this: Array<T>) { return this.reduce((p, v) => (p < v ? p : v)); };
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.max) {
    Array.prototype.max = function<T>(this: Array<T>) { return this.reduce((p, v) => (p > v ? p : v)); };
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.first) {
    Array.prototype.first = function<T>(this: Array<T>, f?: (i: T) => boolean) {
        const item = f === undefined ? this[0] : this.find(f);
        if (item === undefined) {
            throw new Error('No matching item found');
        }
        return item;
    };
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.single) {
    Array.prototype.single = function<T>(this: Array<T>, f?: (i: T) => boolean) {
        if (f === undefined) {
            if (this.length !== 1) {
                throw new Error(`Expected an array with a single item but was ${this.length} `)
            }
            return this[0];
        }
        const matches = this.filter(f);
        if (matches.length === 0) {
            throw new Error('No matching item found');
        }
        if (matches.length > 1) {
            throw new Error('More than one match found');
        }
        return matches[0];
    };
}
