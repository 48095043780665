import { bitArrayToNumber } from '../common/bits';

/* Opcode mappings for use in assembler/disassembler */

type AluFlags = [number, number, number, number, number];

// Used by assembler to get the bits for an opcode
export const aluFlagsMappingsOrdered: [string, AluFlags][] = [
    // zx, nx, zy, ny, f, no
    // x&y first because it is all zeroes
    ['X&Y', [0, 0, 0, 0, 0]],
    ['X|Y', [0, 0, 1, 0, 0]],
    ['X^Y', [0, 1, 0, 0, 0]],
    ['~X', [0, 1, 1, 0, 0]],
    ['~Y', [0, 1, 1, 0, 1]],
    ['X+Y', [1, 0, 0, 0, 0]],
    ['X-Y', [1, 1, 0, 0, 0]],
    ['Y-X', [1, 1, 0, 0, 1]],
    ['0', [0, 0, 0, 1, 0]],
    ['1', [1, 0, 1, 1, 0]],
    ['-1', [1, 1, 1, 1, 0]],
    ['X', [1, 0, 0, 1, 1]],
    ['Y', [1, 0, 0, 1, 0]],
    ['-Y', [1, 1, 0, 1, 0]],
    ['-X', [1, 1, 0, 1, 1]],
    ['X+1', [1, 0, 1, 0, 0]],
    ['Y+1', [1, 0, 1, 0, 1]],
    ['X-1', [1, 1, 1, 0, 0]],
    ['Y-1', [1, 1, 1, 0, 1]]
];

export const aluFlagsMappings: { [key: string]: AluFlags } =
    toDict(aluFlagsMappingsOrdered);

/* Used by disassembler. Since multiple bit-configurations may map to the same operation,
    we support more than the 'canonical' mappings

    We map to the canonical opcode, e.g. X+Y even when the operands are reversed.
*/
export const aluFlagsMappingsOrderedReversed: readonly [number, string][] = [
    // u, op0, op1, zx, sw
    // u = 0: logic
    [0b00000, 'X&Y'],
    [0b00001, 'X&Y'],
    [0b00010, '0'],
    [0b00011, '0'],
    [0b00100, 'X|Y'],
    [0b00101, 'X|Y'],
    [0b00110, 'Y'],
    [0b00111, 'X'],
    [0b01000, 'X^Y'],
    [0b01001, 'X^Y'],
    [0b01010, 'Y'],
    [0b01011, 'X'],
    [0b01100, '~X'],
    [0b01101, '~Y'],
    [0b01110, '-1'],
    [0b01111, '-1'],
    // u=1: arithmetic
    [0b10000, 'X+Y'],
    [0b10001, 'X+Y'],
    [0b10010, 'Y'],
    [0b10011, 'X'],
    [0b10100, 'X+1'],
    [0b10101, 'Y+1'],
    [0b10110, '1'],
    [0b10111, '1'],
    [0b11000, 'X-Y'],
    [0b11001, 'Y-X'],
    [0b11010, '-Y'],
    [0b11011, '-X'],
    [0b11100, 'X-1'],
    [0b11101, 'Y-1'],
    [0b11110, '-1'],
    [0b11111, '-1'],
];
export const aluFlagsMappingsReverse: { [key: string]: string } =
    toDict(
        aluFlagsMappingsOrderedReversed
            .map(([bits, op]): [string, string] =>
                [bits.toString(), op]));

export const jumpFlagsMappings: { [key: string]: number[] } = {
    'NULL': [0, 0, 0],
    'JGT': [0, 0, 1],
    'JEQ': [0, 1, 0],
    'JGE': [0, 1, 1],
    'JLT': [1, 0, 0],
    'JNE': [1, 0, 1],
    'JLE': [1, 1, 0],
    'JMP': [1, 1, 1]
};
export const jumpFlagsMappingsReverse =
    invertDict(jumpFlagsMappings, bits => bitArrayToNumber(bits).toString());

// Turns an ordered list of tuples into a dictionary
function toDict<TValue>(tuples: [string, TValue][]) {
    const init: { [key: string]: TValue } = {};
    return tuples.reduce((dict, [key, value]) => { dict[key] = value; return dict; }, init);
}
function invertDict<TValue>(dict: { [key: string]: TValue }, select: ((s: TValue) => string)) {
    const init: { [key: string]: string } = {};
    return Object.keys(dict).reduce((dict1, key) => {
        dict1[select(dict[key]!)] = key; return dict1;
    }, init);
}
