import { PatternType, TokenAction } from './tokenize';

export class TokenSpecification {
    error?: string;
    constructor(
        public id: number,
        public type: PatternType,
        public pattern: string,
        public action: TokenAction,
        public label?: string
    ) {
        const _valid = this.isValid;
    }
    get isValid() {
        this.error = undefined;
        if (this.pattern === '') {
            this.error = 'Empty pattern';
            return false;
        }

        if (this.type === PatternType.Pattern) {
            if (this.pattern.includes('(') || this.pattern.includes(')')) {
                this.error = 'Patterns cannot contain parentheses.';
                return false;
            }
            try {
                const p = new RegExp(this.pattern, 'msuy');
                if (p.test('')) {
                    this.error = 'Pattern matches empty string';
                    return false;
                }
            } catch (e) {
                if (e instanceof Error) {
                    this.error = 'Invalid pattern: ' + e.message;
                } else {
                    this.error = 'Invalid pattern';
                }
                console.log(e);
                return false;
            }
        }
        if (this.action === TokenAction.Name && !this.label) {
            this.error = 'Name should be specified';
            return false;
        }
        return true;
    }
    static nextId(tokens: TokenSpecification[]) {
        return tokens.length === 0 ? 1 :
            Math.max(...tokens.map(l => l.id)) + 1
    }
}
