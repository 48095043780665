import React, { useState } from 'react';
import './Help.css';

export function Help(props: { children: React.ReactNode }) {
    const [expanded, setExpanded] = useState(true);
    if (expanded) {
        return (
            <div className="help-expanded">
                <div className="help-content">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setExpanded(st => !st)}></button>
                    {props.children}
                </div>
                <div className="arrow"></div>
            </div>
        );
    } else {
        return (
            <div className="help-collapsed">
                <i className="bi bi-question-circle"  onClick={() => setExpanded(st => !st)}></i>
            </div>
        );
    }
}
