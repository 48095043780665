import React from 'react';
import './TranslateComponent.css';
import { SectionComponent } from './SectionComponent';
import { TranslationContext, TranslationItem } from './item';
import { useParams } from 'react-router-dom';

export function TranslateComponent() {
    const { lang } = useParams() as { lang: string};
	const [state, setState] = React.useState(()=>{
        const backend = new TranslationContext(lang);
		return { jsonExport: '', backend: backend }
    });
    function getChildren() { return state.backend.getChildren(); }
    function exportToJson() {
        const json = state.backend.jsonExport();
        setState({...state, jsonExport: json});
    }
    function updateText(item: TranslationItem, txt: string) {
        item.otherText = txt;
        setState({...state, backend: state.backend});
    }
    async function saveOnServer() {
        /* The JSON representation of the translations are passed as a string property */
        const json = state.backend.jsonExport();
        const data = { texts: json };
        const url = '/api/translations/' + lang;
        console.log(url);
        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            console.log(response);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (e) {
            console.error(e);
            alert(`Could not save on server. Error: ${String(e)} `);
        }
    }
    function saveOnServerClick() {
        void saveOnServer();
    }

	return (<>
<div className='jsonbox'>
    <div>Translation to <b>{lang} </b> </div>
    <p>English text is to the left. Write translations in the box to the right. The text is saved in the browser local storage.</p>
    <div>
        <button onClick={saveOnServerClick} className='btn btn-primary'>Save Translations on Server</button>
    </div>
    <div className='jsonbox'>
        <p>Click <b>Export to JSON</b> to generate.</p>
        <button onClick={exportToJson} className='btn btn-secondary'>Export to JSON</button>
        <textarea className='form-control' value={state.jsonExport} readOnly />
    </div>
</div>
<div>
        {(getChildren()).map(item => (
            <SectionComponent key={item.key} item={item} updateText={updateText} />
        ))}
</div>
</>);
}
