import { diagram } from './missions';
import { ComponentInternalState } from '../componentType';
import { PinGroup, bit, word } from '../pins';
import { SequentialTest, Step } from '../sequentialTest';
import { ram64kbNodeType, RamState } from './ramMissions';
import { dff16NodeType, RegisterState } from './registerMission';
import { nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType } from './logicMissions';
import { CompositeStateView, MemoryStateView, MemoryState } from '../stateViews';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';
import { ComponentInstanceState } from 'diagram/componentState';

export const combinedStateMission = diagram({
    key: 'CPU_STATE',
    inputPins: [
        new PinGroup('dst', [bit('a'), bit('d'), bit('*a')]),
        word('X'),
        bit('cl')
    ],
    outputPins: [word('A'), word('D'), word('*A')],
    palette: [
        dff16NodeType, ram64kbNodeType, nandNodeType, invNodeType, andNodeType, orNodeType,
        xorNodeType
    ],
    tests: [
        // a,d,*a, X, cl - A, D, *A
        // store and write A
        new SequentialTest([
            Step.set([1, 0, 0, 7, 0],
                'Set <b>a</b>=1 and <b>X</b>=7. Should store the number 7 in register A'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted on <b>A</b>.'),
            Step.assertOutputs([7, 0, 0])
        ]),
        // store and write D
        new SequentialTest([
            Step.set([0, 1, 0, 3, 0],
                'Set <b>d</b>=1 and <b>X</b>=3. Should store the number 3 in register D'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted on <b>D</b>.'),
            Step.assertOutputs([0, 3, 0])
        ]),

        // store and write A and *A
        new SequentialTest([
            Step.set([1, 0, 0, 2, 0],
                'Set <b>a</b>=1 and <b>X</b>=2. Should store the number 2 in register A'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1. Clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0. Clock cycle complete.'),
            Step.assertOutput('A', 2, 'The stored number should now be emitted from register A.'),
            Step.assertOutput('D', 0, 'D should be 0 since nothing is stored yet.'),
            Step.assertOutput('*A', 0, '*A should be 0 since nothing is stored yet.'),
            Step.set([0, 0, 1, 9, 0],
                'Set <b>*a</b>=1 and <b>X</b>=9. The number 9 should be stored in RAM at address 2'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1. Clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0. Clock cycle complete.'),
            Step.assertOutput('A', 2, 'A should still be 2.'),
            Step.assertOutput('D', 0, 'D should be 0 since nothing is stored yet.'),
            Step.assertOutput('*A', 9, 'The stored number should now be emitted on <b>*A</b>'),
            Step.set([1, 0, 0, 3, 0],
                'Set <b>a</b>=1 and <b>X</b>=3. Should store the number 3 in register A'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1. Clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0. Clock cycle complete.'),
            Step.assertOutput('A', 3, ''),
            Step.assertOutput('D', 0, 'D should be 0 since nothing is stored yet.'),
            Step.assertOutput('*A',
                0,
                '<b>*A</b> should be 0 since nothing is yet stored in RAM at address 3.'),
        ])
    ],
    score: { min: 3 },
} as const);


export class CompositeState2 implements ComponentInternalState, MemoryState {
    aState = new RegisterState();
    dState = new RegisterState();
    ramState: RamState;
    stateView = new CompositeStateView([
        ['A', this.aState.stateView],
        ['D', this.dState.stateView],
        // As the *A state, display the value addressed by A
        ['*A', new MemoryStateView(this)]
    ]);

    constructor(readonly node: ComponentInstanceState) {
        this.ramState = new RamState(node);
    }
    resolveOutputs(node: ComponentInstanceState) {
        const da = node.inputConnectorStates[0]!.bitState;
        const dd = node.inputConnectorStates[1]!.bitState;
        const dm = node.inputConnectorStates[2]!.bitState;
        const data = node.inputConnectorStates[3]!.numState;
        const clock = node.inputConnectorStates[4]!.bitState;
        return this.resolve(da, dd, dm, data, clock);
    }

    get address() { return this.aState.output; }

    peekState(addr: number) { return this.ramState.peekState(addr); }

    peek(addr: number) { return this.ramState.peek(addr); }

    resolve(a: boolean, d: boolean, m: boolean, data: number, clock: boolean) {
        const [a1] = this.aState.resolve(a, data, clock);
        const [d1] = this.dState.resolve(d, data, clock);
        const [m1] = this.ramState.resolve(m, data, a1, clock);
        return [a1, d1, m1] as const;
    }

    reset() {
        this.aState.reset();
        this.dState.reset();
        this.ramState.reset();
    }
}


// like cpu state, but bit-pins are separate
export const cpuState2NodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'memory';
    readonly key = 'STATE';
    readonly inputs = [
        bit('a'), bit('d'), bit('*a'),
        word('X'),
        bit('cl')
    ];
    readonly outputs = [word('A'), word('D'), word('*A')];
    readonly hasInternalState = true;
    // cannot expand because the diagram has different pins (dst is three pins)
    readonly depends = depends(combinedStateMission, 1, false);
    createInternalState = (node: ComponentInstanceState) =>
        new CompositeState2(node);
};
