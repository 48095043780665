import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Register } from '../assembler/machine';
import { toHexWord } from '../common/hex';

import './RegisterComponent.css';

export function IdeWord(props: { value: number }) {
    const [randomID, _setRandomID] = React.useState(String(nextId()))
    const [tooltip, _showTooltip] = React.useState(true);
    const value = props.value;
    const hex = toHexWord(value);
    const neg = value >= 0x8000 ? <>or {0x10000 - value}</> : null;
    const binary = value.toString(2);
    return (
        <span>
            <span className="register-state" data-tip data-for={randomID}>
                {hex}
            </span>
            {tooltip && <ReactTooltip id={randomID} place="top">
                <pre>
                    <div>Hex: {hex} </div>
                    <div>
                        Decimal: {value} {neg}
                    </div>
                    <div>Binary: {binary}</div>
                </pre>
            </ReactTooltip>}
        </span>
    );
}

// generate unique Id's for tootips
let idCounter = 0;
const nextId = () => idCounter++;

export function IdeAddress(props: { address: number }) {
    return (
        <div>
            <IdeWord value={props.address} />
        </div>
    );
}

export function EditRegisterContent({ initialState, onSave, onCancel }: { initialState: number, onSave: (num:number) => void; onCancel: () => void}){
    const [state, setState] = React.useState({
        decimalValue: initialState.toString(10),
        hexValue: initialState.toString(16),
        isValid: true,
    });
    function onChangeHex(hexValue: string) {
        const isValid = /[a-fA-F0-9]{1,4}/.test(hexValue);
        const decimalValue = isValid ? parseInt(hexValue, 16).toString(10) : ''
        setState(_st => ({
            decimalValue: decimalValue,
            hexValue: hexValue,
            isValid: isValid,
        }));
    }
    function onChangeDecimal(decimalValue: string) {
        const numValue = parseInt(decimalValue, 10);
        const isValid = /-?[0-9]{1,5}/.test(decimalValue) && numValue <= 0xFFFF && numValue > -0x7fff;
        const hexValue = isValid ? numValue.toString(16) : '';
        setState(_st => ({
            decimalValue: decimalValue,
            hexValue: hexValue,
            isValid: isValid
        }));
    }
    function onOk() {
        const num = parseInt(state.decimalValue, 10);
        onSave(num);
    }
    return (<div style={{backgroundColor:'coal'}}>
        <div className='input-group'>
            <input type="text" value={state.decimalValue} maxLength={6} className={`form-control ${state.isValid ? 'is-valid' : 'is-invalid'} `} style={{width:'4em',textAlign:'right'}}
                onChange={ev=>onChangeDecimal(ev.target.value)} />
            <div className='input-group-append'><div className='input-group-text'>decimal</div></div>
        <div className='input-group'></div>
            <input type="text" value={state.hexValue} maxLength={4} className={`form-control ${state.isValid ? 'is-valid' : 'is-invalid'} `} style={{width:'4em',textAlign:'right'}}
                onChange={ev=>onChangeHex(ev.target.value)} />
            <div className='input-group-append'><div className='input-group-text'>hex</div></div>
        </div>
        <button disabled={!state.isValid} className='btn btn-sm btn-primary' onClick={onOk}><span className="bi bi-check"></span></button>
        <button className='btn btn-sm btn-danger' onClick={onCancel}><span className="bi bi-x"></span></button>
    </div>);
}

function EditRegister({ initialState, onCommit }: { initialState: number, onCommit: (num:number) => void }) {
    const toggleRef = React.useRef(null as HTMLButtonElement | null);
    function close() {
        toggleRef.current!.click();
    }
    function onCancel() {
        close();
    }
    function onSave(val: number) {
        onCommit(val);
        close();
    }
    return (
        <OverlayTrigger
            trigger="click"
            key={`edit-register`}
            placement='right'
            overlay={
            <Tooltip id={`edit-register`}>
                <EditRegisterContent initialState={initialState} onCancel={onCancel} onSave={onSave} />
            </Tooltip>
            }
      >
        <button ref={toggleRef} className='edit-register-button btn btn-sm'><span className="bi bi-pencil"></span></button>
      </OverlayTrigger>
      );
}

export function EditableRegisterState({ register, onUpdate} : { register: Register, onUpdate: ()=>void; }) {
    function onCommit(val: number) {
        register.setCurrent(val);
        onUpdate();
    }
    const value = register.get();
    return (<>
        <IdeWord value={value} />
        <EditRegister initialState={value} onCommit={onCommit} />
    </>);
}

export function IdeRegister({ register, onUpdate} : { register: Register, onUpdate: ()=>void; }) {
    const hasPendingChange = register.hasPendingChange;
    return (
        <span className="ide-register">
            <EditableRegisterState register={register} onUpdate={onUpdate}  />
            {hasPendingChange &&
                <span className="will-change">
                    <span className="will-change-text">Will change to:</span>
                    <IdeWord value={register.nextState} />
                </span>}
        </span>
    );
}
