import { IoRegister } from 'assembler/machine';
import { LampDevice } from '../../assembler/lampDevice';
import './BlinkenlightComponent.css';
import { LedConnectorConnected } from './Led';

export function BlinkenlightComponent(props: { lampIo: LampDevice, register: IoRegister }) {
    return (
        <div className="blinkenlight-box">
            <div className={'blinkenlight ' + (props.lampIo.lampIsOn ? 'on' : '')}></div>
            <div className="network-device-io-bus">
            <div className="network-device-io-pin" >
                <div>Off</div>
                <LedConnectorConnected state={props.register.getBit(1)} id="network-device-io-1" targetPin={1} />
            </div>
            <div className="network-device-io-pin" >
                <div>On</div>
                <LedConnectorConnected state={props.register.getBit(0)}  id="network-device-io-0" targetPin={0} />
            </div>
        </div>
    </div>
    );
}
