import React from 'react';
import { MacroAssembler } from '../assembler/assembler';
import { Controller1 } from '../assembler/controller';
import { AssemblerEditorBackend } from '../assembler/assemblerEditorBackend';
import { AppControls } from '../engine/controls/ControlsComponent';
import { EngineComponent } from '../engine/EngineComponent';
import { AssemblerEditorComponent } from '../ide/editor/EditorComponent';
import { DisplayComponent } from '../engine/devices/DisplayComponent';
import { GameStateContext } from '../app/gameState';
import { StackInstructionsSet } from './stack/stackInstructionsSet';
import './AssemblerMissionComponent.css';
import { NetworkDeviceComponent } from '../engine/devices/NetworkDeviceComponent';
import { hardwareSetup } from './hardwareSetup';
import { AssemblerMissionState } from './assemblerMissions';
import { FileModel } from 'assembler/fileModel';

export function NetworkMissionComponent(props: { missionState: AssemblerMissionState }) {
    // async callback from device when state changes
    // should re-render engine since memory mapped state
    const gameState = React.useContext(GameStateContext);
    function onUpdate() {
        setState(st => ({ ...st }));
    }
    const [state, setState] = React.useState(() => {
        const missionState = props.missionState;
        const source = missionState.code;
        const stackGroupState = gameState.missionProgression.sharedConstants;
        const combinedConstants = stackGroupState;
        const macroProvider = new StackInstructionsSet(gameState.missionProgression);
        const assembler = new MacroAssembler(macroProvider, combinedConstants);
        const codeFileModel = new FileModel(source);

        const editor = new AssemblerEditorBackend(props.missionState.mission.key + '/code', codeFileModel);

        const hardware = hardwareSetup();
        const { networkDevice, networkRegister, machine } = hardware;
        // add network device
        networkDevice.changed.addListener(() => onUpdate());
        networkRegister.changed = () => onUpdate();

        const controller = new Controller1(machine, editor, assembler);
        codeFileModel.source.onChanged.addListener(() => { 
            // Update state when code is changed
            onUpdate(); 
        });
        return {
            source: source,
            networkDevice,
            networkRegister,
            hardware,
            machine: machine,
            controller: controller,
            editor: editor,
        };
    });
    function onTick(batchSize: number) {
        let complete = false;
        for (let i = 0; i < batchSize; i++) {
            complete = state.controller.tick();
            if (complete) {
                break;
            }
            state.networkDevice.machineTick();
        }
        setState(st => ({ ...st }));
        return complete;
    }
    function onReset() {
        state.controller.reset();
        state.networkDevice.reset();
        setState(st => ({ ...st }));
    }
    const { display, machine, networkDevice, networkRegister } = state.hardware;
    return (
        <div className="ide">
            <div>
                <div style={{ height: '300px' }}>
                    <AssemblerEditorComponent editorBackend={state.editor} />
                </div>
            </div>
            <div className="machine-column">
                <DisplayComponent displayIo={display} />
                <NetworkDeviceComponent networkDevice={networkDevice} />
                <div className="machine">
                    <div className="machine-header">
                        <p>
                            <b>computer</b>
                        </p>
                        <AppControls
                            onTick={onTick}
                            onReset={onReset}
                            isFinished={state.controller.isFinished}
                          />
                    </div>
                    <div className="engine">
                        <EngineComponent machine={machine}
                            onUpdate={onUpdate}
                            ioRegister={networkRegister}
                            showMemoryMapped />
                    </div>
                </div>
            </div>
        </div>
    );
}
