import './SectionComponent.css';

import { TranslationItem } from './item';

export function SectionComponent(props: {
    item: TranslationItem;
    updateText: (item: TranslationItem, newText: string) => void
}) {
    const otherText =  props.item.otherText;
    const isDirty =  props.item.isDirty;
	const item = props.item;

    if (item.isLeaf) {
        return (
            <div>
                <span className='key'>{item.key}</span>
                <span><textarea value={item.enText as string} readOnly /></span>
                <div className={'other' + (isDirty ? ' is-dirty' : '')}>
                    <textarea
                        value={otherText}
                        onChange={(ev)=>props.updateText(item, ev.target.value)} name='otherText' />
                    </div>
            </div>
        );
    } else {
        return (
        <div className='section'>
            <span className='key'>{item.key}</span>
            {item.getChildren().map(sub =>
                <SectionComponent key={sub.key} item={sub} updateText={props.updateText} /> )}
        </div>);
    }
}

