import { EscapeMissionState } from './escapeMission';
import { MazeComponent } from './MazeComponent';
import { useState } from 'react';
import { EngineComponent } from '../../engine/EngineComponent';
import { AppControls } from '../../engine/controls/ControlsComponent';
import { AssemblerEditorComponent } from '../../ide/editor/EditorComponent';
import './EscapeMissionComponent.css';

export function EscapeMissionComponent(props: { missionState: EscapeMissionState }) {
    // async callback from robot when state changes
    // should re-render engine since memory mapped state
    const missionState = props.missionState;
    function onUpdate() {
        setState(st => ({ ...st }));
    }
    const [state, setState] = useState(() => {
        const missionState = props.missionState;
        const universe = missionState.universe;
        const machine = missionState.machine;
        const robotIo = missionState.robotIo;
        // TODO: ensure only added once
        robotIo.changed.addListener(() => onUpdate());
        const source = missionState.code;
        return {
            source: source,
            robotIo: robotIo,
            universe: universe,
            machine: machine,
        };
    });
    function onTick() {
        const complete = missionState.controller.tick();
        setState(st => ({ ...st }));
        return complete;
    }
    function onReset() {
        missionState.controller.reset();
        setState(st => ({ ...st }));
    }
    return (
        <div className="escape-mission ide">
            <div>
                <div style={{ height: '300px', width: '600px' }}>
                    <AssemblerEditorComponent editorBackend={missionState.editorBackend} />
                </div>
            </div>
            <div>
                <div className="machine">
                    <div className="machine-header">
                        <p>
                            <b>computer</b>
                        </p>
                        <AppControls onTick={onTick} onReset={onReset} isFinished={missionState.controller.isFinished} />
                    </div>
                    <div className="engine">
                        <EngineComponent machine={state.machine}
                            onUpdate={onUpdate}
                            showMemoryMapped
                            showRam
                            ioRegister={props.missionState.robotRegister} />
                    </div>
                </div>
            </div>
            <div>
                <MazeComponent model={state.universe} />
            </div>
        </div>
    );
}
