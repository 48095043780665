import * as Instructions from './instructions';
import { FunctionDefinition, Segment } from './instructions';
import { Consumer, Token, Scanner, TokenDefinition, TokenAction } from './scanner';
import { Option, ErrorResult, SourcedErrorResult } from '../compiler/shared';
import { ILine } from 'ide/editor/iEditorBackend';
import { DummySourceUnit, SourceUnit } from 'common/location';

/* Vm source code might contain different segments with different tracking info
    This is not yet supported */
export interface SourceSegmentSet {
    asString(): string;
    unit: SourceUnit;
}

export class VmScanner extends Scanner {
    constructor() {
        const patterns = [
            new TokenDefinition('//.*?$', TokenAction.Token, 'comment'),
            new TokenDefinition('#.*?$', TokenAction.Token, 'comment'),
            new TokenDefinition('[ \\t]+', TokenAction.Ignore),
            new TokenDefinition('0?x[_0-9a-hA-H]+', TokenAction.Token, 'number'),
            new TokenDefinition('0?b[_01]+\\d+', TokenAction.Token, 'number'),
            // new TokenDefinition("'\\?.'", TokenAction.Token, 'number'),
            // Should un-prefixed integers be allowed?
            new TokenDefinition('\\d+', TokenAction.Token, 'number'),
            new TokenDefinition('\\S+', TokenAction.Token, 'identifier'),
            new TokenDefinition('\\.', TokenAction.Error),
        ];
        super(patterns);
    }
}

export class ParsedUnit {
    constructor(public functions: Map<string, FunctionDefinition>, public lines: VmSyntaxLine[]) {}
    get errors() { return this.lines.filter(l => !l.isValid).map(l => ({ text: l.errorText ?? 'Error' })); }
}

export class VmSyntaxLine implements ILine {
    isValid = true;
    errorText?: string;
    constructor(public tokens: Token[], public instruction?: Instructions.Instruction) {
        if (tokens.find(t => !t.isValid)) {
            this.setError('Syntax error');
        }
    }
    setError(message: string) {
        this.isValid = false;
        this.errorText = message;
    }
    get lineOffset() {
        return this.tokens[0]!.location.lineOffset;
    }
    get lineNumber() {
        return this.tokens[0]!.location.lineNumber;
    }
    toDebugText() {
        const src = this.tokens.map(t => t.value).join(' ');
        return `${src} at line ${this.lineNumber} file ${this.sourceUnit}`;
    }
    get sourceUnit() {
        return this.tokens[0]?.location.unit;
    }
}

export class VmParser {
    unit;
    functions = new Map<string, FunctionDefinition>();
    constructor(src: SourceSegmentSet, topLevel: boolean) {
        this.unit = this.parse(src, topLevel);
    }
    consumeInstruction(tokens: Consumer, fun: FunctionDefinition): Instructions.Instruction | string | undefined {
        const token = tokens.consume();
        if (token.type === 'comment'){
            return undefined;
        }
        const type = token.value;

        switch (type.toLocaleLowerCase()) {
            case 'label': {
                const label = tokens.consume().value;
                const addr = fun.instructions.length;
                fun.labels.set(label, new Instructions.Label(addr));
                // don't return an instruction.
                return undefined;
            }
            case 'init.stack': {
                return new Instructions.InitStackInstruction(token);
            }
            case 'stop': {
                return new Instructions.StopInstruction(token);
            }
            case 'goto': {
                const symbol = this.consumeSymbol(tokens);
                return new Instructions.GotoInstruction(token, symbol);
            }
            case 'if_goto': {
                const symbol = this.consumeSymbol(tokens);
                return new Instructions.IfGotoInstruction(token, symbol);
            }
            case 'add':
                return new Instructions.Add(token);
            case 'sub':
                return new Instructions.Sub(token);
            case 'neg':
                return new Instructions.Neg(token);
            case 'eq':
                return new Instructions.Eq(token);
            case 'lt':
                return new Instructions.Lt(token);
            case 'gt':
                return new Instructions.Gt(token);
            case 'and':
                return new Instructions.And(token);
            case 'or':
                return new Instructions.Or(token);
            case 'not':
                return new Instructions.Not(token);
            case 'push.static':
            case 'push_static': {
                const index = this.consumeInteger(tokens);
                return new Instructions.PushInstruction(token, Segment.static, index);
            }
            case 'pop.static':
            case 'pop_static': {
                const index = this.consumeInteger(tokens);
                return new Instructions.PopInstruction(token, Segment.static, index);
            }
            case 'push.local':
            case 'push_local': {
                const index = this.consumeInteger(tokens);
                return new Instructions.PushInstruction(token, Segment.local, index);
            }
            case 'pop.local':
            case 'pop_local': {
                const index = this.consumeInteger(tokens);
                return new Instructions.PopInstruction(token, Segment.local, index);
            }
            case 'push.argument':
            case 'push_arg': {
                const index = this.consumeInteger(tokens);
                return new Instructions.PushInstruction(token, Segment.argument, index);
            }
            case 'pop.argument':
            case 'pop_arg': {
                const index = this.consumeInteger(tokens);
                return new Instructions.PopInstruction(token, Segment.argument, index);
            }
            case 'push.memory':
            case 'push_memory': {
                return new Instructions.PushMemoryInstruction(token);
            }
            case 'pop.memory':
            case 'pop_memory': {
                return new Instructions.PopMemoryInstruction(token);
            }
            case 'push.value':
            case 'push_value': {
                const value = this.consumeInteger(tokens);
                return new Instructions.PushValueInstruction(token, value);
            }
            case 'call': {
                const functionNameToken = this.expectToken(tokens, 'name');
                const functionName = functionNameToken.value;
                const argumentsCount = this.consumeInteger(tokens);
                return new Instructions.CallInstruction(token, functionName, argumentsCount);
            }
            case 'return':
                return new Instructions.ReturnInstruction(token);
            default:
                token.isValid = false;
                return `Unknown instruction: "${type}" `;
        }
    }
    parse(src: SourceSegmentSet, topLevel: boolean) {
        const s = new VmScanner();
        const lines = s.scanLines(src.asString(), src.unit);
        const outLines: VmSyntaxLine[] = [];

        if (topLevel) {
            // consume top level code (code not inside a function), if any.
            // in case of top-level code, it is added to a pseudo-function called [toplevel]
            const hasTopLevelCode = lines.length > 0 && lines[0]?.[0]?.value.toLocaleLowerCase() !== 'function';
            if (hasTopLevelCode) {
                const name = '[toplevel]';
                const pseudoFunction = new FunctionDefinition(name, null as unknown as Token, 0);
                this.consumeInstructions(pseudoFunction, lines, outLines);
                if (pseudoFunction.instructions.length > 0) {
                    pseudoFunction.isTop = true;
                    this.functions.set(name, pseudoFunction);
                }
            }
        }
        // consume functions
        while (lines.length > 0) {
            this.consumeFunction(lines, outLines);
        }

        // validate functions
        new Validator(this.functions);
        return new ParsedUnit(this.functions, outLines);
    }
    // Consumes a function definition
    consumeFunction(lines: Token[][], outLines: VmSyntaxLine[]) {
        let line = lines.shift();
        // comments and empty lines before function
        // eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unnecessary-condition
        while (true) {
            if (!line) {
                break;
            } else if (line.length === 0) {
                // empty line, skip
            } else if (line[0]?.type === 'comment') {
                outLines.push(new VmSyntaxLine(line));
            } else {
                break;
            }
            line = lines.shift()!;
        }
        if (!line) {
            // unexpected EOF
            return;
        }
        const consumer = new Consumer(line);
        const keywordToken = consumer.consume();
        const syntax = new VmSyntaxLine(line);
        outLines.push(syntax);
        if (keywordToken.value.toLocaleLowerCase() !== 'function') {
            syntax.setError(`Expected 'function' `);
            return;
        }
        const nameToken = this.expectToken(consumer, 'name');
        const name = nameToken.value;
        if (this.functions.has(name)) {
            nameToken.isValid = false;
            syntax.setError(`A function with the name "${name}" is already defined.`);
        }
        const localsCount = this.consumeInteger(consumer);
        const fun = new FunctionDefinition(name, keywordToken, localsCount);
        this.functions.set(name, fun);
        this.consumeInstructions(fun, lines, outLines);
    }
    /* consumes instructions until EOF or new function */
    consumeInstructions(fun: FunctionDefinition, lines: Token[][], outLines: VmSyntaxLine[]) {
        while (lines.length > 0 && lines[0]?.[0]?.value?.toLocaleLowerCase() !== 'function') {
            const line = lines.shift()!;
            if (line.length === 0) {
                // empty line - skip
                continue;
            }
            if (line[0]?.type === 'comment') {
                outLines.push(new VmSyntaxLine(line));
                continue;
            }
            const consumer = new Consumer(line);
            let outLine;
            try {
                const instr = this.consumeInstruction(consumer, fun);
                if (typeof instr === 'string') {
                    const syntax = new VmSyntaxLine(line);
                    syntax.setError(instr);
                    outLines.push(syntax);
                    continue;
                }
                outLine = new VmSyntaxLine(line, instr);
                // returns after consuming enough tokens, so if there are more on the same line it must be an error
                if (!consumer.atEnd) {
                    const unexpectedToken = consumer.consume();
                    if (unexpectedToken.type !== 'comment') {
                        unexpectedToken.isValid = false;
                        outLine.setError(`Unexpected token '${unexpectedToken.value}' after instruction`);
                    }
                }
                // labels are also parsed but does not generate an instruction
                fun.body.push(outLine);
                if (instr) {
                    fun.instructions.push(instr);
                }
            } catch (e: unknown) {
                if (e instanceof ErrorResult) {
                    outLine = new VmSyntaxLine(line);
                    outLine.setError(e.message);
                } else {
                    throw e;
                }
            }
            outLines.push(outLine);
        }
    }
    expectToken(tokens: Consumer, expected: string) {
        if (tokens.atEnd) {
            // TODO: how to indicate position
            const previousToken = tokens.last;
            const endOfToken = {
                startCharacterIndex: previousToken.endCharacterIndex,
            };
            throw new SourcedErrorResult(`Unexpected end of line. Expected ${expected} `, endOfToken);
        }
        return tokens.consume();
    }
    consumeInteger(tokens: Consumer) {
        const token = this.expectToken(tokens, 'number');
        if (token.type !== 'number') {
            token.isValid = false;
            const foundText = token.kind.typeName ? token.kind.typeName : `${token.value}'`;
            throw new SourcedErrorResult(`Expected integer, found: ${foundText} `, token);
        }
        if (token.value.startsWith('x')) {
            return parseInt(token.value.substring(1), 16);
        }
        if (token.value.startsWith('b')) {
            return parseInt(token.value.substring(1), 2);
        }
        // handles 0x and 0b natively
        return parseInt(token.value);
    }
    consumeSymbol(tokens: Consumer) {
        const token = this.expectToken(tokens, 'label');
        if (token.type !== 'identifier') {
            token.isValid = false;
            const foundText = token.kind.typeName ? token.kind.typeName : `${token.value}'`;
            throw new SourcedErrorResult(`Expected label, found: ${foundText} `, token);
        }
        return token.value;
    }
    consumeName(tokens: Consumer) {
        return tokens.consume();
    }
}

/*
    File-level validation of VM code
    (Does not validate cross-file like checking if a called function exists)
*/
class Validator {
    constructor(public functions: Map<string, FunctionDefinition>) {
        this.functions.forEach(fun => this.validateFunction(fun));
    }
    validateFunction(fun: FunctionDefinition) {
        // assign label addresses
        // resolve functions
        for (const line of fun.body) {
            if (line.instruction && line.instruction instanceof Instructions.GotoInstruction) {
                const gotoInstr = line.instruction;
                const label = fun.labels.get(gotoInstr.symbol);
                if (!label) {
                    gotoInstr.token.isValid = false;
                    line.setError(`Label '${gotoInstr.symbol}' not found for goto.`);
                } else {
                    gotoInstr.address = label.addr;
                }
            }
        }
        if (!fun.isTop) {
            // check function ends with a return
            if (fun.instructions.length > 0) {
                const last = fun.instructions.at(-1)!;
                if (last.type.toLocaleLowerCase() !== 'return') {
                    throw new SourcedErrorResult(`Function block should end with a return.`, last.token);
                }
            } else {
                // empty function. Should at least have a return!
                throw new SourcedErrorResult(`Function block should end with a return. `, fun.token);
            }
        } else {
            if (this.functions.size > 1) {
                // TODO: top level code should exit before function
                const last = fun.instructions.at(-1)!;
                if (last.type.toLocaleLowerCase() !== 'stop') {
                    throw new SourcedErrorResult(`Top level code should end with a STOP before first function.`, last.token);
                }
            }
        }
    }
}

/* Generated code need support for different segments which different tracking info,
    but the runtime library is just a single string, so generate a single segment. */
export class StringSourceProvider {
    unit = new DummySourceUnit();
    constructor(readonly src: string) { }
    asString() { return this.src; }
}

export class UnitSourceProvider {
    constructor(readonly src: string, readonly unit: SourceUnit) { }
    asString() { return this.src; }
}

export function parseVmStr(src: string): Option<ParsedUnit> {
    return parseVm(new StringSourceProvider(src));
}

export function parseVmStrRaw(src: string): ParsedUnit {
    return parseVmRaw(new StringSourceProvider(src));
}

export function parseVmRaw(src: SourceSegmentSet, topLevel = false): ParsedUnit {
    const p = new VmParser(src, topLevel);
    return p.unit;
}

export function parseVm(src: SourceSegmentSet): Option<ParsedUnit> {
    try {
        const p = new VmParser(src, true);
        if (p.unit.errors.length) {
            return new ErrorResult(p.unit.errors[0]!.text);
        }
        return p.unit;
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal parser error: ${(e as Error).message} `);
    }
}

export function linkVm(units: ParsedUnit[]): Option<LinkedUnits> {
    try {
        return linkVm1(units);
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal linker error: ${(e as Error).message} `);
    }
}

/** All calls have been verified and resolved */
export class LinkedUnits {
    constructor(
        readonly instructionToSyntax: Map<Instructions.Instruction, VmSyntaxLine>,
        readonly funcs: Map<string, FunctionDefinition>) { }
}

export function resolveCalls(map: Map<string, FunctionDefinition>) {
    const instructionToSyntax = new Map<Instructions.Instruction, VmSyntaxLine>();
    for (const fun of map.values()) {
        for(const line of fun.body) {
            if (line.instruction) {
                instructionToSyntax.set(line.instruction, line);
                if (line.instruction instanceof Instructions.CallInstruction) {
                    const callInstr = line.instruction;
                    const functionDefinition = map.get(callInstr.functionName);
                    if (!functionDefinition) {
                        callInstr.token.isValid = false;
                        line.setError(`Function '${callInstr.functionName}' not found.`);
                    } else {
                        callInstr.functionDefinition = functionDefinition;
                    }
                }
            }
        }
    }
    return new LinkedUnits(instructionToSyntax, map);
}

/** Link multiple code units. Errors if a function name is defined multiple times.
 * Checks if all called functions are defined
*/
export function linkVm1(units: ParsedUnit[]): LinkedUnits {
    const map = new Map<string, FunctionDefinition>();
    for (const unit of units) {
        for (const [name, func] of unit.functions.entries()) {
            if (map.has(name)) {
                throw new ErrorResult(`Function with name '${name}' defined multiple times. `);
            }
            map.set(name, func)
        }
    }
    return resolveCalls(map);
}
