import { deleteItem } from "common/utilities";
import { ComponentInstance, ComponentInstanceEvents, NodeInputConnector, NodeOutputConnector } from "./circuitStructure";
import { ComponentType, IComponentInstanceState } from "./componentType";
import { ConnectorState, InputConnectorState, NodeInputConnectorState, NodeOutputConnectorState, OutputConnectorState } from "./connectorState";
import { Pos } from "./position";
import { ComponentError } from "./componentErrors";

/** Global counter generating unique ID's for component instances */
export let componentInstanceIdCounter = 0;

interface ICircuitState {
    update(): void;
    deleteNode(node: ComponentInstanceState): void;
    addNode(nodeType: ComponentType, pos: Pos): ComponentInstanceState;
}


export class NodeStateSnapshot {
    constructor(readonly state: ConnectorState[]) { }
    isSame(state2: NodeStateSnapshot) {
        const s2 = state2.state;
        return this.state.length === s2.length
            && this.state.every((s, ix) => s === s2[ix]);
    }
}

/*
 * State of a component instance in a circuit instance.
 */
export class ComponentInstanceState implements IComponentInstanceState, ComponentInstanceEvents {
    oscillating = false;
    errorKind?: ComponentError;
    internalState;

    inputConnectorStates!: InputConnectorState[];
    outputConnectorStates!: OutputConnectorState[];
    id;

    constructor(readonly diagram: ICircuitState, readonly componentInstance: ComponentInstance) {
        // create connector instances
        this.loadConnectors(componentInstance);
        this.internalState = componentInstance.nodeType.createInternalState(this);
        this.componentInstance.registerInstanceState(this);
        this.id = componentInstanceIdCounter++;
    }

    loadConnectors(instance: ComponentInstance) {
        this.inputConnectorStates = instance.inputConnectors.map(cnn => new NodeInputConnectorState(cnn, this));
        this.outputConnectorStates = instance.outputConnectors.map(cnn => new NodeOutputConnectorState(cnn, this));
    }

    resolveOutput() {
        const states = this.internalState.resolveOutputs(this);
        states.forEach((state, ix) => {
            this.outputConnectorStates[ix]!.state = state;
        });
    }
    getState(): NodeStateSnapshot {
        // the state of output connectors.
        // for convenience we convert into a number
        return new NodeStateSnapshot(this.outputConnectorStates.map(conn => conn.state));
    }
    stateChanged() {
        // callback when internal state changes
        // only used by IO devices, since they can change state on their own
        this.diagram.update();
    }
    delete() { this.componentInstance.delete(); }
    moved() { this.componentInstance.moved(); }

    get pos() { return this.componentInstance.pos; }
    moveTo(pos: Pos) { this.componentInstance.pos = pos; }
    get nodeType() { return this.componentInstance.nodeType; }

    inputPinDeleted(connector: NodeInputConnector) {
        const doomed = this.inputConnectorStates.find(c => c.connector === connector);
        deleteItem(this.inputConnectorStates, doomed);
    }
    outputPinDeleted(connector: NodeOutputConnector) {
        const doomed = this.outputConnectorStates.find(c => c.connector === connector);
        deleteItem(this.outputConnectorStates, doomed);
    }
    inputPinAdded(connector: NodeInputConnector) {
        const cnnState = new NodeInputConnectorState(connector, this);
        this.inputConnectorStates.push(cnnState);
    }
    outputPinAdded(connector: NodeOutputConnector) {
        const cnnState = new NodeOutputConnectorState(connector, this);
        this.outputConnectorStates.push(cnnState);
    }
    markError(errorKind: ComponentError) {
        this.errorKind = errorKind;
        this.oscillating = true;
    }
    clearError() {
        this.errorKind = undefined;
        this.oscillating = false;
        for (const out of this.outputConnectorStates) {
            out.oscillating = false;
        }
        for (const inp of this.inputConnectorStates) {
            inp.oscillating = false;
        }
    }
}
