import { StateView } from './componentType';


export interface MemoryState {
    address: number;
    peekState(addr: number): StateView;
}

export class MemoryStateView implements StateView {
    kind = 'Memory';
    constructor(readonly state: MemoryState) { }
    get addresses() {
        const currentAddr = this.state.address;
        const windowOffset = currentAddr % 4;
        const windowStart = currentAddr - windowOffset;
        return [0, 1, 2, 3].map(ix =>
            ({
                address: windowStart + ix,
                value: this.state.peekState(windowStart + ix),
                isAddress: ix === windowOffset
            }));
    }
    getState() { return undefined; }
}

export class CompositeStateView implements StateView {
    kind = 'Composite';
    hint = undefined;
    constructor(readonly stateViews: [string, StateView][]) { }
    getState() { return undefined; }
}

export interface SimpleStateView extends StateView {
    getState(): number;
}

export class DelegateStateView implements SimpleStateView {
    constructor(readonly stateF: (() => number), readonly kind: string) { }
    getState() { return this.stateF(); }
}
