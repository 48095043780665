import { useState } from 'react';
import './WordEditor.css';
import { getBit, setBit } from '../../../../common/bits';
import { ComponentInstance } from '../../../circuitStructure';
import { HexInput } from '../../node/HexInput';
import { ComponentInstanceState } from 'diagram/componentState';


class Bit {
    constructor(readonly word: WordEditor, readonly bitIx: number) { }
    toggle() {
        this.setBit(!this.bit);
    }
    get bit() {
        return this.word.getBit(this.bitIx);
    }
    setBit(status: boolean) {
        this.word.setBit(this.bitIx, status);
    }
}

export class WordEditor {
    bits = Array(16).fill(1).map((_, ix) => new Bit(this, ix)).reverse();
    private value: number;
    constructor(readonly nodeInstance: ComponentInstance) {
        this.value = nodeInstance.persistentState as number;
    }
    setValue(val: number) {
        this.value = val;
        this.changed();
    }
    getValue() { return this.value; }
    changed() {
        this.nodeInstance.persistentState = this.value;
        this.nodeInstance.diagram.notifyStructureChanged();
    }
    setBit(bitIx: number, status: boolean) {
        this.value = setBit(this.value, bitIx, status);
        this.changed();
    }
    getBit(bitIx: number) {
        const b = getBit(this.value, bitIx);
        return b;
    }
}

export function AppWordEdit(props: { node: ComponentInstanceState }) {

	const [state, setState] = useState(()=>{
        const word = new WordEditor(props.node.componentInstance);
		return {word: word};
	});

    function setValue(value: number) {
        state.word.setValue(value);
        setState({word: state.word});
    }
	const word = state.word;
    const value = word.getValue();

	return (<>
    <div className='word-edit bits'>
    {(word.bits).map((bit, ix) => (
        <span key={ix}>
        <span tabIndex={1} onKeyPress={()=>bit.toggle()} onClick={()=>bit.toggle()} className='bit'>{bit.bit ? '1' : '0'}</span>
    </span>))}
    </div>

    <div>
        <HexInput value={value} update={setValue} />
    </div>
</>);
}

