import React from "react";
import { LedConnectorConnected } from "./Led";
import { NetworkDevice } from "./networkDevice";
import './NetworkDeviceComponent.css';


export function NetworkDeviceComponent({ networkDevice }: { networkDevice: NetworkDevice }) {
    const [state, setState] = React.useState(() => {
       return {
           started: false,
           running: false,
           dataIndex: 0,
       };
    });
    return (<>
        <div className='network-device'>
        <p><i>The Other Computer</i></p>
        <div className="network-device-io-bus">
            <div className="network-device-io-pin" >
                <div>Sync</div>
                <LedConnectorConnected state={networkDevice.syncFlag} id="network-device-io-1" targetPin={1} />
            </div>
            <div className="network-device-io-pin" >
                <div>Data</div>
                <LedConnectorConnected state={networkDevice.dataFlag}  id="network-device-io-0" targetPin={0} />
            </div>
        </div>
    </div>

    </>);
}
