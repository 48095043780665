import { ComponentInstanceState } from 'diagram/componentState';
import { ComponentInternalState } from '../componentType';

export class LampInternalState implements ComponentInternalState {
    isOn = false;
    resolveOutputs(node: ComponentInstanceState) {
        const on = node.inputConnectorStates[0]!.bitState;
        const off = node.inputConnectorStates[1]!.bitState;
        return this.resolve(on, off);
    }
    reset(): void {
        this.isOn = false;
    }
    resolve(onFlag: boolean, offFlag: boolean) {
        if (onFlag) {
            this.isOn = true;
        }
        if (offFlag) {
            this.isOn = false;
        }
        return [];
    }
}
