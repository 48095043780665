import { EventEmitter2 } from "common/eventEmitter";

export interface SourceFile {
    readonly onChanged: EventEmitter2<unknown>;
    load(): string;
    save(src: string): void;
}

export class StringSourceFile {
    readonly onChanged = new EventEmitter2();
    constructor(private src: string) { }
    save(src: string) {
        this.src = src;
        this.onChanged.fire(undefined);
    }
    load() { return this.src; }
}
