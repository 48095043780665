import { SourceMap } from 'missions/stack/sourceMap';
import { EventEmitter2 } from '../common/eventEmitter';
import { AssemblerProgram, MacroAssembler } from './assembler';
import { FileModel } from './fileModel';
import { Machine } from './machine';
import { AsmInstructionSyntax, BlankLineAsm } from './syntax';
import { AssemblerEditorBackend } from './assemblerEditorBackend';


class Builder1 {
    onParsed?: () => void;
    program!: AssemblerProgram;
    sourceMap!: SourceMap;
    constructor(
        readonly assembler: MacroAssembler,
        readonly code: FileModel,
    ) {
        this.parse();
        code.source.onChanged.addListener(()=> { this.parse(); });
    }
    parse() {
        this.code.parsed = this.assembler.assemble(this.code.source.load(), this.code);
        this.program = this.code.parsed;
        this.sourceMap = new SourceMap(this.program);
        this.onParsed?.();
    }
}

export class Controller1 {
    currentInstruction: AsmInstructionSyntax | undefined;
    currentLineIx: number | undefined;
    currentAddr = 0;
    currentValue = 0;
    // onValueChanged is fired if the value of the currently selected ROM address changes
    // (due to editing the assembler)
    // TODO: Are these needed?
    readonly currentValueChanged = new EventEmitter2<number>();
    readonly currentAddressChanged = new EventEmitter2<number>();
    builder;
    onChanged?: () => void;
    constructor(
        readonly machine: Machine,
        readonly editorBackend: AssemblerEditorBackend,
        readonly assembler: MacroAssembler,
    ) {
        this.builder = new Builder1(assembler, editorBackend.fileModel);
        this.reset();
        this.builder.onParsed = () => { this.update(); }
    }
    tick() {
        this.machine.tick();
        this.update();
        return this.isFinished;
    }
    tickMultiple(count: number) {
        for (let i = 0; i < count; i++) {
            this.tick();
        }
        return this.isFinished;
    }
    markCurrentLine() {
        let currentLineIx = undefined;
        if (this.program.getAllErrors().length === 0) {
            if (this.isFinished) {
                // mark blank line at end of top-level code
                const lines = this.editorBackend.getLines();
                if (lines.length > 0) {
                    let lastIx = -1;
                    let lastLine = lines.at(lastIx);
                    // in case of trailing blank lines, we backtrack to last non-blank line
                    while (lastLine instanceof BlankLineAsm && lines.length + lastIx > 0) {
                        lastIx = lastIx - 1;
                        lastLine = lines.at(lastIx)
                    }
                    if (lastLine && lastLine.tokens.length > 0) {
                        currentLineIx = lastLine.lineOffset + 1;
                    }
                } else {
                    // no lines in code
                    currentLineIx = 0;
                }
            } else {
                const pc = this.machine.pc.get();
                currentLineIx = this.builder.sourceMap.addrToSyntaxLine.get(pc)!;
            }
        }
        this.editorBackend.setCurrentLine(currentLineIx);
    }
    update() {
        // fetches value of address and updates current line
        const word = this.fetch(this.machine.pc.get());
        this.machine.resolve(word);
        this.markCurrentLine();
        this.onChanged?.()
    }
    reset() {
        this.machine.reset();
        this.update();
    }
    get program() {
        return this.builder.program;
    }
    get isFinished() {
        return this.machine.pc.get() >= this.builder.program.instructions.length;
    }
        // When the engine fetches an instruction.
    // Marks the line as 'current'
    fetch(addr: number) {
        const isChanged = this.currentAddr !== addr;
        this.currentAddr = addr;
        this.refreshCurrentValue();
        if (isChanged) {
            this.currentAddressChanged.fire(this.currentAddr);
        }
        return this.currentValue;
    }
    /* update current value */
    private refreshCurrentValue() {
        if (this.currentAddr >= this.builder.program.instructions.length) {
            // clear current
            this.currentLineIx = undefined;
            this.currentValue = 0;
        } else {
            const instruction = this.builder.sourceMap.addrToInstruction.get(this.currentAddr);
            if (instruction) {
                this.currentValue = instruction.instruction.toWord();
            }
            this.currentLineIx = this.builder.sourceMap.addrToSyntaxLine.get(this.currentAddr);
        }
    }
}
