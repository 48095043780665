import { ComponentInstanceState } from 'diagram/componentState';
import { ComponentInstance } from '../circuitStructure';
import { BuiltinComponentType, ComponentCounter, ComponentInternalState, MissionDependency } from '../componentType';
import { ConnectorState } from '../connectorState';
import { Pin } from '../pins';
import { OutputRule } from './outputRules';


/*
 * Base class for built-in component types
 */
export abstract class BaseBuiltinComponentType implements BuiltinComponentType {
    abstract readonly name: string;
    abstract readonly key: string;
    abstract readonly inputs: Pin[];
    abstract readonly outputs: Pin[];
    abstract readonly depends: MissionDependency;
    readonly displayHint?: string;
    readonly hasInternalState: boolean = false;
    readonly hasPersistentState: boolean = false;
    get countNode() { return this.depends.countNode; }
    abstract createInternalState: (node: ComponentInstanceState) => ComponentInternalState;
    componentCount = (counter: ComponentCounter) => this.depends.componentCount(counter);
    getPersistentState = (_node: ComponentInstance): unknown => undefined;
    initPersistentState = (): unknown => undefined;
    restorePersistentState = (data: unknown): unknown => data;
}

export class RuleInternalState implements ComponentInternalState {
    constructor(readonly rule: OutputRule) { }
    reset(): void { }
    resolveOutputs(node: ComponentInstanceState): readonly ConnectorState[] {
        return this.rule.resolve(node);
    }
}

/* Component with no internal state, and where the output can be defined by a rule */
export class RuleComponentType extends BaseBuiltinComponentType {
    constructor(public readonly name: string,
        public readonly key: string,
        public readonly inputs: Pin[],
        public readonly outputs: Pin[],
        public readonly rule: OutputRule,
        public readonly depends: MissionDependency,
        public readonly displayHint?: string) { super(); }
    createInternalState = (_node: ComponentInstanceState): ComponentInternalState => new RuleInternalState(this.rule);
}

// convenience function for creating rule-based components
export function component(name: string, key: string,
    inputs: Pin[], outputs: Pin[],
    rule: OutputRule,
    depends: MissionDependency,
    displayHint?: string) {
    return new RuleComponentType(name, key, inputs, outputs, rule, depends, displayHint);
}
