import { useState } from 'react';
import { WithPopupWindow } from '../../app/WithPopupWindow';
import './AssemblerHelp.css';
import { RichText } from 'locale/UiText';

export function AssemblerHelp() {
    const [isVisibleState, setIsVisible] = useState(false);
    function onClose() {
        setIsVisible(false);
    }
    if (!isVisibleState) {
        return (
            <button className="btn btn-secondary btn-sm" onClick={() => setIsVisible(true)}>
                Show Assembler Help
            </button>
        );
    }
    return (
        <>
            <button className="btn btn-secondary btn-sm" onClick={() => setIsVisible(false)}>
                Hide Assembler Help
            </button>
            <WithPopupWindow title="Assembler Help" onClose={onClose}>
                <div className="quick-reference"><RichText id="assembler_quick_reference"/></div>
            </WithPopupWindow>
        </>
    );
}
