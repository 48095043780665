import { MissionItem, MissionProgression } from '../../app/missionProgression';
import { MissionStatus } from '../../app/missionStatus';
import './MacroList.css';
import { StackMission } from './stackMission';

function MacroLabel(props: { mission: MissionItem; selectMission: (m: MissionItem) => void; currentMission: MissionItem }) {
    const mission = props.mission.mission as StackMission;
    const macroName = mission.macro.name;
    if (props.mission === props.currentMission) {
        return (
            <div className="list-group-item active">
                <i className="bi bi-play-fill"></i>
                <span>{macroName}</span>
            </div>
        );
    }
    if (props.mission.status === MissionStatus.Locked) {
        return (
            <div className="list-group-item list-group-item-danger">
                <i className="bi bi-lock-fill"></i>
                <span>{macroName}</span>
            </div>
        );
    } else if (props.mission.status === MissionStatus.Complete) {
        return (
            <button className="list-group-item list-group-item-action"
                onClick={() => props.selectMission(props.mission)}>
                <i className="bi bi-check-lg" style={{visibility: 'hidden'}}></i>
                <span>{macroName}</span>
            </button>
        );
    } else {
        return (
            <div className="list-group-item list-group-item-action list-group-item-danger">
                <i className="bi bi-x-lg"></i>
                <button className="btn" onClick={() => props.selectMission(props.mission)}>
                    {macroName}
                </button>
            </div>
        );
    }
}

export function MacroList(props: {
    history: MissionProgression;
    selectMission: (m: MissionItem) => void;
    currentMission: MissionItem;
}) {
    const macroMissions = props.history.stackMissions.filter(m => (m.isStarted || m.isCompleted));
    const levels = macroMissions.map((m, ix) => (
        <MacroLabel key={ix} mission={m} currentMission={props.currentMission} selectMission={props.selectMission} />
    ));
    return <nav className="macro-list">{levels}</nav>;
}
