import React from 'react';
import { Form } from 'react-bootstrap';
import './ControlsComponent.css';

export function RunningState(props: {
        rate: number;
        onTick: (batchSize: number) => boolean;
        onStop: () => void;
    }) {
    function onTick() {
        const batchSize = props.rate === 0 ? 100 : 1;
        const terminated = props.onTick(batchSize);
        if (terminated) {
            props.onStop();
        }
    }
    React.useEffect(() => {
        // effect when component mount/unmount or rate change
        const timeout = props.rate === 0 ? 1 : props.rate;
        const timeoutId = window.setInterval(() => onTick(), timeout);
        return () => {
            window.clearInterval(timeoutId);
        };
    }, [props.rate]);
    return (
        <div className="btn-group me-1">
            <button className="btn btn-secondary" onClick={props.onStop}>
                <i className="bi bi-stop-fill"></i> Stop
            </button>
        </div>
    );
}

export function AppControls(props: {
    onTick: (batchSize: number) => boolean;
    onReset: () => void;
    isFinished: boolean;
}) {
    const [runningState, setRunningState] = React.useState(false);
    const [rate, setRate] = React.useState(100);
    function onRun() {
        setRunningState(true);
    }
    function onReset() {
        props.onReset();
    }
    function onStop() {
        setRunningState(false);
    }
    function change(ev: React.ChangeEvent<HTMLSelectElement>) {
        const val = ev.currentTarget.value;
        setRate(parseInt(val, 10));
    }

    const controls = runningState ? (<>
        <RunningState onStop={onStop} onTick={props.onTick} rate={rate} />
        <div>
            Clock frequency:
        <Form.Select onChange={change} value={rate}>
            <option value="100">Step 10 Hz</option>
            <option value="10">Step 100 Hz</option>
            <option value="1">Step 1 KHz</option>
            <option value="0">Max</option>
        </Form.Select>
        </div>
    </>) : (
        <div>
            {!props.isFinished ?
            <div className="btn-group me-1">
                <button className="btn btn-secondary" onClick={()=>props.onTick(1)}>
                    <i className="bi bi-skip-end-fill"></i> Tick
                </button>
                <button className="btn btn-secondary" onClick={onRun}>
                    <i className="bi bi-play-fill"></i> Run
                </button>
            </div>
            : <div>PC is past last instruction</div>
            }
            <button onClick={onReset} className="btn btn-secondary">
                Reset
            </button>
        </div>
    );
    return <div className="toolbar">{controls}</div>;
}
