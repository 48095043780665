import { IComponentInstanceState } from './componentType';
import { BaseBuiltinComponentType } from './missions/baseNodeType';
import { CustomComponentType } from './customComponent';
import { GameState } from '../app/gameState';
import { CircuitStructure, ComponentInstance, EdgeOutputConnector, InputConnector, NodeOutputConnector, OutputConnector }
    from './circuitStructure';
import { Pos } from './position';
import { ComponentInstanceState } from './componentState';

/*
    Expands a composite node into its components.

    Locates the diagram which defines the node. We call this the template.
    For all nodes in the template create a corresponsing clone in the current diagram.
        keep a map between the template nodes and the clones

    For all nodes in the template
        for all input connectors with a connection
            find source
            if node:
                find corresponding new nodes for both ends, create connection
            if connection from edge input:
                find the corresponding input pin in the composite node
                create a connection from the source node to the new node
        for all edge outputs in the template
            find the corresponding output pin on the composite node
            for all targets of connections from this pin
                create a connection from the clone of the source to target

*/
export class ExpanderHelper {
    constructor(public readonly gameState: GameState) { }

    canExpand(node: IComponentInstanceState): boolean {
        return (node.nodeType instanceof CustomComponentType) ||
            (node.nodeType instanceof BaseBuiltinComponentType &&
                node.nodeType.depends.canExpand &&
                this.isMissionComplete(node.nodeType));
    }
    isMissionComplete(nodeType: BaseBuiltinComponentType) {
        const state = this.getMissionState(nodeType);
        return state !== undefined && state.isCompleted;
    }
    getMissionState(nodeType: BaseBuiltinComponentType) {
        const missionType = nodeType.depends.mission;
        if (!missionType) {
            return undefined;
        }
        return this.gameState.diagramSet.getDiagramMissionStateByType(missionType);
    }
    getDefinitionDiagram(node: IComponentInstanceState) {
        if (node.nodeType instanceof CustomComponentType) {
            return node.nodeType.diagram;
        }
        else if (node.nodeType instanceof BaseBuiltinComponentType &&
            this.canExpand(node)) {
            const item = this.getMissionState(node.nodeType);
            if (!item) {
                throw new Error();
            }
            return item.diagram.structure;
        }
        throw new Error();
    }
    // offset of the top left node in the template diagram
    getOffset(diagram: CircuitStructure) {
        const left = diagram.nodes.map(n => n.pos.x).min();
        const top = diagram.nodes.map(n => n.pos.y).min();
        return new Pos(left, top);
    }
    expand(node: ComponentInstanceState) {
        function getCorrespondingSource(oldToNew: Map<ComponentInstance, ComponentInstanceState>, sourceConnector: OutputConnector) {
            if (sourceConnector instanceof NodeOutputConnector) {
                const newSourceNode = oldToNew.get(sourceConnector.node)!;
                return newSourceNode.outputConnectorStates[sourceConnector.index]!.connector;

            } else if (sourceConnector instanceof EdgeOutputConnector) {
                // connections from input pins on the expanded diagram should be replicated as
                // connection to the input pins on the expanded node
                const connector = node.inputConnectorStates[sourceConnector.index]!;
                return connector.connection?.sourceConnector;
            } else {
                throw new Error();
            }
        }

        function createNewConnection(oldToNew: Map<ComponentInstance, ComponentInstanceState>, newInputConnector: InputConnector, sourceConnector: OutputConnector) {
            newInputConnector.deleteConnection();
            const newSource = getCorrespondingSource(oldToNew, sourceConnector);
            if (newSource) {
                newInputConnector.createConnection(newSource);
            }
        }

        const templateDiagram = this.getDefinitionDiagram(node);
        const targetDiagram = node.diagram;
        const oldToNew = new Map<ComponentInstance, ComponentInstanceState>();
        if (templateDiagram.nodes.length > 0) {
            const startPos = node.pos;
            const offset = this.getOffset(templateDiagram);
            // clone nodes
            for (const templateNode of templateDiagram.nodes) {
                const newNode = targetDiagram.addNode(templateNode.nodeType, startPos.addPos(templateNode.pos.sub(offset)));
                oldToNew.set(templateNode, newNode);
            }

            // clone internal connections
            for (const templateNode of templateDiagram.nodes) {
                const newNode = oldToNew.get(templateNode)!;
                for (const connector of templateNode.inputConnectors) {
                    if (connector.connection) {
                        const connection = connector.connection;
                        const newInputConnector = newNode.inputConnectorStates[connection.targetConnector.index]!;
                        createNewConnection(oldToNew, newInputConnector.connector, connection.sourceConnector);
                    }
                }
            }
        }
        for (const templateOutput of templateDiagram.outputNodes) {
            // for connections to output nodes in the template diagram,
            // create similar connections, but direct to the targets of the x-node outputs.
            if (templateOutput.connection) {
                // find new node
                const newSource = getCorrespondingSource(oldToNew, templateOutput.connection.sourceConnector);
                if (newSource) {
                    const nodeOutputConnector = node.outputConnectorStates[templateOutput.index]!;
                    const targets = nodeOutputConnector.connector.connections.map(c => c.targetConnector);
                    for (const target of targets) {
                        target.deleteConnection();
                        target.createConnection(newSource);
                    }
                }
            }
        }
        // lastly, delete the expanded node.
        // node.delete();
        targetDiagram.deleteNode(node);
    }
}
