import { StorageService } from 'common/storage.service';
import { CircuitState } from 'diagram/circuitState';
import { DiagramSerializer } from 'diagram/diagramPersistence';
import { Repository } from './repository';

export class DiagramRepository {
    repository;
    constructor(private readonly storage: StorageService) {
        this.repository = new Repository(storage);
    }
    isNewGame() {
        const levels = this.repository.getLevels();
        return (levels == null || levels.length === 0);
    }

    getLevels() {
        return this.repository.getLevels();
    }

    saveDiagram(levelKey: string, diag: CircuitState) {
        const ser = new DiagramSerializer();
        const levelPersistence = ser.persistDiagram(diag.structure);
        this.repository.saveLevel(levelKey, levelPersistence);
    }

    saveLevels(levels: string[]) {
        this.repository.saveLevels(levels);
    }
}



