import { useState } from "react";
import { CompilerPlayground } from "compiler/CompilerPlayground";
import { AssemblerPlayground } from "./AssemblerPlayground";

export function PlaygroundPage() {
    // until compiler playground ready
    return (<AssemblerPlayground />);
}

// TODO

type Tab = 'assembler' | 'compiler'

export function PlaygroundPage2() {
    const [state, setState] = useState({tab: null as (Tab | null) });
    function selectTab(tab: Tab | null) {
        setState({tab:tab})
    }
    return (<>
        {state.tab === null && <>
            <b>Playground</b>
            <div></div>
            <ul>
                <li onClick={()=>selectTab('assembler')}>Assembler</li>
                <li onClick={()=>selectTab('compiler')}>Compiler</li>
            </ul>
            </>
        }
        {state.tab === 'assembler' && <>
            <ol className="breadcrumb m-2">
                <li className="breadcrumb-item" onClick={()=>selectTab(null)}>Playground</li>
                <li className="breadcrumb-item active">Assembler</li>
            </ol>
            <AssemblerPlayground />
            </> }
        {state.tab === 'compiler' && <>
            <ol className="breadcrumb m-2">
                <li className="breadcrumb-item" onClick={()=>selectTab(null)}>Playground</li>
                <li className="breadcrumb-item active">Compiler</li>
            </ol>
            <CompilerPlayground />
            </> }
    </>);
}
