import { Machine } from '../../assembler/machine';
import { VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { initStackMission } from './initMission';
import { StackMissionBase } from './stackMission';


class PushDTest extends CodeTester {
    setup(machine: Machine) {
        machine.d.setImmediately(42);
    }
    verify(machine: Machine) {
        const err = this.expectStack(machine, [42]);
        if (err) {
            return err;
        }
        return new VerificationOk();
    }
}

export const pushDMission = new class extends StackMissionBase {
    key = 'STACK_PUSH_D';
    macro = { name: 'push.D', lowLevel: true };
    depends = [initStackMission];
    examples = [
        {label: 'before', registers: [{name: 'D', value: '42'}], stack: []},
        {label: 'after', stack: [{value:'42'}]},
    ];
    tests = [PushDTest];
    defaultTestCode = `\
init.stack
D = 1
push.d
# inspect top of stack. Should be 1.
`
};
