import { VerificationResultSet } from './verificationResults';
import { Hint } from 'diagram/hint';
import { MissionItem, MissionProgression } from './missionProgression';
import { StorageService } from 'common/storage.service';
import { GameState } from './gameState';

export enum MissionKind {
    Custom,
    ComponentBuilder,
    Assembler,
    Stack,
    Tokenize,
    Grammar,
    Compiler,
    Function,
}


export interface Task {
    // persistent key used in serialization
    // cannot be changed!
    readonly key: string;
    readonly tag?: string;
    readonly kind: MissionKind;
    readonly unlock?: boolean;
    readonly hints?: readonly Hint[];
    // The task unlock when these missions are completed:
    readonly depends?: Task[],
    // Component used for rendering the mission
    readonly component?: JSX.Element,
    start(storage: StorageService, missions: MissionProgression): MissionState;
    restore(storage: StorageService, missions: MissionProgression): MissionState;
    // component shown in spec pane
    additionalSpec?(): JSX.Element | undefined;
}

export interface MissionState {
    verify(): VerificationResultSet;
    hasState: boolean;
    resetState(): void;
    readonly isCompleted: boolean;
    store(storage: StorageService): void;
    // Component to render the level UI
    getComponent?(gameState: GameState, selectMission: (mission: MissionItem) => void): JSX.Element;
}
