import { DiagramMissionType, DiagramType } from './diagramMissionType';
import { DiagramAdapter } from './missions/missions';
import { CircuitStructure } from './circuitStructure';
import { CircuitState } from './circuitState';
import { TestCase, DiagramVerificationResultSet } from './verification';
import { DiagramRepository } from '../app/diagramRepository';
import { VerificationResultSet } from '../app/verificationResults';
import { GameState } from '../app/gameState';
import { MissionState } from '../app/task';
import { DiagramMissionsSet } from '../app/missionProgression';
import { getScoreText } from './score';
import { StorageService } from 'common/storage.service';


// The state for a mission which have been started
// exposes the circuit state of the diagram
export class DiagramMissionState implements MissionState {
    isCompleted = false;
    diagram: CircuitState;
    constructor(readonly missionType: DiagramMissionType, private readonly storage: StorageService) {
        this.diagram = this.createDiagram();
    }
    get palette() { return this.missionType.palette; }
    verify() {
        const testResults = this.verifyDiagram();
        this.isCompleted = testResults.succeeded;
        return testResults;
    }
    verifyDiagram(): VerificationResultSet {
        return this.runTests(this.diagram.structure, this.missionType.tests);
    }
    /* Run tests on a separate diagram-state without affecting the state of the current diagram */
    runTests(diagram: CircuitStructure, tests: readonly TestCase[]) {
        // first reset all inputs to 0 so flip-flops can be written
        const diagramState = new CircuitState(diagram);
        const adapter = new DiagramAdapter(diagramState);
        adapter.resetState();
        const results = new DiagramVerificationResultSet(adapter);
        // apply all test cases to the diagram
        for (const testCase of tests) {
            const result = testCase.verify(adapter);
            results.add(result);
        }
        return results;
    }
    createDiagram() {
        const structure = new CircuitStructure(
            this.missionType.inputPins,
            this.missionType.outputPins,
            this.missionType.diagramType);
        return new CircuitState(structure);
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        const repository = new DiagramRepository(storage);
        repository.saveDiagram(this.missionType.key, this.diagram);
    }
    getPalette(gameState: GameState) {
        return this.palette.concat(this.availableCustomComponents(gameState));
    }
    availableCustomComponents(gameState: GameState) {
        if (this.missionType.diagramType === DiagramType.TransistorLevel) {
            return [];
        }
        // add all custom nodes which does not recursively depend on this mission
        return gameState.customComponents.customComponentBuilders
            .map(cc => cc.nodeType)
            .filter(nodeType => !gameState.diagramSet.nodeDependsOnMission(nodeType, this.missionType));
    }
    getScoreText(h: DiagramMissionsSet) {
        return getScoreText(this, h);
    }
    get hasState() {
        return this.diagram.hasState;
    }
    resetState() {
        this.diagram.resetState();
    }
}
