import './StateViewComponent.css';
import { MemoryView } from './MemoryView';
import { BinaryComponent } from '../node/BinaryComponent';
import { toHexWord } from '../../../common/hex';
import { toDecimalString } from '../../../common/arithmetics';
import { CompositeStateView, MemoryStateView } from '../../stateViews';
import { StateView } from '../../componentType';

export function StateViewComponent(props: { stateView: StateView; }) {
	const stateView = props.stateView;

	return (<span className='state-view'>
{ stateView instanceof CompositeStateView && (
    <>
    {(stateView.stateViews).map((tuple, ix) => (
        <div key={ix} className='labelled-state'>
        <b className='state-component-label'>{tuple[0]}</b>
        <StateViewComponent stateView={tuple[1]} />
    </div>))}
</>)}
{ stateView.kind==='Bit' && (
    <span className='state'>{stateView.getState() as number}</span>
)}
{ stateView.kind==='Word' && (
    <>
    <div>
        <BinaryComponent value={stateView.getState() as number} />
    </div>
    <div className='numeric-state'>
        <span className='state-number-type'>Hex</span>
        <span className='state'>{ toHexWord(stateView.getState() as number)}</span>
        {stateView.hint !== 'hex' && (<>
            <span className='state-number-type'>Dec</span>
            <span className='state'>{toDecimalString(stateView.getState() as number)}</span>
        </>)}
    </div>
</>)}
{ stateView instanceof MemoryStateView && (
    <MemoryView stateView={stateView} />)}
{ stateView.kind==='WordCompact' && (
    <BinaryComponent value={stateView.getState() as number} />)}
{ stateView.kind==='InstructionCompact' && (
    <BinaryComponent value={stateView.getState() as number} />)}
</span>);
}

