import { AssemblerProgram, MacroAssembler, PlaceholderValues } from '../../assembler/assembler';
import { ConstantsProvider, InstructionProvider, Placeholder } from '../../assembler/instructionProvider';
import { Machine } from '../../assembler/machine';
import { CodeMissionPersistence, MissionSourceFile } from '../codeMission';
import { MissionItem, MissionProgression } from '../../app/missionProgression';
import { CompositeVerificationResultSet, VerificationResult } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { MissionMacro, StackInstructionsSet } from './stackInstructionsSet';
import { MissionState } from '../../app/task';
import { StorageService } from 'common/storage.service';
import { Repository } from 'app/repository';
import { GameState } from 'app/gameState';
import React from 'react';
import { StackMissionComponent } from './StackMissionComponent';
import { StackMission } from './stackMission';
import { BlankLineAsm } from 'assembler/syntax';
import { FileModel } from 'assembler/fileModel';
import { EventEmitter2 } from 'common/eventEmitter';
import { SourceMap } from './sourceMap';
import { AssemblerEditorBackend, MacroEditorBackend } from 'assembler/assemblerEditorBackend';

export type TestClass = new (
    macros: InstructionProvider,
    constants: ConstantsProvider,
    placeholders:  PlaceholderValues
    ) => CodeTester;

class Builder {
    onParsed?: () => void;
    program!: AssemblerProgram;
    sourceMap!: SourceMap;
    constructor(readonly assembler: MacroAssembler,
        readonly code: FileModel,
        readonly testCode: FileModel,
    ) {
        this.parse();
        code.source.onChanged.addListener(()=> {
            this.parse();
            // TODO: should trigger refresh of editor squiggles
        });
        testCode.source.onChanged.addListener(()=> {
            this.parse();
            // TODO: should trigger refresh of editor squiggles
        });
    }
    parse() {
        this.code.parsed = this.assembler.assemble(this.code.source.load(), this.code);
        this.testCode.parsed = this.assembler.assemble(this.testCode.source.load(), this.testCode);
        this.program = this.testCode.parsed;
        this.sourceMap = new SourceMap(this.program);
        this.onParsed?.();
    }
}


/** Coordinates stepping in engine and marking current line in editors */
export class ControllerWithTest {
    onChanged?: () => void;
    constructor(
        readonly machine: Machine,
        readonly builder: Builder,
        readonly editorBackend: MacroEditorBackend,
        readonly testCodeEditorBackend: AssemblerEditorBackend,
        readonly macroName: string,
    ) {
        this.reset();
        this.builder.onParsed = () => { this.update(); }
    }
    /** aggregated errors from both editors */
    get errors() {
        return this.program.getAllErrors().map(e => ({ text: e }));
    }
    markCurrentLine() {
        let editorBackendCurrentLineIx = undefined;
        let testCodeEditorBackendCurrentLineIx = undefined;
        if (this.program.getAllErrors().length === 0) {
            if (this.isFinished) {
                // mark blank line at end of top-level code
                const lines = this.testCodeEditorBackend.getLines();
                if (lines.length > 0) {
                    let lastIx = -1;
                    let lastLine = lines.at(lastIx);
                    // in case of trailing blank lines, we backtrack to last non-blank line
                    while (lastLine instanceof BlankLineAsm && lines.length + lastIx > 0) {
                        lastIx = lastIx - 1;
                        lastLine = lines.at(lastIx)
                    }
                    if (lastLine && lastLine.tokens.length > 0) {
                        testCodeEditorBackendCurrentLineIx = lastLine.lineOffset + 1;
                    }
                } else {
                    // no lines in code
                    testCodeEditorBackendCurrentLineIx = 0;
                }
            } else {
                const pc = this.machine.pc.get();
                const sourceMap = this.builder.sourceMap;
                const currentLineIx = this.builder.sourceMap.addrToSyntaxLine.get(pc)!;
                testCodeEditorBackendCurrentLineIx = currentLineIx;

                // Macro
                const currentInstruction = sourceMap.addrToInstruction.get(pc);
                const unit = currentInstruction?.tokens[0]?.location.unit;
                if (unit instanceof MissionMacro) {
                    const macroName = unit.identifier;
                    if (macroName === this.macroName) {
                        const macroLineIx = currentInstruction?.lineOffset;
                        editorBackendCurrentLineIx = macroLineIx;
                    }
                }
            }
        }
        this.editorBackend.setCurrentLine(editorBackendCurrentLineIx);
        this.testCodeEditorBackend.setCurrentLine(testCodeEditorBackendCurrentLineIx);
    }
    update() {
        // fetches value of address and updates current line
        const word = this.fetch(this.machine.pc.get());
        this.machine.resolve(word);
        this.markCurrentLine();
        this.onChanged?.()
    }

    // TODO: How much of this is used?
    currentLineIx: number | undefined;
    currentAddr = 0;
    currentValue = 0;
    // onValueChanged is fired if the value of the currently selected ROM address changes
    // (due to editing the assembler)
    readonly currentValueChanged = new EventEmitter2<number>();
    readonly currentAddressChanged = new EventEmitter2<number>();

    // When the engine fetches an instruction.
    // Marks the line as 'current'
    fetch(addr: number) {
        const isChanged = this.currentAddr !== addr;
        this.currentAddr = addr;
        this.refreshCurrentValue();
        if (isChanged) {
            this.currentAddressChanged.fire(this.currentAddr);
        }
        return this.currentValue;
    }
    /* update current value */
    private refreshCurrentValue() {
        if (this.currentAddr >= this.program.instructions.length) {
            // clear current
            this.currentLineIx = undefined;
            this.currentValue = 0;
        } else {
            const instruction = this.builder.sourceMap.addrToInstruction.get(this.currentAddr);
            if (instruction) {
                this.currentValue = instruction.instruction.toWord();
            }
            this.currentLineIx = this.builder.sourceMap.addrToSyntaxLine.get(this.currentAddr);
        }
    }
    tick() {
        this.machine.tick();
        this.update();
        return this.isFinished;
    }
    reset() {
        this.machine.reset();
        this.update();
    }
    get program() {
        return this.builder.program;
    }
    get isFinished() {
        return this.machine.pc.get() >= this.program.instructions.length;
    }
}

export class StackMissionState implements MissionState {
    readonly code: MissionSourceFile;
    readonly testCode: MissionSourceFile;
    isCompleted = false;
    readonly machine;
    readonly codeFileModel;
    readonly testCodeFileModel;
    readonly editorBackend;
    readonly testCodeEditorBackend;
    readonly controller;
    readonly macroProvider;
    readonly placeholders;
    readonly globalConstants;
    readonly builder;
    constructor(
        public readonly mission: StackMission,
        private readonly repository: StorageService,
        protected readonly game: MissionProgression,
        data?: CodeMissionPersistence
    ) {
        this.code = new MissionSourceFile(this.getCode(data), this);
        this.testCode = new MissionSourceFile(this.getTestCode(data), this);

        this.machine = new Machine();
        this.globalConstants = game.sharedConstants;
        this.placeholders = new PlaceholderValues(this.mission.macro.placeholders ?? [] as Placeholder[]) as unknown as PlaceholderValues;
        this.macroProvider = new StackInstructionsSet(game);

        const assembler = new MacroAssembler(this.macroProvider, this.globalConstants, this.placeholders);

        this.codeFileModel = new FileModel(this.code);
        this.editorBackend = new MacroEditorBackend(this.mission.key + '/code', this.codeFileModel);

        this.testCodeFileModel = new FileModel(this.testCode);
        this.testCodeEditorBackend = new AssemblerEditorBackend(this.mission.key + '/test', this.testCodeFileModel);

        this.builder = new Builder(assembler, this.codeFileModel, this.testCodeFileModel);
        this.controller = new ControllerWithTest(this.machine, this.builder, this.editorBackend, this.testCodeEditorBackend, this.mission.macro.name);
    }
    defaultCode = `# Assembler code \n`;
    getCode(data?: CodeMissionPersistence) {
        if (data) {
            return data.code;
        } else {
            return this.defaultCode;
        }
    }
    getTestCode(data?: CodeMissionPersistence) {
        // TODO: fallback only because of compile error
        return data?.testCode ?? this.mission.defaultTestCode ?? '';
    }
    save() {
        this.store(this.repository);
    }
    store(storage: StorageService) {
        const data: CodeMissionPersistence = { code: this.code.text, testCode: this.testCode.text };
        const repository = new Repository(storage);
        repository.saveLevel(this.mission.key, data);
    }
    verify(): CompositeVerificationResultSet {
        const tests = this.tests;
        const results: VerificationResult[] = tests.map(t =>
            new t(this.macroProvider, this.globalConstants, this.placeholders).test(this.code));
        const result = new CompositeVerificationResultSet(results);
        this.isCompleted = result.succeeded;
        return result;
    }
    hasState = false;
    resetState() {
        /* do nothing */
    }
    get tests(): TestClass[] { return this.mission.tests; }
    getComponent(gameState: GameState, selectMission: (mission: MissionItem) => void): JSX.Element {
        // invoked every time the mission is displayed
        // we re-parse since other macros may have been loaded or updated.
        this.builder.parse();
        return React.createElement(StackMissionComponent,
            {missionState: this,
            selectMission: selectMission,
            gameState: gameState});

    }
}
