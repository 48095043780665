import { bit, word } from "diagram/pins";
import { dffNodeType } from "./dffMissions";
import { dff16NodeType } from "./registerMission";
import { diagram } from "./missions";
import { nandNodeType, splitterNodeType } from "./logicMissions";
import { transparent } from "./dependency";
import { component } from "./baseNodeType";
import { OutputRuleArray } from "./outputRules";
import { SequentialTest, Step, TestState, TestStep } from "diagram/sequentialTest";

export const ledRNodeType = component('led',
    'LED_R',
    [bit('')],
    [],
    new OutputRuleArray(([_]) => []),
    transparent(),
    'led'
);

export const ledGNodeType = component('led',
    'LED_G',
    [bit('')],
    [],
    new OutputRuleArray(([_]) => []),
    transparent(),
    'led'
);

export const ledBNodeType = component('led',
    'LED_B',
    [bit('')],
    [],
    new OutputRuleArray(([_]) => []),
    transparent(),
    'led'
);

function testDisplayState(expectedOnCount: number): TestStep {
    return (st: TestState) => {
        const leds = st.findNodes(ledGNodeType)
        if (leds.length < 15) {
            return 'Expected 15 leds.';
        }
        const ledsOn = leds.filter(l => l.inputConnectorStates[0]?.bitState === true);
        if (ledsOn.length != expectedOnCount) {
            return `Expected ${expectedOnCount} leds to be on.`;
        }
        return null;
    };
}

export const displayMemoryMission = diagram({
    key: 'DISPLAY16',
    inputPins: [bit('st'), word('X'), bit('cl')],
    outputPins: [],
    palette: [
        nandNodeType,
        // ledRNodeType,
        ledGNodeType,
        // ledBNodeType,
        dffNodeType,
        dff16NodeType,
        splitterNodeType,
        nandNodeType
    ],
    tests: [
        new SequentialTest([
            // st, word, cl
            Step.setPin('X', 0b111_100_110_100_100_0, 'set <b>X</b>=111_100_110_100_100  in binary'),
            Step.setPin('st', 1, 'set <b>st</b>=1'),
            Step.setPin('cl', 1, 'set <b>cl</b>=1'),
            Step.setPin('cl', 0, 'set <b>cl</b>=0 - clock cycle complete. Display should reflect input'),
            testDisplayState(8),
        ]),
    ],
    score: undefined
} as const);
