import React from "react";
import { NavLink } from "react-router-dom";
import { CustomComponentsPage } from "../../diagram/components/customComponent/CustomComponentsPage";
import { TextContext } from "../../locale/text.service";
import { UiText } from "../../locale/UiText";
import { GameStateContext } from "../gameState";
import { MissionItem } from "../missionProgression";
import { MissionState, Task } from "../task";
import { GameComponent } from "./GameComponent";
import { LanguageMenu } from "./LanguageMenu";
import { MissionsOverview } from "./MissionsOverview";
import { MissionTextLoader } from "./missionTextLoader";
import { PlaygroundPage } from "../PlaygroundPage";

/* passed down through the UI - represent the current active mission */
export class ActiveMission {
    constructor(readonly item: MissionItem, readonly state: MissionState, readonly texts: MissionTextLoader) {}
    get key() { return this.item.mission.key; }
    get mission(): Task { return this.item.mission; }
}

export function TopLevel() {
    type Tab = 'solve' | 'levels' | 'custom' | 'playground';
    const gameState = React.useContext(GameStateContext);
    const textService = React.useContext(TextContext);

    const [state, setState] = React.useState(() => ({
        tab: 'solve' as Tab,
        activeMission: getActiveMission(gameState.currentMission)
    }));
    const {tab, activeMission} = state;
    function tabClick(tab: Tab) {
        setState({...state, tab: tab});
    }
    async function selectLanguage(lang: string) {
        await textService.changeLanguage(lang);
        // force re-render
        setState({...state});
    }
    function getActiveMission(missionItem: MissionItem) {
        gameState.selectMission(missionItem);
        const missionTexts = new MissionTextLoader(missionItem.mission, textService);
        return new ActiveMission(missionItem, missionItem.state!, missionTexts);
    }

    function selectMission(missionItem: MissionItem) {
        setState(st => ({...st,
            tab: 'solve',
            activeMission: getActiveMission(missionItem)
        }));
    }

    return (
<div className='game-page'>
<nav className='navbar navbar-dark navbar-collapse game-topbar'>
    <div className='container-fluid'>
        <div className='nav navbar-brand'>
            Nandgame
        </div>
        <div className='nav nav-tabs navbar-light bg-light'>
            <button onClick={()=>tabClick('solve')} className={'nav-link' + (tab==='solve' ? ' active' : '')}><UiText id='solve_level' /></button>
            <button onClick={()=>tabClick('levels')} className={'nav-link' + (tab==='levels' ? ' active' : '')}>Levels</button>
            { gameState.hasUnlockedCustomComponents && (
                <button onClick={()=>tabClick('custom')} className={'nav-link' + (tab==='custom' ? ' active' : '')}><UiText id='custom_components' /></button>)}
                        { gameState.hasUnlockedCustomComponents && (
                <button onClick={()=>tabClick('playground')} className={'nav-link' + (tab==='playground' ? ' active' : '')}>Playground</button>)}
        </div>

        <ul className='nav nav-pills navbar-dark me-auto'>
            <li className='nav-item'><NavLink to='/settings'  className={({ isActive }) => isActive ? 'nav-active' : 'nav-link'}>
                <i className='bi bi-gear'></i>
            </NavLink></li>
            <li className='nav-item'><NavLink to='/donate'  className={({ isActive }) => isActive ? 'nav-active' : 'nav-link'}><UiText id='donate' /></NavLink></li>
            <li className='nav-item'><NavLink to='/about'  className={({ isActive }) => isActive ? 'nav-active' : 'nav-link'}><UiText id='about' /></NavLink></li>
        </ul>
        <LanguageMenu selectLanguage={(lang) => void selectLanguage(lang)} />
    </div>
</nav>

{ tab==='solve' && <GameComponent key={activeMission.key}
    activeMission={activeMission}
    selectMission={selectMission}
    /> }
{ tab==='custom' && (<div><CustomComponentsPage /></div>)}
{ tab==='playground' && (<div><PlaygroundPage /></div>)}
{ tab==='levels' && (<div>
    <MissionsOverview currentMission={activeMission.item} selectMission={selectMission} />
</div>)}
</div>);
}
