import { MissionKind, Task } from "app/task";
import { MacroSpecification } from "./macroSpecification";
import { MissionProgression } from "app/missionProgression";
import { StorageService } from "common/storage.service";
import { Hint } from "diagram/hint";
import { StackMissionState, TestClass } from "./stackMissionState";
import { Repository } from "app/repository";
import { CodeMissionPersistence } from "missions/codeMission";
import React from "react";
import { StackExample } from "./StackVisual";

export interface StateExample {
    readonly label: string;
    readonly stack: {address?: string, value: string, marker?: string}[];
    readonly registers?: {name: string, value:string}[];
}

export interface StackMission extends Task {
    readonly macro: MacroSpecification;
    readonly examples?: StateExample[];
    readonly tests: TestClass[];
    readonly defaultTestCode: string;
}

export abstract class StackMissionBase implements Task {
    abstract key: string;
    readonly kind = MissionKind.Stack;
    unlock?: boolean | undefined;
    hints?: readonly Hint[] | undefined;
    depends?: Task[] | undefined;
    component?: JSX.Element | undefined;
    abstract tests: TestClass[];
    start(storage: StorageService, game: MissionProgression): StackMissionState {
        return new StackMissionState(this, storage, game);
    }
    restore(storage: StorageService, game: MissionProgression): StackMissionState {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as CodeMissionPersistence;
        return new StackMissionState(this, storage, game, data);
    }
    abstract macro: MacroSpecification;
    abstract defaultTestCode: string;
    examples?: StateExample[];
    showTestToolbox = true;
    additionalSpec() {
        if (this.examples) {
            return React.createElement(StackExample, { examples: this.examples });
        }
        return undefined;
    }
}
