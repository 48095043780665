import { VerificationError, VerificationOk } from 'app/verificationResults';
import { bitArrayToNumber, boolArrayToNumber, wordToBitArray } from '../../common/bits';
import { ComponentCounter, ComponentInternalState } from "../componentType";
import { add16NodeType, incNodeType, zeroNodeType } from "../missions/arithmeticMissions";
import { BaseBuiltinComponentType, component } from "../missions/baseNodeType";
import { clockNodeType, ClockState } from "../missions/clockNodeType";
import { controlUnit3NodeType } from '../missions/controlUnit3mission';
import { counterNodeType } from '../missions/counterMission';
import { depends, transparent } from "../missions/dependency";
import { dffNodeType, DffState } from "../missions/dffMissions";
import { andNodeType, bundlerNodeType, demuxNodeType, inv16NodeType, invNodeType, nandNodeType, orNodeType, selector16NodeType, selectorNodeType, splitterNodeType, xorNodeType } from "../missions/logicMissions";
import { diagram, DiagramAdapter } from "../missions/missions";
import { OutputRuleArray } from '../missions/outputRules';
import { dff16NodeType, RegisterState } from "../missions/registerMission";
import { bit, Pin, PinGroup, word } from "../pins";
import { SequentialTest, Step } from "../sequentialTest";
import { CompositeStateView, DelegateStateView, MemoryState, MemoryStateView } from "../stateViews";
import { numericTest, TestCase, VerificationSubjectAdapter } from '../verification';
import { assembleInstruction, csAssemble, osCode } from './csAssembler';
import { ComponentInstanceState } from 'diagram/componentState';


export const csTriggerMission = diagram({
    key: 'CS_TRIGGER',
    tag: 'preview',
    unlock: true,
    inputPins: [bit('cl')],
    outputPins: [bit('tr')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        counterNodeType,
        dff16NodeType,
        incNodeType, add16NodeType,
        inv16NodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        new SequentialTest([
            Step.assertOutput('tr', 0, ''),
            Step.setAndTick([0], ''),
            Step.assertOutput('tr', 0, ''),
        ]),
        {
            verify(adapter: VerificationSubjectAdapter) {
                adapter.resetState();
                adapter.setInput('cl', 0);
                for (let ix = 0; ix < 1000; ix++) {
                    const tr = adapter.getOutput('tr');
                    const expectedTr = ix > 0 && (ix % 0x100 === 0);
                    if (tr===1 && !expectedTr) {
                        return new VerificationError(`tr was ${tr} at tick ${ix}. Expected 0`);
                    }
                    if (tr===0 && expectedTr) {
                        return new VerificationError(`tr was ${tr} at tick ${ix}. Expected 1`);
                    }
                    adapter.setInput('cl', 1);
                    adapter.setInput('cl', 0);
                }
                return new VerificationOk();
            }
        }
    ],
    score: undefined, // TODO
} as const);

export class CsTriggerState implements ComponentInternalState {
    registerState = new RegisterState();
    resolveOutputs(node: ComponentInstanceState) {
        const clock = node.inputConnectorStates[0]!.bitState;
        return this.resolve(clock);
    }
    resolve(clock: boolean) {
        const trigger = this.registerState.output === 0x00FF;
        const newRegisterValue = (this.registerState.output + 1) & 0x00FF;
        this.registerState.resolve(true, newRegisterValue, clock);
        return [trigger ? 1 : 0];
    }

    reset() {
        this.registerState.reset();
    }
}

export const csTriggerNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'trigger';
    readonly key = 'CS_TRIGGER';
    readonly inputs = [bit('cl')];
    readonly outputs = [bit('tr')];
    readonly hasInternalState = true;
    readonly depends = depends(csTriggerMission);
    readonly createInternalState = () => new CsTriggerState();
};

export const csModeMission = diagram({
    key: 'CS_MODE',
    tag: 'preview',
    unlock: true,
    inputPins: [bit('sw'), bit('tt'), bit('cl')],
    outputPins: [bit('md'), bit('sw'), word('IR')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        dffNodeType,
        csTriggerNodeType,
        selector16NodeType,
        bundlerNodeType,
    ],
    tests: [
        new SequentialTest([
            Step.setAndTick([0, 0, 0], 'Set <b>sw</b>=0 and <b>tt</b>=0. '),
            Step.assertOutputs([0, 0, 0]),
        ]),
        new SequentialTest([
            Step.setAndTick([1, 0, 0], 'Set <b>sw</b>=1. '),
            Step.assertOutput('md', 1, '- sw input should cause mode to change to 1.'),
            Step.assertOutput('sw', 1, '- sw input should cause sw output.'),
            Step.assertOutput('IR', 2, 'For sw input, IR should be 10 in binary = 2 in decimal'),
            Step.setAndTick([0, 0, 0], 'Set <b>sw</b>=0'),
            Step.assertOutput('md', 1, 'Mode (md) should remain 1 until next interrupt.'),
        ]),
        new SequentialTest([
            Step.setAndTick([0, 1, 0],
                'Set <b>tt</b>=1. '),
            Step.assertOutput('sw', 0, 'tt should not cause sw output in kernel mode (md=0).'),
        ]),
    ],
    score: undefined, // TODO
} as const);

export class CsModeState implements ComponentInternalState {
    mode = new DffState();
    stateView = new CompositeStateView([
        ['mode', this.mode.stateView]
    ]);
    resolveOutputs(node: ComponentInstanceState) {
        const sw = node.inputConnectorStates[0]!.bitState;
        const tt = node.inputConnectorStates[1]!.bitState;
        const cl = node.inputConnectorStates[2]!.bitState;
        const mode = this.mode.oldState;
        const toggleMode = sw || (tt && mode);
        this.mode.resolve(toggleMode, !mode, cl);
        const ir_value = toggleMode ? boolArrayToNumber([sw, tt]) : 0;
        return [ mode ? 1 : 0, toggleMode ? 1 : 0, ir_value];
    }
    reset() {
        this.mode.reset();
    }
}

export const csModeNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'mode';
    readonly key = 'CS_MODE';
    readonly inputs = [bit('sw'), bit('tt'), bit('cl')];
    readonly outputs = [bit('md'), bit('sw'), word('IR')];
    readonly hasInternalState = true;
    readonly depends = depends(csModeMission);
    readonly createInternalState = () => new CsModeState();
};


/* Swap values when sw=1. If input at the same time as sw, the input will be written to the shadow register */
export const csRegisterMission = diagram({
    key: 'CS_REGISTER',
    tag: 'preview',
    unlock: true,
    inputPins: [bit('st'), bit('sb'), word('X'), bit('sw'), bit('md'), word('X0'), bit('cl')],
    outputPins: [word('R'), word('Rb')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        dff16NodeType,
        selector16NodeType
    ],
    tests: [
        new SequentialTest([
            Step.setAndTick([1, 0, 42, 0, 0, 0, 0],
                'Set <b>st</b>=1 and <b>X</b>=42. Should store the number 42 '),
            Step.assertOutput('R', 42, 'The stored number should now be emitted.'),
        ]),
        new SequentialTest([
            Step.setAndTick([1, 1, 42, 0, 0, 0, 0], 'Should store the number 42 in backup register '),
            Step.assertOutput('Rb', 42, 'The stored number should now be emitted on Rb.'),
        ]),
    ],
    score: undefined, // TODO
} as const);

export class CsRegisterState implements ComponentInternalState {
    registerState = new RegisterState();
    backupState = new RegisterState();
    // TODO: Should show backup register?
    readonly stateView = new DelegateStateView(() => this.registerState.output, 'Word');
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates[0]!.bitState;
        const storeBackup = node.inputConnectorStates[1]!.bitState;
        const data = node.inputConnectorStates[2]!.numState;
        const swap = node.inputConnectorStates[3]!.bitState;
        const mode = node.inputConnectorStates[4]!.bitState;
        const kernelModeInit = node.inputConnectorStates[5]!.numState;
        const clock = node.inputConnectorStates[6]!.bitState;
        return this.resolve(st, storeBackup, data, swap, mode, kernelModeInit, clock);
    }
    resolve(st: boolean, storeBackup: boolean, data: number, swap: boolean, mode: boolean, kernelInit: number, clock: boolean) {
        const writeRegister = swap || (st && !storeBackup);
        const newRegisterValue = swap ? (mode ? kernelInit : this.backupState.output) : data;
        const writeBackup = swap || (st && storeBackup);
        const newBackupValue = swap ? (st ? data : this.registerState.output) : data;
        this.registerState.resolve(writeRegister, newRegisterValue, clock);
        this.backupState.resolve(writeBackup, newBackupValue, clock);
        return [this.registerState.output, this.backupState.output] as const;
    }

    reset() {
        this.registerState.reset();
        this.backupState.reset();
    }
}

export const csRegisterNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'register';
    readonly key = 'CS_REGISTER';
    readonly inputs = [bit('st'), bit('sb'), word('X'), bit('sw'), bit('md'), word('X0'), bit('cl')];
    readonly outputs = [word('R'), word('Rb')];
    readonly hasInternalState = true;
    readonly depends = depends(csRegisterMission);
    readonly createInternalState = () => new CsRegisterState();
};

export const csProgramCounterMission = diagram({
    key: 'CS_PROGRAM_COUNTER',
    tag: 'preview',
    unlock: true,
    inputPins: [bit('j'), bit('sb'), word('X'), bit('sw'), bit('md'), bit('cl')],
    outputPins: [word('PC'), word('PCb')],
    palette: [
        csRegisterNodeType,
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        dff16NodeType,
        selector16NodeType,
        incNodeType, add16NodeType,
        inv16NodeType,
    ],
    tests: [
        new SequentialTest([
            Step.setAndTick([0, 0, 0, 0, 0, 0], ''),
            Step.assertOutput('PC', 1, 'PC should increment to 1'),
            Step.setAndTick([0, 0, 0, 0, 0, 0], ''),
            Step.assertOutput('PC', 2, 'PC should increment to 2'),
        ]),
        new SequentialTest([
            Step.setAndTick([1, 0, 42, 0, 0, 0],
                'Set <b>j4</b>=1 and <b>X</b>=42. Should store the number 42 '),
            Step.assertOutput('PC', 42, 'The stored number should now be emitted.'),
            Step.setAndTick([0, 0, 0, 0, 0, 0], 'No input'),
            Step.assertOutput('PC', 43, 'PC should increment with 1'),
        ]),
        new SequentialTest([
            Step.setAndTick([1, 0, 42, 0, 0, 0],
                'Set <b>j</b>=1 and <b>X</b>=42. Should store the number 42 '),
            Step.setAndTick([0, 0, 0, 1, 1, 0],
                    'Set <b>sw</b>=1 and <b>md</b>=1. Should store the current SP (now 43) in backup and set SP to 0 '),
            Step.assertOutput('PC', 0, 'SP should be set to 0'),
            Step.assertOutput('PCb', 43, 'Previous SP should be saved in backup'),
            Step.setAndTick([0, 0, 0, 1, 0, 0],
                'Set <b>sw</b>=1 and <b>md</b>=0. Should swap the current SP and backup.'),
            Step.assertOutput('PC', 43, 'SP should be set to 43'),
            Step.assertOutput('PCb', 1, 'Previous SP should be saved in backup'),
        ]),
    ],
    score: undefined, // TODO
} as const);

export class CsPcState implements ComponentInternalState {
    registerState = new RegisterState();
    backupState = new RegisterState();
    // TODO: Should show backup register?
    readonly stateView = new DelegateStateView(() => this.registerState.output, 'Word');
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates[0]!.bitState;
        const storeBackup = node.inputConnectorStates[1]!.bitState;
        const data = node.inputConnectorStates[2]!.numState;
        const swap = node.inputConnectorStates[3]!.bitState;
        const mode = node.inputConnectorStates[4]!.bitState;
        const clock = node.inputConnectorStates[5]!.bitState;
        return this.resolve(st, storeBackup, data, swap, mode, clock);
    }
    resolve(st: boolean, storeBackup: boolean, data: number, swap: boolean, mode: boolean, clock: boolean) {
        const next = this.registerState.output + 1;
        const newRegisterValue = swap ? (mode ? 0 : this.backupState.output) :
            (st && !storeBackup) ? data : next
        const writeBackup = swap || (st && storeBackup);
        const newBackupValue = swap ? (st ? data : next) : data;
        this.registerState.resolve(true, newRegisterValue, clock);
        this.backupState.resolve(writeBackup, newBackupValue, clock);
        return [this.registerState.output, this.backupState.output] as const;
    }

    reset() {
        this.registerState.reset();
        this.backupState.reset();
    }
}

export const csPcNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'pc';
    readonly key = 'CS_PC';
    readonly inputs = [bit('j'), bit('sb'), word('X'), bit('sw'), bit('md'), bit('cl')];
    readonly outputs = [word('PC'), word('PCb')];
    readonly hasInternalState = true;
    readonly depends = depends(csRegisterMission);
    readonly createInternalState = () => new CsPcState();
};


export const csRegisterBlockMission = diagram({
    key: 'CS_REGISTER_BLOCK',
    tag: 'preview',
    unlock: true,
    inputPins: [
        word('X'), bit('a'), bit('d'), bit('m'), word('PC'), bit('j'), bit('sb'), bit('md'), bit('sw'), word('IR'),
        bit('cl')
    ],
    outputPins: [word('A'), word('Ab'), word('D'), word('D'), word('M'), word('Mb'), word('PC'), word('PCb')],
    palette: [
        csRegisterNodeType,
        csPcNodeType,
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        dffNodeType,
        dff16NodeType,
        bundlerNodeType,
        inv16NodeType,
        selector16NodeType
    ],
    tests: [

    ],
    score: undefined, // TODO
} as const);

export class CsRegistersState implements ComponentInternalState {
    aState = new CsRegisterState();
    dState = new CsRegisterState();
    mmState = new CsRegisterState();
    pcState = new CsPcState();
    stateView = new CompositeStateView([
        ['A', this.aState.stateView],
        ['D', this.dState.stateView],
        ['M', this.mmState.stateView],
        ['PC', this.pcState.stateView],
    ]);
    resolveOutputs(node: ComponentInstanceState) {
        const x = node.inputConnectorStates[0]!.numState;
        const a = node.inputConnectorStates[1]!.bitState;
        const d = node.inputConnectorStates[2]!.bitState;
        const m = node.inputConnectorStates[3]!.bitState;
        const pc = node.inputConnectorStates[4]!.numState;
        const j = node.inputConnectorStates[5]!.bitState;
        const sb = node.inputConnectorStates[6]!.bitState;
        const mode = node.inputConnectorStates[7]!.bitState;
        const swap = node.inputConnectorStates[8]!.bitState;
        const ir_value = node.inputConnectorStates[9]!.numState;
        const cl = node.inputConnectorStates[10]!.bitState;
        const stm = m && !mode;
        const [aReg, ab] = this.aState.resolve(a, sb, x, swap, mode, this.aState.backupState.output, cl);
        const [dReg, db] = this.dState.resolve(d, sb, x, swap, mode, ir_value, cl);
        const [mmReg, mmb] = this.mmState.resolve(stm, sb, x, swap, mode, this.mmState.backupState.output, cl);
        const [pcReg, pcb] = this.pcState.resolve(j, sb, pc, swap, mode, cl);
        return [aReg, ab, dReg, db, mmReg, mmb, pcReg, pcb] as const;
    }
    reset() {
        this.aState.reset();
        this.dState.reset();
        this.mmState.reset();
        this.pcState.reset();
    }
}

export const csRegistersNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'registers';
    readonly key = 'CS_REGISTER_BLOCK';
    readonly inputs = [
        word('X'), bit('a'), bit('d'), bit('m'), word('PC'), bit('j'), bit('sb'), bit('md'), bit('sw'), word('IR'),
        bit('cl')
    ];
    readonly outputs = [
        word('A'), word('Ab'), word('D'), word('Db'), word('M'), word('Mb'), word('PC'), word('PCb')
    ];
    readonly hasInternalState = true;
    readonly depends = depends(csRegisterMission);
    readonly createInternalState = () => new CsRegistersState();
};


export const generalPurposeMemoryMission = diagram({
    key: 'CS_GENERAL_MEMORY',
    tag: 'preview',
    unlock: true,
    inputPins: [
        new PinGroup('A', [bit('a1'), bit('a0')]),
        bit('st'), word('X'),
        new PinGroup('PC', [bit('pc1'), bit('pc0')]),
        bit('cl')],
    outputPins: [word('*A'), word('I')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        demuxNodeType, selectorNodeType, selector16NodeType,
        dff16NodeType,
        inv16NodeType,
        bundlerNodeType, splitterNodeType,
    ],
    tests: [
        new SequentialTest([
            Step.setAndTick([1, 0, 1, 42, 0, 0, 0], ''),
            Step.assertOutput('*A', 42, '*A should be the previously stored value at address 10'),
        ]),
    ],
    score: undefined, // TODO
} as const);

export class MemoryBlockState implements ComponentInternalState, MemoryState {
    newState: number[] = [];
    oldState: number[] = [];
    stateView = new MemoryStateView(this);

    constructor(private node: ComponentInstanceState) { }
    resolveOutputs(node: ComponentInstanceState) {
        const ramState = node.internalState as MemoryBlockState;
        const st = node.inputConnectorStates[0]!.bitState;
        const data = node.inputConnectorStates[1]!.numState;
        const addr = node.inputConnectorStates[2]!.numState;
        const pc = node.inputConnectorStates[3]!.numState;
        const clock = node.inputConnectorStates[4]!.bitState;
        return ramState.resolve(st, data, addr, pc, clock);
    }
    get address() { return this.node.inputConnectorStates[2]!.numState; }

    peekState(addr: number) {
        return new DelegateStateView(() => this.peek(addr), 'WordCompact');
    }

    peek = (addr: number) => this.oldState[addr] ?? 0;

    resolve(st: boolean, data: number, addr: number, pc: number, clock: boolean) {
        if (st && !clock) {
            this.newState[addr] = data;
        }
        if (clock) {
            this.oldState[addr] = this.newState[addr] ?? 0;
        }
        // if the address have never ween written to, it is undefined. We default to 0
        const value = this.peek(addr);
        return [value];
    }

    reset() {
        this.oldState = [];
        this.newState = [];
    }
}

export const gpMemoryBlockNodeType = new class extends BaseBuiltinComponentType {
    name = 'ram 18';
    key = 'GP_MEMORY_512K';
    inputs = [bit('st'), word('X'), new Pin(18, 'Ad'), new Pin(18, 'PC'), bit('cl')];
    outputs = [word('A*'), word('I')];
    hasInternalState = true;
    depends = depends(generalPurposeMemoryMission, 1, false);
    createInternalState = (node: ComponentInstanceState) => new MemoryBlockState(node);
    /* override 'depends' for special-case text */
    componentCount = (counter: ComponentCounter) => {
        const ramCount = counter.countForMission(generalPurposeMemoryMission);
        if (ramCount.isError) {
            return ramCount;
        }
        // ram mission is 2 words = 4 bytes
        // TODO - calculate
        const ram64 = ramCount.mul(256);
        return counter.empty(`And ${ram64.count} for each kilobyte of RAM.`);
    };
};

export const gpMemoryBlock18NodeType = new class extends BaseBuiltinComponentType {
    name = 'ram 18';
    key = 'GP_MEMORY_512K';
    inputs = [bit('st'), word('X'), new Pin(18, 'Ad'), new Pin(18, 'PC'), bit('cl')];
    outputs = [word('A*'), word('I')];
    hasInternalState = true;
    depends = depends(generalPurposeMemoryMission, 1, false);
    createInternalState = (node: ComponentInstanceState) => new MemoryBlockState(node);
    /* override 'depends' for special-case text */
    componentCount = (counter: ComponentCounter) => {
        const ramCount = counter.countForMission(generalPurposeMemoryMission);
        if (ramCount.isError) {
            return ramCount;
        }
        // ram mission is 2 words = 4 bytes
        // TODO - calculate
        const ram64 = ramCount.mul(256);
        return counter.empty(`And ${ram64.count} for each kilobyte of RAM.`);
    };
};

export const bundler18NodeType = component('bundler 18',
    'BUNDLE18',
    [
        bit('17'), bit('16'),
        bit('15'), bit('14'), bit('13'), bit('12'),
        bit('11'), bit('10'), bit('9'), bit('8'),
        bit('7'), bit('6'), bit('5'), bit('4'),
        bit('3'), bit('2'), bit('1'), bit('0')
    ],
    [new Pin(18)],
    new OutputRuleArray(bits => [bitArrayToNumber(bits)]),
    transparent(),
    'bundler'
);

export const csMemoryMission = diagram({
    key: 'CS_MAPPED_MEMORY',
    tag: 'preview',
    unlock: true,
    inputPins: [word('A'), bit('st'), word('X'), word('PC'), word('M'), bit('cl')],
    outputPins: [word('*A'), word('I')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        gpMemoryBlockNodeType,
        incNodeType, add16NodeType,
        inv16NodeType,
        bundler18NodeType, splitterNodeType, selector16NodeType
    ],
    tests: [    ],
    score: undefined, // TODO
} as const);

export class CsMemoryState implements ComponentInternalState, MemoryState {
    newState: number[] = [];
    oldState: number[] = [];
    stateView = new MemoryStateView(this);

    constructor(private node: ComponentInstanceState) { }
    resolveOutputs(node: ComponentInstanceState) {
        const ramState = node.internalState as CsMemoryState;
        const addr = node.inputConnectorStates[0]!.numState;
        const st = node.inputConnectorStates[1]!.bitState;
        const data = node.inputConnectorStates[2]!.numState;
        const pc = node.inputConnectorStates[3]!.numState;
        const mm = node.inputConnectorStates[4]!.numState;
        const clock = node.inputConnectorStates[5]!.bitState;
        return ramState.resolve(st, data, addr, pc, mm, clock);
    }
    get address() { return this.node.inputConnectorStates[2]!.numState; }

    peekState(addr: number) {
        return new DelegateStateView(() => this.peek(addr), 'WordCompact');
    }
    setBlock(offset: number, data: number[]) {
        data.forEach((d, ix) => this.oldState[offset + ix] = d);
    }

    peek = (addr: number) => this.oldState[addr] ?? 0;

    resolve(st: boolean, data: number, addr: number, pc: number, mm: number, clock: boolean) {
        const hiRamMapping = (mm >> 12) & 0xf;
        const lowRamMapping = (mm >> 8) & 0xf;
        const hiRomMapping = (mm >> 4) & 0xf;
        const lowRomMapping = mm & 0xf;
        const ramSelect = (addr >> 15) & 1
        const romSelect = (pc >> 15) & 1
        const ramMapping = ramSelect === 1 ? hiRamMapping : lowRamMapping;
        const romMapping = romSelect === 1 ? hiRomMapping : lowRomMapping;
        const physicalAddr = (ramMapping << 15) | addr;
        const physicalPc = (romMapping << 15) | pc;
        if (st && !clock) {
            this.newState[physicalAddr] = data;
        }
        if (st && clock) {
            // if the address have never ween written to, it is undefined. We default to 0
            this.oldState[physicalAddr] = this.newState[physicalAddr] ?? 0;
        }
        const value = this.peek(physicalAddr);
        const instr = this.peek(physicalPc);
        return [value, instr];
    }

    reset() {
        this.oldState = [];
        this.newState = [];
    }
}

export const mappedMemoryNodeType = new class extends BaseBuiltinComponentType {
    name = 'memory';
    key = 'CS_MAPPED_MEMORY';
    inputs = [word('A'), bit('st'), word('X'), word('PC'), word('M'), bit('cl')];
    outputs = [word('X'), word('I')];
    hasInternalState = true;
    depends = depends(csMemoryMission, 1, false);
    createInternalState = (node: ComponentInstanceState) => new CsMemoryState(node);
    /* override 'depends' for special-case text */
    componentCount = (counter: ComponentCounter) => {
        const ramCount = counter.countForMission(csMemoryMission);
        if (ramCount.isError) {
            return ramCount;
        }
        // ram mission is 2 words = 4 bytes
        const ram64 = ramCount.mul(256);
        return counter.empty(`And ${ram64.count} for each kilobyte of RAM.`);
    };
};

export const csControlUnitMission = diagram({
    key: 'CS_CONTROL_UNIT',
    tag: 'preview',
    unlock: true,
    inputPins: [
        word('I'), word('A*'), word('A'), word('Ab'), word('D'), word('Db'), word('M'), word('Mb'), word('PC'), word('PCb')
    ],
    outputPins: [bit('a'), bit('d'), bit('*a'), bit('m'), bit('sb'), word('X'), bit('j'), word('PC'), bit('sw')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        controlUnit3NodeType,
        selectorNodeType, selector16NodeType,
        bundlerNodeType, splitterNodeType
    ],
    tests: [
        numericTest([
            /* I */ assembleInstruction('D -> Db'),
            /* *A */ 0,
            /* A */ 7, 9,
            /* D  */ 13, 4,
            /* M */ 0, 0,
            /* PC */ 0, 0],
        [
            /*dest: a, d, *a, m, sb */ 0, 1, 0, 0, 1, /* X */ 13, /* jmp */ 0, /* PC */ 13, /* sw */ 0
        ]),
    ],
    score: undefined, // TODO
} as const);

type Tuple10 = [number, number, number, number, number, number, number, number, number, number];

export const csControlUnitNodeType = component('control unit',
    'CS_CONTROL_UNIT',
    [
        word('I'), word('A*'), word('A'), word('Ab'), word('D'), word('Db'), word('M'), word('Mb'), word('PC'), word('PCb')
    ],
   [bit('a'), bit('d'), bit('*a'), bit('m'), bit('sb'), word('X'), bit('j'), word('PC'), bit('sw')],
    new OutputRuleArray((flags) => {
        const [i, xa_val, a, ab, d, db, mm, mmb, pc, pcb] = flags as Tuple10;
        const [is1, is2, sw, , , , , s1, s0, sb, a_mov, d_mov, xa_mov, m_mov, j, b] = wordToBitArray(i);
        const cs = controlUnit3NodeType;
        // TODO: Decide on selector flags
        if (is1 === 1 && is2 === 0) {
            // MOV block
            const regs = [a, ab, d, db, mm, mmb, pc, pcb];
            const ix = s1 * 4 + s0 * 2 + sb;
            const x = regs[ix]!;
            return [a_mov, d_mov, xa_mov, m_mov, b, x, j, x, sw] as const;
        } else {
            const [x1, a1, d1, xa1, j1] = cs.rule.resolve1([i, a, d, xa_val]) as [number, number, number, number, number];
            return [a1, d1, xa1, 0, 0, x1, j1, a, 0] as const;
        }
    }),
    depends(csControlUnitMission)
);

type Assert =
    | {type: 'ram', addr: number, expectedValue: number}
    | {type: 'register', name: string, expectedValue: number};

interface CsProcessorTestCase {
    code: string;
    cycles: number;
    asserts: Assert[];
}

class CsProcessorTest implements TestCase {
    constructor(readonly test: CsProcessorTestCase) { }
    verify(adapter: VerificationSubjectAdapter) {
        const d = (adapter as DiagramAdapter).diagram;
        let machineCode;
        try {
            const program = csAssemble(this.test.code);
            if (program.errors.length > 0) {
                return new VerificationError(`Code error: ${program.errors[0]!.lineNumber} ${program.errors[0]!.errorText} `);
            }

            machineCode = program.instructions.map(i => i.instruction.toWord())
        }
        catch (e) {
            return new VerificationError(`Internal error: ${String(e)}`);
        }

        /*
         * We locate the components clock and memory unit
         * (this is actually cheating since the user might not use those exact components)
         */

        const clockComponent = d.nodes.find(n => n.nodeType === clockNodeType);
        if (!clockComponent) {
            return new VerificationError('Clock component missing');
        }
        const clockState = clockComponent.internalState as ClockState;

        const memNode = d.nodes.find(n => n.nodeType === mappedMemoryNodeType);
        if (!memNode) {
            return new VerificationError('Memory component missing');
        }
        const ram = memNode.internalState as CsMemoryState;

        const registersNode = d.nodes.find(n => n.nodeType === csRegistersNodeType);
        if (!registersNode) {
            return new VerificationError('Register block component missing');
        }

        // Reset state of RAM and clock
        d.resetState();
        // load code into ram
        ram.reset();
        ram.setBlock(0, machineCode);
        d.resolve();

        // run n clock cycles
        for (let i = 0; i < this.test.cycles; i++) {
            clockState.tick();
        }

        for (const assert of this.test.asserts) {
            if (assert.type === 'ram') {
                const { addr,  expectedValue } = assert;
                const actual = ram.peek(assert.addr);
                if (actual !== assert.expectedValue) {
                    return new VerificationError(`Address ${addr.toString(16)} was ${actual.toString(16)}, expected ${expectedValue.toString(16)}`);
                }
            }
            if (assert.type === 'register') {
                const { name,  expectedValue } = assert;
                const actual = registersNode.outputConnectorStates.find(s => s.name === name)?.state;
                if (actual !== assert.expectedValue) {
                    return new VerificationError(`Register ${name} was ${actual?.toString(16)}, expected ${expectedValue.toString(16)}`);
                }
            }
        }

        return new VerificationOk();
    }
}

export const csCombineMission = diagram({
    key: 'CS_COMBINE',
    tag: 'preview',
    unlock: true,
    inputPins: [],
    outputPins: [],
    palette: [
        nandNodeType,
        andNodeType,
        csRegistersNodeType,
        mappedMemoryNodeType,
        clockNodeType,
        csModeNodeType,
        csTriggerNodeType,
        csControlUnitNodeType,
        selectorNodeType,
        selector16NodeType,
        bundlerNodeType,
        splitterNodeType
    ],
    tests: [
        // Smoke test
        new CsProcessorTest({
            code: `
            A = 0x99
            *A = -1
            `,
            cycles: 10,
            asserts: [
                {type: 'ram', addr: 0x99, expectedValue: 0xffff},
            ]
        }),
        // Write backup registers test
        new CsProcessorTest({
            code: `
            D = 1
            [D -> Db]
            D = D + 1
            [D -> Ab]
            D = D + 1
            [D -> PCb]
            `,
            cycles: 10,
            asserts: [
                {type: 'register', name: 'Db', expectedValue: 1},
                {type: 'register', name: 'Ab', expectedValue: 2},
                {type: 'register', name: 'PCb', expectedValue: 3},
            ]
        }),
        // Swap to user mode test
        new CsProcessorTest({
            code: `
            D = 1
            [D -> Db]
            D = D + 1
            [D -> Ab]
            A = UserCode
            [A -> PCb]
            [SWAP]
            LABEL UserCode:
            -1
            `,
            cycles: 7,
            asserts: [
                {type: 'register', name: 'D', expectedValue: 1},
                {type: 'register', name: 'PC', expectedValue: 7},
                {type: 'register', name: 'A', expectedValue: 2},
            ]
        }),
        // Test syscall
        // provide A as pointer argument to the syscall
        new CsProcessorTest({
            code: `
            A = Boot
            D;JEQ
        # Syscall:
        # write FFFF at Ab
            [Ab -> A]
            *A = -1
            # Return to user code
            [SWAP]

        LABEL Boot:
            A = UserCode
            [A -> PCb]
            [SWAP]

        LABEL UserCode:
            A = 0x100
            [SWAP]
            A = 0x101
            [SWAP]
        LABEL End:
            0;JMP
            `,
            cycles: 50,
            asserts: [
                {type: 'ram', addr: 0x100, expectedValue: 0xffff},
                {type: 'ram', addr: 0x101, expectedValue: 0xffff},
            ]
        }),
        // interrupt test
        // increment 0x81 per interrupt
        new CsProcessorTest({
            code: `
            A = Boot
            D;JEQ
        # Syscall:
        # write FFFF at Ab
            A = 0x71
            *A = *A + 1
            # Return to user code
            [SWAP]

        LABEL Boot:
            A = UserCode
            [A -> PCb]
            [SWAP]
        LABEL UserCode:
            A = 0x70
            *A = *A + 1
            A = UserCode
            0;JMP
            `,
            cycles: 1000,
            asserts: [
                {type: 'ram', addr: 0x70, expectedValue: 0xf5},
                {type: 'ram', addr: 0x71, expectedValue: 3},
            ]
        }),
        // Virtual memory test
        new CsProcessorTest({
            code: `
            # map low ram block to 1
            A = 0b0000_0001_0000_0000
            [A -> M]
            A = 0x20
            *A = -1
            # map low ram block to 2
            A = 0b0000_0010_0000_0000
            [A -> M]
            A = 0x20
            *A = -1
            `,
            cycles: 10,
            asserts: [
                {type: 'register', name: 'M', expectedValue: 0x200},
                {type: 'ram', addr: 0x8020, expectedValue: 0xffff},
                {type: 'ram', addr: 0x10020, expectedValue: 0xffff},
            ]
        }),
        // Isolated memory test
        // (user process has its own memory block)
        new CsProcessorTest({
            code: `
            # map low ram block to 1
            A = 0b0000_0001_0000_0000
            [A -> Mb]
            A = UserCode
            [A -> PCb]
            [SWAP]
        LABEL End:
            A = End
            0;JMP

        LABEL UserCode:
            A = 0x10
            *A = -1
            A = 0x10
            *A = -1
            `,
            cycles: 8,
            asserts: [
                {type: 'register', name: 'M', expectedValue: 0x100},
                {type: 'register', name: 'PC', expectedValue: 10},
                {type: 'ram', addr: 0x8010, expectedValue: 0xffff},
            ]
        }),
        //
        new CsProcessorTest({
            code: osCode,
            cycles: 2000,
            asserts: [
                {type: 'ram', addr: 0x220, expectedValue: 0x2a},
                {type: 'ram', addr: 0x221, expectedValue: 0x120},
            ]
        }),
    ],
    score: undefined, // TODO
} as const)

