import { pureStackTest } from './codeTester';
import { StackMissionBase } from './stackMission';

export const stackAddMission = new class extends StackMissionBase {
    key = 'STACK_ADD';
    macro = { name: 'add' };
    examples = [
        {label: 'before', stack: [{value:'2'}, {value:'2'}]},
        {label: 'after', stack: [{value:'4'}]},
    ];
    tests = [
        pureStackTest([9, 9], [18])
    ];
    defaultTestCode = `\
init.stack
push.value x0008
push.value x0005
add
# inspect top of stack. Should be 000d.
`
};

export const stackSubMission = new class extends StackMissionBase {
    key = 'STACK_SUB';
    macro = { name: 'sub' };
    examples = [
        {label: 'before', stack: [{value:'5'}, {value:'3'}]},
        {label: 'after', stack: [{value:'2'}]},
    ];
    tests = [
        pureStackTest([9, 8], [1])
    ];
    defaultTestCode = `\
init.stack
push.value x0008
push.value x0005
sub
# inspect top of stack. Should be 0003.
`
};

export const stackNegMission = new class extends StackMissionBase {
    key = 'STACK_NEG';
    macro = { name: 'neg' };
    examples = [
        {label: 'before', stack: [{value:'7'}]},
        {label: 'after', stack: [{value:'-7'}]},
    ];
    defaultCode = `# Arithmetic negation (twos-complement) \n`;
    tests = [
        pureStackTest([0], [0]),
        pureStackTest([1], [0xFFFF])
    ];
    defaultTestCode = `\
init.stack
push.value x0007
neg
# inspect top of stack. Should be FFF8.
`
};


/* Bit-wise logic */
export const stackAndMission = new class extends StackMissionBase {
    key = 'STACK_AND';
    macro = { name: 'and' };
    tests = [
        pureStackTest([0x100, 0x101], [0x100]),
    ];
    defaultTestCode = `\
init.stack
push.value x0FF0
push.value x0F0F
and
# inspect top of stack. Should be 0F00.
`
};

export const stackOrMission = new class extends StackMissionBase {
    key = 'STACK_OR';
    macro = { name: 'or' };
    tests = [
        pureStackTest([0x100, 0x001], [0x101])
    ];
    defaultTestCode = `\
init.stack
push.value x0F0F
push.value x00FF
or
# inspect top of stack. Should be 0FFF.
`
};

export const stackNotMission = new class extends StackMissionBase {
    key = 'STACK_NOT';
    macro = { name: 'not' };
    examples = [
        {label: 'before', stack: [{value:'0'}]},
        {label: 'after', stack: [{value:'FFFF'}]},
    ];
    defaultCode = `# Bit-wise negation \n`;
    tests = [
        pureStackTest([0], [0xFFFF]),
        pureStackTest([1], [0xFFFE])
    ];
    defaultTestCode = `\
init.stack
push.value x0F0F
not
# inspect top of stack. Should be F0F0.
`
};
