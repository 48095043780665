import './BinaryComponent.css';
import { toBinWord } from '../../../common/hex';


export function BinaryComponent({value, width = 16}: {value: number; width?: number}) {

    const binaryWord =  toBinWord(value);

	return (<>
    { width===16 && (
        <div className='binary'>
            <span className='bits'>
                <span className='bitset'>{binaryWord.substring(0, 4)}</span>
                <span className='bitset'>{binaryWord.substring(4, 8)}</span>
                <span className='bitset'>{binaryWord.substring(8, 12)}</span>
                <span className='bitset'>{binaryWord.substring(12, 16)}</span>
            </span>
        </div>
    )}
    { width===6 && (
        <div className='binary'>
            <span className='bitset'>{binaryWord.substring(10, 12)}</span>
            <span className='bitset'>{binaryWord.substring(12, 14)}</span>
            <span className='bitset'>{binaryWord.substring(14, 16)}</span>
        </div>
    )}
    </>);
}

