import React from 'react';
import './RomView.css';
import { MemoryView } from './MemoryView';
import { RomState } from 'diagram/missions/romNodeType';
import { RomEditor } from './RomEditor';
import { IComponentInstanceState } from 'diagram/componentType';
import { ComponentInstanceState } from 'diagram/componentState';

export function DiagramRomview(props: { node: IComponentInstanceState; diagramStateChanged: ()=>void; }) {

    const node = props.node as ComponentInstanceState;
	const [state, setState] = React.useState({displayEditor: false});

    function toggleEditor() {
        setState({displayEditor: !state.displayEditor});
    }
    function onClosed() {
        // update, since currently emitted instruction might have changed
        node.diagram.update();
        setState(() => ({ displayEditor: false }));
        props.diagramStateChanged();
    }
    const stateView =  (props.node.internalState as RomState).stateView;

	return (<div className='rom-view'>
    <div>
        <button type='button' onClick={toggleEditor} className='btn btn-sm btn-outline-secondary'>Edit ROM</button>
        { state.displayEditor && (
            <div className='editor'>
            <RomEditor stateView={stateView} closed={onClosed} />
        </div>)}
    </div>
    <MemoryView stateView={stateView} />
    </div>);
}

