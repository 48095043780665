import { DiagramMissionState } from './diagramMissionState';
import { ComponentCounter } from './componentType';
import { ComponentInstance } from './circuitStructure';
import { DiagramMissionType } from './diagramMissionType';
import { DiagramSet } from './diagramSet';
import { DiagramMissionsSet } from '../app/missionProgression';


export class CountError implements ComponentCounter {
    constructor(readonly message: string) { }
    isError = true;
    count = 0;
    add(_count: CountNumber): ComponentCounter {
        return this;
    }
    addNum(_count: number): ComponentCounter {
        return this;
    }
    mul(_factor: number): ComponentCounter {
        return this;
    }
    fresh(_count: number): ComponentCounter {
        throw new Error();
    }
    empty(): ComponentCounter {
        throw new Error();
    }
    addText(_txt: string) {}
    countForMission(_missionType: DiagramMissionType): ComponentCounter { return this; }
    countForNodes(_nodes: ComponentInstance[]): ComponentCounter { return this; }
}

export class CountNumber implements ComponentCounter {
    constructor(readonly diagramHistory: DiagramSet, public count: number = 0) {}
    isError = false;
    conditions?: string;
    text = '';

    addText(
        text: string) {
        this.text += text + ' ';
    }
    add(count: CountNumber) {
        this.count += count.count;
        this.text += count.text;
        return this;
    }
    addNum(val: number): ComponentCounter {
        this.count += val;
        return this;
    }
    mul(factor: number) {
        this.count *= factor;
        return this;
    }
    fresh(count = 0): ComponentCounter {
        return new CountNumber(this.diagramHistory, count);
    }
    empty(text?: string) {
        const n = new CountNumber(this.diagramHistory, 0);
        if (text) {
            n.addText(text);
        }
        return n;
    }
    countForMission(missionType: DiagramMissionType): ComponentCounter {
        const item = this.diagramHistory.getDiagramMissionStateByType(missionType);
        if (!item || !item.isCompleted) {
            return new CountError(`Mission ${missionType.key} is not completed, so the total number of gates could not be counted.`);
        }
        return this.countForMissionItem(item);
    }

    countForMissionItem(mission: DiagramMissionState): ComponentCounter {
        return this.countForNodes(mission.diagram.nodes.map(n => n.componentInstance));
    }
    countForNodes(nodes: ComponentInstance[]): ComponentCounter {
        const counter = new CountNumber(this.diagramHistory);
        for (const node of nodes) {
            const countForNode = node.nodeType.componentCount(this);
            if (countForNode instanceof CountError) {
                return countForNode;
            }
            if (countForNode instanceof CountNumber) {
                counter.add(countForNode);
            }
        }
        return counter;
    }
}

export function getNandCount(mission: DiagramMissionState, diagramHistory: DiagramMissionsSet) {
    const c = new CountNumber(diagramHistory);
    return c.countForMissionItem(mission);
}
