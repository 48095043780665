import { useContext } from 'react';

import { TextContext } from '../../locale/text.service';

export function LanguageMenu(props: {
    selectLanguage: (lang: string) => void
}) {

    const textService = useContext(TextContext);
    const languageNames =  textService.languageNames;
    const currentLanguage =  textService.currentLang;


	return (<ul className='nav nav-pills justify-content-end ms-auto'>
                {(languageNames).map(language => (
                    <li className='nav-item' key={language.lang}>
                <button
                    onClick={()=>props.selectLanguage(language.lang)}
                    className={'nav-link' + (language.lang===currentLanguage ? ' active' : '')}>{language.name}</button>
            </li>))} </ul>);
}

