import { StorageService } from 'common/storage.service';
import { Repository } from '../../app/repository';
import { ConstantsProvider } from '../../assembler/instructionProvider';
import { Scanner } from '../../assembler/scanner';
import { deleteItem } from '../../common/utilities';
import { DummySourceUnit } from 'common/location';

export class Constant {
    value;
    constructor(public name: string, public valueText: string) {
        this.value = this.parse(valueText);
    }
    setValue(valueText: string) {
        this.valueText = valueText;
        this.value = this.parse(valueText);
    }
    parse(valueText: string) {
        const s = new Scanner(valueText, -1, new DummySourceUnit());
        const val = s.optNumber();
        if (!val) {
            return undefined;
        }
        // todo handle error
        return val.numericValue;
    }
}

type ConstantPersistence = { readonly name: string; readonly value: string };
type ConstantsPersistence = { constants: ConstantPersistence[] };

// state shared across all stack levels
// for example constants are global
export class SharedConstants implements ConstantsProvider {
    readonly key = 'SHARED_STACK_CONSTANTS';
    readonly constants: Constant[] = [];
    constructor(readonly storage: StorageService) {
        const repository = new Repository(storage);
        const data = repository.getLevelData<ConstantsPersistence>(this.key);
        if (data) {
            this.constants = data.constants.map(c => new Constant(c.name, c.value));
        } else {
            this.constants = [];
        }
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        const repository = new Repository(storage);
        const constantsData: ConstantPersistence[] = this.constants.map(c => ({ name: c.name, value: c.valueText }));
        const data: ConstantsPersistence = { constants: constantsData };
        repository.saveLevel(this.key, data);
    }
    get names() {
        return this.constants.filter(c => c.name !== '' && c.value !== undefined).map(c => c.name);
    }
    get(name: string) {
        return this.constants.find(c => c.name === name)?.value;
    }
    add() {
        this.constants.push(new Constant('', ''));
        this.save();
    }
    delete(c: Constant) {
        deleteItem(this.constants, c);
    }
    updateValue(c: Constant, text: string) {
        c.setValue(text);
        this.save();
    }
    updateName(c: Constant, text: string) {
        c.name = text;
        this.save();
    }
}
