import { useState } from 'react';
import { CodeGenerationRule, CodeSegment, generateCode, GeneratedVmCode } from './codeGeneration';
import { runMain, RunResult } from '../vm/engine';
import { parse, SyntaxNode } from './parse';
import { getTerminalSymbols, PatternType, TokenAction, tokenize, TokenSet } from './tokenize';
import { ErrorResult, Option, SourcedErrorResult } from './shared';
import { ParsedUnit, LinkedUnits, linkVm, parseVm, parseVmStr } from '../vm/vmParse';
import { GrammarSet } from './earley';
import { showContext } from './errorContext';
import { Help } from './Help';
import { ErrorDisplay } from './ErrorDisplay';
import { Tokenized, LexerRulesComponent } from './LexerRules';
import { CodeEditor } from './SourceCodeEditor';
import './Workbench.css';
import { UISegment } from './UiSection';
import { SyntaxRule, SyntaxRules, validateCodegenRules, validateGrammarRules } from './SyntaxRules';
import { SyntaxTreeComponent } from './SyntaxTreeComponent';
import { TokenSpecification } from './tokenSpecification';
import { PopupHelp, VmInstructionsHelp } from './VmHelp';


function RunTimeLibrary(props: { source: string; parsed: Option<ParsedUnit>; onCodeChange: (value: string) => void }) {
    const error = props.parsed instanceof ErrorResult ? props.parsed : undefined;
    return (
        <div>
            <Help>Run-time library in the low level language. Generated code can call functions defined here.</Help>
            <div className='text-end'>
                <PopupHelp>
                    Functions in the stack-machine language which can be called from the generated code.
                    <VmInstructionsHelp />
                </PopupHelp>
            </div>
            <CodeEditor source={props.source} onCodeChange={props.onCodeChange} error={error} />
        </div>
    );
}

function SourceCode(props: { source: string; error?: ErrorResult; onSourceChange: (value: string) => void }) {
    return (
        <div>
            <Help>
                Source code - input to the compiler. Will be tokenized, parsed, translated to low-level code and executed according to the
                specifications below.
            </Help>
            <CodeEditor source={props.source} onCodeChange={props.onSourceChange} error={props.error} />
        </div>
    );
}

function GeneratedCodeSegment(props: { segment: CodeSegment }) {
    return <span>{props.segment.code}</span>;
}

function GeneratedCodeDisplay(props: { code: Option<GeneratedVmCode>; parsed: Option<ParsedUnit>; linked: Option<LinkedUnits> }) {
    if (props.code instanceof ErrorResult) {
        return <ErrorDisplay message={props.code.message} />;
    }
    const err =
        props.parsed instanceof SourcedErrorResult
            ? props.parsed.message + '\n' + showContext(props.code.asString(), props.parsed.characterIndex)
            : props.parsed instanceof ErrorResult
            ? `Error in generated code: ${props.parsed.message}`
            : props.linked instanceof ErrorResult
            ? `Error in linking code with runtime library: ${props.linked.message}`
            : undefined;
    const segments = props.code.segments.map((s, ix) => <GeneratedCodeSegment key={ix} segment={s} />);
    return (
        <div>
            <Help>The result of the code-generation.</Help>
            <div>
                <pre className="generated-code">{segments}</pre>
            </div>
            <ErrorDisplay message={err} />
        </div>
    );
}

function ExecutionResult(props: { result: Option<RunResult> }) {
    const status =
        props.result instanceof ErrorResult ? (
            <ErrorDisplay message={props.result.message} />
        ) : (
            <div className="execution-result">
                <pre>Return value: {props.result.result} </pre>
            </div>
        );
    return (
        <div>
            <Help>Result of executing the generated code.</Help>
            {status}
        </div>
    );
}

interface CompilerComponentState {
    lexical: TokenSpecification[];
    tokenSet: Option<TokenSet>;
    source: string;
    rules: SyntaxRule[];
    parsedGrammar: Option<GrammarSet>;
    codeGenRules: CodeGenerationRule[];
    syntaxTree: Option<SyntaxNode>;
    generatedCode: Option<GeneratedVmCode>;
    generatedParsed: Option<ParsedUnit>;
    runtimeLibrarySourceCode: string;
    runtimeLibraryParsed: Option<ParsedUnit>;
    linked: Option<LinkedUnits>;
    runResult: Option<RunResult>;
}

export interface CompilerConfig {
    lexical: TokenSpecification[];
    runtimeLibrary: string;
    rules: SyntaxRule[];
    save():void;
}

export function Workbench(props: { source: string; config: CompilerConfig, startSymbol: string }) {
    const compilerConfig = props.config;
    const [state, setState] = useState(() => {
        const config = props.config;
        const lexical = config.lexical;
        const rules = config.rules;
        const st = {
            source: props.source,
            lexical: lexical,
            rules: rules,
            runtimeLibrarySourceCode: config.runtimeLibrary,
        } as CompilerComponentState;
        return updateState({} as CompilerComponentState, st);
    });
    function onSourceChange(value: string) {
        setState(st => {
            return updateState(st, { source: value });
        });
    }
    /*
    Cascade state changes
    (e.g a change to grammer updates syntax tree, a change to syntax tree updates codegen etc.)
  */
    function updateState(st: Readonly<CompilerComponentState>, updates: Partial<CompilerComponentState>) {
        if (updates.lexical || updates.source) {
            const lexical = updates.lexical ?? st.lexical;
            const source = updates.source ?? st.source;
            const validatedLexical = lexical.filter(l => l.isValid);
            updates.tokenSet = tokenize(source, validatedLexical);
        }
        /*
            TODO: distinguish beween updates to rules which affect parsing vs codegen
            We should not need to reparse for every edit of codegen
        */
        if (updates.lexical || updates.rules) {
            const lexical = updates.lexical ?? st.lexical;
            const rules = updates.rules ?? st.rules;
            const validatedLexical = lexical.filter(l => l.isValid);
            const terminals = getTerminalSymbols(validatedLexical);
            updates.parsedGrammar = validateGrammarRules(rules, terminals);
        }
        if (updates.tokenSet || updates.parsedGrammar) {
            const tokenSet = updates.tokenSet ?? st.tokenSet;
            const parsedGrammar = updates.parsedGrammar ?? st.parsedGrammar;
            if (tokenSet instanceof ErrorResult || tokenSet.isError) {
                updates.syntaxTree = new ErrorResult('Invalid input - token error.');
            } else if (parsedGrammar instanceof ErrorResult) {
                updates.syntaxTree = new ErrorResult('Invalid input - grammar error.');
            } else {
                updates.syntaxTree = parse(props.startSymbol, tokenSet.tokens, parsedGrammar);
            }
        }
        if (updates.syntaxTree || updates.rules) {
            const syntaxTree = updates.syntaxTree ?? st.syntaxTree;
            const rules = updates.rules ?? st.rules;
            if (syntaxTree instanceof SyntaxNode) {
                const codegenRules = validateCodegenRules(rules);
                if (codegenRules instanceof ErrorResult) {
                    updates.generatedCode = codegenRules;
                } else {
                    updates.generatedCode = generateCode(syntaxTree, codegenRules);
                }
            } else {
                // cascading error
                updates.generatedCode = new ErrorResult('Invalid input - error in syntax tree.');
            }
        }
        if (updates.runtimeLibrarySourceCode !== undefined) {
            updates.runtimeLibraryParsed = parseVmStr(updates.runtimeLibrarySourceCode);
        }
        if (updates.generatedCode) {
            if (updates.generatedCode instanceof GeneratedVmCode) {
                updates.generatedParsed = parseVm(updates.generatedCode);
            } else {
                updates.generatedParsed = new ErrorResult('Invalid input - error in generated code.');
            }
        }
        if (updates.generatedParsed || updates.runtimeLibraryParsed) {
            const generatedParsed = updates.generatedParsed ?? st.generatedParsed;
            const runtimeLibraryParsed = updates.runtimeLibraryParsed ?? st.runtimeLibraryParsed;
            if (generatedParsed instanceof ParsedUnit && runtimeLibraryParsed instanceof ParsedUnit) {
                updates.linked = linkVm([generatedParsed, runtimeLibraryParsed]);
            } else {
                updates.linked = new ErrorResult('Invalid input to linker');
            }
        }
        if (updates.linked) {
            if (updates.linked instanceof LinkedUnits) {
                updates.runResult = runMain(updates.linked);
            } else {
                updates.runResult = new ErrorResult('Invalid input - error in code generation.');
            }
        }
        props.config.save();
        return { ...st, ...updates } as CompilerComponentState;
    }
    function onRunTimeLibraryChange(value: string) {
        setState(st => {
            compilerConfig.runtimeLibrary = value;
            return updateState(st, { runtimeLibrarySourceCode: compilerConfig.runtimeLibrary });
        });
    }
    function onRuleChanged(index: number, newRule: SyntaxRule) {
        setState(st => {
            compilerConfig.rules = compilerConfig.rules.map((item, ix) => (ix === index ? newRule : item));
            //props.config.save();
            return updateState(st, { rules: compilerConfig.rules });
        });
    }
    function onAddRule() {
        const newRule = new SyntaxRule('', '');
        compilerConfig.rules = [...compilerConfig.rules, newRule];
        setState(st => {
            // TODO: compilerConfig.save();
            // no cascading updates, since the new rule is empty and invalid
            return { ...st, rules: compilerConfig.rules };
        });
    }
    function onDeleteRule(index: number) {
        compilerConfig.rules = compilerConfig.rules.filter((_item, ix) => ix !== index);
        setState(st => {
            //props.config.save();
            return updateState(st, { rules: compilerConfig.rules });
        });
    }
    function onTokenAdd() {
        setState(st => {
            const nextId = TokenSpecification.nextId(compilerConfig.lexical);
            const newToken = new TokenSpecification(nextId, PatternType.Pattern, '', TokenAction.Literal, '');
            compilerConfig.lexical = [...compilerConfig.lexical, newToken];
            // no cascading updates, since the new token spec is empty
            return { ...st, lexical: compilerConfig.lexical };
        });
    }
    function onTokenChange(token: TokenSpecification) {
        setState(st => {
            compilerConfig.lexical = compilerConfig.lexical.map(l => (l.id === token.id ? token : l));
            return updateState(st, { lexical: compilerConfig.lexical });
        });
    }
    function onTokenDelete(id: number) {
        compilerConfig.lexical = compilerConfig.lexical.filter(item => item.id !== id);
        setState(st => {
            return updateState(st, { lexical: compilerConfig.lexical });
        });
    }
    function sourceCodeError() {
        return state.tokenSet instanceof ErrorResult ? state.tokenSet :
            state.tokenSet.error ? state.tokenSet.error :
            state.syntaxTree instanceof ErrorResult ? state.syntaxTree :
            undefined;
    }
    return (
        <div className="workbench">
            <UISegment title="Source code">
                <SourceCode source={state.source} error={sourceCodeError()} onSourceChange={onSourceChange} />
                <ExecutionResult result={state.runResult} />
            </UISegment>
            <UISegment title="Token definitions">
                <LexerRulesComponent
                    tokens={state.lexical}
                    onTokenAdd={onTokenAdd}
                    onTokenDelete={onTokenDelete}
                    onTokenChange={onTokenChange}
                />
            </UISegment>
            <UISegment title="Tokens">
                <Tokenized tokenSet={state.tokenSet} />
            </UISegment>
            <UISegment title="Syntax rules">
                <SyntaxRules
                    rules={state.rules}
                    showCodegen
                    parsedGrammar={state.parsedGrammar}
                    onRuleChanged={onRuleChanged}
                    onAddRule={onAddRule}
                    onDeleteRule={onDeleteRule}
                />
            </UISegment>
            <UISegment title="Syntax Tree">
                <SyntaxTreeComponent syntaxTree={state.syntaxTree} />
            </UISegment>
            <UISegment title="Generated code">
                <GeneratedCodeDisplay code={state.generatedCode} parsed={state.generatedParsed} linked={state.linked} />
            </UISegment>
            <UISegment title="Runtime library">
                <RunTimeLibrary
                    source={state.runtimeLibrarySourceCode}
                    parsed={state.runtimeLibraryParsed}
                    onCodeChange={onRunTimeLibraryChange}
                />
            </UISegment>
            <UISegment title="Result of execution">
                <ExecutionResult result={state.runResult} />
            </UISegment>
        </div>
    );
}
