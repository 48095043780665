import { VerificationError, VerificationOk } from '../app/verificationResults';
import { ComponentInternalState, ComponentType } from './componentType';
import { TestCase, VerificationSubjectAdapter } from './verification';

export type StepResult = string | null;

export type TestStep = (st: TestState) => StepResult;

/** A SequentialTest test contains a number of Step's executed in sequence.
 *  A Step may either setup some conditions or verify a condition.
 * */
export class SequentialTest implements TestCase {
    constructor(private steps: TestStep[]) { }

    verify(adapter: VerificationSubjectAdapter) {
        const state = new TestState(adapter);
        for (const step of this.steps) {
            const result = step(state);
            if (result !== null) {
                const text = state.toText(result);
                return new VerificationError(text);
            }
        }
        return new VerificationOk();
    }
}


/** A set of function which creates test steps */
export const Step = {

    set(input: number[], message: string) {
        return (st: TestState): StepResult => {
            return st.set(input, message);
        };
    },

    /** used in latch for the first steps - unstable/undefined output is accepted. */
    setAllowUnstable(pin: string, input: number, message: string) {
        return (st: TestState): StepResult => {
            // ignore unstable result
            st.setPin(pin, input, message);
            return null;
        };
    },

    setPin(pin: string, input: number, message: string) {
        return (st: TestState): StepResult => {
            return st.setPin(pin, input, message);
        };
    },

    setAndTick(input: number[], message: string) {
        return (st: TestState): StepResult => {
            st.setAndTick(input, message);
            return null; // no error possible
        };
    },

    setInternalState(nodeType: ComponentType, action: (state: ComponentInternalState) => void) {
        return (st: TestState) => {
            const node = st.findNode(nodeType);
            if (node) {
                action(node.internalState);
            }
            return null;
        };
    },

    assertOutputs(expected: number[]) {
        return (st: TestState) => st.assertOutput(expected);
    },

    assertOutput(label: string, expected: number, message?: string) {
        return (st: TestState) =>
            st.assertSingleOutput(label, expected, message);
    },

    /* Check that a given component have a given input state. */
    internalInput(
            nodeType: ComponentType,
            connectorName: string,
            expected: number, text: string) {
        return (st: TestState) => {
            const node = st.findNode(nodeType);
            if (node) {
                const connector = node.inputConnectorStates.find(c => c.name === connectorName);
                if (!connector) {
                    throw new Error(`Connector '${connectorName}' not found.`);
                }
                if (connector.state !== expected) {
                    const nodeName = nodeType.name;
                    return `Expected <b>${connectorName}</b> input to <b>${nodeName}</b>
                        to be ${expected} ${text}
                        but was ${connector.state ?? 0}. `;
                }
            }
            return null;
        };
    }
}

export class TestState {
    log: string[] = [];
    constructor(private adapter: VerificationSubjectAdapter) {
        adapter.resetState();
    }
    set(input: number[], message: string) {
        this.log.push(message);
        // sets pins sequentially
        for (let ix = 0; ix < input.length; ix++) {
            const result = this.adapter.setInputIx(ix, input[ix]!);
            // Early exit if there is an error
            if (typeof result === 'string') {
                return result;
            }
        }
        return null;
    }
    setPin(label: string, input: number, message: string) {
        this.log.push(message);
        return this.adapter.setInput(label, input);
    }
    setAndTick(input: number[], message: string) {
        this.log.push(message);
        this.adapter.setInputs(input);
        this.adapter.setInput('cl', 1);
        // this.adapter.getOutputs();
        this.log.push('Clock cycle');
        this.adapter.setInput('cl', 0);
        // this.adapter.getOutputs();
    }
    assertOutput(expected: number[]): StepResult {
        const actuals = this.adapter.getOutputs();
        const outputLabels = this.adapter.getConnectorLabels()[1];
        if (expected.length !== actuals.length) {
            throw new Error('Output array size mismatch');
        }
        for (let ix = 0; ix < expected.length; ix++) {
            const expectedState = expected[ix];
            const actual = actuals[ix];
            if (actual !== expectedState) {
                console.log('expectedState', expectedState, 'actual', actual);
                // early exit at fist error, so the diagram state is at the error
                return `Expected output <b>${outputLabels[ix]}</b> to be ${expectedState} but was ${actual ?? 0}`;
            }
        }
        return null;
    }
    assertSingleOutput(label: string, expected: number, message?: string): StepResult {
        const actual = this.adapter.getOutput(label);
        if (actual !== expected) {
            const msg = message ?? '';
            return `Expected output <b>${label}</b> to be ${expected} but was ${actual ?? 0}. ` + msg;
        }
        return null;
    }
    toText(result: string): string {
        if (this.log.length === 1) {
            const header = '<p>This verification was performed:</p>';
            const steps = `<p>${this.log[0]}</p>`;
            const footer = `<p>${result}</p>`;
            return header + steps + footer;
        } else {
            const header = '<p>These verification steps were performed:</p>';
            const steps = '<ol>' + this.log.map(line => `<li>${line}</li>`).join('\n') + '</ol>';
            const footer = `<p>${result}</p>`;
            return header + steps + footer;
        }
    }
    findNode(type: ComponentType) {
        return this.adapter.findInternalNode(type);
    }
    findNodes(type: ComponentType) {
        return this.adapter.findInternalNodes(type);
    }
}
