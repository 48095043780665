import { InstructionProvider, Macro } from '../../assembler/instructionProvider';
import { MissionKind } from '../../app/task';
import { MissionProgression } from '../../app/missionProgression';
import { StackMissionState } from './stackMissionState';

export class MissionMacro implements Macro {
    identifier;
    expansion;
    placeholders;
    constructor(public missionState: StackMissionState) {
        this.identifier = missionState.mission.macro.name;
        this.placeholders = missionState.mission.macro.placeholders ?? [];
        this.expansion = missionState.code.text;
    }
}

/*
    Provides definitions for the editor syntax coloring
    Two kinds of idetifiers:
        macros defined by other (completed) stack missions
        placeholders defined by the current macro
*/
export class StackInstructionsSet implements InstructionProvider {
    constructor(public missionProgression: MissionProgression) {}
    get(name: string) {
        const instr = this.instructions.find(i => i.mission.macro.name.toLowerCase() === name.toLowerCase());
        if (instr) {
            return new MissionMacro(instr);
        }
        return undefined;
    }
    private get instructions() {
        return this.missionProgression.activatedItems
            .filter(m => m.mission.kind === MissionKind.Stack)
            .filter(m => m.isStarted)
            .map(m => m.state as StackMissionState);
    }
    get names() {
        return this.instructions.map(m => m.mission.macro.name);
    }
}
