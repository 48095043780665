import { boolArrayToNumber } from "common/bits";
import { DefaultAssembler } from "assembler/assembler";
import { DummySourceUnit } from "common/location";

const srcMap: Record<string, [boolean, boolean, boolean]> = {
    'A': [false, false, false],
    'Ab':  [false, false, true],
    'PC': [true, true, false],
    'PCb': [true, true, true],
    'M': [true, false, false],
    'Mb': [true, false, true],
    'D': [false, true, false],
    'Db':  [false, true, true],
};
const targetMap: Record<string, [boolean, boolean, boolean, boolean, boolean, boolean]> = {
    // a, d, *a, m, j, b
    'PC':  [false, false, false, false, true, false],
    'PCb': [false, false, false, false, true, true],
    'M':   [false, false, false, true, false, false],
    'Mb':  [false, false, false, true, false, true],
    'D':   [false, true, false, false, false, false],
    'Db':  [false, true, false, false, false, true],
    '*A':  [false, false, true, false, false, false],
    'A':   [true, false, false, false, false, false],
    'Ab':  [true, false, false, false, false, true],
}

export function assembleInstruction(line: string) {
    if (line==='SWAP') {
        const flags = [
            // selector
            true, false,
            // swap
            true,
            // unused
            false, false, false, false,
            // src select
            false, false, false,
            // a, d, *a,
            false, false, false,
             // m, j, sb
            false, false, false
        ];
        return boolArrayToNumber(flags);
    }
    else if (line.indexOf('->') > -1) {
        const [src, target] = line.split('->', 2).map(s => s.trim()) as [string, string];
        if (!srcMap[src]) {
            throw new Error(`No match for ${src}`);
        }
        const [s1, s0, sb] = srcMap[src]!;
        if (!target || !targetMap[target]) {
            throw new Error(`No match for ${target}`);
        }
        const [a, d, xa, m, j, b] = targetMap[target]!;
        const flags = [
            // selector
            true, false,
            // swap
            false,
            // unused
            false, false, false, false,
            // src select
            s1, s0, sb,
            // a, d, *a
            a, d, xa,
            // m, j, sb
            m, j, b
        ];
        const val = boolArrayToNumber(flags);
        return val;
    }
    else {
        throw new Error(`Cannot assemble ${line}`);
    }
}

export function csPreprocess(code: string) {
    return code.replace(/\[(.*?)\]/g, (substring: string, capture1: string) => {
        const value = assembleInstruction(capture1);
        return `MACHINE 0b${value.toString(2)}`;
    });
}

export const osCode =`
    # init -
    DEFINE CurrentProcess 0x1ff
    DEFINE ProcessStore 0x200
    DEFINE UserStore 0x220

    A = Boot
    D;JEQ
    #
    #  Context switch
    #  --------------
    #
    # Save current process
    #
    #
    # process storag =
    #  curent_process * 4 + ProcessStore
    #
    A = CurrentProcess
    D = *A
    A = D
    D = D + A
    A = D
    D = D + A
    A = ProcessStore
    D = D + A
    #
    # Save
    #
    [Ab -> *A]
    A = A + 1
    [Db -> *A]
    A = A + 1
    [PCb -> *A]
    A = A + 1
    [Mb -> *A]
    #
    # Select next process
    # (*CurrentProcess + 1) modulo 2
    #
    A = CurrentProcess
    D = *A + 1
    A = 0b1
    D = D & A
    A = CurrentProcess
    *A = D
    #
    #  Resume process
    #
LABEL ResumeCurrentProcess:
    #
    # process storag =
    #  curent_process * 4 + ProcessStore
    #
    A = CurrentProcess
    D = *A
    A = D
    D = D + A
    A = D
    D = D + A
    A = ProcessStore
    A = D + A
    #
    #  restore
    # (*A to D, then D to backup register
    #  instruction set does not support direct
    #  *A to backup register
    #
    D = *A
    [D -> Ab]
    A = A + 1
    D = *A
    [D -> Db]
    A = A + 1
    D = *A
    [D -> PCb]
    A = A + 1
    D = *A
    [D -> Mb]
    #
    # Yield to user process
    #
    [SWAP]
    #
    #
    #
LABEL Boot:
    # init process block
    # set PCb to UserProcess
    # on all processes
    A = UserProcess
    D = A

    A = ProcessStore

    # init process 0

    # 0.A = 0
    *A = 0
    A = A + 1
    # init D to process nr
    # 0.D = 0
    *A = 0
    A = A + 1
    # init SP to user process
    *A = D
    A = A + 1
    # 0.MM = 0
    *A = 0
    A = A + 1
    #
    # init process 1
    #
    # 0.A = 0
    *A = 0
    A = A + 1
    # init D to process nr
    # 0.D = 1
    *A = 1
    A = A + 1
    # init SP to user process
    *A = D
    A = A + 1
    # MM = 0
    *A = 0
    #
    #  Set current process to 0
    A = CurrentProcess
    *A = 0
    #
    A = ResumeCurrentProcess
    0;JMP

    #
    #  Code for user processes:
    #
LABEL UserProcess:
    A = UserStore
    A = D + A
    *A = *A + 1
    A = UserProcess
    0;JMP
`;

export function csAssemble(code: string) {
    const code1 = csPreprocess(code);
    const a = new DefaultAssembler();
    const program = a.assemble(code1, new DummySourceUnit());
    return program;
}

export function assembleOs() {
    return csAssemble(osCode);
}

export function assembleOsArray() {
    const program = csAssemble(osCode);
    return program.instructions.map(i => i.instruction.toWord())
}
