import { useContext, useState } from "react"
import { GameStateContext } from "./gameState";
import { toJSON } from "common/json";
import { NavLink } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

export function Settings() {
    const gameState = useContext(GameStateContext);
    const [state, setState] = useState({
        showImport: false,
        showExport: false,
        showComplete: false,
        exportJson: undefined as string | undefined,
        importJson: undefined as string | undefined,
    });
    function toggleExport() {
        if (!state.showExport) {
            const exportJson = toJSON(gameState.getSnapshot());
            setState({ ...state, exportJson, showExport: true });
        } else {
            setState({ ...state, showExport: false });
        }
    }
    function toggleImport() {
        setState(st => ({ ...st, showImport: !st.showImport }));
    }
    function updateImportJson(text: string) {
        setState(st => ({ ...st, importJson: text }));
    }
    function onImportJson() {
        const snapshotData = JSON.parse(state.importJson!) as Record<string, unknown>;
        gameState.restoreShapshot(snapshotData);
        setState(st => ({ ...st, showImport: false, showComplete: true }));
    }
    return <>
        <nav className='navbar navbar-dark bg-dark'>
            <div className='nav nav-pills'>
                <NavLink to='/' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
                    <i className='bi bi-chevron-left'></i> Back
                </NavLink>
            </div>
        </nav>
        <div><button className="btn" onClick={toggleExport}>Export</button></div>
        { state.showExport && <textarea readOnly value={state.exportJson}></textarea> }
        <div><button className="btn" onClick={toggleImport}>Import</button></div>
        { state.showImport && <div>
            <div>Insert JSON below:</div>
            <textarea value={state.importJson} onChange={(ev)=>updateImportJson(ev.target.value)}></textarea>
            <div><button onClick={onImportJson}>Import JSON</button></div>
            <div> (Warning: this will overwrite current state)</div>
        </div> }
        { state.showComplete && <Alert variant="success">State have been imported.</Alert>}

    </>
}
