import Arrow, { DIRECTION } from 'react-arrows';
import './Led.css';

export function LedLamp({ state }: {state: boolean }) {
    return (<span className={'led ' + (state ? 'led-on' : 'led-off')}></span>);
}

export function LedConnector({ state, id }: {state: boolean, id: string }) {
    return (<div>
        <LedLamp state={state} />
        <div className='io-connector-pin' id={id}></div>
    </div>);
}

export function LedConnectorConnected({ state, id, targetPin }: {state: boolean, id: string, targetPin: number }) {
    const targetId = `io-pin-${targetPin}`;
    return (<>
        <LedConnector state={state} id={id} />
        <Arrow className="network-arrow"
                from={{
                    direction: DIRECTION.BOTTOM,
                    node: () => document.getElementById(id),
                    translation: [0, 1],
                }}
                to={{
                    direction: DIRECTION.BOTTOM,
                    node: () => document.getElementById(targetId),
                    translation: [1, .3],
                }} head="none" />
    </>);
}
