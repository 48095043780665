export enum HintPosition {
    Left,
    Right,
    Below,
}

export class Hint {
    constructor(readonly key: string, readonly selector: string, readonly position: HintPosition,
        readonly offsetX = 0, readonly offsetY = 0, readonly width = 150) { }
}
