import './Palette.css';
import { TrashComponent } from '../trash/TrashComponent';
import { NodeComponent } from '../node/NodeComponent';
import { createPaletteNode, PaletteComponentInstance } from '../../circuitState';
import { ComponentBuilder } from '../../componentBuilder';
import { Pos } from '../../position';
import { ComponentType } from '../../componentType';
import { DiagramActions } from './DiagramBuilder';

export function Palette(props: {
		model: ComponentBuilder;
        addNode: (nodeType: ComponentType, canvasPos: Pos)=>void;
}) {

    function getPaletteNodes() {
        const paletteOptions = props.model.palette;
        const palette: PaletteComponentInstance[][] = [];
        for (const type of paletteOptions) {
            // create two nodes stacked
            // so we can drag the top one to the canvas (will be returned when dropped)
            palette.push([
                createPaletteNode(type),
                createPaletteNode(type)]);
        }
        return palette;
    }

	const paletteNodes = getPaletteNodes();

    /* create a dummy handler, since palette nodes does not have any abilities except 'addNode' */
    const diagramActions: DiagramActions = {
        addNode: (nodeType: ComponentType, canvasPos: Pos) => {
            props.addNode(nodeType, canvasPos);
        },
        deleteOutputPin: () => {},
        updateOutputPinLabel: () => {},
        deleteInputPin: () => {},
        updateInputPinLabel: () => {},
        connect: () => {},
        clickConnectStart: () => {},
        cancelConnectMode: () => {},
        disconnect: () => {},
        stateChanged: () => {},
        deleteNode: () => {},
        expand: () => {},
        diagramStateChanged: () => {}
    };

	return (<div className='sidebar' id='palette'>
    <div id='temp-nodes'></div>

    <div className='palette-header-box'>
        <div className='palette-header'>Toolbox</div>
    </div>

    <div className='palette'>
        {(paletteNodes).map(nodeSet => (
            <div key={nodeSet[1]!.nodeType.key} className='palette-nodetype'>

            <div className='palette-slot'>
                <NodeComponent node={nodeSet[0]!} isPalette
                    selectedConnector={undefined}
                    diagramActions={diagramActions}
                    />
                <div className='draggable-overlay'>
                    <NodeComponent node={nodeSet[1]!} isPalette
                        selectedConnector={undefined}
                        diagramActions={diagramActions}
                    />
                </div>
            </div>

            <>{(()=>{ switch (nodeSet[1]!.nodeType.displayHint) {
                case 'lamp': return (
                    <div className='palette-label'>lamp</div>);
                case 'button': return (
                    <div className='palette-label'>button</div>);
                case 'wireJunction': return (
                    <div className='palette-label'>junction</div>);
                case 'relay-on': return (
                    <div className='palette-label'>relay (default on)</div>);
                case 'relay-off': return (
                    <div className='palette-label'>relay (default off)</div>);
                case 'splitter': return (
                    <div className='palette-label'>splitter</div>);
                case 'bundler': return (
                    <div className='palette-label'>bundler</div>);
                default: return (<></>);
            }})()} </>
        </div>))}
        </div>


    <div className='trash-panel'>
        <TrashComponent />
    </div>

</div>);
}

