import { CircuitState } from "./circuitState";
import { ComponentInstanceState, NodeStateSnapshot } from "./componentState";
import { InputConnectorState } from "./connectorState";

export class Resolver {
    constructor(readonly circuit: CircuitState) { }

    resolve(): ComponentInstanceState[] {
        const nodes = this.circuit.nodes;

        const changeCount = new Map<ComponentInstanceState, number>();
        nodes.forEach(n => changeCount.set(n, 0));

        // reset
        this.circuit.outputPins.forEach(n => n.oscillating = false);
        nodes.forEach(n => n.clearError());

        // propagate signals (output to input)
        this.propagateSignal();

        // Resolve all nodes
        nodes.forEach(n => n.resolveOutput());

        // Record initial state
        const snapshot = new Map<ComponentInstanceState, NodeStateSnapshot>();
        this.circuit.nodes.forEach(n => snapshot.set(n, n.getState()));

        const maxIterations = 100;
        let iteration = 0;
        while (iteration <= maxIterations) {
            iteration++;

            // propagate signals (output to input)
            this.propagateSignal();

            // Check for changes
            let hasChanges = false;
            for (const node of this.circuit.nodes) {
                const oldState = snapshot.get(node)!;
                node.resolveOutput();
                const newState = node.getState();
                if (!newState.isSame(oldState)) {
                    hasChanges = true;
                    changeCount.set(node, (changeCount.get(node) ?? 0) + 1);
                    snapshot.set(node, newState);
                }
            }
            if (!hasChanges) {
                // circuit is stable
                break;
            }
        }
        return Array.from(changeCount.entries()).filter(([_node, count]) => count >= maxIterations).map(([node, _count]) => node);
    }
    propagateSignal() {
        for (const node of this.circuit.nodes) {
            this.propagateToNode(node);
        }
        for (const out of this.circuit.outputPins) {
            this.propagateToInputConnector(out);
        }
        // output nodes
    }
    propagateToInputConnector(inputConnectorState: InputConnectorState) {
        if (inputConnectorState.connection) {
            const source = this.circuit.findOutputConnector(inputConnectorState.connection.sourceConnector);
            let state = source.state;
            // take low bits if input is narrower than output
            if (state) {
                state = state % (2 ** inputConnectorState.width);
            }
            inputConnectorState.state = state;
        } else {
            inputConnectorState.state = this.circuit.disconnectedState;
        }
    }
    propagateToNode(node: ComponentInstanceState) {
        for (const inputConnectorState of node.inputConnectorStates) {
            this.propagateToInputConnector(inputConnectorState);
        }
    }
}
