import { Device } from "../../assembler/machine";
import { boolArrayToNumber, numberToBoolArray } from "../../common/bits";
import { EventEmitter2 } from "../../common/eventEmitter";

/*
    Protocol: Two binary signals, data and sync
    They change interleaved, never at the same time (to avoid racing conditions)
    Sync changes every cycle, allowin the client to identify a new data bit
    The data protocol:
        Before message starts, data is 0, but sync may change.
        Message starst when data changes to 1. Every sync change hereafter indicate a new data bit.
        After the first 1, 16 data bits are sent.
        The another bit is sent
            if it is 0, this indicate the end of message,
            if 1, indicate another 16 bits of payload will be sent.
*/
export class NetworkDevice implements Device {
    readonly changed = new EventEmitter2<number>();
    inputMask = 0x00FF;
    dataStreamIndex = 0;
    syncFlag = false;
    dataFlag = false;
    state: 'NotStarted' | 'Transmitting' | 'Finished' = 'NotStarted';
    lastChange: 'Data' | 'Sync' = 'Sync';
    machineTickCount = 0;
    poke(_word: number): void {
        // only one-way
    }
    peek(): number {
        return boolArrayToNumber([this.syncFlag, this.dataFlag]);
    }
    tick(): void {
        // clock tick is ignored, since the device have its own clock
    }
    reset(): void {
        this.dataStreamIndex = 0;
        this.state = 'NotStarted';
        this.dataFlag = false;
        this.syncFlag = false;
        this.changed.fire(this.peek());
    }
    machineTick() {
        // callback with a clock-tick from the machine
        this.machineTickCount++;
        if (this.machineTickCount > 200) {
            this.machineTickCount = 0;
            this.step();
        }
    }
    step() {
        if (this.state === 'Finished') {
            this.dataFlag = false;
            this.syncFlag = false;
            return;
        }
        if (this.state === 'NotStarted') {
           // start message
           this.dataStreamIndex = 0;
           this.syncFlag = false;
           this.state = 'Transmitting';
           this.lastChange = 'Data';
        } else {
            if (this.lastChange === 'Data') {
                this.syncFlag = !this.syncFlag;
                this.lastChange = 'Sync';
            } else {
                this.dataStreamIndex += 1;
                if (this.dataStreamIndex >= this.dataStream.length) {
                    this.state = 'Finished';
                    this.dataFlag = false;
                    this.syncFlag = false;
                    return;
                }
                this.lastChange = 'Data';
            }
        }
        this.dataFlag = this.dataStream[this.dataStreamIndex]!;
        this.changed.fire(this.peek());
    }
    payloadWords = [
        0b0001110001110000,
        0b0010001010001000,
        0b0100000100000100,
        0b0100000000000100,
        0b0100000000000100,
        0b0010000000001000,
        0b0001000000010000,
        0b0000100000100000,
        0b0000010001000000,
        0b0000001010000000,
        0b0000000100000000,
    ];
    // start with 1, then 16 bit blocks each followed by a 1, except the last.
    dataStream = this.payloadWords.flatMap(word => [true].concat(numberToBoolArray(word, 16))).concat(false);
}
