import './Verification.css';
import { DiagramVerificationResultSet } from 'diagram/verification';
import { VerificationResultSet, UnitVerificationResultSet, VerificationError, CompositeVerificationResultSet } from '../verificationResults';
import { toDecimalString } from 'common/arithmetics';
import { toHexWord } from 'common/hex';

export function VerificationTable(props: {
		results: DiagramVerificationResultSet}) {

    const table =  props.results;
    let resultList;
    // TODO: create something like takeWhile
    // we take all test up to and including the first error
    const firstErr = table.ioResults.findIndex(r => !r.succeeded);
    if (firstErr === -1) {
        // no errors found.
        // Only show some of the tests
        resultList = table.ioResults.slice(0, 10);
    } else {
        resultList = table.ioResults.slice(0, firstErr + 1);
    }
    // If there is only one input and one output, we don't show the additional row with connector labels.
    const showLabels =  table.inputLabels.length + table.outputLabels.length > 2;
    /** Show a number. If negative or larger than 10, we show both decimal and hex */
    function show(state: number | null) {
        if (state===null) { return ''; }
        if (state >= 0 && state < 10) { return <div className="dec">{toDecimalString(state)}</div> }
        return <div className="dec-hex">{toDecimalString(state)} <span className="hex">(<code>{toHexWord(state)}</code>)</span></div>;
    }
	return (
        <table className='test-results'>
            <colgroup className='input'>
                {(table.inputLabels).map((o, ix) => <col key={ix} />)}
            </colgroup>
            <colgroup className='output'>
                {(table.outputLabels).map((o, ix) => <col key={ix} />)}
            </colgroup>
            <thead>
                <tr>
                    <th colSpan={table.inputLabels.length} className='input'>Input</th>
                    <th colSpan={table.outputLabels.length} className='output'>Output</th>
                </tr>
                { showLabels && (
                    <tr>
                    {(table.inputLabels).map(label => <th key={label}>{label}</th>)}
                    {(table.outputLabels).map(label => <th key={label}>{label}</th>)}
                    <th></th>
                </tr>)}
            </thead>
            <tbody>
                {(resultList).map((result, ix1) => (
                    <>
                    { result.succeeded && (
                        <tr className='ok' key={ix1}>
                            {(result.inputs).map((val, ix) => <td key={ix}>{show(val)}</td>)}
                            {(result.outputs).map((comp, ix) => <td key={ix}>{show(comp.actual)}</td>)}
                            <td>✔</td>
                        </tr>
                    )}
                    { !result.succeeded && (
                        <>
                        <tr className='error' key={ix1}>
                            {(result.inputs).map((val, ix) => (
                                <td key={ix}>{show(val)}</td>))}
                            {(result.outputs).map((comp, ix) => (
                                <td key={ix}>
                                { comp.isSame && (<>{show(comp.actual)}</>)}
                                { !comp.isSame && (
                                    <b style={{color: 'red'}}>{show(comp.actual)}</b> )}
                                </td>))}
                            <td>✗</td>
                        </tr>
                        <tr className='expected' key={ix1}>
                            <th colSpan={table.inputLabels.length} style={{textAlign: 'left', fontStyle: 'italic'}}>Expected:</th>
                            {result.outputs.map((comp, ix) => (
                                <td key={ix}>
                                { comp.isSame && (<>{show(comp.expected)}</>) }
                                { !comp.isSame && <b>{show(comp.expected)}</b> }
                            </td>))}
                            <td></td>
                        </tr>
                    </>)}
                </>))}
            </tbody>
        </table>);
}


export function Verification(props: {results: VerificationResultSet}) {
    let statefulResults;
    /* UnitVerificationResultSet has a single result and CompositeVerificationResultSet has multiple */
    if (props.results instanceof UnitVerificationResultSet) {
        statefulResults = [props.results.result];
    } else if (props.results instanceof DiagramVerificationResultSet) {
        statefulResults = props.results.results;
    } else if (props.results instanceof CompositeVerificationResultSet) {
        statefulResults = props.results.results;
    } else {
        console.error(props.results)
        throw new Error();
    }
    const statefulError =  statefulResults.find(t => !t.succeeded);
    const showStatelessErrors =  statefulError === undefined &&
            props.results instanceof DiagramVerificationResultSet &&
            props.results.ioResults.length > 0;
	const results = props.results;

	return (<>
{ showStatelessErrors && results instanceof DiagramVerificationResultSet && (
    <VerificationTable results={results} />)}
{ statefulError && statefulError instanceof VerificationError  && (
    <div dangerouslySetInnerHTML={{__html: statefulError.errorMessage}}></div>)}
</>);
}

