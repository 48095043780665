
export type Point = { readonly x: number; readonly y: number };

/** A point relative to viewport. Should be short lived, since it will get out of sync with scrolling
 * Defined as a separate type from Point so we don't mix viewport-relative coordinates with canvas-relative */
export type ClientPos = { readonly clientX: number; readonly clientY: number };


export class Pos implements Point {
    constructor(readonly x: number, readonly y: number) { }
    add(x: number, y: number): Pos {
        return new Pos(this.x + x, this.y + y);
    }
    addPos({ x, y }: Point): Pos {
        return new Pos(this.x + x, this.y + y);
    }
    sub(s: Pos): Pos {
        return new Pos(this.x - s.x, this.y - s.y);
    }
    equal(s: Pos): boolean {
        return this.x === s.x && this.y === s.y;
    }
}

export class Area {
    constructor(readonly x: number, readonly y: number, readonly width: number, readonly height: number) { }
    relativeTo(pos: Pos) {
        return new Area(this.x - pos.x, this.y - pos.y, this.width, this.height);
    }
    get right() { return this.x + this.width; }
    get bottom() { return this.y + this.height; }
}

export function getPositionRelativeTo(elem: HTMLElement, refElem: HTMLElement) {
    const elemClientPos = elem.getBoundingClientRect();
    const canvasClientPos = refElem.getBoundingClientRect();
    return new Pos(
        elemClientPos.left - canvasClientPos.left,
        elemClientPos.top - canvasClientPos.top
    );
}
export function getPointRelativeTo(clientPos: ClientPos, refElem: HTMLElement) {
    const canvasClientPos = refElem.getBoundingClientRect();
    return new Pos(
        clientPos.clientX - canvasClientPos.left,
        clientPos.clientY - canvasClientPos.top
    );
}
