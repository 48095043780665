import React, { useState, useContext, useEffect } from 'react';
import './node.css';
import { InputConnectorComponent } from '../connector/InputConnectorComponent';
import { OutputConnectorComponent } from '../connector/OutputConnectorComponent';
import { DiagramRomview } from '../stateview/RomView';
import { AppWordEdit } from '../stateview/word-edit/WordEditor';
import { StateViewComponent } from '../stateview/StateViewComponent';
import { DiagramNodeWireJunction, LedComponent } from './SpecialNodes';
import { ButtonComponent } from './ButtonComponent';
import { LampComponent } from './SpecialNodes';
import { EventPoint, DragDropProvider, DragEventHandler } from '../drag.service';
import { Pos, getPositionRelativeTo, Area } from '../../position';
import { TextContext } from '../../../locale/text.service';
import { IComponentInstanceState } from '../../componentType';
import { ExpanderHelper } from '../../expanderHelper';
import { CanvasContext, CanvasService, DiagramActions } from '../builder/DiagramBuilder';
import { GameStateContext } from '../../../app/gameState';
import { DiagramNodeHelp } from '../tip/NodeHelp';
import { CanvasOverlay } from './CanvasOverlay';
import { ClockComponent } from '../stateview/ClockComponent';
import { DiagramNodeRelayOn, DiagramNodeRelayOff, DiagramNodeRelayDouble } from './Relay';
import { InputConnectorState } from 'diagram/connectorState';
import { ComponentInstanceState } from 'diagram/componentState';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { ComponentError } from 'diagram/componentErrors';

/* The rendered area of a diagram node */
export class NodeLocation {
    constructor(readonly element: HTMLDivElement, readonly canvasService: CanvasService) { }
    get width() { return this.element.offsetWidth; }
    get height() { return this.element.offsetHeight; }
    get canvasPos() {
        return this.canvasService.getRelativePos(this.element);
    }
    get area() {
        const pos = this.canvasPos;
        return new Area(pos.x, pos.y, this.width, this.height);
    }
}

export function findParentDiagramNode(element: Element | null): HTMLDivElement {
    if (!element) {
        throw new Error('Not found')
    } else if (element.classList.contains('diagram-node')) {
        return element as HTMLDivElement;
    } else {
        return findParentDiagramNode(element.parentElement);
    }
}

const componentErrorMessages: Record<ComponentError, string> = {
    'Unstable': 'component_error_output_unstable',
    'ShortCircuit': 'component_error_short_curcuit'
}

export function NodeError(props: { kind: ComponentError }) {
    const textService = useContext(TextContext);
    const message = textService.getText('ui', componentErrorMessages[props.kind]!);
    const popover = (
        <Popover id="popover-basic">
          <Popover.Body>
            { message }
          </Popover.Body>
        </Popover>
      );
    return (
        <div className='error-message'>
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant="warning">⚠</Button>
        </OverlayTrigger>
        </div>
    );
}


export function NodeComponent(props: {
		node: IComponentInstanceState;
		isPalette: boolean;
        selectedConnector: InputConnectorState | undefined;
        move?: (()=>void);
        diagramActions: DiagramActions;
    }) {

	const [state, setState] = useState(()=>{
		return {
			isDragging: false,
            component: undefined as NodeLocation | undefined,
            x: props.node.pos.x,
            y: props.node.pos.y,
        };
	});
    const elementRef = React.createRef<HTMLDivElement>();
    const dragContext = useContext(DragDropProvider);
    const gameStateService = useContext(GameStateContext);
    const expanderHelper = new ExpanderHelper(gameStateService);
    const canvasService = useContext(CanvasContext);

    useEffect(()=>{
        const element = elementRef.current!.firstElementChild! as HTMLDivElement;
        setState({...state, component: new NodeLocation(element, canvasService)});
        return ()=>{};
    }, [])

    const x =  props.node.pos.x;
    const y =  props.node.pos.y;

    const label =  props.node.nodeType.name;
    const stateView =  props.node.internalState.stateView;
    const hasPersistentState =  props.node.nodeType.hasPersistentState;
    const oscillating =  props.node.oscillating;
    const errorKind =  props.node.errorKind;
    const nodeType =  props.node.nodeType.key;
    const nodeDisplay =  props.node.nodeType.displayHint;

    /* mousedown  */

    function mouseDown(event: React.MouseEvent) {
        dragStart(event, event);
    }
    function touchStart(event: React.TouchEvent) {
        if (event.touches.length === 1) {
            const touch = event.touches.item(0)!;
            dragStart(touch, event);
        }
    }

    function paletteStartDrag(nodeElement: HTMLDivElement) {
        const paletteElement = nodeElement.ownerDocument.getElementById('palette')!;
        const pos = getPositionRelativeTo(nodeElement, paletteElement);
        const tempNodes = paletteElement.querySelector('#temp-nodes')!;
        tempNodes.appendChild(nodeElement);
        return pos;
    }

    function dragStart(point: EventPoint, event: React.UIEvent) {
        const current = elementRef.current!;
        const parent = current.parentElement!;
        const initialPos = props.node.pos;
        let startPos = initialPos;
        if (props.isPalette) {
            // move element to outer element to escape scroll area
            const pos = paletteStartDrag(elementRef.current!);
            moveTo(pos);
            startPos = pos;
        }

        const handlers: DragEventHandler = {
            onDragMove: (deltaX, deltaY) => {
                moveTo(startPos.add(deltaX, deltaY));
            },
            onDrop: (targetElement) => {
                // dropped on trashcan
                if (targetElement.classList.contains('trash-area')) {
                    if (props.isPalette) {
                        // return the node to the palette
                        parent.appendChild(current);
                        moveTo(initialPos);
                    } else {
                        // remove node from diagram
                        props.diagramActions.deleteNode(props.node);
                    }
                    return;
                }
                if (!props.isPalette && parent.id==='temp-nodes') {
                    console.error('ERR, not palette but still in temp-nodes');
                }
                if (!props.isPalette) {
                    // for a regular node, it already have the place we want.
                    props.node.moved();
                } else {
                    // find position relative to canvas
                    const canvasPos = canvasService.getRelativePos(current);
                    // return the dragged node to the palette
                    parent.appendChild(current);
                    moveTo(initialPos);
                    // add clone on canvas at the dropped position
                    props.diagramActions.addNode(props.node.nodeType, canvasPos);
                }
                setState({...state, isDragging: false});
            },
            onCancel: () => {
                if (props.isPalette) {
                    // return this node to the palette
                    parent.appendChild(current);
                    moveTo(initialPos);
                } else {
                    // return node to original postion
                    moveTo(startPos);
                }
                setState({...state, isDragging: false});
            },
            hasDropTarget(elem) {
                if (elem.classList.contains('node-droptarget')) {
                    return elem;
                }
                return null;
            },
            onDragEnd: () => {
                state.isDragging = false;
            }
        };
        state.isDragging = true;
        dragContext.startDrag('node', point, event.nativeEvent, handlers);
    }

    function moveTo(pos: Pos) {
        props.node.moveTo(pos);
        setState({...state, x: x, y: y});
        if (props.move) {
            props.move();
        }
    }

    function deleteClick() {
        props.diagramActions.deleteNode(props.node);
    }
    const canExpand =  expanderHelper.canExpand(props.node);
    function expandClick() {
        props.diagramActions.expand(props.node as ComponentInstanceState);
    }

	const node = props.node;
	const isPalette = props.isPalette;
	const isDragging = state.isDragging;

	return (<div ref={elementRef}
        onMouseDown={(ev)=>mouseDown(ev)}
        onTouchStart={(ev)=>touchStart(ev)}
        className={'diagram-node free-node ' + nodeType
            + (isDragging ? ' drag-dragging' : '')
            + (oscillating ? ' oscillating' : '')}
            style={{top: y, left: x}}>

    { errorKind && <NodeError kind={errorKind} />}

    <>{(()=>{ switch (nodeDisplay) {

        case 'lamp': return (
            <LampComponent node={node} />);
        case 'led': return (
            <LedComponent node={node} />);
        case 'button': return (
            <ButtonComponent node={node} isPalette={isPalette}
                diagramStateChanged={props.diagramActions.diagramStateChanged} />);
        case 'wireJunction': return (
            <DiagramNodeWireJunction node={node} />);
        case 'relay-on': return (
            <DiagramNodeRelayOn node={node} />);
        case 'relay-off': return (
            <DiagramNodeRelayOff node={node} />);
        case 'relay-double': return (
            <DiagramNodeRelayDouble node={node} />);
        case 'splitter': return (
            <div className='splitter-base'></div>);
        case 'bundler': return (
            <div className='splitter-base'></div>);


        default: return (
            <div className='node-box'>
            <span className='node-label'>
                {label}
            </span>
            { !isPalette && (
                <>
                {nodeDisplay === 'clock' &&
                    <ClockComponent node={node} diagramStateChanged={props.diagramActions.diagramStateChanged} /> }
                { stateView && (
                    <StateViewComponent stateView={stateView} /> )}
                { hasPersistentState && (
                    <>{(()=>{ switch (nodeDisplay) {

                        case 'const': return (
                            <AppWordEdit node={node as ComponentInstanceState} />);
                        case 'bit': return (
                            <AppWordEdit node={node as ComponentInstanceState} />);
                        case 'rom': return (
                            <DiagramRomview node={node} diagramStateChanged={props.diagramActions.diagramStateChanged} />);
                        default: return <></>;
                    }})()} </>
                )}
            </>)}
        </div>);

    }})()} </>



    <div className='connector-row output-connector-row'>
        {node.outputConnectorStates.map((connector, ix) => (
            <OutputConnectorComponent key={ix}
                selectedConnector={props.selectedConnector}
                isPalette={props.isPalette}
                connector={connector}
                diagramActions={props.diagramActions}
                isFree />))}
    </div>

    <div className='connector-row input-connector-row'>
        {node.inputConnectorStates.map((connector, ix) => (
            <InputConnectorComponent key={ix}
                selectedConnector={props.selectedConnector}
                diagramActions={props.diagramActions}
                isPalette={props.isPalette}
                isFree
                connector={connector as InputConnectorState} />))}
    </div>

    <div className='node-toolbar'>
        <HelpIcon node={props.node} elementRef={elementRef} isDragging={isDragging} />

        { !isPalette && (
            <div className='node-menu btn-group dropright'>
            <button type='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='btn btn-sm btn-secondary dropdown-toggle' style={{lineHeight: '1em', paddingTop: '0'}}>
            </button>
            <div className='dropdown-menu'>
                <button onClick={()=>deleteClick()} className='dropdown-item'>Delete</button>
                { canExpand && (
                    <button onClick={()=>expandClick()} className='dropdown-item'>Replace with parts</button>)}
            </div>
        </div>)}
    </div>
</div>
);
}

function HelpIcon(props: {
    node: IComponentInstanceState;
    isDragging: boolean;
    elementRef: React.RefObject<HTMLDivElement>
}) {
    const textService = useContext(TextContext);
    const [state, setState] = useState({ helpVisible: false });
    function toggleHelp() {
        setState({helpVisible: !state.helpVisible});
    }
    function showHelp() {
        setState({helpVisible: true});
    }
    const helpText =  textService.getText('components', props.node.nodeType.key, 'help');
    function hideHelp() {
        setState({helpVisible: false});
    }
    function helpTouchend(_event: React.TouchEvent) {
        toggleHelp();
    }
    function helpMousedown(event: React.MouseEvent) {
        /* Prevent bubble to prevent a drag-start */
        event.nativeEvent.cancelBubble = true;
    }
    return (<>
            <div className='help-icon'>
            <span onTouchEnd={helpTouchend}
                onMouseDown={helpMousedown}
                onClick={toggleHelp}
                className='info-icon'>i</span>
        </div>

        {state.helpVisible && helpText && !props.isDragging &&
            <CanvasOverlay>
                <DiagramNodeHelp elementRef={props.elementRef} nodeHelp={helpText} close={hideHelp} />
            </CanvasOverlay>}
    </>);
}






