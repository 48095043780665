/* Display an error text. For convenience it allows an undefined message, in which case nothing is rendered */
export function ErrorDisplay(props: { message?: string }) {
    if (!props.message) {
        return null;
    }
    return (
        <div>
            <pre className="error-message">{props.message}</pre>
        </div>
    );
}
