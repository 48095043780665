import { diagram } from './missions';
import { nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, selectorNodeType } from './logicMissions';
import { SequentialTest, Step } from '../sequentialTest';
import { bit } from '../pins';
import { DelegateStateView } from '../stateViews';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';
import { ComponentInstanceState } from 'diagram/componentState';


/* SR Latch*/

class SRLatchState implements ComponentInternalState {
    state = 0;
    stateView = new DelegateStateView(() => this.state, 'Bit');
    resolveOutputs(node: ComponentInstanceState) {
        const set = node.inputConnectorStates[0]!.bitState;
        const reset = node.inputConnectorStates[1]!.bitState;
        if (reset && !set) {
            this.state = 0;
        } else if (!reset && set) {
            this.state = 1;
        }
        return [this.state];
    }
    reset() { this.state = 0; }
}

export const srLatchMission = diagram({
    key: 'SR_LATCH',
    inputPins: [bit('s'), bit('r')],
    outputPins: [bit('')],
    palette: [nandNodeType, andNodeType, orNodeType, invNodeType, xorNodeType],
    tests: [
        // reset, set
        new SequentialTest([
            Step.setPin('s', 1, 'Set <b>s</b>=1. A 1 should be held.'),
            Step.assertOutput('', 1, ''),
            Step.setPin('r', 1, 'Change <b>r</b> to 1. Since both inputs are 1, the previosuly held value (1) should be output.'),
            Step.assertOutput('', 1, ''),
        ]),
        new SequentialTest([
            Step.setPin('r', 1, 'Set <b>r</b>=1 A 0 should be held.'),
            Step.assertOutput('', 0, ''),
            Step.setPin('s', 1, 'Change <b>s</b> to 1. Since both inputs are 1 now, the previosuly held value (0) should be output.'),
            Step.assertOutput('', 0, ''),
        ]),
    ],
    score: { min: 2, nands: 2 },
} as const);

export const srLatchNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'sr latch';
    readonly key = 'SR_LATCH';
    readonly inputs = [bit('s'), bit('r')];
    readonly outputs = [bit()];
    readonly hasInternalState = true;
    readonly depends = depends(srLatchMission);
    readonly createInternalState = () => new SRLatchState();
};


/* D Latch*/

class LatchState implements ComponentInternalState {
    state = 0;
    stateView = new DelegateStateView(() => this.state, 'Bit');
    resolveOutputs(node: ComponentInstanceState) {
        if (node.inputConnectorStates[0]!.bitState) {
            this.state = node.inputConnectorStates[1]!.numState;
        }
        return [this.state];
    }
    reset() { this.state = 0; }
}

export const latchMission = diagram({
    key: 'LATCH',
    inputPins: [bit('st'), bit('d')],
    outputPins: [bit('')],
    palette: [srLatchNodeType, nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, selectorNodeType],
    tests: [
        // store, data
        new SequentialTest([
            Step.setAllowUnstable('d', 1, 'Set <b>d</b>=1.'),
            Step.setPin('st', 1, 'Set <b>st</b>=1 A 1 should be stored and emitted.'),
            Step.assertOutput('', 1, ''),
            Step.setPin('st', 0, 'Change <b>st</b> to 0. Output should not change.'),
            Step.assertOutput('', 1, '1 should still be emitted'),
            Step.setPin('d', 0, 'Change <b>d</b> to 0.  Output should not change.'),
            Step.assertOutput('', 1,
                '1 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0'),
            Step.setPin('st', 1, 'Set <b>st</b> to 1. A 0 should be stored and emitted.'),
            Step.assertOutput('', 0, ''),
        ]),
        new SequentialTest([
            Step.setAllowUnstable('d', 0, 'Set <b>d</b>=0.'),
            Step.setPin('st', 1, 'Set <b>st</b>=1 A 0 should be stored and emitted.'),
            Step.assertOutputs([0]),
            Step.setPin('st', 0, 'Change <b>st</b> to 0. 0 should still be emitted.'),
            Step.assertOutputs([0]),
            Step.setPin('d', 1,
                'Change <b>d</b> to 1. 0 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0.'),
            Step.assertOutputs([0]),
        ]),
        new SequentialTest([
            Step.setAllowUnstable('d', 1, 'Set <b>d</b>=1.'),
            Step.setPin('st', 1, 'Set <b>st</b>=1 A 1 should be stored and emitted.'),
            Step.assertOutput('', 1, '1 should be emitted.'),
            Step.setPin('d', 0, 'Change <b>d</b> to 0. A 0 should be stored and emitted.'),
            Step.assertOutput('', 0, '0 should be emitted'),
            Step.setPin('st', 0, 'Change <b>st</b> to 0.  Output should not change.'),
            Step.assertOutput('', 0, 'Stored 0 should still be emitted.'),
            Step.setPin('d', 1,
                'Change <b>d</b> to 1. 0 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0.'),
            Step.assertOutput('',
                0,
                '0 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0'),
        ]),
    ],
    score: { min: 1, nands: 5 },
} as const);

export const latchNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'd latch';
    readonly key = 'LATCH';
    readonly inputs = [bit('st'), bit('d')];
    readonly outputs = [bit()];
    readonly hasInternalState = true;
    readonly depends = depends(latchMission);
    readonly createInternalState = () => new LatchState();
};
