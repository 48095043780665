import { EventEmitter2 } from "common/eventEmitter";
import { SourceFile } from "ide/sourceFile";

export type CodeMissionPersistence = { code: string, testCode?: string };

interface AssemblyMissionState {
    save(): void;
}

export class MissionSourceFile implements SourceFile {
    onChanged = new EventEmitter2();
    constructor(public text: string, readonly missionState: AssemblyMissionState) {}
    load() {
        return this.text;
    }
    save(src: string) {
        this.text = src;
        this.missionState.save();
        this.onChanged.fire(undefined);
    }
}
