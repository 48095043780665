import { useState } from 'react';
import { AppControls } from '../../engine/controls/ControlsComponent';
import { EngineComponent } from '../../engine/EngineComponent';
import { AssemblerEditorComponent } from '../../ide/editor/EditorComponent';
import { MacroList } from './MacroList';
import { ConstantsComponent } from './Constants';
import { MissionItem } from '../../app/missionProgression';
import { GameState } from '../../app/gameState';
import './StackMissionComponent.css';
import { StackMission } from './stackMission';
import { StackMissionState } from './stackMissionState';
import { ErrorsDisplay } from 'engine/controls/ErrorsDisplay';


function MacroPlacholders(props: { mission: StackMission }) {
    const params = props.mission.macro.placeholders;
    if (params && params.length > 0) {
        const elems = params.map((p, ix) => <code key={ix}>{p.name} {' '}</code>);
        return <div>Placeholders: {elems}</div>;
    } else {
        return <></>;
    }
}

export function StackMissionComponent({missionState, gameState, selectMission}: {
    missionState: StackMissionState;
    gameState: GameState;
    selectMission: (m: MissionItem) => void;
}) {
    const [_state, setState] = useState({});
    missionState.controller.onChanged = () => {
        // Trigger re-render
        setState(st => ({ ...st }));
    }
    function onTick() {
        return missionState.controller.tick();
    }
    function onReset() {
        missionState.controller.reset();
    }
    function onUpdate() {
        setState(st => ({ ...st }));
    }
    const controller = missionState.controller;
    return (
        <div className="stack-ide">
            <div>
                <b>Available macros</b>
                <MacroList history={gameState.missionProgression} selectMission={selectMission}
                    currentMission={gameState.currentMission} />
            </div>
            <div className="ide">
                <div>
                    <div className='macro-header'>
                        <b>Macro name: <code>{missionState.mission.macro.name}</code></b>
                        <MacroPlacholders mission={missionState.mission} />
                    </div>
                    <div style={{ height: '300px' }}>
                        <AssemblerEditorComponent editorBackend={missionState.editorBackend} half={true} />
                        <p>Test code:</p>
                        <AssemblerEditorComponent editorBackend={missionState.testCodeEditorBackend} half={true} />
                    </div>
                </div>
                <div className="machine-column">
                    <div className="machine">
                        <div className="machine-header">
                            <p>
                                <b>computer</b>
                            </p>
                            { controller.errors.length
                                ? <ErrorsDisplay errors={controller.errors} />
                                : <AppControls
                                    onTick={onTick}
                                    onReset={onReset}
                                    isFinished={missionState.controller.isFinished}
                                />
                            }
                        </div>
                        <div className="engine">
                            <EngineComponent machine={missionState.machine} showRam showStack
                                onUpdate={onUpdate}
                                constantsProvider={gameState.missionProgression.sharedConstants} />
                        </div>
                    </div>
                </div>
                <div>
                    <ConstantsComponent constants={gameState.missionProgression.sharedConstants} updated={onUpdate} />
                </div>
            </div>
        </div>
    );
}
