import { GrammarTest, testCodegen } from './grammarTests';
import { UnitVerificationResultSet, VerificationError, VerificationOk, VerificationResultSet } from '../app/verificationResults';
import { MissionState, Task } from '../app/task';
import { MissionProgression } from '../app/missionProgression';
import { Repository } from '../app/repository';
import { StorageService } from 'common/storage.service';
import { CompilerMission } from 'compiler/CompilerMission';
import { GameState } from 'app/gameState';

/*
    For compiler-state missions,
    only the source code (used for testing) is persisted pr mission
    The actual configuration for tokenizer/parser/codegen is persisted globally
*/
export type CodegenMissionPersistence = { source: string };

export abstract class CodegenMissionState implements MissionState {
    abstract tests: GrammarTest[];
    abstract startSymbol: string;
    source;
    constructor(
        readonly mission: Task,
        readonly storage: StorageService,
        readonly game: MissionProgression,
        readonly data?: CodegenMissionPersistence
    ) {
        if (data) {
            this.source = data.source;
        } else {
            this.source = this.getInitialSource();
        }
    }
    isCompleted = false;
    get compilerState() {
        return this.game.sharedCompiler;
    }
    abstract getInitialSource(): string;
    verify1() {
        for (const { text, result: expectedResult } of this.tests) {
            const result = testCodegen(text, this.compilerState, this.startSymbol);
            if (result instanceof VerificationError) {
                return new VerificationError(`Error executing '${text}' ${result.errorMessage} `);
            }
            const actualResult = result.runResult.result;
            if (actualResult !== expectedResult) {
                return new VerificationError(`Expected result for '${text}' to be ${expectedResult} but was ${actualResult} `);
            }
        }
        return new VerificationOk();
    }
    verify(): VerificationResultSet {
        const result = this.verify1();
        this.isCompleted = result.succeeded;
        return new UnitVerificationResultSet(result);
    }
    hasState = false;
    resetState(): void {
        // TODO
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        this.compilerState.store(storage);
        const repository = new Repository(storage);
        repository.saveLevel(this.mission.key, { source: this.source });
    }
    getComponent(gameState: GameState) {
        return (<CompilerMission missionState={this} gameState={gameState} />);
    }
}
