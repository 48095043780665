import { useState, useContext } from 'react';
import './CustomComponentsPage.css';
import { DiagramBuilder } from '../builder/DiagramBuilder';
import { GameStateContext } from '../../../app/gameState';
import { CustomComponentBuilderModel } from '../../customComponentBuilder';
import { UiText } from '../../../locale/UiText';
import { DiagramObserver } from '../../../app/game/diagramHints';

class VoidDiagramObserver implements DiagramObserver {
    ready() { }
    nodeAdded() {}
    connectModeActivated() { }
    connectModeCancelled() { }
    connectionCreated() {}
    connectionDeleted() {}
}


/* The page with all custom components.
    Shows a banner if there is no components. */
export function CustomComponentsPage() {
    const gameState = useContext(GameStateContext);
    const [state, setState] = useState(()=>{
        const components =  gameState.customComponents.customComponentBuilders;
        return {components: components};
    });
    const {components} = state;
    const componentList =  components.sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1);

    function createFirst() {
        const _comp = gameState.customComponents.create();
        setState({components: gameState.customComponents.customComponentBuilders});
    }

    if (componentList.length === 0) {
        return (<div>
        <div className='custom-components start-banner'>
            <div className='alert alert-info'>
                <p>
                    <UiText id='custom_components_start'/>
                </p>
                <button onClick={createFirst} className='btn btn-primary'>Create first component</button>
            </div>
        </div>
    </div>);
    }
    return <MultipleCustom components={componentList} />
}

export function MultipleCustom(props: {components: CustomComponentBuilderModel[]}) {
    const gameState = useContext(GameStateContext);
    const components = props.components;

	const [state, setState] = useState(()=>{
        const comp = props.components[0]!;
		return {
			currentComponent: comp,
            name: comp.name
        };
	});

    function selectComponent(comp: CustomComponentBuilderModel) {
        comp.activate();
        setState({currentComponent: comp, name: comp.name});
    }
    function deleteComponent() {
        if (state.currentComponent) {
            if (!window.confirm('Are you sure you want to delete this component?')) {
                return;
            }
            const ix = components.indexOf(state.currentComponent);
            gameState.customComponents.deleteComponent(state.currentComponent);
            if (components.length > 0) {
                if (components.length === ix) {
                    // deleted last in list - we select the one before
                    selectComponent(components[ix - 1]!);
                } else {
                    selectComponent(components[ix]!);
                }
            }
        }
    }

	const currentComponent = state.currentComponent;

    function create() {
        const comp = gameState.customComponents.create();
        selectComponent(comp);
    }

    function setName(component: CustomComponentBuilderModel, name: string) {
        component.name = name;
        setState({...state});
    }
    function clearCanvas(component: CustomComponentBuilderModel) {
        component.diagram.clearCanvas();
        setState({...state});
    }

	return (
    <div className='custom-components comp'>
    <div></div>

    <div className='card components-panel mx-2'>
        <div className='card-header'>Custom Components:</div>
        <div className='list-group list-group-flush'>
            {components.map(comp => (
                <div key={comp.key}>
                    { comp===currentComponent && (
                        <span className='list-group-item active'>
                        {comp.name}
                    </span>)}
                    { comp!==currentComponent && (
                        <button onClick={()=>selectComponent(comp)} className='list-group-item list-group-item-action'>
                        {comp.name}
                        </button>)}
            </div>))}
        </div>
        <div className='navbar navbar-expand toolbar'>
            <button onClick={create} className='btn btn-secondary'>
                <i className='bi bi-plus-square'></i> Create new component
            </button>
        </div>
    </div>

    <CustomComponentsDesigner
        component={currentComponent} key={currentComponent.key}
        deleteComponent={deleteComponent}
        clearCanvas={clearCanvas}
        setName={setName}
        />

</div>);
}

function CustomComponentsDesigner(props: {
    component: CustomComponentBuilderModel;
    setName: (component: CustomComponentBuilderModel, name: string) => void;
    deleteComponent: (component: CustomComponentBuilderModel) => void;
    clearCanvas: (component: CustomComponentBuilderModel) => void
}) {
    const component = props.component;
    function setName(name: string) {
        props.setName(props.component, name);
    }
    function clearCanvas() {
        props.clearCanvas(props.component);
    }
    function deleteComponent() {
        props.deleteComponent(props.component);
    }
    // dummy listener, since we dont provide any hints yet
    const listener = new VoidDiagramObserver();
    return (<>
    <div className='custom-component tool-header'>
        <div className='navbar navbar-expand toolbar'>
            <div style={{width: '120px'}}></div>
            <span className='navbar-text'>
                Component Name:
            </span>
            <div className='form-inline'>
                <input type='text' value={component.name} onChange={ev=>setName(ev.target.value)} className='form-control' style={{fontWeight: 'bold'}} />
            </div>

            <div className='btn-group ms-2'>
                <button id='btnClear' onClick={clearCanvas} className='btn btn-secondary'>
                    <i className='bi bi-eraser'></i> <UiText id='btn_clear_canvas' />
                </button>
            </div>
            <div className='btn-group ms-2'>
                <button onClick={deleteComponent} className='btn btn-danger'>
                    <i className='bi bi-x-lg'></i> Delete component
                </button>
            </div>

        </div>
    </div>

    <div className='builder'>
        <DiagramBuilder model={props.component} listener={listener} />
    </div>
    </>);
}



