import { MacroAssembler, PlaceholderValues } from '../../assembler/assembler';
import { Controller1 } from '../../assembler/controller';
import { HeadlessEditorBackend } from '../../assembler/assemblerEditorBackend';
import { Machine } from '../../assembler/machine';
import { VerificationError, VerificationOk, VerificationResult } from '../../app/verificationResults';
import { add, sub } from '../../common/arithmetics';
import { ConstantsProvider, InstructionProvider } from '../../assembler/instructionProvider';
import { SourceFile } from 'ide/sourceFile';

export const Consts = {
    SP: 0,
    ARGS: 1,
    LOCALS: 2,
    TEMP: 5,
    RETVAL: 6,
    STACK_START: 0x100,
} as const;

export abstract class CodeTester {
    constructor(readonly macros: InstructionProvider,
        readonly constants: ConstantsProvider,
        readonly placeholders?: PlaceholderValues
    ) {}
    getPlaceholders(): Record<string, number> | undefined {
        return undefined;
    }
    test(source: SourceFile): VerificationResult {
        // (1) Verify syntax of macro source code
        if (this.placeholders) {
            const pl = this.getPlaceholders();
            if (pl) {
                for (const key in pl) {
                    const value = pl[key]!;
                    this.placeholders.set(key, value);
                }
            }
        }
        const placeholders = this.placeholders;
        const assembler = new MacroAssembler(this.macros, this.constants, placeholders);
        const program1 = assembler.assemble(source.load(), source);
        const errors = program1.errors;
        if (errors.length > 0) {
            const err = errors[0]!;
            console.error('assembler errors', program1.errors.length, program1.errors);
            return new VerificationError(`Syntax error in assembler code: ${err.errorText} at line ${err.lineNumber} `);
        }
        const allErrors = program1.getAllErrors();
        if (allErrors.length > 0) {
            return new VerificationError(`Error in assembler code ${allErrors[0]}`);
        }
        if (program1.instructions.length === 0) {
            return new VerificationError('Program does not have any instructions.');
        }

        // (2) Execute test code in sandbox
        const a = new MacroAssembler(this.macros, this.constants, placeholders);
        const provider = new HeadlessEditorBackend();
        provider.updateCode(source.load())
        const machine = new Machine();
        const controller = new Controller1(machine, provider, a);
        const program = controller.builder.program;
        if (program.errors.length > 0) {
            const err = program.errors[0]!;
            return new VerificationError(`Syntax error in test code: ${err.errorText} at line ${err.lineNumber} `);
        }
        if (program.instructions.length === 0) {
            return new VerificationError('Test program does not have any instructions.');
        }


        this.init(machine);
        this.setup(machine);

        // execute 1000 steps or until end of program
        let count = 0;
        const maxTicks = 1000;
        while (machine.pc.get() < program.instructions.length) {
            controller.tick();
            const err = this.onTick(machine);
            if (err) {
                return err;
            }
            count++;
            if (count > maxTicks) {
                return new VerificationError(`Ran ${count} clock cycles without finishing.`);
            }
        }

        return this.verify(machine, program.instructions.length);
    }
    onTick(_machine: Machine): VerificationResult | null {
        // override to inspect state after each tick
        return null;
    }
    init(machine: Machine) {
        // set up stack pointer. Corresponding to INIT macro
        machine.ram.pokeImmediately(0, 0x100);
    }
    push(machine: Machine, val: number) {
        let sp = machine.ram.peek(0);
        machine.ram.pokeImmediately(sp, val);
        sp = add(sp, 1);
        machine.ram.pokeImmediately(0, sp);
    }
    setupStack(machine: Machine, stack: number[]) {
        stack.forEach(val => this.push(machine, val));
    }
    setupMemory(machine: Machine, memory: Record<string, number>) {
        for (const key in memory) {
            const value = memory[key]!;
            machine.ram.pokeImmediately(Number(key), value)
        }
    }
    pop(machine: Machine) {
        let sp = machine.ram.peek(0);
        const val = machine.ram.peek(sp - 1);
        sp = sub(sp, 1);
        machine.ram.pokeImmediately(0, sp);
        return val;
    }
    expectStacTop(machine: Machine, val: number) {
        const top = this.stackTop(machine);
        if (top !== val) {
            return new VerificationError(`Expected the number ${val} to be on the stack (Was ${top.toString(16)}).`);
        }
        return undefined;
    }
    /* stack top is SP - 1 */
    stackTop(machine: Machine) {
        const sp = machine.ram.peek(Consts.SP);
        return machine.ram.peek(sp - 1);
    }
    expectStack(machine: Machine, stack: number[]) {
        let err = this.expectStackSize(machine, stack.length);
        if (err) {
            return err;
        }
        if (stack.length > 0) {
            if (stack.length !== 1) {
                throw new Error('Not supported yet!');
            }
            const expectedTop = stack[0]!;
            err = this.expectStacTop(machine, expectedTop);
            if (err) {
                return err;
            }
        }
        return undefined;
    }
    expectStackSize(machine: Machine, size: number) {
        const expectedSp = Consts.STACK_START + size;
        const sp = machine.ram.peek(Consts.SP);
        if (sp !== expectedSp) {
            return new VerificationError(`Expected SP (Ram address 0) to be hex ${expectedSp.toString(16)}. (Was ${sp.toString(16)})`);
        }
        return undefined;
    }
    expectNamedMemorySlot(machine: Machine, addr: number, expectedVal: number, name: string) {
        const actualVal = machine.ram.peek(addr);
        if (actualVal !== expectedVal) {
            return new VerificationError(
                `Expected ${name} (memory address ${addr}) to have value hex ${expectedVal.toString(16)}. (Was ${actualVal.toString(16)})`
            );
        }
        return undefined;
    }
    expectMemory(machine: Machine, addr: number, expectedVal: number) {
        const actualVal = machine.ram.peek(addr);
        if (actualVal !== expectedVal) {
            return new VerificationError(
                `Expected ram address ${addr} to have value hex ${expectedVal.toString(16)}. (Was ${actualVal.toString(16)})`
            );
        }
        return undefined;
    }
    expectAtEnd(machine: Machine, programLength: number) {
        const pc = machine.pc.get();
        if (pc !== programLength) {
            return new VerificationError(
                `Expected PC to be at the end of the code (${programLength.toString(16)}} but was ${pc.toString(16)}.`
            );
        }
        return undefined;
    }
    abstract setup(machine: Machine): void;
    abstract verify(machine: Machine, programLength: number): VerificationResult;
}

/* Test a macro which only modifies the stack */
export function pureStackTest(stack: number[], stackAfter: number[]) {
    return stateTest({stack: stack}, {stack: stackAfter});
}

interface BeforeState {
    stack?: number[];
    placeholders?: Record<string, number>;
    memory?: Record<number, number>;
}
interface AfterState {
    stack?: number[];
    memory?: Record<number, [number, string]>;
    pc?: number;
}
export function stateTest(before: BeforeState, after: AfterState) {
    return class extends CodeTester {
        setup(machine: Machine) {
            if (before.stack) {
                this.setupStack(machine, before.stack);
            }
            if (before.memory) {
                this.setupMemory(machine, before.memory);
            }
        }
        getPlaceholders() {
            return before.placeholders;
        }
        verify(machine: Machine, programLength: number) {
            if (after.stack) {
                const err = this.expectStack(machine, after.stack);
                if (err) {
                    return err;
                }
            }
            if (after.memory) {
                for (const key in after.memory) {
                    const [value, _hint] = after.memory[key]!;
                    const err = this.expectMemory(machine, Number(key), value);
                    if (err) {
                        return err;
                    }
                }
            }
            if (after.pc === undefined) {
                const err = this.expectAtEnd(machine, programLength);
                if (err) {
                    return err;
                }
            }
            return new VerificationOk();
        }
    };
}
