import { useState } from 'react';
import {toDecimalString, fromDecimalString} from '../../../common/arithmetics';


export function DecimalInput(props: {value: number, update: (value: number) => void}) {
	const [state, setState] = useState(()=>{
		return { value: props.value, decimalString: toDecimalString(props.value), hexError: false};
	});
    if (props.value !== state.value) {
        reset(props.value);
    }
    function reset(value: number) {
        setState({value: value, decimalString: toDecimalString(value), hexError: false});
    }
    function mousedown(ev: React.MouseEvent) {
        // avoid drag start if in component
        ev.stopPropagation();
    }
    function blur() {
        if (state.hexError) {
            reset(props.value);
        }
    }
    function setDecimalState(decimalStr: string) {
        if (!/^-?[0-9]{1,5}$/.test(decimalStr)) {
            setState({...state, decimalString: decimalStr, hexError: true});
        } else {
            const numeric = fromDecimalString(decimalStr);
            // TODO: clamp
            setState({value: numeric, decimalString: decimalStr, hexError: false});
            props.update(numeric);
        }
    }

    return (<div className='input-row'>
            <label>decimal<> </>
                <input type='text' minLength={0} maxLength={6} value={state.decimalString}  pattern='-?[0-9]{0,5}'
                            onChange={(ev)=>setDecimalState(ev.target.value)}
                            onMouseDown={mousedown}
                            onBlur={blur}
                            className={'decimal-input ' + (state.hexError ? 'is-invalid' : '')} />
            </label>
        </div>);
}
