import { StorageService } from '../common/storage.service';

const levelsKey = 'NandGame:Levels';
const levelKey = 'NandGame:Levels:';

export class Repository {
    constructor(readonly storage: StorageService) { }

    getLevelKey(level: string) {
        return levelKey + level;
    }

    getLevels() {
        return this.storage.getItem<string[]>(levelsKey);
    }

    getLevelData<T>(levelKey: string) {
        return this.storage.getItem<T>(this.getLevelKey(levelKey));
    }

    saveLevel(levelKey: string, data: unknown) {
        this.storage.setItem(this.getLevelKey(levelKey), data);
    }

    saveLevels(levels: string[]) {
        this.storage.setItem(levelsKey, levels);
    }
}
