import './MemoryView.css';
import { StateViewComponent } from './StateViewComponent';
import { MemoryStateView } from '../../stateViews';

export function MemoryView(props: {
		stateView: MemoryStateView}) {

	const stateView = props.stateView;

	return (
    <table className='memory-view'>
        <thead>
            <tr><th>Addr</th><th>Content</th></tr>
        </thead>
        <tbody>
        {(stateView.addresses).map((addr, ix) => (
            <tr key={ix} className={'memory-row' + (addr.isAddress ? ' is-address' : '')}>
            <td style={{textAlign: 'right'}}>{addr.address}</td>
            <td>
                <StateViewComponent stateView={addr.value} />
            </td>
        </tr>))}
        </tbody>
    </table>);
}

