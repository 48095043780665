import { useContext } from "react";
import { TextContext } from "./text.service";


export function UiText(props: {id: string}) {
    const textService = useContext(TextContext);
    const text = textService.getText('ui', props.id);
    return <>{text}</> ;
}

export function RichText(props: {id: string}) {
    const textService = useContext(TextContext);
    const html = textService.getText('ui', props.id);
    return <div dangerouslySetInnerHTML={{__html: html}}></div>;
}
