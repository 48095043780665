
import { Machine } from '../assembler/machine';
import { Bit, CodeUnit } from './codeline';
import './MachineCodeComponent.css';

/* Wrapper around the machine */
export class ProgramCounter {
    constructor(private machine: Machine) {}
    get() {
        return this.machine.pc.get();
    }
}

/*
    Used both by RomEditor and by the machine code mission
*/
export function MachinecodeComponent(props: {
    code: CodeUnit;
    programCounter: ProgramCounter;
    toggleBit: (bit: Bit) => void;
}) {
    const labels = [
        { label: '', bits: ['ci'] },
        { label: '-', bits: ['-', '-'] },
        { label: '', bits: ['*'] },
        { label: '-', bits: ['-'] },
        { label: 'operation', bits:['u', 'op1', 'op0', 'zx', 'sw'] },
        { label: 'target', bits:['a', 'd', '*a']},
        { label: 'jump', bits:['lt', 'eq', 'gt']}
    ];
    const code = props.code;
    const currentAddress = props.programCounter.get();
    const isOutsideOfProgram = currentAddress >= code.lines.length;

    const linesNodes = code.lines.map((instr, ix) => {
        const bitNodes = instr.bits.map((bit, ix) =>
            <td key={ix}><span className="bit" tabIndex={1} onKeyPress={()=>props.toggleBit(bit)} onClick={()=>props.toggleBit(bit)}>{bit.bit ? '1' : '0' }</span></td>);
        return (<tr key={ix}>
            <td className="gutter">{instr.address === currentAddress ? <span>🡆</span> : null}</td>
            <td className="addr">{instr.address}</td>
            {bitNodes}
            <td className="val">{instr.value}</td>
            <td className="val hex">{instr.hex}</td>
            <td className="assembler">{instr.text}</td>
        </tr>);});
    const colGroupsNodes = labels.map((group, ix) => {
        const bitsNodes = group.bits.map((bit, ix) => <col key={ix} />);
        return (<colgroup key={ix} style={{borderLeft:'2px solid gray'}}>
                    {bitsNodes}
                </colgroup>)});
     const groupsNodes = labels.map((group, ix) =>
        <th key={ix} colSpan={group.bits.length}>
                    {group.label}
                </th>);
     const labelsNodes = labels.flatMap((group, ix1) =>
        group.bits.map((bit, ix2) => <th key={ix1 * 100 + ix2}>{bit}</th>));
    const outsideNode =  isOutsideOfProgram ?
        <div className="alert alert-warning" style={{marginTop: '1em', width: '600px'}}>
            Program counter is outside of the defined program. All addresses outside the program has the value 0.
            </div> : null;
    return (
        <div>
    <table className="machine-code">
        <colgroup></colgroup>
        <colgroup></colgroup>
        {colGroupsNodes}
        <thead>
            <tr>
                <th rowSpan={3} className="gutter"></th>
                <th rowSpan={3} className="addr">Address</th>
                <th colSpan={16}>Bit flags</th>
                <th rowSpan={3}>Decimal</th>
                <th rowSpan={3}>Hex</th>
                <th rowSpan={3}>Assembler</th>
            </tr>
            <tr>
                {groupsNodes}
            </tr>
            <tr>
                {labelsNodes}
            </tr>
        </thead>
        <tbody>
            {linesNodes}

        </tbody>
    </table>
    {outsideNode}
</div>
    );
}
