import { EventEmitter2 } from 'common/eventEmitter';
import { Device } from 'assembler/machine';
import { useState } from 'react';
import { toHex } from 'common/hex';
import './KeyboardComponent.css';

export class KeyboardDevice implements Device {
    readonly changed = new EventEmitter2<number>();
    inputState = 0;
    inputMask = 0xFFFF;
    poke() { }
    peek() { return this.inputState; }
    tick() { }
    reset() { }
    setState(value: number) {
        if (this.inputState != value) {
            this.inputState = value;
            this.changed.fire(value);
        }
    }
}

interface LetterKey {
    type: 'Letter';
    symbol: string;
}
interface SymbolKey {
    type: 'Symbol';
    symbol: string;
    shiftSymbol: string;
}
interface SpecialKey {
    type: 'Special';
    symbol: string;
    display: string;
    width?: number;
}
interface ShiftKey {
    type: 'Shift';
}
type InputKey = LetterKey | SymbolKey | SpecialKey;
type Key = InputKey | ShiftKey;

export function KeyComponent(props: { kkey: Key,
    onKeyDown: (key: InputKey) => void,
    onKeyUp: (key: InputKey) => void,
    onShiftDown: () => void,
    onShiftUp: () => void,
    currentKey?: string | undefined,
    shift: boolean,
}) {
    const key = props.kkey;
    let className = 'keyboard-key ';
    if (key.type === 'Shift' || key.type === 'Special') {
        className += 'special-key';
    }
    const isSelected = key.type === 'Shift' ? props.shift : (key.symbol === props.currentKey);
    if (isSelected) {
        className += ' selected';
    }
    if (key.type === 'Shift') {
        return (
            <button className={className}
                onMouseDown={() => props.onShiftDown()}
                onMouseUp={() => props.onShiftUp()}
                style={{width: '5em'}}
            >
                <div>&nbsp;</div>
                <div>Shift</div>
            </button>);
    }
    const shiftSymbol = (key.type === 'Symbol') ? key.shiftSymbol : '\u00A0';
    const display = (key.type === 'Special') ? key.display : (key.type === 'Letter') ? key.symbol.toUpperCase() : key.symbol;
    const style = (key.type === 'Special' && key.width) ? {width: `${key.width}em`} : {};
    return (
        <button className={className}
            onMouseDown={() => props.onKeyDown(key)}
            onMouseUp={() => props.onKeyUp(key)}
            style={style}>
            <div>{shiftSymbol}</div>
            <div>{display}</div>
        </button>);
}

export function KeyboardComponent(props: {onChange: (value:number)=>void}) {
    const [state, setState] = useState({ key: null as InputKey | null, shift: false, code: 0, sticky: false });
    function getCode(key: Key | null, shift: boolean) {
        if (!key) {
            return 0;
        } else if (key.type === 'Letter') {
            return (shift ? key.symbol.toUpperCase() : key.symbol).charCodeAt(0);
        } else if (key.type === 'Symbol') {
            return (shift ? key.shiftSymbol : key.symbol).charCodeAt(0);
        } else if (key.type === 'Special') {
            return key.symbol.charCodeAt(0);
        } else {
            return 0;
        }
    }
    const code = getCode(state.key, state.shift);
    props.onChange(state.code);
    function onKeyDown(key: InputKey) {
        setState(st => {
            if (st.sticky && st.key && st.key.symbol === key.symbol) {
                return { ...st, key: null, code: 0 };
            } else {
                return { ...st, key, code: getCode(key, st.shift) };
            }
        });
    }
    function onKeyUp(key: InputKey) {
        setState(st => {
            if (st.key && key.symbol === st.key.symbol && !st.sticky) {
                return { ...st, key: null, code: 0 };
            }
            else {
                return st;
            }
        });

    }
    function onShiftDown() {
        setState(st => {
            if (st.sticky && st.shift) {
                return { ...st, shift: false, code: getCode(st.key, false) }
            } else {
                return { ...st, shift: true, code: getCode(st.key, true) }
            }
        });
    }
    function onShiftUp() {
        setState(st => {
            if (st.sticky) {
                return st;
            } else {
                return { ...st, shift: false, code: getCode(st.key, false) };
            }
        });
    }
    function setSticky(isSticky: boolean) {
        setState({ sticky: isSticky, shift: false, key: null, code: 0 });
    }
    function symbols(row: string, row2: string): Key[] {
        return Array.from(row).map((ch, ix) => ({ type: 'Symbol' as const, symbol: ch, shiftSymbol: row2[ix]! }));
    }
    function letters(row: string): Key[] {
        return Array.from(row).map((ch) => ({ type: 'Letter' as const, symbol: ch }));
    }
    function special(display: string, symbol: string, width: number): Key[] {
        return [{ type: 'Special', display, symbol, width }];
    }
    function shift(_display: string): Key[] {
        return [{ type: 'Shift' }];
    }
    const rows: Key[][] = [
        symbols('1234567890-=', "!@#$#^&*()_+").concat(special('Del', '\u007f', 4)),
        special('tab', '\t', 4).concat(letters('qwertyuiop')).concat(symbols('[]/', '{}|')),
        letters('asdfghjkl').concat(symbols(';\'', ':"')).concat(special('Enter', '\n', 5)),
        shift('Shift').concat(letters('zxcvbnm')).concat(symbols(',./', '<>?')).concat(shift('Shift')),
        special('\u00A0', '\u0020', 16)
    ];
    return (<div className="keyboard"> {
        rows.map((row, ix) => <div key={ix} className="keyboard-row">{
            row.map((key, ix) =>
            <KeyComponent key={ix} kkey={key}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onShiftDown={onShiftDown}
                onShiftUp={onShiftUp}
                shift={state.shift}
                currentKey={state.key?.symbol}
            /> )  }</div>)
    }
    <div className='option'><label><input type="checkbox" className="form-switch" checked={state.sticky} onChange={(ev)=>setSticky(ev.target.checked)} /> Sticky</label></div>
    <div className='spy'>{toHex(code, 2)}</div>
    </div>);
}
