import React from "react";
import './WithHelpBox.css';

export function WithHelpBox({children}: {children: React.ReactNode }) {
    const [state, setState] = React.useState(false);
    if (!state) {
        return <button className='btn helpbox-btn' onClick={()=>setState(!state)}><i className='bi bi-question-circle'></i></button>;
    }
    return (
    <div className="helpbox">
        <button className='btn-close' onClick={()=>setState(!state)}></button>
        {children}
        <div className='helpbox-arrow'></div>
    </div>)
}
