import { ComponentType } from 'diagram/componentType';
import { ComponentInstance, NodeInputConnector, NodeOutputConnector, Connector, CircuitStructure, InputConnector, EdgeOutputConnector, EdgeInputConnector, Connection, OutputConnector } from './circuitStructure';
import { DiagramMissionState } from './diagramMissionState';
import { Pos } from './position';
import { componentTypes } from './missions/nodetypes';
import { isDefined } from '../common/utilities';


type NodeIdMap = Map<ComponentInstance, number>;
type IdNodeMap = Map<number, ComponentInstance>;

export class DiagramSerializer {

    persistDiagram(diag: CircuitStructure) {
        // Assign ID to nodes
        // (we do not need to assign id to output nodes because they are not referenced)

        const freeNodes = diag.nodes;
        const nodeIdTuples = freeNodes.map((node, ix) => [node, ix] as [ComponentInstance, number]);
        const nodeIdMap = new Map(nodeIdTuples);
        const freeNodeInputConnectors = diag.nodes.flatMap(node => node.inputConnectors);
        const inputConnectors = (freeNodeInputConnectors as InputConnector[]).concat(diag.outputNodes);

        return {
            nodes: freeNodes.map(node => this.persistNode(node, nodeIdMap)),
            connections: inputConnectors
                .map(ic => ic.connection)
                .filter(isDefined)
                .map(connection => this.persistConnection(connection, nodeIdMap))
        } as DiagramPersistence;
    }

    persistNode(node: ComponentInstance, nodeIdMap: NodeIdMap) {
        const nodeId = nodeIdMap.get(node);
        if (nodeId === undefined) {
            throw new Error('');
        }
        const state = node.nodeType.getPersistentState(node);
        return {
            type: node.nodeType.key,
            x: node.pos.x,
            y: node.pos.y,
            id: nodeId.toString(),
            state: state
        } as NodePersistence;
    }

    persistEndPoint(connector: Connector, nodeIdMap: NodeIdMap): EndPointPersistence {
        // input or output pin
        if (connector instanceof EdgeOutputConnector) {
            return { nodeId: 'input', connectorId: connector.index.toString() };
        }
        if (connector instanceof EdgeInputConnector) {
            return { nodeId: 'output', connectorId: connector.index.toString() };
        }
        if (connector instanceof NodeInputConnector || connector instanceof NodeOutputConnector) {
            const node = connector.node;
            const endPoint = nodeIdMap.get(node);
            if (endPoint === undefined) {
                throw new Error();
            }
            const nodeId = endPoint.toString();
            return { nodeId: nodeId, connectorId: connector.index.toString() };
        }
        throw new Error();
    }

    persistConnection(connection: Connection, nodeIdMap: NodeIdMap) {
        return {
            source: this.persistEndPoint(connection.sourceConnector, nodeIdMap),
            target: this.persistEndPoint(connection.targetConnector, nodeIdMap),
            // if there are no waypoints, we don't store the property
            points: (connection.waypoints.length > 0 ? connection.waypoints : undefined)
        } as ConnectionPersistence;
    }
}

export class DiagramDeserializer {
    nodeTypeMap = new Map<string, ComponentType>();

    constructor(customNodeTypes: ComponentType[]) {
        this.nodeTypeMap = componentTypes.getNodeTypeMap();
        // key->nodeType for custom components
        for (const nodeType of customNodeTypes) {
            this.nodeTypeMap.set(nodeType.key, nodeType);
        }
    }

    restoreLevel(data: DiagramPersistence, mission: DiagramMissionState) {
        const d = mission.diagram.structure;
        this.restoreDiagram(data, d);
    }

    restoreDiagram(data: DiagramPersistence, diagram: CircuitStructure) {
        try {
            this.restoreDiagram1(data, diagram);
        } catch (e) {
            // While a restore error is bad, we don't want it to take down the whole app.
            console.error('Restore error', e);
        }
    }

    restoreDiagram1(data: DiagramPersistence, diagram: CircuitStructure) {
        const mapIdToNode = new Map<number, ComponentInstance>();
        // First add nodes, and keep a map of ids
        for (const nodeInfo of data.nodes) {
            const nodeType = this.getNodeType(nodeInfo.type);
            // If the node type is not found (e.g. definition removed, we just skip)
            if (nodeType) {
                let persistentState;
                if (nodeType.hasPersistentState) {
                    // defensive in case of currupted storage
                    try {
                        persistentState = nodeType.restorePersistentState(nodeInfo.state);
                    } catch (e) {
                        persistentState = nodeType.initPersistentState();
                        console.error(e);
                    }
                }
                const node = diagram.addNode(nodeType,
                    new Pos(nodeInfo.x, nodeInfo.y), persistentState);
                mapIdToNode.set(parseInt(nodeInfo.id, 10), node);
            }
        }
        // then add connections
        for (const connectionInfo of data.connections) {
            const sourceInfo = connectionInfo.source;
            const sourceConnector = this.getSourceConnector(sourceInfo, mapIdToNode, diagram);
            if (!sourceConnector) {
                /* this should not happen, but happens if a diagram is not saved
                 * after a custom component has a pin removed
                 * */
                console.log(`Source not found: ${connectionInfo.source.nodeId}: ${connectionInfo.source.connectorId} `);
                continue;
            }
            const targetConnector = this.getTargetConnector(connectionInfo.target, mapIdToNode, diagram);
            if (!targetConnector) {
                /* this should not happen, but happens if a diagram is not saved
                 * after a custom component has a pin removed
                 * */
                console.log(`Target not found: ${connectionInfo.target.nodeId}: ${connectionInfo.target.connectorId} `);
                continue;
            }
            targetConnector.createConnection(sourceConnector, connectionInfo.points);
        }
    }

    getSourceConnector(endPoint: EndPointPersistence, idNodeMap: IdNodeMap, diagram: CircuitStructure): OutputConnector | undefined {
        const nodeId = endPoint.nodeId;
        if (nodeId === 'input') {
            return diagram.inputNodes[parseInt(endPoint.connectorId, 10)];
        }
        const node = this.getNode(idNodeMap, nodeId);
        return node.outputConnectors[parseInt(endPoint.connectorId, 10)];
    }

    getTargetConnector(endPoint: EndPointPersistence, idNodeMap: IdNodeMap, diagram: CircuitStructure): InputConnector | undefined {
        const nodeId = endPoint.nodeId;
        if (nodeId === 'output') {
            return diagram.outputNodes[parseInt(endPoint.connectorId, 10)];
        }
        const node = this.getNode(idNodeMap, nodeId);
        return node.inputConnectors[parseInt(endPoint.connectorId, 10)];
    }

    getNode(idNodeMap: IdNodeMap, nodeId: string) {
        const node = idNodeMap.get(parseInt(nodeId, 10));
        if (!node) {
            throw new Error(`Node id ${ nodeId } not found`);
        }
        return node;
    }

    getNodeType(typeName: string) {
        const nodeType = this.nodeTypeMap.get(typeName);
        if (!nodeType) {
            console.error(`Node type ${typeName} not found`);
        }
        return nodeType;
    }
}

export type DiagramPersistence = {
    readonly nodes: NodePersistence[];
    readonly connections: ConnectionPersistence[];
};

type NodePersistence = {
    readonly x: number;
    readonly y: number;
    readonly type: string;
    readonly id: string;
    readonly state?: unknown;
};

type Point = {
    readonly x: number;
    readonly y: number;
};

type ConnectionPersistence = {
    readonly source: EndPointPersistence;
    readonly target: EndPointPersistence;
    readonly points?: Point[];
};

type EndPointPersistence = {
    readonly connectorId: string;
    readonly nodeId: string;
};


