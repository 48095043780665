import { WithPopupWindow } from "../WithPopupWindow";

export function SplashPopup(props: {html: string; title: string, close: ()=>void}) {
    return (
        <WithPopupWindow onClose={props.close} title={props.title} btnOk>
            <div className="content card-body">
                <div className='popup-message'>
                    <div className='content'>
                        <div dangerouslySetInnerHTML={{__html: props.html}}></div>
                    </div>
                </div>
            </div>
        </WithPopupWindow>);
    }
