import { bitArrayToNumber } from '../common/bits';


export const aluFlagsMappingsOrdered: [string, number[]][] = [
    // zx, nx, zy, ny, f, no
    // x&y first because it is all zeroes
    ['X&Y', [0, 0, 0, 0, 0, 0]],
    ['X|Y', [0, 1, 0, 1, 0, 1]],
    ['X+Y', [0, 0, 0, 0, 1, 0]],
    ['X-Y', [0, 1, 0, 0, 1, 1]],
    ['Y-X', [0, 0, 0, 1, 1, 1]],
    ['0', [1, 0, 1, 0, 0, 0]],
    ['1', [1, 1, 1, 1, 1, 1]],
    ['-1', [1, 1, 1, 0, 1, 0]],
    ['X', [0, 0, 1, 1, 0, 0]],
    ['Y', [1, 1, 0, 0, 0, 0]],
    ['~X', [0, 0, 1, 1, 0, 1]],
    ['~Y', [1, 1, 0, 0, 0, 1]],
    ['-X', [0, 0, 1, 1, 1, 1]],
    ['-Y', [1, 1, 0, 0, 1, 1]],
    ['X+1', [0, 1, 1, 1, 1, 1]],
    ['Y+1', [1, 1, 0, 1, 1, 1]],
    ['X-1', [0, 0, 1, 1, 1, 0]],
    ['Y-1', [1, 1, 0, 0, 1, 0]]
];

export const aluFlagsMappings: { [key: string]: number[] } =
    toDict(aluFlagsMappingsOrdered);

/* Used by disassembler. Since multiple bit-configurations may map to the same operation,
    we support more than the 'canonical' mappings
*/
const aluFlagsMappingsOrderedReversed: readonly [number, string][] = [
    // zx, nx, zy, ny, f, no
    [0b001010, 'X'],
    [0b001100, 'X'],
    [0b011011, 'X'],
    [0b011101, 'X'],
    [0b100010, 'Y'],
    [0b100111, 'Y'],
    [0b110000, 'Y'],
    [0b110101, 'Y'],
    [0b000000, 'X&Y'],
    [0b010101, 'X|Y'],
    [0b001011, '~X'],
    [0b001101, '~X'],
    [0b011010, '~X'],
    [0b011100, '~X'],
    [0b100011, '~Y'],
    [0b100110, '~Y'],
    [0b110001, '~Y'],
    [0b110100, '~Y'],
    [0b000010, 'X+Y'],
    [0b010011, 'X-Y'],
    [0b000111, 'Y-X'],
    [0b001000, '0'],
    [0b011000, '0'],
    [0b100000, '0'],
    [0b100100, '0'],
    [0b101000, '0'],
    [0b101010, '0'],
    [0b101100, '0'],
    [0b101111, '0'],
    [0b111000, '0'],
    [0b111011, '0'],
    [0b111101, '0'],
    [0b001001, '-1'],
    [0b011001, '-1'],
    [0b100001, '-1'],
    [0b100101, '-1'],
    [0b101001, '-1'],
    [0b101011, '-1'],
    [0b101101, '-1'],
    [0b101110, '-1'],
    [0b111001, '-1'],
    [0b111010, '-1'],
    [0b111100, '-1'],
    [0b111111, '1'],
    [0b001111, '-X'],
    [0b110011, '-Y'],
    [0b011111, 'X+1'],
    [0b110111, 'Y+1'],
    [0b001110, 'X-1'],
    [0b110010, 'Y-1']
];
export const aluFlagsMappingsReverse: { [key: string]: string } =
    toDict(
        aluFlagsMappingsOrderedReversed
            .map(([bits, op]): [string, string] =>
                [bits.toString(), op]));

export const jumpFlagsMappings: { [key: string]: number[] } = {
    'NULL': [0, 0, 0],
    'JGT': [0, 0, 1],
    'JEQ': [0, 1, 0],
    'JGE': [0, 1, 1],
    'JLT': [1, 0, 0],
    'JNE': [1, 0, 1],
    'JLE': [1, 1, 0],
    'JMP': [1, 1, 1]
};
export const jumpFlagsMappingsReverse =
    invertDict(jumpFlagsMappings, bits => bitArrayToNumber(bits).toString());

// Turns an ordered list of tuples into a dictionary
function toDict<TValue>(tuples: [string, TValue][]) {
    const init: { [key: string]: TValue } = {};
    return tuples.reduce((dict, [key, value]) => { dict[key] = value; return dict; }, init);
}
function invertDict<TValue>(dict: { [key: string]: TValue }, select: ((s: TValue) => string)) {
    const init: { [key: string]: string } = {};
    return Object.keys(dict).reduce((dict1, key) => {
        dict1[select(dict[key]!)] = key; return dict1;
    }, init);
}
