import { boolArrayToNumber, toBitSet } from './bits';

export class BitFields {
    bits: boolean[];
    constructor(value: number) {
        this.bits = toBitSet(value);
    }
    getBit(ix: number) {
        return this.bits[ix]!;
    }
    sliceValue(start: number, end: number) {
        const bits = this.bits.slice(start, end).reverse();
        return boolArrayToNumber(bits);
    }
}
