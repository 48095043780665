import { IInputConnectorState } from './componentType';
import { NodeInputConnector, NodeOutputConnector, PaletteComponentInstance, InputConnector, EdgeOutputConnector, OutputConnector } from './circuitStructure';
import { ComponentInstanceState } from './componentState';
export { PaletteComponentInstance };


// null represent an unknown state, e.g. an unstable circular feedback
export type ConnectorState = number | null;

export class OutputConnectorState {
    state: ConnectorState = 0;
    oscillating = false;

    constructor(readonly connector: OutputConnector) { }

    get numState(): number { return this.state ?? 0; }
    get bitState(): boolean { return this.state === 1; }

    setState(state: number) {
        if (state >= 2 ** this.connector.pin.width) {
            throw new Error(`input ${state} is too large to bus width of ${this.connector.pin.width} bit `);
        }
        this.state = state;
    }

    get name() { return this.connector.name; }
    get width() { return this.connector.width; }
    get index() { return this.connector.index; }
}

export class NodeOutputConnectorState extends OutputConnectorState {
    constructor(readonly connector: NodeOutputConnector, readonly node: ComponentInstanceState) {
        super(connector);
    }
}

export class EdgeOutputConnectorState extends OutputConnectorState {
    constructor(readonly connector: EdgeOutputConnector) {
        super(connector);
    }
    clear() {
        this.setState(this.connector.pin.defaultValue);
    }
}

export let connectorIdCounter = 0;

export class InputConnectorState implements IInputConnectorState {
    state: ConnectorState = 0;
    oscillating = false;
    id = connectorIdCounter++;

    constructor(readonly connector: InputConnector) { }

    get numState(): number { return this.state ?? 0; }
    get bitState(): boolean { return this.state === 1; }

    setState(state: number) {
        if (state >= 2 ** this.connector.pin.width) {
            throw new Error(`input ${state} is too large to bus width of ${this.connector.pin.width} bit `);
        }
        this.state = state;
    }
    createConnection(targetConnector: OutputConnectorState) {
        this.connector.createConnection(targetConnector.connector);
    }
    deleteConnection() { this.connector.deleteConnection(); }

    get name() { return this.connector.name; }
    get width() { return this.connector.width; }
    get index() { return this.connector.index; }
    get connection() { return this.connector.connection; }
}

export class NodeInputConnectorState extends InputConnectorState {
    constructor(connector: NodeInputConnector, readonly node: ComponentInstanceState) {
        super(connector);
    }
}

export class EdgeInputConnectorState extends InputConnectorState { }


