

import { Machine } from '../assembler/machine';
import { EngineComponent } from '../engine/EngineComponent';
import { MachinecodeComponent, ProgramCounter } from '../machinecode/MachineCodeComponent';
import { CodeMissionState } from './machineCodeMission';
import './MachineCodeMissionComponent.css';
import { useState } from 'react';
import { Bit } from '../machinecode/codeline';
import { EventEmitter2 } from 'common/eventEmitter';
import { MachineCodeProvider } from './machineCodeProvider';

export class MachineCodeController {
    currentLineIx: number | undefined;
    currentAddr = 0;
    currentValue = 0;
    // onValueChanged is fired if the value of the currently selected ROM address changes
    // (due to editing the assembler)
    readonly currentValueChanged = new EventEmitter2<number>();
    readonly currentAddressChanged = new EventEmitter2<number>();
    constructor(
        readonly machine: Machine,
        readonly code: MachineCodeProvider,
    ) {
        // this.builder.onParsed = () => { this.update(); }
    }
    tick() {
        this.machine.tick();
        this.update();
        return this.isFinished;
    }
    tickMultiple(count: number) {
        for (let i = 0; i < count; i++) {
            this.tick();
        }
        return this.isFinished;
    }
    update() {
        // fetches value of address and updates current line
        const word = this.fetch(this.machine.pc.get());
        this.machine.resolve(word);
        this.recheckCurrent();
    }
    reset() {
        this.machine.reset();
        this.update();
    }
    get isFinished() {
        return this.machine.pc.get() >= this.code.size;
    }
    // When the engine fetches an instruction.
    // Marks the line as 'current'
    fetch(addr: number) {
        const isChanged = this.currentAddr !== addr;
        this.currentAddr = addr;
        this.refreshCurrentValue();
        if (isChanged) {
            this.currentAddressChanged.fire(this.currentAddr);
        }
        return this.currentValue;
    }
    /* update current value */
    private refreshCurrentValue() {
        if (this.currentAddr >= this.code.size) {
            // clear current
            this.currentValue = 0;
        } else {
            this.currentValue = this.code.fetch(this.currentAddr);
        }
    }
    /* update current value and fire event if it has changed */
    private recheckCurrent() {
        const prevValue = this.currentValue;
        this.refreshCurrentValue();
        if (prevValue !== this.currentValue) {
            this.currentValueChanged.fire(this.currentValue);
        }
    }
}

export function MachineCodeMissionComponent(props: {missionState: CodeMissionState}) {
    const missionState = props.missionState;
    const codeProvider = missionState.codeProvider;

    const [state, setState] = useState(()=>{
        const machine = new Machine();
        const programCounter = new ProgramCounter(machine);
        const controller = new MachineCodeController(machine, codeProvider);
        const code = missionState.code;
        return {
            machine: machine,
            code: code,
            controller: controller,
            programCounter: programCounter
        };
    })
    function onUpdate() {
        setState(st => ({ ...st }));
    }
    function onTick() {
        state.controller.tick();
        onUpdate();
    }
    function onReset() {
        state.controller.reset();
        onUpdate();
    }
    function toggleBit(bit: Bit): void {
        bit.toggle();
        onUpdate();
    }

    return (
        <div className="content machine-code-mission">
            <div className="program">
                <p><b>Instructions in program memory</b></p>
                <MachinecodeComponent toggleBit={toggleBit} code={state.code}
                    programCounter={state.programCounter} />
            </div>

            <div>
                <div className="machine">
                    <div className="machine-header">
                        <p><b>computer</b></p>
                        <div className="btn-group">
                            <button className="btn btn-primary" onClick={onTick}>Clock Tick</button>
                            <button className="btn btn-secondary" onClick={onReset}>Reset</button>
                        </div>
                    </div>
                    <EngineComponent machine={state.machine} showRam={false} onUpdate={onUpdate} />
                </div>
            </div>
        </div>);
}
