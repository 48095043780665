import { SourceUnit, TokenLocation } from "common/location";
import { IToken } from "ide/editor/iEditorBackend";

export enum TokenAction {
    Ignore,
    Token,
    Error,
}

export class TokenDefinition {
    constructor(public pattern: string, public action: TokenAction, public typeName?: string) { }
}

export class Token implements IToken {
    type;
    constructor(public value: string, readonly location: TokenLocation, public kind: TokenDefinition) {
        this.type = kind.typeName ?? value;
    }
    toString() {
        return this.type;
    }
    // TODO - escape linebreak
    get safeDisplayValue() {
        return this.value;
    }
    get lineIndex() {
        return this.location.lineOffset;
    }
    get startCharacterIndex() {
        return this.location.startColumn;
    }
    get endCharacterIndex() {
        return this.location.endColumn;
    }
    isValid = true;
}

export class Scanner {
    regex: RegExp;
    constructor(readonly patterns: TokenDefinition[]) {
        const pattern = this.patterns.map(p => '(' + p.pattern + ')').join('|');
        /*  s = dot matches newline
            m = multiline, $ matches end of each line */
        this.regex = new RegExp(pattern, 'gsm');
    }
    scanLines(code: string, unit: SourceUnit) {
        const lines = code.split(/\r?\n/);
        return lines.map((line, ix) => this.scan(line, ix, unit));
    }
    scan(code: string, lineIndex: number, unit: SourceUnit) {
        const tokens = [];
        this.regex.lastIndex = 0;
        while (this.regex.lastIndex < code.length) {
            const m = this.regex.exec(code);
            if (!m) {
                // should not happen, since we have catch-all subpattern
                throw new Error('No match');
            }
            let kind: TokenDefinition | undefined;
            for (let i = 1; i < m.length; i++) {
                if (m[i] !== undefined) {
                    kind = this.patterns[i - 1];
                    break;
                }
            }
            if (!kind) {
                throw new Error();
            }
            if (kind.action === TokenAction.Ignore) {
                continue;
            }
            const value = m[0];
            const token = new Token(value, new TokenLocation(lineIndex, m.index, value.length, unit), kind);
            tokens.push(token);
            if (kind.action === TokenAction.Error) {
                break;
            }
        }
        return tokens;
    }
}

export class Consumer {
    index = 0;
    constructor(public tokens: Token[]) {}
    consume() {
        this.index++;
        return this.tokens[this.index - 1]!;
    }
    get atEnd() {
        return this.index >= this.tokens.length;
    }
    get next() {
        return this.tokens[this.index]!;
    }
    get last() {
        return this.tokens.at(-1)!;
    }
}

/* Splits a sequence of tokens into sub-lists.
    The splitOn-tokens are discarded.
    No empty lists are generated.  */
export function splitOn(tokens: Token[], splitOnTokenType: string) {
    const segments: Token[][] = [];
    let inSegement = false;
    let segment: Token[] = [];
    for (const token of tokens) {
        if (token.type === splitOnTokenType) {
            if (inSegement) {
                segments.push(segment);
                segment = [];
                inSegement = false;
            }
        } else {
            if (!inSegement) {
                inSegement = true;
            }
            segment.push(token);
        }
    }
    if (inSegement) {
        segments.push(segment);
    }
    return segments;
}
