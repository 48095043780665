import { useState } from 'react';

/* A collapsible area (default collapsed) */
export function UISegment(props: { title: string; children: React.ReactNode; expanded?: boolean }) {
    const [expanded, setExpanded] = useState(props.expanded ?? true);
    const marker = expanded ? '-' : '+';
    const className = expanded ? 'segment-expanded' : 'segment-collapsed';
    return (
        <div className="segment">
            <button className="btn btn-outline-secondary toggle-segment" onClick={() => setExpanded(st => !st)}>
                {marker}
            </button>
            <div className="segment-block">
                <h1 className="segment-title">{props.title}</h1>
                <div className={className}>{props.children}</div>
            </div>
        </div>
    );
}
