import { useState } from 'react';
import './RomEditor.css';
import { Bit, CodeLine, CodeUnit } from '../../../machinecode/codeline';
import { RomProgram, RomStateView } from 'diagram/missions/romNodeType';
import { MemoryState } from '../../stateViews';
import { MachinecodeComponent, ProgramCounter } from '../../../machinecode/MachineCodeComponent';
import { Machine } from '../../../assembler/machine';
import Draggable from 'react-draggable';
import { CanvasOverlay } from '../node/CanvasOverlay';

export function RomEditor(props: {
		stateView: RomStateView;
		closed: (x: boolean) => void}) {

	const [state, setState] = useState(()=>{
        const programCounter = new RomProgramCounter(props.stateView.state);
        const code = new RomCodeUnit(props.stateView.program);
		return {
			code: code,
			programCounter: programCounter};
		});
    function toggle(bit: Bit) {
        bit.toggle();
        setState({...state});
    }
    function mouseDown(ev: React.MouseEvent) {
        // avoid mouse events getting captured by drag-functionality in containing node
        ev.stopPropagation();
    }
    function closeEditor() {
        props.closed(true);
    }
	const code = state.code;
	const programCounter = state.programCounter;

	return (
        <CanvasOverlay>
        <Draggable onStart={ev=>ev.stopPropagation()} cancel='button'>
        <div onMouseDown={mouseDown} className='rom-editor card frame'>
            <div className='card-header'>
                <button type='button' aria-label='Close' onClick={closeEditor} className='close btn-close'></button>
                <div>Edit the data in the ROM-circuit in binary format</div>
            </div>
            <div className='card-text'>
                <MachinecodeComponent code={code} programCounter={programCounter} toggleBit={(bit)=>{toggle(bit)}} />
            </div>
        </div>
        </Draggable>
        </CanvasOverlay>
    );
}


class RomCodeUnit implements CodeUnit {
    lines: CodeLine[];
    constructor(private romProgram: RomProgram) {
        this.lines = romProgram.words.map((v, ix) => new CodeLine(this, v, ix));
    }
    get length() { return this.romProgram.words.length; }
    save(address: number, value: number) {
        this.romProgram.words[address] = value;
        this.romProgram.save();
    }
}

class RomProgramCounter extends ProgramCounter {
    constructor(private readonly state: MemoryState) { super(null as unknown as Machine); }
    get() { return this.state.address; }
}
