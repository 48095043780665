import { useRef } from 'react';
import { editor } from 'monaco-editor';
import Editor, { Monaco } from '@monaco-editor/react';
import { EditorConfigurator, LanguageConfiguration } from './editorConfiguration';
import { AssemblerHelp } from '../help/AssemblerHelp';
import { IEditorBackend } from './iEditorBackend';
import './EditorComponent.css';

export function VmEditorComponent(props: { editorBackend: IEditorBackend }) {
    return (<>
            <EditorComponent editorBackend={props.editorBackend} height="40vh" />
    </>);
}


export function AssemblerEditorComponent(props: { editorBackend: IEditorBackend, half?: boolean }) {
    return (<>
            <div className="quick-reference">
                <AssemblerHelp />
            </div>
            <EditorComponent editorBackend={props.editorBackend} height={props.half ? '40vh' : "80vh"} />
    </>);
}

// TODO: Rename to EditorInstance
export function EditorComponent(props: { editorBackend: IEditorBackend, height: string }) {
    const monacoRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const editorConfigRef = useRef<EditorConfigurator | null>(null);
    // Register backend
    LanguageConfiguration.registerBackend(props.editorBackend);

    if (editorConfigRef.current) {
        if (editorConfigRef.current.backend !== props.editorBackend) {
            editorConfigRef.current.setBackend(props.editorBackend);
        }
    }

    function handleEditorWillMount(monaco: Monaco) {
        // theme need to be defined before editor is created
        console.log('MOUNT INIT MONACO')
        LanguageConfiguration.init(monaco);
    }

    function handleEditorDidMount(editor: editor.IStandaloneCodeEditor, monaco: Monaco) {
        monacoRef.current = editor;
        editorConfigRef.current = new EditorConfigurator(monaco, editor, props.editorBackend);
    }

    return (<Editor
                height={props.height}
                width="500px"
                options={{
                    glyphMargin: true,
                    fontSize: 16,
                    scrollBeyondLastLine: false,
                    // semantic tokens provider is disabled by default
                    'semanticHighlighting.enabled': true,
                    // disabled until better suggestions implemented!
                    acceptSuggestionOnEnter: 'off',
                    quickSuggestions: false,
                }}
                language="NandgameAssembler"
                theme="NandgameAssemblerTheme"
                path={props.editorBackend.path}
                beforeMount={handleEditorWillMount}
                onMount={handleEditorDidMount}
            />);
}
