import { boolToBit } from "../common/bits";
import { EventEmitter2 } from "../common/eventEmitter";


export enum RobotMoveState {
    Moving,
    TurningLeft,
    TurningRight,
    Stopped,
}

export class Robot {
    lampState = false;
    obstacle = false;
    moveState = RobotMoveState.Stopped;
    // robot risk tipping if moving and turning at the same time
    isTipped = false;
    timeoutId?: number;
    // used for testing - no timeouts
    synchronousMode = false;

    readonly stateChanged = new EventEmitter2();
    readonly turnedLeft = new EventEmitter2();
    readonly turnedRight = new EventEmitter2();
    readonly movedStep = new EventEmitter2();

    poke(bitFlags: boolean[]) {
        const lampOff = bitFlags[0];
        const lampOn = bitFlags[1];
        const move = bitFlags[2];
        const left = bitFlags[3];
        const right = bitFlags[4];

        if (lampOn) {
            this.lampState = true;
        }
        if (lampOff) {
            this.lampState = false;
        }

        if (!this.isTipped) {
            if (move && this.isTurning) {
                this.tipOver();
            }
            if (move && this.moveState === RobotMoveState.Stopped) {
                this.startMove();
            }
            if ((left || right) && this.moveState === RobotMoveState.Moving) {
                this.tipOver();
            }
            if (left && this.moveState === RobotMoveState.Stopped) {
                this.startTurn(RobotMoveState.TurningLeft);
            }
            if (right && this.moveState === RobotMoveState.Stopped) {
                this.startTurn(RobotMoveState.TurningRight);
            }
        }

        return this.getState();
    }
    tipOver() {
        this.isTipped = true;
        this.moveState = RobotMoveState.Stopped;
    }
    get isTurning() {
        return this.moveState === RobotMoveState.TurningLeft ||
            this.moveState === RobotMoveState.TurningRight;
    }
    getState() {
        return [
            // output bits - 9-11
            boolToBit(this.isMoving),
            boolToBit(this.isTurning),
            boolToBit(this.obstacle),
            // the 8 low bits are input
            0,0,0,0,0,0,0,0
        ];
    }
    get isMoving() { return this.moveState === RobotMoveState.Moving; }
    startTurn(turn: RobotMoveState) {
        this.moveState = turn;
        this.delayed(() => {
            this.stopTurn();
            this.stateChanged.fire(null);
        }, 3000);
    }
    /* For testing, we can't wait for the callback, so we use synchronousMode  */
    delayed(handler: (() => void), delayMs: number) {
        if (this.synchronousMode) {
            // execute immediately
            handler();
        } else {
            this.timeoutId = window.setTimeout(handler, delayMs);
        }
    }
    stopTurn() {
        const direction = this.moveState;
        this.moveState = RobotMoveState.Stopped;
        this.stateChanged.fire(null);
        if (direction === RobotMoveState.TurningLeft) {
            this.turnedLeft.fire(null);
        } else if (direction === RobotMoveState.TurningRight) {
            this.turnedRight.fire(null);
        }
    }
    startMove() {
        this.moveState = RobotMoveState.Moving;
        this.delayed(() => {
            this.movedStep.fire(null);
            this.stopMove();
        }, 3000);
    }
    stopMove() {
        this.moveState = RobotMoveState.Stopped;
    }
    setObstacle(status: boolean) {
        this.obstacle = status;
        this.stateChanged.fire(null);
    }
    resetState() {
        window.clearTimeout(this.timeoutId);
        this.lampState = false;
        this.isTipped = false;
        this.obstacle = false;
        this.moveState = RobotMoveState.Stopped;
    }
}
