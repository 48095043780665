import { CustomComponentType } from './customComponent';
import { deleteItem } from '../common/utilities';

export class CustomComponents {
    constructor(readonly list: CustomComponentType[]) { }
    delete(componentType: CustomComponentType) {
        deleteItem(this.list, componentType);
    }
    add(node: CustomComponentType) {
        this.list.push(node);
    }
}
