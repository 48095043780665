import { Machine } from '../../assembler/machine';
import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { initStackMission } from './initMission';
import { StackMissionBase } from './stackMission';

class PopATest extends CodeTester {
    setup(machine: Machine) {
        // Setup - value 42 on stack
        this.push(machine, 42);
        // set D - this value should not be affected
        machine.d.setImmediately(13);
    }
    verify(machine: Machine) {
        // assert A is the value which was on the stack
        const a = machine.a.get();
        if (a !== 42) {
            return new VerificationError(`Expected A to be 42. (Was ${a.toString(16)})`);
        }
        const err = this.expectStack(machine, []);
        if (err) {
            return err;
        }
        // assert D is not corrupted
        const d = machine.d.get();
        if (d !== 13) {
            return new VerificationError(`Expected D to be unaffected. (Was changed to ${d.toString(16)})`);
        }

        return new VerificationOk();
    }
}

export const popAMission = new class extends StackMissionBase {
    key = 'STACK_POP_A';
    macro = { name: 'pop.A', lowLevel: true };
    depends = [initStackMission];
    examples = [
        {label: 'before', stack: [{value:'42'}]},
        {label: 'after', registers: [{name: 'A', value: '42'}], stack: []},
    ];
    tests = [PopATest];
    defaultTestCode = `\
init.stack
D = 1
push.D
A = 0x48
D = A
pop.A
# inspect A. Should be 1.
# inspect D - should be 0x42
`
};
