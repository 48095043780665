/** an object which indicate the source "file" of a token. Does not have any intrinsic semantics.   */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SourceUnit {

}

export class DummySourceUnit implements SourceUnit {}


// offsets are 0-based, but line and column numbers used in Monaco editor is 1-based
export class TokenLocation {
    constructor(readonly lineOffset: number, readonly charOffset: number, readonly length: number, readonly unit: SourceUnit) {}
    get lineNumber() {
        return this.lineOffset + 1;
    }
    get startColumn() {
        return this.charOffset + 1;
    }
    get endColumn() {
        return this.startColumn + this.length;
    }
}
