import { EventEmitter2 } from '../common/eventEmitter';
import { resolveControlUnit3 } from 'diagram/missions/controlUnit3mission';
import { nodeTypes } from 'diagram/missions/nodetypes';


export class RamState {
    // Since we only have one RAM write pr cycle, we only have one pending state
    // but we treat it in a generic manner as a collection.
    pendingStates = new Map<number, number>();
    oldState: number[] = [];

    // Just for debug/tracing - the address most recently read or written
    recentAccessed = 0;

    constructor(readonly memoryMapped: MemoryMapping[]) { }

    // inspect without affecting 'recentAccessed'
    peek(addr: number) {
        const mapped = this.getMapping(addr);
        if (mapped) {
            return mapped.device.peek(addr - mapped.from);
        } else {
            return this.oldState[addr] ?? 0;
        }
    }
    get(addr: number) {
        const mapped = this.getMapping(addr);
        if (!mapped) {
            this.recentAccessed = addr;
        }
        return this.peek(addr);
    }
    getMapping(addr:number) {
        return this.memoryMapped.find(mm => mm.from <= addr && mm.to >= addr);
    }
    poke(addr: number, val: number) {
        const mapped = this.getMapping(addr);
        if (mapped) {
            mapped.device.poke(addr - mapped.from, val);
        } else {
            this.pendingStates.set(addr, val);
            this.recentAccessed = addr;
        }
    }
    /* effective immedately, does not require a tick */
    pokeImmediately(addr: number, val: number) {
        const mapped = this.getMapping(addr);
        if (mapped) {
            mapped.device.pokeImmediately(addr - mapped.from, val);
        } else {
            this.oldState[addr] = val;
        }
    }
    tick() {
        if (this.pendingStates.size > 0) {
            this.pendingStates.forEach((val, addr) => {
                this.oldState[addr] = val;
            });
            this.pendingStates.clear();
        }
        for (const map of this.memoryMapped) {
            map.device.tick();
        }
    }
    reset() {
        this.pendingStates.clear();
        this.recentAccessed = 0;
        this.oldState = [];
        for (const map of this.memoryMapped) {
            map.device.reset();
        }
    }
    getRamRegister(address: number) {
        return new RamRegister(this, address);
    }
}

export interface Register {
    get(): number;
    set(val: number): void;
    setCurrent(val: number): void;
    hasPendingChange: boolean;
    nextState: number;
}

/*  Expose a ram address as a register
 */
class RamRegister implements Register {
    constructor(readonly ram: RamState, readonly address: number) { }
    get nextState() { return this.ram.pendingStates.get(this.address) ?? this.get(); }
    get() {
        if (this.ram.peek(this.address) === undefined) {
            throw new Error();
        }
        return this.ram.peek(this.address);
    }
    set(val: number) {
        this.ram.poke(this.address, val);
    }
    setCurrent(val: number) {
        this.ram.pokeImmediately(this.address, val);
    }
    setPending(val: number) {
        this.ram.poke(this.address, val);
    }
    get hasPendingChange() {
        return this.ram.pendingStates.get(this.address) !== undefined;
    }
}

class RegisterState implements Register {
    oldState = 0;
    nextState = 0;

    get() {
        return this.oldState;
    }

    set(val: number) {
        this.nextState = val & 0xFFFF;
    }
    setCurrent(val: number) {
        this.oldState = val & 0xFFFF;
    }
    setPending(val: number) {
        this.nextState = val & 0xFFFF;
    }

    /* used for test setups */
    setImmediately(val: number) {
        this.oldState = val;
        this.nextState = val;
    }
    tick() {
        this.oldState = this.nextState;
    }

    reset() {
        this.oldState = 0;
        this.nextState = 0;
    }

    get hasPendingChange() {
        return this.oldState !== this.nextState;
    }
}

/*  Memory mapped pseudo-register. Connected with some actual IO device.
 *  Output is guarded by clock signal, so the curent output is only passed to the device on tick
 *  Input from the device is immediately reflected.
 *
 *  inputMask defines the bits which are input to the device.
 *  outputMask defines the bits which are outputs from the device.
 *  they must not overlap!
 */
export class IoRegister implements MemoryMappedDevice, Register {
    deviceState = 0;
    // state poked from code. The exposed state this combined with the device state
    currentOutputState = 0;
    nextState = 0;
    changed? : () => void;
    constructor(readonly device: Device) {
        // updates from device immediately updates state
        device.changed.addListener(val => {
            if (this.deviceState != val){
                this.deviceState = val;
                if (this.changed) {
                    this.changed();
                }
            }
        });
        this.deviceState = device.peek();
    }
    pokeImmediately(addr: number, val: number) {
        this.currentOutputState = val;
    }
    setCurrent(val: number) {
        this.currentOutputState = val;
    }
    poke(_addr: number, val: number) {
        this.set(val);
    }
    peek(_addr: number) {
        return this.get();
    }
    get() {
        return this.currentOutputState | this.deviceState;
    }
    getBit(bitNr: number) {
        return (this.get() & (1 << bitNr)) !== 0;
    }

    set(val: number) {
        // low bits are input to device, high bits putput from device.
        // we cannot set high bits from code, so they are masked away
        this.nextState = val & this.device.inputMask;
    }

    tick() {
        this.currentOutputState = this.nextState;
        this.device.poke(this.nextState);
        // IO register does not have any memory
        // so unless anything is written in next cycle, it is 0 again
        this.nextState = 0;
        this.deviceState = this.device.peek();
    }

    reset() {
        this.currentOutputState = 0;
        this.nextState = 0;
    }

    get hasPendingChange() {
        return this.currentOutputState !== this.nextState;
    }
}

export interface MemoryMappedDevice {
    poke(addr: number, word: number): void;
    pokeImmediately(addr: number, word: number): void;
    peek(addr: number): number;
    tick(): void;
    reset(): void;
}

export interface Device {
    readonly changed: EventEmitter2<number>;
    readonly inputMask: number;
    poke(word: number): void;
    peek(): number;
    tick(): void;
    reset(): void;
}

/*
    Default mapping:

    4000-5FFF - screen
    6000 keyboard
    6001 network
*/
export interface MemoryMapping {
    from: number;
    to: number;
    device: MemoryMappedDevice;
}

export class Machine {
    readonly alu = nodeTypes.alu2;
    readonly cond = nodeTypes.condition;

    // registers
    readonly pc = new RegisterState();
    readonly a = new RegisterState();
    readonly d = new RegisterState();
    readonly ram: RamState;

    instruction = 0;

    // pseudo-register
    aluResult = 0;

    constructor(memoryMapped: MemoryMapping[] = []) {
        this.ram = new RamState(memoryMapped);
    }

    resolve(i: number) {
        this.instruction = i;
        const a = this.a.get();
        const d = this.d.get();
        const m = this.ram.get(a);
        const [inpToState, da, dd, dm, j] = resolveControlUnit3(i, a, d, m);
        if (da) {
            this.a.set(inpToState);
        }
        if (dd) {
            this.d.set(inpToState);
        }
        if (dm) {
            this.ram.poke(a, inpToState);
        }
        const pc = j ? a : (this.pc.get() + 1);
        this.pc.set(pc);
    }

    tick() {
        this.pc.tick();
        this.a.tick();
        this.d.tick();
        this.ram.tick();
    }

    reset() {
        this.pc.reset();
        this.a.reset();
        this.d.reset();
        this.ram.reset();
    }
}

