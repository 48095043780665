import { stateTest } from './codeTester';
import { opcodes2Mission } from '../opcodes2';
import { StackMissionBase } from './stackMission';

export const initMissionTests = [
    stateTest({
        memory: {0: 0}
    },
    {
        memory: {0: [0x100, '']}
    })
];

export const initStackMission = new class extends StackMissionBase {
    key = 'STACK_INIT';
    macro = { name: 'init.stack', lowLevel: true };
    examples = undefined;
    depends = [opcodes2Mission];
    tests = initMissionTests;
    // Special case for init:
    showTestToolbox = false;
    defaultTestCode = `
init.stack
`
};
