import { Universe } from './escapeMission';

import './MazeComponent.css';

export function MazeComponent(props: { model: Universe }) {
    const model = props.model;
    const history = model.history;
    const steps = history.map((step, ix) => <div key={ix}>{step}</div>);
    const state = model.isEscaped ? (
        <div className="alert alert-success">The robot has escaped the labyrinth!</div>
    ) : model.robot.isTipped ? (
        <div className="alert alert-danger">The robot has tipped over and cannot move.</div>
    ) : (
        <div className="alert alert-info">
            <p>{model.stateText}</p>
        </div>
    );
    return (
        <div className="maze-outer">
            {steps}
            {state}
        </div>
    );
}
