import { AssemblerProgram } from "assembler/assembler";
import { AsmInstructionSyntax, AsmMacroInvocation, AsmSyntax } from "assembler/syntax";

export class SourceMap {
    addrToInstruction!: Map<number, AsmInstructionSyntax>;
    addrToSyntaxLine!: Map<number, number>;
    constructor(program: AssemblerProgram) {

        this.addrToInstruction = new Map<number, AsmInstructionSyntax>();
        for (const instruction of program.instructions) {
            if (instruction instanceof AsmInstructionSyntax && instruction.address !== undefined) {
                this.addrToInstruction.set(instruction.address, instruction);
            }
        }

        // map instruction addresses to top-level code lines
        // (i.e. multiple adresses would map to the same macro instruction)
        this.addrToSyntaxLine = new Map<number, number>();
        const mapInstructionToLineNr = (lines: AsmSyntax[], lineIx: number) => {
            for (const line of lines) {
                if (line instanceof AsmInstructionSyntax && line.address !== undefined) {
                    this.addrToSyntaxLine.set(line.address, lineIx);
                }
                if (line instanceof AsmMacroInvocation) {
                    mapInstructionToLineNr(line.expansion, lineIx);
                }
            }
        }
        program.lines.forEach((line, lineIx) => {
            mapInstructionToLineNr([line], lineIx);
        });
    }
}
