import { KeyboardDevice } from 'app/KeyboardComponent';
import { Machine, IoRegister, MemoryMapping } from '../assembler/machine';
import { DisplayDevice } from '../engine/devices/DisplayComponent';
import { NetworkDevice } from '../engine/devices/networkDevice';

export type HardwareSetup = ReturnType<typeof hardwareSetup>;

export function hardwareSetup() {
    const mappings: MemoryMapping[] = [];
    // display
    const display = new DisplayDevice();
    mappings.push({from: 0x4000, to: 0x5FFF, device: display});
    // keyboard
    const keyboardDevice = new KeyboardDevice();
    const keyboardRegister = new IoRegister(keyboardDevice);
    mappings.push({from: 0x6000, to: 0x6000, device: keyboardRegister});
    // network device
    const networkDevice = new NetworkDevice();
    const networkRegister = new IoRegister(networkDevice);
    mappings.push({from: 0x6001, to: 0x6001, device: networkRegister});
    const ioRegister = networkRegister;
    const machine = new Machine(mappings);
    return {
        machine,
        display,
        ioRegister,
        networkDevice,
        networkRegister,
        keyboardDevice,
        keyboardRegister,
    };
}
