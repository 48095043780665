import { IComponentInstanceState } from 'diagram/componentType';
import './ClockComponent.css';
import { ClockState } from 'diagram/missions/clockNodeType';
import { useState } from 'react';

export function ClockComponent(props: { node: IComponentInstanceState; diagramStateChanged: ()=>void; }) {
	const clockState = (props.node.internalState as ClockState)

    const [state, setState] = useState(clockState.state);

    function clockTick() {
        clockState.flip();
        setState(() => clockState.state);
        props.diagramStateChanged();
        window.setTimeout(() => {
            clockState.flip();
            setState(() => clockState.state);
            props.diagramStateChanged();
        }, 300);
    }

	return (<div className='input-controls'>
            <button type='button' disabled={state === 2} onClick={()=>clockTick()} className='btn btn-primary'>
                { state === 0 && (
                    <i className='bi bi-heart'></i>)}
                { state === 1 && (
                    <i className='bi bi-heart-fill'></i>)}
                <> </>Pulse
            </button>
        </div>);
}

