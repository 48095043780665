export function toJSON(data: unknown) {
    /** replacer with support for Map */
    function jsonReplacer(name: string, val: unknown) {
        function mapToObject(map: Map<unknown, unknown>) {
            const obj: Record<string, unknown> = {};
            for (const [k, v] of map) {
                if (k !== undefined && k !== null) {
                    const key = k as string | number | boolean | object;
                    obj[key.toString()] = v;
                }
            }
            return obj;
        }
        if (val instanceof Map){
            return mapToObject(val);
        } else {
            return val;
        }
    }
    return JSON.stringify(data, jsonReplacer);
}
