import { Machine, Register } from '../assembler/machine';
import { IdeAddress, IdeRegister, IdeWord } from './RegisterComponent';
import './EngineComponent.css';
import { ConstantsProvider } from '../assembler/instructionProvider';
import { wordToBoolArray } from 'common/bits';
import { LedConnector } from './devices/Led';

export function IOBus({ ioRegister }: { ioRegister: Register }) {
    const bits = wordToBoolArray(ioRegister.get());
    return (<div className='io-bus'>
        <div className='leds'>
            { bits.map((b, ix) =>
                <div key={ix} className="small-led-box">
                    <div>{(15-ix).toString()}</div>
                    <LedConnector state={b} id={`io-pin-${(15-ix).toString()}`} />
                </div>) }
        </div>
    </div>
    );
}

export function EngineComponent({
        machine,
        showMemoryMapped = false,
        showRam = false,
        showCurrent = false,
        showStack = false,
        constantsProvider,
        ioRegister,
        onUpdate,
    }: {
            machine: Machine;
            showMemoryMapped?: boolean;
            showRam?: boolean;
            showCurrent?: boolean;
            showStack?: boolean,
            constantsProvider?: ConstantsProvider,
            ioRegister?: Register,
            onUpdate: ()=>void
        }) {
    function ramWindow(addrs: number[]) {
        return addrs.map(addr => ({
            addr: addr,
            register: machine.ram.getRamRegister(addr),
        }));
    }
    const firstPage = ramWindow([0, 1, 2, 3]);
    const a = machine.ram.recentAccessed;
    const currentPage = ramWindow([0, 1, 2, 3].map(ix => (ix + a) & 0xffff));
    const addrToConst = new Map<number, string>();
    if (constantsProvider) {
        constantsProvider.names.forEach(n => addrToConst.set(constantsProvider.get(n)!, n));
    }

    const firstPageTsx = firstPage.map((mem, ix) => (
        <tr key={ix} className="register">
            <td>{addrToConst.get(mem.addr)}</td>
            <td className="name">
                <IdeAddress address={mem.addr} />
            </td>
            <td>
                <IdeRegister register={mem.register} onUpdate={onUpdate} />
            </td>
        </tr>
    ));
    const currentPageTsx = currentPage.map((mem, ix) => (
        <tr key={ix}>
            <td>{addrToConst.get(mem.addr)}</td>
            <td className="name">
                <IdeAddress address={mem.addr} />
            </td>
            <td>
                <IdeRegister register={mem.register} onUpdate={onUpdate} />
            </td>
        </tr>
    ));

    let ram = <></>;
    if (showRam) {
        ram = (
            <div>
                <h3>Ram:</h3>
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <th>Addr</th>
                            <th>Content</th>
                        </tr>
                    </thead>
                    <tbody>{firstPageTsx}</tbody>
                </table>
                {showCurrent && <>
                <div>Current:</div>
                <table>
                    <tbody>{currentPageTsx}</tbody>
                </table></>}
            </div>
        );
    }

    return (
        <div className="registers">
            <div>
                <h3>Registers</h3>
            </div>
            <div>
                <div className="register">
                    <span className="name">A</span>
                    <span>
                        <IdeRegister register={machine.a} onUpdate={onUpdate} />
                    </span>
                </div>
                <div className="register">
                    <span className="name">D</span>
                    <span>
                        <IdeRegister register={machine.d} onUpdate={onUpdate} />
                    </span>
                </div>
                <div className="register">
                    <span className="name">PC</span>
                    <span>
                        <IdeRegister register={machine.pc} onUpdate={onUpdate} />
                    </span>
                </div>
            </div>
            <div>
                <h3>Internal</h3>
            </div>
            <div>
                <div className="register">
                    <span className="name">Instruction</span>
                    <span>
                        <IdeWord value={machine.instruction} />
                    </span>
                </div>
                <div className="register">
                    <span className="name">ALU</span>
                    <span>
                        <IdeWord value={machine.aluResult} />
                    </span>
                </div>
            </div>
            {ram}
            {showMemoryMapped && ioRegister && <MemoryMapped machine={machine} ioRegister={ioRegister} onUpdate={onUpdate} />}
            {showStack && <StackView machine={machine} onUpdate={onUpdate} />}
            {showMemoryMapped && ioRegister && <IOBus ioRegister={ioRegister} />}
        </div>
    );
}


function StackView({machine, onUpdate}: {machine: Machine; onUpdate: () => void}) {
    const sp = machine.ram.peek(0);
    if (sp === 0) {
        return null;
    }
    if (sp < 0x0100 || sp >= 0x8000) {
        return <i>Stack not valid</i>;
    }
    const size = sp - 0x0100;
    if (size === 0) {
        return <i>Stack is empty</i>;
    }
    if (size < 0) {
        return <i>Invalid SP ({sp})</i>;
    }
    const addrs = Array(size)
        .fill(0)
        .map((_element, index) => 0x0100 + index);
    const stackAddrs = addrs.map(addr => ({
        addr: addr,
        value: machine.ram.getRamRegister(addr),
    }));
    return (
        <div>
            <h3>Stack:</h3>
            <table>
                <tbody>
                    <tr>
                        <th>Addr</th>
                        <th>Content</th>
                    </tr>
                    {stackAddrs.map((mem, ix) =>
                        <tr key={ix}>
                            <td className="name">
                                <IdeAddress address={mem.addr} />
                            </td>
                            <td>
                                <IdeRegister register={mem.value} onUpdate={onUpdate} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

function MemoryMapped({machine, ioRegister, onUpdate}: {machine: Machine; ioRegister: Register; onUpdate: () => void }) {
    const ioAddress = machine.ram.memoryMapped.find(m => m.device as object === ioRegister)!.from;
    return(
        <div>
            <h3>Memory mapped IO:</h3>
            <table>
                <thead>
                    <tr>
                        <th>Addr</th>
                        <th>Content</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="name">
                            <IdeAddress address={ioAddress} />
                        </td>
                        <td>
                            <IdeRegister register={ioRegister} onUpdate={onUpdate} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
